import React, { useState } from 'react';
import { Container, Grid, Paper, TextField, Input, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const CompanyPage = () => {
  const [gst, setGst] = useState('');
  const [certification, setCertification] = useState('');
  const [gstFile, setGstFile] = useState(null);
  const [certificationFile, setCertificationFile] = useState(null);
  const [errors, setErrors] = useState({});

  const handleGstFileChange = (e) => {
    handleFileChange(e, 'gstFile');
  };

  const handleCertificationFileChange = (e) => {
    handleFileChange(e, 'certificationFile');
  };

  const handleFileChange = (e, fileType) => {
    const selectedFile = e.target.files[0];

    // File size limit (2MB)
    const fileSizeLimit = 2 * 1024 * 1024; // 2MB in bytes
    const allowedFileTypes = ['application/pdf'];

    const validationErrors = {};

    if (selectedFile.size > fileSizeLimit || !allowedFileTypes.includes(selectedFile.type)) {
      validationErrors[fileType] = 'Only PDF files are allowed and file size should be below 2MB limit';
    } else {
      validationErrors[fileType] = '';
      if (fileType === 'gstFile') {
        setGstFile(selectedFile);
      } else if (fileType === 'certificationFile') {
        setCertificationFile(selectedFile);
      }
    }

    setErrors({ ...errors, ...validationErrors });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'gst':
        setGst(value);
        break;
      case 'certification':
        setCertification(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform form validation and submission logic here
    const validationErrors = {};
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const formData = new FormData();
      formData.append('gst', gst);
      formData.append('certification', certification);
      formData.append('fileGst', gstFile); // Use different names for different files
      formData.append('fileCertification', certificationFile);

      // Make API call or perform other actions with formData
    }
  };

  return (
    <div>
      <div className='row m-0 align-items-center justify-content-center'>
        <div className='col-lg-7'>
          <form onSubmit={handleSubmit}>
            <Container maxWidth="sm" className="d-flex p-0">
              <Grid container spacing={2} direction="column" justifyContent="center">
                <div style={{ width: 'inherit' }}>
                  <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
                    <h3>Optional Field Form</h3>
                    <Grid container spacing={2} sx={{ border: '1px solid rgba(0,0,0,0.125)', zIndex: '3' }} style={{ padding: '30px' }}>
                      <Grid item xs={12}>
                        <TextField
                          type="text"
                          name="gst"
                          placeholder="GST Certification Number"
                          value={gst}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          type="file"
                          name="fileGst"
                          onChange={handleGstFileChange}
                        />
                        {errors.gstFile && <p style={{ color: 'red' }}>{errors.gstFile}</p>}
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          type="text"
                          name="certification"
                          placeholder="Certification of Incorporation"
                          value={certification}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          type="file"
                          name="fileCertification"
                          onChange={handleCertificationFileChange}
                        />
                        {errors.certificationFile && <p style={{ color: 'red' }}>{errors.certificationFile}</p>}
                      </Grid>
                      <Grid item xs={12}>
                        <Button type="submit" variant="contained" size="medium">
                          <Link to="/Payment">Submit</Link>
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              </Grid>
            </Container>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CompanyPage;
