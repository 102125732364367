import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import { Snackbar, Card } from '@mui/material';
import { fetchRegistrationList, uploadRegistrationList } from '../redux/actions/Actions';
import { useSelector, useDispatch } from 'react-redux';

const TransitionBar = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OnlineClassRegistration = () => {
  const { courseId } = useParams();
  const [open, setOpen] = useState(true);
  const [name1, setName1] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [registrations, setRegistrations] = useState([]);
  const dispatch = useDispatch();

  const fetchRegistration = useSelector((state) => state.fetchRegistration.registrationOnlineclass);
  console.log('fetchRegistration', fetchRegistration);

  // useEffect(() => {
  //   dispatch(fetchRegistrationList());
  // }, [dispatch]);

  const isValidEmail = (email) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.(com|COM)$/.test(email);
  const isValidMobileNumber = (mobileNumber) => /^[0-9]{1,15}$/.test(mobileNumber);
  const isValidName = (name) => /^[A-Za-z._%+-\s]+$/.test(name);

  const handleClickOpenBar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseBar = () => {
    setOpenSnackbar(false);
  };

  const handleMobileNumberInput = (e) => {
    const value = e.target.value;
    // Limit input to 15 characters
    if (value.length <= 15) {
      setMobileNumber(value);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let trimmedValue = value;
    if (name === 'name1' && value.length > 50) {
      trimmedValue = value.slice(0, 50);
    } else if (name === 'recipientEmail' && value.length > 100) {
      trimmedValue = value.slice(0, 100);
    }

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[name];
      return updatedErrors;
    });

    switch (name) {
      case 'name1':
        setName1(trimmedValue);
        break;
      case 'mobileNumber':
        setMobileNumber(value);
        break;
      case 'recipientEmail':
        setRecipientEmail(trimmedValue);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationChecks = [
      { field: 'name1', value: name1, validator: isValidName, errorMsg: 'Enter Valid Name' },
      { field: 'recipientEmail', value: recipientEmail, validator: isValidEmail, errorMsg: 'Enter Valid email ID' },
      { field: 'mobileNumber', value: mobileNumber, validator: isValidMobileNumber, errorMsg: 'Enter Valid Mobile number' },
    ];
    setErrors({});

    const updatedErrors = validationChecks.reduce((acc, { field, value, validator, errorMsg }) => {
      if (!value.trim() || !validator(value)) {
        acc[field] = errorMsg;
      }
      return acc;
    }, {});
    setErrors(updatedErrors);

    // If there are no errors, proceed with form submission
    if (Object.keys(updatedErrors).length === 0) {
      // Handle form submission here
      handleClose();
      handleClickOpenBar(true);
    }
    
      dispatch(uploadRegistrationList(formData));
     
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formData= {name1:name1, recipientEmail:recipientEmail, mobileNumber:mobileNumber };
  console.log('formData', formData);
  useEffect(() => {
    dispatch(uploadRegistrationList(formData));
  }, [dispatch]);

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth TransitionComponent={TransitionBar} >
        <DialogTitle style={{ textAlign: 'left', marginBottom: '1rem', border: '1px solid #ccc', padding: '8px', marginLeft: '1.5rem', marginTop: '1rem', width: '80%', height: '3rem', boxShadow: '0px 0px 10px #3251A3', backgroundColor: 'rgba(206, 238, 244, 0.8)' }}>
          Course Name: <span style={{ fontWeight: 'bold', color: 'rgba(14, 31, 166, 0.8)' }}>{courseId}</span>
        </DialogTitle>
        <IconButton aria-label="close" onClick={handleClose} style={{ position: 'absolute', top: '8px', right: '8px', color: 'rgba(14, 31, 166, 0.8)' }}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom></Typography>
          <div style={{ marginTop: '1rem' }}>
            <div style={{ fontSize: '1.5rem', fontFamily: 'sans-serif', marginLeft: 'auto' }}>Enter Your Name</div>
            <TextField style={{ width: '90%', marginLeft: '1rem' }} name="name1" value={name1} onChange={handleChange} variant="outlined" label="Full Name" />
            {errors.name1 && <div style={{ color: 'red', fontSize: '0.8rem', marginLeft: '1rem' }}>{errors.name1}</div>}
          </div>
          <div style={{ marginTop: '1rem' }}>
            <div style={{ fontSize: '1.5rem', fontFamily: 'sans-serif', marginLeft: 'auto' }}>Enter Your Email ID</div>
            <TextField style={{ width: '90%', marginLeft: '1rem' }} name="recipientEmail" value={recipientEmail} onChange={handleChange} variant="outlined" label="Email ID" />
            {errors.recipientEmail && <div style={{ color: 'red', fontSize: '0.8rem', marginLeft: '1rem' }}>{errors.recipientEmail}</div>}
          </div>
          <div style={{ marginTop: '1rem' }}>
            <div style={{ fontSize: '1.5rem', fontFamily: 'sans-serif', marginLeft: 'auto' }}>Enter Your Mobile Number</div>
            <TextField
              style={{ width: '90%', marginLeft: '1rem' }}
              name="mobileNumber"
              value={mobileNumber}
              onChange={handleMobileNumberInput}
              variant="outlined"
              label="Mobile Number"
            />
            {errors.mobileNumber && <div style={{ color: 'red', fontSize: '0.8rem', marginLeft: '1rem' }}>{errors.mobileNumber}</div>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Register
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseBar}
        message="Registered Successfully!"
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseBar}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
<Grid container spacing={2} justifyContent="center">
  {Array.isArray(fetchRegistration) && fetchRegistration.map((registration) => (
    <Grid item key={registration.id}>
      <Card registration={registration} />
    </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default OnlineClassRegistration;
