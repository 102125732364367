import { ActionTypes } from "../constants/action-types";

const initialState = {
  instructorById: {},
};

const FetchInstructorById = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_INSTRUCTOR_BY_ID:
      return { ...state, instructorById: payload };
    default:
      return state;
  }
};

export default FetchInstructorById;
