import { ActionTypes } from "../constants/action-types"

const initialState = {
    CompanyNameByCompanyName: []
}

const FetchCompanyNameByCompanyName = (state=initialState, {type, payload})=>{
    switch(type){
        case ActionTypes.FETCH_COMPANY_NAME_BY_COMPANY_NAME : 
        return {...state, 
            CompanyNameByCompanyName: payload}           
        default: return state;
    }
    
}


export default FetchCompanyNameByCompanyName;