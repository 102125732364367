import React from 'react'

const AddPromotionForm = () => {
  return (
    <>
      <h2>Upload Promotion</h2>
    </>
  )
}

export default AddPromotionForm
