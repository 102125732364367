import { ActionTypes } from "../constants/action-types"
import axiosinstance from '../../../api/axiosinstance'
import axios from "axios"
import securedInstance from "../../../api/securedInstance"
import { toast } from "react-toastify"

/* GET ALL PROGRAMS */
export const fetchProgramList = () => async (dispatch) => {
    try {
      const response = await axiosinstance.get('/api/program');
  
      if (response.status === 200) {
        const listData = response.data;
        dispatch({
          type: ActionTypes.FETCH_PROGRAMS,
          payload: listData,
        });
      } else {
        // Handle non-200 status codes here, if necessary.
        console.error('Unexpected status code:', response.status);
      }
    } catch (error) {
      // Handle network errors or other exceptions here.
      console.error('Error while fetching programs:', error);
    }
  };

// Get Testimonials
export const fetchTestimonials = () => async (dispatch) => {
    try {
      const response = await axiosinstance.get('/api/testimonials');
  
      if (response.status === 200) {
        const testimonials = response.data;
        dispatch({
          type: ActionTypes.FETCH_TESTIMONIALS,
          payload: testimonials,
        });
      } else {
        // Handle non-200 status codes here, if necessary.
        console.error('Unexpected status code:', response.status);
      }
    } catch (error) {
      // Handle network errors or other exceptions here.
      console.error('Error while fetching testimonials:', error);
    }
  };

/* GET PROGRAM BY ID */
export const fetchProgramId = (id) => async (dispatch) => {
    try {
      const response = await axiosinstance.get(`/api/program/${id}`);
  
      if (response.status === 200) {
        const listData = response.data;
        // Dispatch the data to the Redux store
        dispatch({
          type: ActionTypes.FETCH_PROGRAM_ID,
          payload: listData,
        });
      } else {
        // Handle non-200 status codes here and log errors to the console
        console.error('Unexpected status code:', response.status);
      }
    } catch (error) {
      // Handle network errors or other exceptions here and log them to the console
      console.error('Error while fetching program by ID:', error);
    }
  };

/* GET PROGRAM BY ID */
export const fetchSubProgramId = (id) => async (dispatch) => {
  try {
    const response = await axiosinstance.get(`/api/subprogram/programid/${id}`);

    if (response.status === 200) {
      const listData = response.data;
      console.log("subprograms selected", listData);

      dispatch({
        type: ActionTypes.FETCH_SUBPROGRAM_ID,
        payload: listData,
      });
    } else {
      // Handle non-200 status codes here and log errors to the console
      console.error('Unexpected status code:', response.status);
    }
  } catch (error) {
    // Handle network errors or other exceptions here and log them to the console
    console.error('Error while fetching subprogram by ID:', error);
  }
};

/* GET PROGRAM BY ID from programCourseLinking */
export const fetchProgramswithCourses = () => async (dispatch) => {
    try {
      const response = await axiosinstance.get(`/api/category/all/`);
  
      if (response.status === 200) {
        const listData = response.data;
        // Dispatch the data to the Redux store
        dispatch({
          type: ActionTypes.FETCH_PROGRAMS_WITH_COURSE,
          payload: listData,
        });
      } else {
        // Handle non-200 status codes here and log errors to the console
        console.error('Unexpected status code:', response.status);
      }
    } catch (error) {
      // Handle network errors or other exceptions here and log them to the console
      console.error('Error while fetching programs with courses:', error);
    }
  };

  //upload Course Image
export const uploadCourseImage = (newProgramImg) => async (dispatch) => {
  
  try {
      const response = await securedInstance.post('/api/CourseImgAWS', newProgramImg)
      const imgData = response.data.message;      
      dispatch({
          type:'UPLOAD_COURSE_IMG',
          payload: imgData
      }) 
  } catch (error) {
      console.error('Error adding program img:', error)
  }
};

  export const fetchProgramById = (id) => async (dispatch) => {
    try {
      const response = await axiosinstance.get(`/api/category/all/${id}`);
  console.log(response.data,"action");
      // const response = await axiosinstance.get(`/api/category/all/${programId}`);
  
      if (response.status === 200) {
        const listData = response.data;
        // Dispatch the data to the Redux store
        dispatch({
          type: ActionTypes.FETCH_PROGRAM_BY_ID,
          payload: listData,
        });
      } else {
        // Handle non-200 status codes here and log errors to the console
        console.error('Unexpected status code:', response.status);
      }
    } catch (error) {
      // Handle network errors or other exceptions here and log them to the console
      console.error('Error while fetching program by ID:', error);
    }
  };

  export const fetchProgramCourseImageById = (id,courseInterestedId) => async (dispatch) => {
    try {
      const response = await axiosinstance.get(`/api/category/all/${id}/${courseInterestedId}`);
  console.log(response.data,"action");
      if (response.status === 200) {
        const listData = response.data;
        // Dispatch the data to the Redux store
        dispatch({
          type: ActionTypes.FETCH_PROGRAM_COURSE_BY_ID,
          payload: listData,
        });
      } else {
        // Handle non-200 status codes here and log errors to the console
        console.error('Unexpected status code:', response.status);
      }
    } catch (error) {
      // Handle network errors or other exceptions here and log them to the console
      console.error('Error while fetching program by ID:', error);
    }
  };

/* GET ALL COURSES BELONGS TO PROGRAM */
export const fetchCourseList = () => async (dispatch) => {
    try {
      const response = await axiosinstance.get('/api/courses');
  
      if (response.status === 200) {
        const listData = response.data;
        // Dispatch the data to the Redux store
        dispatch({
          type: ActionTypes.FETCH_COURSES,
          payload: listData,
        });
      } else {
        // Handle non-200 status codes here and log errors to the console
        console.error('Unexpected status code:', response.status);
      }
    } catch (error) {
      // Handle network errors or other exceptions here and log them to the console
      console.error('Error while fetching courses:', error);
    }
  };

  
/* GET INDIVIDUAL COURSE DETAILS BY ID */

export const fetchCoursesById = (id) => async (dispatch) => {
    try {
      const response = await axiosinstance.get(`/api/courses/${id}`);
  
      if (response.status === 200) {
        const listData = response.data;
        // Dispatch the data to the Redux store
        dispatch({
          type: ActionTypes.FETCH_COURSE_BY_ID,
          payload: listData,
        });
      } else {
        // Handle non-200 status codes here and log errors to the console
        console.error('Unexpected status code:', response.status);
      }
    } catch (error) {
      // Handle network errors or other exceptions here and log them to the console
      console.error('Error while fetching course by ID:', error);
    }
  };
  
/* GET PORTION DOWNLOAD BY SENDING EMAIL ID */
export const getEmailId = (userData) => async (dispatch) => {
    try {
      const res = await axiosinstance.post('/api/download', userData);
      dispatch({
        type: ActionTypes.GET_MAIL_DOWNLOAD,
        payload: res.data,
      });
    } catch (error) {
      console.log(error)
    }
  };

  /* GET VISITORS */
  export const getVisitors = () =>async (dispatch)=>{
   
   try{
    const response = await securedInstance.get('/api/visitor/visitorRoleProgram' )
    .then((res)=>{   
        const listData = res.data
        const sortedData = listData.sort((a,b)=> b.user_id - a.user_id)
       //console.log("programs loaded", sortedData)
        return sortedData;
    })
    dispatch({
        type: ActionTypes.GET_VISITORS,
        payload: response
    })
   }  catch(err){
    console.log(err)
   }
    
}

export const getVisitorById = (id) => async (dispatch) => {
    try {
      const response = await securedInstance.get(`/api/visitor/${id}`);
      const listData = response.data;
      dispatch({
        type: ActionTypes.GET_VISITORS_BY_ID,
        payload: listData,
      });
    } catch (error) {
      // Handle the error here (e.g., dispatch an error action or log the error)
      console.error("An error occurred while fetching visitor data:", error);
    }
  };
  
  export const getVisitorByStatus = (status) => async (dispatch) => {
    try {
      const response = await securedInstance.get(`/api/visitor/visitorByStatus/${status}`);
      const listData = response.data;
      dispatch({
        type: ActionTypes.GET_VISITORS_BY_STATUS,
        payload: listData,
      });
    } catch (error) {
      // Handle the error here, you can log it or dispatch an error action
      console.error("Error in getVisitorByStatus:", error);
      // You can dispatch an error action if needed:
      // dispatch({
      //   type: ActionTypes.GET_VISITORS_BY_STATUS_ERROR,
      //   payload: error.message,
      // });
    }
  };
  
//Update Visitor status
export const updateVisitorStatusById = (updatedStatus, id) => async (dispatch) => {
    
    try {
        const response = await securedInstance.put(`/api/visitor/${id}/status`, updatedStatus)
        .then((res)=>{   
            const listData = res.data
          // console.log("user loaded", listData)
            return listData;
        })
        dispatch({
            type: ActionTypes.UPDATE_STATUS,
            payload: response
        }) 
    } catch (error) {
      console.log(error)
    }
  };

  /* GET STATUS lIST */
 export const getStatusList = () => async (dispatch) => {
  try {
    const response = await axiosinstance.get('/api/visitor/statusList');
    const listData = response.data;
    dispatch({
      type: ActionTypes.GET_STATUS_LIST,
      payload: listData,
    });
  } catch (error) {
    // Handle the error, you can log it or dispatch an error action, for example:
    console.error("An error occurred while fetching status list:", error);
    // You might want to dispatch an error action as well
    dispatch({
      type: ActionTypes.ERROR_OCCURRED,
      payload: error.message, // or other error details
    });
  }
};

  /* GET ROLES */
  export const getRoles = () => async (dispatch) => {
    try {
        const response = await axiosinstance.get('/api/role');
        const listData = response.data;
        // console.log("roles loaded", listData)
        dispatch({
            type: ActionTypes.GET_ROLES,
            payload: listData
        });
    } catch (error) {
        // Handle the error here
        console.error("An error occurred while fetching roles:", error);
        // You can also dispatch an action to handle the error in your Redux store if needed.
    }
}

/* GET All Users */
export const getUsersList = () => async (dispatch) => {
    try {
        const response = await securedInstance.get('/api/allUsers/userRoleProgram');
        const listData = response.data;
        const sortedData = listData.sort((a, b) => b.user_id - a.user_id);

        dispatch({
            type: ActionTypes.GET_ALL_USERS,
            payload: sortedData,
        });
    } catch (error) {
        // Handle the error here, e.g., log it or dispatch an error action
        console.error("Error in getUsersList:", error);
        // You can also dispatch an error action if needed
        // dispatch({
        //     type: ActionTypes.GET_ALL_USERS_ERROR,
        //     payload: error.message,
        // });
    }
}


//get user by id
export const getUserById = (id) => async (dispatch) => {
    try {
      const response = await securedInstance.get(`/api/allUsers/${id}`);
      const listData = response.data;
      dispatch({
        type: ActionTypes.GET_USER_BY_ID,
        payload: listData,
      });
    } catch (error) {
      // Handle the error here, e.g., dispatch an error action or log the error
      console.error("Error fetching user by ID:", error);
    }
  };
  

//get user by status
export const fetchUserByStatus = (status) => async (dispatch) => {
    try {
      const response = await securedInstance.get(`/api/allusers/userByStatus/${status}`);
      const listData = response.data;
  
      dispatch({
        type: ActionTypes.GET_USER_BY_STATUS,
        payload: listData,
      });
    } catch (error) {
      // Handle the error here, e.g., log it or dispatch an action for error handling.
      console.error("An error occurred:", error);
    }
  };
  

/* update user */
export const updateUserById = (updatedUserData, id) => async (dispatch) => {
    
    try {
        const response = await securedInstance.put(`/api/allUsers/${id}`, updatedUserData)
        .then((res)=>{   
            const listData = res.data
          // console.log("user loaded", listData)
            return listData;
        })
        dispatch({
            type: ActionTypes.UPDATE_USER,
            payload: response
        }) 
    } catch (error) {
      console.log(error)
    }
  };

/* update user */
export const updateUserStatusById = (updatedStatus, id) => async (dispatch) => {
    
    try {
        const response = await securedInstance.put(`/api/allUsers/${id}`, updatedStatus)
        .then((res)=>{   
            const listData = res.data
          // console.log("user loaded", listData)
            return listData;
        })
        dispatch({
            type: ActionTypes.UPDATE_STATUS,
            payload: response
        }) 
    } catch (error) {
      console.log(error)
    }
  };

  /* delete User by id */
  export const deleteUserById = (id) => async (dispatch) => {    
    try {
        const response = await securedInstance.delete(`/api/allUsers/${id}`)
        .then((res)=>{
           console.log(`User deleted with Id:${id}`)            
        })
        dispatch({
            type: ActionTypes.DELETE_USER,
            payload: response
        }) 
    } catch (error) {
      console.log(error)
    }
  };

//Upload new program
export const uploadNewProgram = (programData) => { 
  return async (dispatch) => {
    try {
    const response = await securedInstance.post('/api/program/', programData);
    const programId = response.data.program_id;
    console.log(programId,'program id');
    dispatch({ type: 'UPLOAD_PROGRAM_SUCCESS', payload: programId });
    return response;
  } catch (error) {
    console.error("Error creating program with image:", error);
    dispatch({ type: 'UPLOAD_PROGRAM_FAILURE' });
  }

};
};
//update  program
export const updateProgramById = (updatedProgramData, id) => async (dispatch) => {
    
    try {
        const response = await securedInstance.put(`/api/program/${id}`, updatedProgramData)
        .then((res)=>{   
            const listData = res.data
          // console.log("programs loaded", listData)
            return listData;
        })
        dispatch({
            type: ActionTypes.EDIT_PROGRAM,
            payload: response
        }) 
    } catch (error) {
      console.log(error)
    }
  };
//delete a program
export const deleteProgramById = (id) => async (dispatch) => {    
  try {
      const response = await securedInstance.delete(`/api/program/${id}`);
      
      // Assuming response.data contains the data returned from the server
      const data = response.data;

      console.log(`Program deleted with Id:${id}`);
      
      dispatch({
          type: ActionTypes.DELETE_PROGRAM,
          payload: { status: response.status, data },
      });
      
      return { status: response.status, data };
  } catch (error) {
      // Handle errors properly
      console.log(error);

      if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('Error response from server:', error.response.data);
          console.log('Status code:', error.response.status);
          
          return { status: error.response.status, error: error.response.data };
      } else if (error.request) {
          // The request was made but no response was received
          console.log('No response received from server');
          
          return { status: 500, error: 'No response from server' };
      } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error setting up the request:', error.message);
          
          return { status: 500, error: 'Error setting up the request' };
      }
  }
};

//Update course Image
export const updateCourseImage = (formDataWithImage, id) => async (dispatch) => {

  try {
    const response = await securedInstance.put(`/api/courseImgAWS/${id}`, formDataWithImage)
    .then((res) => {
      const listData = res.data
      toast.success("You have successfully updated courseImage")
      return listData;
    })
    dispatch({
      type: ActionTypes.UPDATE_COURSE_IMAGE,
      payload: response
    })
  } catch (error) {
    console.log(error)
  }
}

//Update Program Image
export const updateProgramImage = (formDataWithImage, id) => async (dispatch) => {

  try {
    const response = await securedInstance.put(`/api/programImgAWS/${id}`, formDataWithImage)
    .then((res) => {
      const listData = res.data
      toast.success("You have successfully updated ProgramImage")
      return listData;
    })
    dispatch({
      type: ActionTypes.UPDATE_PROGRAM_IMAGE,
      payload: response
    })
  } catch (error) {
    console.log(error)
  }
}
  // delete ProgramImage By Id
 
  export const deleteProgramImageById  = (id) => async (dispatch) => {    
    try {
        const response = await securedInstance.delete(`/api/programImgAWS/${id}`)
        .then((res)=>{
           console.log(`Program image deleted with Id:${id}`)            
        })
        dispatch({
            type: ActionTypes.DELETE_PROGRAM,
            payload: response
        }) 
    } catch (error) {
      console.log(error)
    }
  };
  // upload proram image
export const uploadProgramImage = (newProgramImg) => async (dispatch) => {
  
  try {
      const response = await securedInstance.post('api/programImgAWS', newProgramImg)
      const imgData = response.data.message;      
      dispatch({
          type:'UPLOAD_PROGRAM_IMG',
          payload: imgData
      }) 
  } catch (error) {
      console.error('Error adding program img:', error)
  }
};
  //get program course with program id and course id
  export const getProgramCourseCombo = (details) => async (dispatch) => {
    
    try {
        const response = await axiosinstance.post(`/api/category/combination/`, details)
        .then((res)=>{   
            const listData = res.data
          // console.log("user loaded", listData)
            return listData;
        })
        dispatch({
            type: ActionTypes.GET_PROGRAM_COURSE_COMBINATION,
            payload: response
        }) 
    } catch (error) {
      console.log(error)
    }
  };

  // subscriber register
  export const registerSubsciber = (userData) => async (dispatch) => {
    try {
      const res = await axiosinstance.post('/api/subscribers', userData);
      dispatch({
        type: ActionTypes.SUBSCRIBER_REGISTER,
        payload: res.data,
      });
      // toast.success("You have submitted Successfully")
    } catch (error) {
      toast.error(error.response.data.error + " " + 'Please TRY again')
    }
  };
/* GET All Users */
// export const getAllSubscribers = () =>async (dispatch)=>{
//    try{
//     // to display subscriber with program name use api='subscriberWithProgram', subscriber with subprogram='subscriberWithSubProgram'
//     const response = await securedInstance.get('/api/subscribers/subscriberWithSubProgram' )
//     .then((res)=>{   
//         const listData = res.data
//         const sortedData = listData.sort((a,b)=> b.user_id - a.user_id)
//       // console.log("programs loaded", sortedData)
//         return sortedData;
//     })
//     dispatch({
//         type: ActionTypes.GET_ALL_SUBSCRIBER,
//         payload: response
//     })  
//    }catch(error){
//     console.log(error)
//    }
    
// }



export const getAllSubscribers = () => async (dispatch) => {
  try {
    // Fetch subscribers with subprogram
    const response = await securedInstance.get('/api/subscribers/');

    // Sort the data by user_id in descending order
    const sortedData = response.data.sort((a, b) => b.user_id - a.user_id);

    // Dispatch action to update the state with subscribers
    dispatch({
      type: ActionTypes.GET_ALL_SUBSCRIBER,
      payload: sortedData // Pass sortedData as payload
    });
  } catch (error) {
    console.error('Error fetching subscribers:', error);
    // Handle error if needed
  }
};
//delete 
export const deleteSubscriberById = (id) => async (dispatch) => {    
    try {
        const response = await securedInstance.delete(`/api/subscribers/${id}`)
        .then((res)=>{
           console.log(`Program deleted with Id:${id}`)            
        })
        dispatch({
            type: ActionTypes.DELETE_PROGRAM,
            payload: response
        }) 
    } catch (error) {
      console.log(error)
    }
  };
  // GET lessons
  export const fetchLessons = () =>async (dispatch)=>{
   try{
    
    const response = await securedInstance.get('/api/lessons' )
    .then((res)=>{   
        const listData = res.data
        return listData;
    })
    dispatch({
        type: ActionTypes.GET_LESSONS,
        payload: response
    })  
   }catch(err){
    console.log(err)
   }
    
}
  // GET units
  export const fetchUnits = () =>async (dispatch)=>{
   
   try{
    const response = await securedInstance.get('/api/units' )
    .then((res)=>{   
        const listData = res.data
        return listData;
    })
    dispatch({
        type: ActionTypes.GET_UNITS,
        payload: response
    })  
   }catch(err){
    console.log(err)
   }
    
}
  // GET units per lessons
  export const fetchUnitsByLesson = (id) =>async (dispatch)=>{
   
    try{
        const response = await securedInstance.get(`/api/units/unitPerLes/${id}` )
    .then((res)=>{   
        const listData = res.data
        return listData;
    })
    dispatch({
        type: ActionTypes.GET_UNITS_BY_LESSON,
        payload: response
    })  
    }catch(err){
        console.log(err)
    }
    
}
  // GET course with lessons and units
  export const fetchCourseWithLesson = (id) =>async (dispatch)=>{
   
    try{
        const response = await securedInstance.get(`/api/lessons/course/${id}` )
    .then((res)=>{   
        const listData = res.data
        return listData;
    })
    dispatch({
        type: ActionTypes.GET_COURSE_WITH_LESSON,
        payload: response
    })  
    }catch(err){
        console.log(err)
    }
    
}

// GET prerequisitions BY courseID
export const fetchPrerequisiteByCourse = (id) =>async (dispatch)=>{
   
    try{
        const response = await securedInstance.get(`/api/prerequisites/fourByCourse/${id}` )
    .then((res)=>{   
        const listData = res.data
        return listData;
    })
    dispatch({
        type: ActionTypes.GET_PREREQUISITE_BY_COURSE,
        payload: response
    })  
    }catch(err){
        console.log(err)
    }
    
}

// GET course with lessons and units
export const fetchLessonByCourseId = (id) =>async (dispatch)=>{
   
    try{
        const response = await securedInstance.get(`/api/lessons/sixLessPerCourse/${id}` )
    .then((res)=>{   
        const listData = res.data
        return listData;
    })
    dispatch({
        type: ActionTypes.GET_LESSONS_BY_COURSE_ID,
        payload: response
    })  
    }catch(err){
        console.log(err)
    }
    
}
// GET ACTIVE PROMOTION FOR HOME PAGE HERO
export const getActivePromotionEvent = () => async (dispatch) => {
    try {
      const response = await axiosinstance.get('/api/promotions/active/event');
  
      if (response.status === 200) {
        const listData = response.data;
        dispatch({
          type: ActionTypes.GET_ACTIVE_PROMOTION_EVENT,
          payload: listData,
        });
      } else {
        // Handle non-200 status codes here, if necessary.
        console.error('Unexpected status code:', response.status);
      }
    } catch (error) {
      // Handle network errors or other exceptions here.
      console.error('Error while fetching active promotion event:', error);
    }
  };

 //Upload new course
 
export const uploadNewCourse = (newCourseData) => { 
  return async (dispatch) => {
    try {
    const response = await securedInstance.post('/api/courses', newCourseData);
    const courseId = response.data.course_id;
    dispatch({ type: 'UPLOAD_COURSE_SUCCESS', payload: courseId });
    return response;
  } catch (error) {
    console.error("Error creating course with image:", error);
    dispatch({ type: 'UPLOAD_COURSE_FAILURE' });
  }
  
};
};
// export const uploadNewCourse = (newCourseData) => async (dispatch) => {
    
//     try {
//         const response = await securedInstance.post('/api/courses', newCourseData)
//         .then((res)=>{   
//             const listData = res.data.courseId;
//           console.log("courses loaded", listData)
//           toast.success("You have successfully uploaded course")  
//             return listData;
//         })
//         dispatch({
//             type: ActionTypes.UPLOAD_COURSE,
//             payload: response
//         }) 
//     } catch (error) {
//       console.log(error)
//     }
//   };

   //delete a course
export const deleteCourseById = (id) => async (dispatch) => {    
    try {
        const response = await securedInstance.delete(`/api/courses/${id}`)
        .then((res)=>{
           //console.log(`Course deleted with Id:${id}`)
           toast.success("You have successfully deleted course")            
        })
        dispatch({
            type: ActionTypes.DELETE_COURSE,
            payload: response
        }) 
    } catch (error) {
      console.log(error)
    }
  }; 

//update  course by id
export const updateCourseById = (updatedCourseData, id) => async (dispatch) => {
    
    try {
        const response = await securedInstance.put(`/api/courses/${id}`, updatedCourseData)
        .then((res)=>{   
            const listData = res.data
          // console.log("courses loaded", listData)
          toast.success("You have successfully updated course") 
            return listData;
        })
        dispatch({
            type: ActionTypes.EDIT_COURSE,
            payload: response
        }) 
    } catch (error) {
      console.log(error)
    }
  };


   //Upload new lesson
export const uploadNewLesson = (newLessonData) => async (dispatch) => {
    
    try {
        const response = await securedInstance.post('/api/lessons', newLessonData)
        .then((res)=>{   
            const listData = res.data
          console.log("lessons loaded", listData)
          toast.success("You have successfully uploaded lessons")  
            return listData;
        })
        dispatch({
            type: ActionTypes.UPLOAD_LESSON,
            payload: response
        }) 
    } catch (error) {
      console.log(error)
    }
  };

   //Upload new unit
export const uploadNewUnit = (newUnitData) => async (dispatch) => {
    
    try {
        const response = await securedInstance.post('/api/units', newUnitData)
        .then((res)=>{   
            const listData = res.data
          console.log("units loaded", listData)
          toast.success("You have successfully uploaded unit")  
            return listData;
        })
        dispatch({
            type: ActionTypes.UPLOAD_UNIT,
            payload: response
        }) 
    } catch (error) {
      console.log(error)
    }
  };

    /* GET Lesson BY ID */
export const fetchLessonId = (id) => async (dispatch) => {
  try {
    const response = await axiosinstance.get(`/api/lessons/${id}`);
    const listData = response.data;
    
    dispatch({
      type: ActionTypes.FETCH_LESSON_ID,
      payload: listData
    });
  } catch (error) {
    // Handle the error here, e.g., log it or dispatch an error action
    console.error("Error in fetchLessonId:", error);
    // You can also dispatch an error action if needed
    // dispatch({
    //   type: ActionTypes.FETCH_LESSON_ID_ERROR,
    //   payload: error
    // });
  }
};


   //Upload new Prerequisite
   export const uploadNewPrerequisite = (newPrerequisiteData) => async (dispatch) => {
    
    try {
        const response = await securedInstance.post('/api/Prerequisites', newPrerequisiteData)
        .then((res)=>{   
            const listData = res.data
          console.log("units loaded", listData)
          toast.success("You have successfully uploaded Prerequisite")  
            return listData;
        })
        dispatch({
            type: ActionTypes.UPLOAD_PREREQUISITE,
            payload: response
        }) 
    } catch (error) {
      console.log(error)
    }
  };

  /* upload PROGRAMcourse - programCourseLinking */
export const createNewProgramCourse = (programImageData) =>async (dispatch)=>{
   
    try{
        const response = await securedInstance.post(`/api/category`,programImageData)
    .then((res)=>{   
        const listData = res.data
        console.log("response", listData)
        return listData;
    })
    //console.log("test")
    dispatch({
        type: ActionTypes.CREATE_PROGRAMCOURSE,
        payload: response
    })  
    }
    catch(error){
        console.log(error)
    }
    
}

//Upload new image
export const uploadNewImage = (imageData) => async (dispatch) => {

    const headers = {
      'Content-Type': 'multipart/form-data',
    };
       
    try {
        const response = await axiosinstance.post('/api/awsimage', imageData, {headers})
        .then((res)=>{   
            const listData = res.data
          console.log("units loaded", listData)
          toast.success("You have successfully uploaded")  
            return listData;
        })
        dispatch({
            type: ActionTypes.UPLOAD_IMAGE,
            payload: response
        }) 
    } catch (error) {
      console.log(error)
    }
  };

//   GET all images localed in images folder. from cloud folder: root directory 
export const getImagesList = () =>async (dispatch)=>{
   
    try{
        const response = await axiosinstance.get('/api/awsimage/list' )
    .then((res)=>{   
        const listData = res.data
       // console.log("images loaded", listData)
        return listData;
    })
    dispatch({
        type: ActionTypes.GET_IMAGE_LIST,
        payload: response
    })
    } catch(error){
        console.log(error)
    } 
    
}
// Get image list for admin panel. table with image name, program name and course name
export const getImagesTable = () =>async (dispatch)=>{
   
    try{
        const response = await axiosinstance.get('/api/awsimage/program' ) //controller: getAllImagesWithProgramName
    .then((res)=>{   
        const listData = res.data
       // console.log("images loaded", listData)
        return listData;
    })
    dispatch({
        type: ActionTypes.GET_IMAGE_TABLE,
        payload: response
    })
    } catch(error){
        console.log(error)
    } 
    
}
//   GET images for all programs page
export const getImageAllPrograms = () =>async (dispatch)=>{
   
    try{
        const response = await axiosinstance.get('/api/awsimage/category' )
    .then((res)=>{   
        const listData = res.data
       // console.log("images loaded", listData)
        return listData;
    })
    dispatch({
        type: ActionTypes.GET_IMAGE_PROGRAM,
        payload: response
    })
    } catch(error){
        console.log(error)
    } 
    
}
//   GET images,courses and program detail for program detail page - program by id
export const getAllDetailsForProgramById = (id) =>async (dispatch)=>{
   
    try{
        const response = await axiosinstance.get(`/api/category/detail/${id}` )
    .then((res)=>{   
        const listData = res.data
       // console.log("loaded", listData)
        return listData;
    })
    dispatch({
        type: ActionTypes.GET_IMGAGE_COURSE_FOR_PROGRAMDETAIL,
        payload: response
    })
    } catch(error){
        console.log(error)
    } 
    
}

//Upload new image
export const sendEmailNotification = (emailData) => async (dispatch) => {

    const headers = {
      'Content-Type': 'application/json',
    };
       
    try {
        const response = await axiosinstance.post('/api/email/send/1', emailData, {headers}) /* here 1 is email template modal. 1 0r 2 */
        .then((res)=>{   
            const listData = res.data
          console.log("submitted", listData)
            
            return listData;
        })
        dispatch({
            type: ActionTypes.SEND_EMAIL_NOTIFICATION,
            payload: response
        }) 
        toast.success(`Email successfully Sent to: ${emailData.emailId}`)
    } catch (error) {
      console.log(error)
    }
  };
  //get user by email to send mail notification - dispatch is commented in register page.
export const findUserByEmail = (registeredEmail) =>async (dispatch)=>{
   
    try{
        const response = await axiosinstance.get(`/api/allUsers/lastEmail/${registeredEmail}` )
    .then((res)=>{   
        const listData = res.data
       console.log("selected user data", listData)
        return listData;
    })
    dispatch({
        type: ActionTypes.GET_USER_BY_EMAIL,
        payload: response
    })  
    }catch(error){
        console.log(error)
    }
    
}

//delete a lesson
export const deleteLessonById = (id) => async (dispatch) => {    
    try {
        const response = await securedInstance.delete(`/api/lessons/${id}`)
        .then((res)=>{
           console.log(`Lesson deleted with Id:${id}`)
           toast.success("You have successfully deleted lesson")            
        })
        dispatch({
            type: ActionTypes.DELETE_LESSON,
            payload: response
        }) 
    } catch (error) {
      console.log(error)
    }
};

 //update  lesson by id
export const updateLessonById = (updatedLessonData, id) => async (dispatch) => {
    
    try {
        const response = await securedInstance.put(`/api/lessons/${id}`, updatedLessonData)
        .then((res)=>{   
            const listData = res.data
          console.log("lessons loaded", listData)
          toast.success("You have successfully updated lesson") 
            return listData;
        })
        dispatch({
            type: ActionTypes.EDIT_LESSON,
            payload: response
        }) 
    } catch (error) {
      console.log(error)
    }
};

//delete a unit
export const deleteUnitById = (id) => async (dispatch) => {    
    try {
        const response = await securedInstance.delete(`/api/units/${id}`)
        .then((res)=>{
           console.log(`Unit deleted with Id:${id}`)
           toast.success("You have successfully deleted unit")            
        })
        dispatch({
            type: ActionTypes.DELETE_UNIT,
            payload: response
        }) 
    } catch (error) {
      console.log(error)
    }
}; 

/* GET unit BY ID */
export const fetchUnitId = (id) => async (dispatch) => {
    try {
      const response = await axiosinstance.get(`/api/units/${id}`);
      const listData = response.data;
      console.log("units selected", listData);
  
      dispatch({
        type: ActionTypes.FETCH_UNIT_ID,
        payload: listData,
      });
    } catch (error) {
      console.error("Error fetching unit ID:", error);
      // You can dispatch an action to handle the error here if needed.
    }
  };
  

//update  unit by id
export const updateUnitById = (updatedUnitData, id) => async (dispatch) => {
    
    try {
        const response = await securedInstance.put(`/api/units/${id}`, updatedUnitData)
        .then((res)=>{   
            const listData = res.data
          console.log("units loaded", listData)
          toast.success("You have successfully updated unit") 
            return listData;
        })
        dispatch({
            type: ActionTypes.EDIT_UNIT,
            payload: response
        }) 
    } catch (error) {
      console.log(error)
    }
};

// GET Prerequisites
export const fetchPrerequisiteList= () =>async (dispatch)=>{
  
    try{
     
     const response = await securedInstance.get('/api/prerequisites')
     
     .then((res)=>{   
         const listData = res.data
        //  console.log("prerequisites fetched",listData) 
         return listData;
     })
     dispatch({
         type: ActionTypes.FETCH_PREREQUISITE,
         payload: response
     })  
    }catch(err){
     console.log(err)
    }
     
};

   /* delete Prerequisite by id */
   export const deletePrerequisiteById = (id) => async (dispatch) => {    
    try {
        const response = await securedInstance.delete(`/api/prerequisites/${id}`)
        .then((res)=>{
           console.log(`prerequisite deleted with Id:${id}`) 
           toast.success("You have successfully deleted  prerequisite")            
        })
        dispatch({
            type: ActionTypes.DELETE_PREREQUISITE,
            payload: response
        }) 
    } catch (error) {
      console.log(error)
    }
};

    //update  Prerequisite by id
    export const updatePrerequisiteById = (updatedPrerequisiteData, id) => async (dispatch) => {
    
        try {
            const response = await securedInstance.put(`/api/prerequisites/${id}`, updatedPrerequisiteData)
            .then((res)=>{   
                const listData = res.data
              console.log("prerequisites loaded", listData)
              toast.success("You have successfully updated prerequisite") 
                return listData;
            })
            dispatch({
                type: ActionTypes.EDIT_PREREQUISITE,
                payload: response
            }) 
        } catch (error) {
          console.log(error)
        }
      };

 /* GET PREREQUISITE BY ID */
 export const fetchPrerequisiteById = (id) => async (dispatch) => {
    try {
        const response = await securedInstance.get(`/api/prerequisites/${id}`);
        const listData = response.data;
        console.log("prerequisites selected", listData);

        dispatch({
            type: ActionTypes.FETCH_PREREQUISITE_BY_ID,
            payload: listData
        });
    } catch (error) {
        // Handle the error here, for example, dispatch an error action
        console.error("Error fetching prerequisite by ID:", error);
        // You can dispatch an error action if needed
        // dispatch({
        //     type: ActionTypes.FETCH_PREREQUISITE_ERROR,
        //     payload: error.message
        // });
    }
};


/*GET EVENTS AD FOR HOMEPAGE*/
export const fetchEventAdList = () => async (dispatch) => {
    try {
      const response = await axiosinstance.get('/api/Events/maxSeven');
      
      if (response.status === 200) {
        const eventAdData = response.data;
        dispatch({
          type: ActionTypes.FETCH_EVENT_AD,
          payload: eventAdData,
        });
      } else {
        // Handle non-200 status codes here, if necessary.
        console.error('Unexpected status code:', response.status);
      }
    } catch (error) {
      // Handle network errors or other exceptions here.
      console.error('Error while fetching event ads:', error);
    }
  };

/* GET EVENTAD BY ID */
export const fetchEventListId = (id) => async (dispatch) => {
    try {
        const response = await axiosinstance.get(`/api/Events/${id}`);
        const eventAdIdData = response.data;
        console.log("event selected", eventAdIdData);
        dispatch({
            type: ActionTypes.FETCH_EVENTAD_BY_ID,
            payload: eventAdIdData
        });
    } catch (error) {
        // Handle the error here, you can log it or dispatch an action to handle it in your application.
        console.error("Error fetching event by ID:", error);
        // You might want to dispatch an error action here if you're using Redux to handle errors.
    }
}


export const registerEventSubsciber = (userData) => async (dispatch) => {
    try {
      const res = await axiosinstance.post('/api/subscribers', userData);
      dispatch({
        type: ActionTypes.EVENT_SUBSCRIBER_REGISTER,
        payload: res.data,
      });
      toast.success("You have submitted Successfully")
    } catch (error) {
      toast.error(error.response.data.error + " " + 'Please TRY again')
    }
  }

  /* Fetch ALL Events */
  export const fetchEvents = () => async (dispatch) => {
    try {
      const response = await securedInstance.get('/api/events');
      const listData = response.data;
      //console.log("event loaded", listData)
      dispatch({
        type: ActionTypes.FETCH_EVENTS,
        payload: listData,
      });
    } catch (error) {
      // Handle the error, for example, you can log it or dispatch an error action.
      console.error("Error fetching events:", error);
      // You might also dispatch an error action here to handle errors in your Redux store.
      // dispatch({
      //   type: ActionTypes.FETCH_EVENTS_ERROR,
      //   payload: error,
      // });
    }
  };
  
  //fetch event by id
  export const fetchEventById = (id) => async (dispatch) => {
    try {
      const response = await securedInstance.get(`/api/events/${id}`);
      const listData = response.data;
  
      dispatch({
        type: ActionTypes.FETCH_EVENT_BY_ID,
        payload: listData,
      });
    } catch (error) {
      // Handle the error here, you can log it or dispatch an error action
      console.error("An error occurred while fetching the event:", error);
      // You can also dispatch an error action here if needed
      // dispatch({ type: ActionTypes.FETCH_EVENT_ERROR, payload: error });
    }
  }
  
  /* delete Event by id */
  export const deleteEventById = (id) => async (dispatch) => {    
    try {
        const response = await securedInstance.delete(`/api/events/${id}`)
        .then((res)=>{
           console.log(`Event deleted with Id:${id}`) 
           //toast.success("You have successfully deleted event")           
        })
        dispatch({
            type: ActionTypes.DELETE_EVENT,
            payload: response
        }) 
    } catch (error) {
      console.log(error)
    }
  };

  export const getEventByStatus = (category) => async (dispatch) => {
    try {
        const response = await securedInstance.get(`/api/events/${category}`);
        const listData = response.data;
        // console.log("selected user data", listData)
        dispatch({
            type: ActionTypes.GET_EVENT_BY_STATUS,
            payload: listData
        });
    } catch (error) {
        // Handle the error here, e.g., log it or dispatch an error action
        console.error("Error fetching event data:", error);
        // You can dispatch an error action here if needed.
    }
}


  export const updateEventById = (updatedStatus, id) => async (dispatch) => {
    
    try {
        const response = await securedInstance.put(`/api/events/${id}/category`, updatedStatus)
        .then((res)=>{   
            const listData = res.data
          // console.log("user loaded", listData)
            return listData;
        })
        dispatch({
            type: ActionTypes.UPDATE_EVENT_BY_ID,
            payload: response
        }) 
    } catch (error) {
      console.log(error)
    }
  };

  /* GET ALL FAQ */
export const fetchFaqList = () =>async (dispatch)=>{
   try{
    const response = await axiosinstance.get('/api/faq');
    const listData = response.data;
    
    dispatch({
        type: ActionTypes.FETCH_FAQ,
        payload: listData
    })  
    }catch (error){
        console.error('Error fetching faq data:', error)
    }
    
}

/* UPLOAD NEW FAQ */
export const uploadNewFaq = (newFaqData) => async (dispatch) => {
    
    try {
        const response = await securedInstance.post('/api/faq', newFaqData)
        const listData = response.data;
        
        dispatch({
            type: ActionTypes.UPLOAD_FAQ,
            payload: listData
        }) 
    } catch (error) {
        console.error('Error adding faq data:', error)
    }
  };
/* GET FAQ BY ID */
export const fetchFaqById = (id) => async (dispatch)=>{
    try{
        const response = await axiosinstance.get(`/api/faq/${id}`)
        console.log("###########response########",response)
        const listData = response.data
        console.log("fetchbyiddata", listData)
    
        dispatch({
            type: ActionTypes.FETCH_FAQ_BY_ID,
            payload: listData
        }) 
       }
       catch (error) {
        console.error('Error fetching faq data by id:', error);
      } 
    
}
/* UPDATE FAQ BY ID */
export const updateFaqById = (updatedFaqData, id) => async (dispatch) => {
    
    try {
        const response = await securedInstance.put(`/api/faq/${id}`, updatedFaqData)  
        const listData = response.data
          console.log("faq loaded", listData)
          toast.success("You have successfully updated faq") 
          
        dispatch({
            type: ActionTypes.EDIT_FAQ,
            payload: listData
        }) 
    } catch (error) {
      console.log('Error updating faq data:',error)
    }
  };
 /* DELETE FAQ BY ID */
   export const deleteFaqById = (id) => async (dispatch) => {    
    try {
        await securedInstance.delete(`/api/faq/${id}`)
           toast.success("You have successfully deleted course")   
                    
        dispatch({
            type: ActionTypes.DELETE_FAQ,
            payload: id
        }); dispatch(fetchFaqList());
    } catch (error) {
      console.log('Error deleting faq data:',error)
    }
  };
//Fetch Jobs

export const fetchJobs = () => async (dispatch) => {
    try {
      const response = await axiosinstance.get('/api/jobs');
      const jobsData = response.data;
      
      dispatch({
        type: ActionTypes.FETCH_JOBS,
        payload: jobsData,
      });
    } catch (error) {
      console.error('Error fetching job data:', error);
    }
  };

  export const fetchJobDetailsById = (id) => async (dispatch) => {
    try {
      const response = await axiosinstance.get(`/api/jobs/${id}`);
      const jobDetails = response.data;
      
      dispatch({
        type: ActionTypes.FETCH_JOBS_BY_ID,
        payload: jobDetails,
      });
    } catch (error) {
      console.error('Error fetching job details:', error);
    }
  };

// Action Creator for fetching event images by eventId
export const fetchEventImages = (eventId) => {
  return async (dispatch) => {
    try {
      const response = await securedInstance.get(`/api/eventImgAWS/${eventId}`);
      const imageUrl = response.data.imageUrl;
      dispatch({
        type: ActionTypes.FETCH_EVENT_IMAGES_SUCCESS,
        payload: {
            imageUrl:imageUrl,
          },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.FETCH_EVENT_IMAGES_FAILURE,
        payload: error.response ? error.response.data : 'Failed to fetch event images',
      });
    }
  };
};

 // GET getAllEventSubscribers
 export const getAllEventSubscribers = () =>async (dispatch)=>{
  try{     
   const response = await securedInstance.get('/api/subscribers/get' )
   .then((res)=>{   
       const listData = res.data
/*        const sortedData = listData.sort((a,b)=> b.user_id - a.user_id) */
      console.log("programs loaded", listData)
       return listData;
   })
   dispatch({
       type: ActionTypes.GET_EVENT_SUBSCRIBER,
       payload: response
   })  
  }catch(error){
   console.log(error)
  }
   
}

export const fetchEventList = () =>async(dispatch)=>{
  try{
    const response = await axiosinstance.get('/api/events');
    if (response.status=200){
      const eventList= response.data;
      dispatch({
        type:ActionTypes.FETCH_EVENT_LIST,
        payload:eventList
      });
    }else{
      console.error('Unexpected Status Code',response.status);
    }
  }catch(error){
    console.error('Error while fetching event list',error);
  
  }
  };

//Job Upload form
export const uploadJobDetails = (jobDetails) => async (dispatch) => {
  try {
    const response = await axiosinstance.post('/api/jobs', jobDetails);
    const listData = response.data;
    console.log("programs loaded", listData);

    dispatch({
      type: ActionTypes.UPLOAD_JOB_DETAILS_SUCCESS,
      payload: listData,
    });
    toast.success("You have successfully Uploaded")
  } catch (error) {
    console.error(error);
   
  }
};
//upload  company new logo to aws
export const uploadCompanyLogo =(formData)=>async (dispatch)=>{
  try{
    const response = await axiosinstance.post('/api/logo-aws/upload',formData)
    const logoData = response.data.message;
    console.log('logoData',logoData)
    // console.log('company_name',)
   dispatch({
    type:'UPLOAD_COMPANY_LOGO',
    payload:logoData
   })
  }catch(error){
    console.error('Erroe adding company logo',error)
  }
  };
  

  

export const fetchPopularCourseList = () =>async (dispatch)=>{
   try{
    const response = await axiosinstance.get('/api/popularcourses/limitcourse/:limitid' )
    .then((res)=>{   
        const listData = res.data
        //console.log("courses loaded", listData)
        return listData;
    })
    //console.log("test")
    dispatch({
        type: ActionTypes.FETCH_POPULAR_COURSES,
        payload: response
    }) ; 
    
   } catch (error) {
    console.error('Error fetching popular courses:', error);
    // Handle the error, e.g., dispatch an action with an error payload
  }
  
}


export const uploadNewEvent = (newEventData) => { 
  return async (dispatch) => {
    try {
    const response = await securedInstance.post('/api/events/Id', newEventData);
    const eventId = response.data.event_id;
    dispatch({ type: 'UPLOAD_EVENT_SUCCESS', payload: eventId });
    return response;
  } catch (error) {
    console.error("Error creating event with image:", error);
    dispatch({ type: 'UPLOAD_EVENT_FAILURE' });
  }
  
};
};

/* UPLOAD NEW EVENT IMAGE DETAILS */
export const uploadEventImage = (newEventImg) => async (dispatch) => {
  
  try {
      const response = await securedInstance.post('api/eventImgAWS', newEventImg)
      const imgData = response.data.message;      
      dispatch({
          type:'UPLOAD_EVENT_IMG',
          payload: imgData
      }) 
  } catch (error) {
      console.error('Error adding Event img:', error)
  }
};
export const getEvent = (id,newEventData) => { 
  return async (dispatch) => {
    try {
    const response = await securedInstance.post('/api/events/Id', newEventData);
    const eventId = response.data.event_id;
    dispatch({ type: 'UPLOAD_EVENT_SUCCESS', payload: eventId });
    return response;
  } catch (error) {
    console.error("Error creating event with image:", error);
    dispatch({ type: 'UPLOAD_EVENT_FAILURE' });
  }
  
};
};

export const setEventRowData = (rowData) => ({
  type: 'SET_EVENT_ROW_DATA',
  payload: rowData,
});

export const getImageName = (eventId) => async (dispatch) => {
  try {
    const response = await securedInstance.get(`/api/eventImgAWS/imageName/${eventId}`);
    const data = response.data;
    console.log('data',data);

    if (response.status === 200) {
      const imageName = data.imageName;
      dispatch({ type: 'GET_IMAGE_NAME', payload: imageName });
    } else {
      // Handle the error scenario
      console.error('Error fetching image name:', data.error);
    }
  } catch (error) {
    // Handle network or other errors
    console.error('An error occurred while fetching image name:', error);
  }
};

export const updateEventRequest = (eventId, updatedFields) => async (dispatch) => {
  try {
    const response = await securedInstance.put(`/api/events/${eventId}`, updatedFields);
    dispatch({
      type: ActionTypes.UPDATE_EVENT_BY_ID,
      payload: response.data, // Assuming the data you need is in the 'data' property
    });
  } catch (error) {
    console.error('Error updating event:', error);
  }
};
export const deleteEventRequest = (eventId) => async (dispatch) => {
  try {
    const response = await securedInstance.delete(`/api/events/${eventId}`);
    dispatch({
      type: ActionTypes.DELETE_EVENT_BY_ID,
      payload: { deletedEventId: eventId },
    });
  } catch (error) {
    console.error('Error deleting event:', error);
  }
};
export const deleteEventImageRequest = (eventId) => async (dispatch) => {
  try {
    const response = await securedInstance.delete(`/api/eventImgAWS/${eventId}`);
    dispatch({
      type: ActionTypes.DELETE_EVENTIMAGE_BY_ID,
      payload: { deletedEventImageId: eventId },
    });
  } catch (error) {
    console.error('Error deleting event:', error);
  }
};

/* GET ALL CATEGORIES BELONGS TO TUTORIAL */
export const fetchTutorialCategoriesList = () =>async (dispatch)=>{
  try {
  const response = await axiosinstance.get('/api/tutorialCategory' )
  .then((res)=>{   
      const categoryData = res.data
      console.log("categories loaded", categoryData)
      return categoryData;
  })
  //console.log("test")
  dispatch({
      type: ActionTypes.FETCH_TUTORIAL_CATEGORY,
      payload: response
  })  
} catch (error) {
  console.log(error)
}   
}

/* GET INDIVIDUAL CATEGORY DETAILS BY ID */
export const fetchTutorialCategoryById = (id) =>async (dispatch)=>{
  try {
  const response = await axiosinstance.get(`/api/tutorialCategory/${id}`)
  .then((res)=>{   
      const categoryData = res.data
  //    console.log("category selected", categoryData)
      return categoryData;
  })
  //console.log("test")
  dispatch({
      type: ActionTypes.FETCH_TUTORIAL_CATEGORY_BY_ID,
      payload: response
  })  
} catch (error) {
  console.log(error)
}
  
}
//Upload new Category
export const uploadNewTutorialCategory = (newTutorialCategoryData) => async (dispatch) => {
  
  try {
      const response = await securedInstance.post('/api/tutorialCategory', newTutorialCategoryData)
      .then((res)=>{   
          const categoryData = res.data
        console.log("response", categoryData)
        toast.success("You have successfully uploaded Category")  
          return categoryData;
      })
      dispatch({
          type: ActionTypes.UPLOAD_TUTORIAL_CATEGORY,
          payload: response
      }) 
  } catch (error) {
    console.log(error)
  }
};

//update  Category by id
export const updateTutorialCategoryById = (updatedTutorialCategoryData, id) => async (dispatch) => {
  
  try {
      const response = await securedInstance.put(`/api/tutorialCategory/${id}`, updatedTutorialCategoryData)
      .then((res)=>{   
          const categoryData = res.data
        // console.log("category loaded", categoryData)
        toast.success("You have successfully updated Category") 
          return categoryData;
      })
      dispatch({
          type: ActionTypes.EDIT_TUTORIAL_CATEGORY,
          payload: response
      }) 
  } catch (error) {
    console.log(error)
  }
};

//delete a Category
export const deleteTutorialCategoryById = (id) => async (dispatch) => {    
  try {
      const response = await securedInstance.delete(`/api/tutorialCategory/${id}`)
      .then((res)=>{
         //console.log(`Category deleted with Id:${id}`)
         toast.success("You have successfully deleted Category")            
      })
      dispatch({
          type: ActionTypes.DELETE_TUTORIAL_CATEGORY,
          payload: response
      }) 
  } catch (error) {
    console.log(error)
  }
}; 


/* GET ALL TECHNOLOGY BELONGS TO TUTORIAL */
export const fetchTutorialTechnologiesList = () =>async (dispatch)=>{
 
  const response = await axiosinstance.get(`/api/tutorialTechnology`)
  .then((res)=>{   
      const technologyData = res.data
      //console.log("technologies loaded", technologyData)
      return technologyData;
  })
  //console.log("test")
  dispatch({
      type: ActionTypes.FETCH_TUTORIAL_TECHNOLOGY,
      payload: response
  })  
  
}
/* GET INDIVIDUAL TECHNOLOGY DETAILS BY ID */
export const fetchTutorialTechnologyById = (id) =>async (dispatch)=>{
 
  const response = await axiosinstance.get(`/api/tutorialTechnology/${id}`)
  .then((res)=>{   
      const technologyData = res.data
     // console.log("technology selected", technologyData)
      return technologyData;
  })
  //console.log("test")
  dispatch({
      type: ActionTypes.FETCH_TUTORIAL_TECHNOLOGY_BY_ID,
      payload: response
  })  
  
}
//Upload new Technology
export const uploadNewTutorialTechnology = (newTutorialTechnologyData) => async (dispatch) => {
  
  try {
      const response = await securedInstance.post('/api/tutorialTechnology', newTutorialTechnologyData)
      .then((res)=>{   
          const technologyData = res.data
        console.log("response", technologyData)
        toast.success("You have successfully uploaded Technology")  
          return technologyData;
      })
      dispatch({
          type: ActionTypes.UPLOAD_TUTORIAL_TECHNOLOGY,
          payload: response
      }) 
  } catch (error) {
    console.log(error)
  }
};

//update  Technology by id
export const updateTutorialTechnologyById = (updatedTutorialTechnologyData, id) => async (dispatch) => {
  
  try {
      const response = await securedInstance.put(`/api/tutorialTechnology/${id}`, updatedTutorialTechnologyData)
      .then((res)=>{   
          const technologyData = res.data
        // console.log("Technology loaded", technologyData)
        toast.success("You have successfully updated Technology") 
          return technologyData;
      })
      dispatch({
          type: ActionTypes.EDIT_TUTORIAL_TECHNOLOGY,
          payload: response
      }) 
  } catch (error) {
    console.log(error)
  }
};

//delete a Technology
export const deleteTutorialTechnologyById = (id) => async (dispatch) => {    
  try {
      const response = await securedInstance.delete(`/api/tutorialTechnology/${id}`)
      .then((res)=>{
         //console.log(`Technology deleted with Id:${id}`)
         toast.success("You have successfully deleted Technology")            
      })
      dispatch({
          type: ActionTypes.DELETE_TUTORIAL_TECHNOLOGY,
          payload: response
      }) 
  } catch (error) {
    console.log(error)
  }
}; 

 
/* GET ALL TOPICS BELONGS TO TUTORIAL */
export const fetchTutorialTopicsList = () =>async (dispatch)=>{
 
  const response = await axiosinstance.get('/api/tutorialTopics' )
  .then((res)=>{   
      const topicsData = res.data
      // console.log("Topics loaded", topicsData)
      return topicsData;
  })
  //console.log("test")
  dispatch({
      type: ActionTypes.FETCH_TUTORIAL_TOPIC,
      payload: response
  })  
  
}
/* GET INDIVIDUAL Topic DETAILS BY ID */
export const fetchTutorialTopicById = (id) =>async (dispatch)=>{
 
  const response = await axiosinstance.get(`/api/tutorialTopics/${id}`)
  .then((res)=>{   
      const topicsData = res.data
     // console.log("Topic selected", topicsData)
      return topicsData;
  })
  //console.log("test")
  dispatch({
      type: ActionTypes.FETCH_TUTORIAL_TOPIC_BY_ID,
      payload: response
  })  
  
}
//Upload new Topic
export const uploadNewTutorialTopic = (newTutorialTopicData) => async (dispatch) => {
  
  try {
      const response = await securedInstance.post('/api/tutorialTopics', newTutorialTopicData)
      .then((res)=>{   
          const topicsData = res.data
        console.log("response", topicsData)
        toast.success("You have successfully uploaded Topic")  
          return topicsData;
      })
      dispatch({
          type: ActionTypes.UPLOAD_TUTORIAL_TOPIC,
          payload: response
      }) 
  } catch (error) {
    console.log(error)
  }
};

//update  Topic by id
export const updateTutorialTopicById = (updatedTutorialTopicData, id) => async (dispatch) => {
  
  try {
      const response = await securedInstance.put(`/api/tutorialTopics/${id}`, updatedTutorialTopicData)
      .then((res)=>{   
          const topicsData = res.data
        // console.log("Topic loaded", topicsData)
        toast.success("You have successfully updated Topic") 
          return topicsData;
      })
      dispatch({
          type: ActionTypes.EDIT_TUTORIAL_TOPIC,
          payload: response
      }) 
  } catch (error) {
    console.log(error)
  }
};

//delete a Topic
export const deleteTutorialTopicById = (id) => async (dispatch) => {    
  try {
      const response = await securedInstance.delete(`/api/tutorialTopics/${id}`)
      .then((res)=>{
         //console.log(`Topic deleted with Id:${id}`)
         toast.success("You have successfully deleted Topic")            
      })
      dispatch({
          type: ActionTypes.DELETE_TUTORIAL_TOPIC,
          payload: response
      }) 
  } catch (error) {
    console.log(error)
  }
}; 


  // fetch all interview categories
  export const fetchCategoriesList = () => async (dispatch) => {
    try {
      const response = await axiosinstance.get('/api/Categories');
      const categoriesData = response.data;

      dispatch({
        type: ActionTypes.FETCH_CATEGORIES,
        payload: categoriesData,
      });
    } catch (error) {
      console.error('Error fetching Categories data:', error);
    }
  };

  // fetch interview category by id
  export const fetchCategoryById = (id) => async (dispatch) => {

    const response = await axiosinstance.get(`/api/Categories/${id}`)
    .then((res)=>{   
        const categoryDataById = res.data
        return categoryDataById;
    })
    dispatch({
        type: ActionTypes.FETCH_CATEGORY_BY_ID,
        payload: response
    }) 
  }

  // upload new interview category
  export const uploadNewCategory = (newCategoryData) => async (dispatch) => {

    try{
      const response = await securedInstance.post('/api/Categories', newCategoryData)
      .then((res) => {
        const categoryData = res.data
        // console.log("Interview Qa loaded", categoryData)
        // toast.success("You have successfully uploaded QA")
          return categoryData;
      })
      dispatch({
        type: ActionTypes.UPLOAD_CATEGORY,
        payload: response
      })
    } catch (error) {
      console.log(error)
    }
  };

  // update interview category by id
  export const updateCategoryById = (updatedCategoryData, id) => async (dispatch) => {

    try {
      const response = await securedInstance.put(`/api/Categories/${id}`, updatedCategoryData)
      .then((res) => {
        const catUpdateData = res.data
        // toast.success("You have successfully updated Interview Qa")
        return catUpdateData;
      })
      dispatch({
        type: ActionTypes.UPDATE_INTERVIEW_QA,
        payload: response
      })
    } catch (error) {
      console.log(error)
    }
  }


  // fetch all interviewQa
  export const fetchInterviewQaList = () => async (dispatch) => {
    try {
      const response = await axiosinstance.get('/api/interviewQa');
      const interviewQaData = response.data;

      dispatch({
        type: ActionTypes.FETCH_INTERVIEW_QA,
        payload: interviewQaData,
      });
    } catch (error) {
      console.error('Error fetching Interview Qa data:', error);
    }
  };

  // delete interview qa by id
  export const deleteInterviewQaById = (id) => async (dispatch) => {    
    try {
        const response = await securedInstance.delete(`/api/interviewQa/${id}`)
        .then((res)=>{
           toast.success("You have successfully deleted Qa")            
        })
        dispatch({
            type: ActionTypes.DELETE_INTERVIEW_QA,
            payload: response
        }) 
    } catch (error) {
      console.log(error)
    }
  };

  // upload interview qa by id
  export const uploadNewInterviewQa = (newQaData) => async (dispatch) => {

    try{
      const response = await securedInstance.post('/api/interviewQa', newQaData)
      .then((res) => {
        const listData = res.data
        console.log("Interview Qa loaded", listData)
        toast.success("You have successfully uploaded QA")
          return listData;
      })
      dispatch({
        type: ActionTypes.UPLOAD_INTERVIEW_QA,
        payload: response
      })
    } catch (error) {
      console.log(error)
    }
  };

  // fetch interview qa by id
  export const fetchInterviewQaById = (id) => async (dispatch) => {

    const response = await axiosinstance.get(`/api/interviewQa/${id}`)
    .then((res)=>{   
        const listData = res.data
       // console.log("course selected", listData)
        return listData;
    })
    //console.log("test")
    dispatch({
        type: ActionTypes.FETCH_INTERVIEW_QA_BY_ID,
        payload: response
    }) 
  }

  // update interview qa by id
  export const updateInterviewQaById = (updatedInterviewQaData, id) => async (dispatch) => {

    try {
      const response = await securedInstance.put(`/api/interviewQa/${id}`, updatedInterviewQaData)
      .then((res) => {
        const listData = res.data
        toast.success("You have successfully updated Interview Qa")
        return listData;
      })
      dispatch({
        type: ActionTypes.UPDATE_INTERVIEW_QA,
        payload: response
      })
    } catch (error) {
      console.log(error)
    }
  }


  // fetch all category topics 
  export const fetchTopicsList = () => async (dispatch) => {
    try {
      const response = await axiosinstance.get('/api/topics');
      const topicsData = response.data;

      dispatch({
        type: ActionTypes.FETCH_TOPICS,
        payload: topicsData,
      });
    } catch (error) {
      console.error('Error fetching Topics data:', error);
    }
  };
  
  // upload new topic
  export const uploadNewTopic = (newTopicData) => async (dispatch) => {

    try{
      const response = await securedInstance.post('/api/topics', newTopicData)
      .then((res) => {
        const topicData = res.data
        // console.log("Interview Qa loaded", categoryData)
        // toast.success("You have successfully uploaded QA")
          return topicData;
      })
      dispatch({
        type: ActionTypes.UPLOAD_TOPIC,
        payload: response
      })
    } catch (error) {
      console.log(error)
    }
  };



  // fetch linkedin url
  export const fetchLinkedinUrlList = () => async (dispatch) => {
    try {
      const response = await axiosinstance.get('/api/linkedinUrl');
      const linkedinUrlData = response.data;

      dispatch({
        type: ActionTypes.FETCH_LINKEDIN_URL,
        payload: linkedinUrlData,
      });
    } catch (error) {
      console.error('Error fetching linkedin url data:', error);
    }
  }


  export const uploadPromotionHeroImage = (formData) => async (dispatch) => {
    try {
      const response = await axiosinstance.post('/api/PromotionHeroAWS/upload', formData);
      const imageData = response.data.message;
      // console.log('imageData', imageData);
      toast.success("You have successfully uploaded ")            
  
      dispatch({
        type: 'UPLOAD_PROMOTION_HERO_IMAGE',
        payload: imageData,
      });
    } catch (error) {
      console.error('Error uploading promotion hero image', error);
    }
  };
  
  // fetch the promotion hero images and upload the image
  export const fetchPromotionImages = () => async (dispatch) => {
    // dispatch({ type: ActionTypes.FETCH_PROMOTION_IMAGES_START });
  
    try {
      const response = await axiosinstance.get(`/api/PromotionHeroAWS/`);
      //  console.log("responise",response);
      const images = response.data;
        // console.log("image ",images);
      dispatch({
        type: ActionTypes.FETCH_PROMOTION_IMAGES_SUCCESS,
        payload: images,
        
      });
      // console.log("imagevvvvvvv"+images);
    } catch (error) {
      dispatch({
        type: ActionTypes.FETCH_PROMOTION_IMAGES_FAILURE,
        payload: error.message || 'Failed to fetch promotion images',
      });
    }
  };
  
  
  export const deletePromotionImage=(id)=>async(dispatch)=>{
    try {
      const response = await securedInstance.delete(`/api/PromotionHeroAWS/${id}`)
      .then((res)=>{
         toast.success("You have successfully deleted ")            
      })
      dispatch({
          type: ActionTypes.DELETE_PROMOTION_AWS_IMAGE,
          payload: response
      }) 
  } catch (error) {
    console.log(error)
  }
  }; 
  
  
  
  
  // export const TOGGLE_SWITCH = 'TOGGLE_SWITCH';
  
  export const toggleSwitchAction = (isEnabled) => {
    return {
      type: ActionTypes.TOGGLE_SWITCH,
      payload: isEnabled,
    };
  };
  
  export const toggleHeroContent = () => {
    return {
      type: ActionTypes.TOGGLE_HERO_CONTENT,
      payload: false,
    };
  };

 // Call your API to send OTP
 export const setEmailCode = (email) => {
  return async (dispatch) => {
    try {
      // Call your API to send OTP
      const response = await axiosinstance.post(`/api/emailOTP/sendOtp`, { email });
      
      // Dispatch the action with response data
      dispatch({
        type:ActionTypes.SET_EMAIL_CODE,
        payload: email, // Assuming message contains the OTP data
      });

      // Handle success if needed
      // toast.success("OTP sent successfully");
    } catch (error) {

      
      // Dispatch failure action if the API call fails
      dispatch({
        type: ActionTypes.SET_EMAIL_CODE_FAILURE,
        payload: error.message || 'Failed to send OTP', // Assuming error.message contains error details
      });

      // Handle error if needed
      console.error('Error sending OTP:', error);
      // toast.error("Failed to send OTP");
    }
  };
};

  // Make API call to fetch OTP
export const fetchOtpFromBackend = () => async (dispatch) => {
  try {
    // Make API call to fetch OTP
    const response = await axiosinstance.get(`/api/emailOTP/fetchEmailOtp`);
    // Extract OTP from response data
    const { otp_id } = response.data;
    // Dispatch success action with OTP data
    dispatch({ type:ActionTypes.FETCH_OTP_SUCCESS, payload: otp_id }) // Use FETCH_OTP_SUCCESS as the action type

    // Log the OTP value
  // console.log('OTP fetched successfully:', otp_id);
  } catch (error) {
    // Log the error
    console.error('Error getting OTP:', error);
  }
};

  //upload job_application infromation to server and db
export const uploadjob_appliactionsDetails=(formData)=>
async(dispatch)=>{
  try{
    const response=await axiosinstance.post('/api/job_applications',formData);
    const listData=response.data;
    console.log("job_aplicationsDetails loaded",listData);

    dispatch({type:ActionTypes.UPLOAD_JOB_APPLICATIONS_DETAILS_SUCCESS,
      payload:listData});

    toast.success('you have successfully uploaded')
  }catch(error){
    console.error(error);
  }

};
//upload new  job-appliaction_DocFile to aws
export const uploadJob_application_DocFile=(DocFileData)=>async(dispatch)=>{
 try{
  const response=await axiosinstance.post('/api/job_applicationFilesAWS/upload',DocFileData)
  const  DocFolderData=response.data.message;
  console.log('DocFolderData',DocFolderData);
  dispatch({
    type:'UPLOAD_JOB_APPLIACTION_DOCFILE',
    payload:DocFolderData
  })
 }catch(error){
  console.error('Error adding job_application_DocFile',error);
 }
};

  // Fetch job_application_DocFile_AWS
  export const fetch_job_aws_applications=()=>async(dispatch)=>{
    try{
      const response =await axiosinstance.get('/api/job_applicationFilesAWS/');
      const job_aws_applicationsData=response.data;
      // console.log('abc',job_applicationsData);
      dispatch({
        type: ActionTypes.FETCH_JOB_AWS_APPLICATIONS,
        payload:job_aws_applicationsData,
      });
    }catch(error){
      console.error('Error fetching job applications data:',error);
    }
  };

  //Fetch job_applications

  export const fetchjob_applications=()=>async(dispatch)=>{
    try{
      const response =await axiosinstance.get('/api/job_applications/');
      const job_applicationsData=response.data;
      // console.log('abc',job_applicationsData);
      dispatch({
        type: ActionTypes.FETCH_JOB_APPLICATIONS,
        payload:job_applicationsData,
      });
    }catch(error){
      console.error('Error fetching job applications data:',error);
    }
  };
  export const deletejobawsapplicationByName12 = (name) => async (dispatch) => {
    try {
      const response = await axiosinstance.delete(`/api/job_applicationFilesAWS/${name}`);
      console.log('response', response);
      // Assuming the deletion was successful, show a success toast message
      toast.success("You have successfully deleted the job application");
      
      dispatch({
        type: ActionTypes.DELETE_JOB_AWS_APPLICATIONS_BY_NAME,
        payload: response // Assuming response contains the result of the deletion operation
      });
    } catch (error) {
      // Log the error and handle it accordingly
      console.log(error);
      console.log("Error response data:", error.response);
      
      // You might want to show an error toast message here as well
      toast.error("An error occurred while deleting the job application");
    }
  };
  
  export const uploadSelectedPromotionHeroImage = (formData) => async (dispatch) => {
    try {
      const response = await axiosinstance.post('/api/promotionSelector/upload', formData);
      const imageData = response.data.message;
      console.log('SelectedimageData', imageData);
      toast.success("You have successfully uploaded ")            

      dispatch({
        type: 'UPLOAD_SELECTED_PROMOTION_HERO_IMAGE',
        payload: imageData,
      });
    } catch (error) {
      console.error('Error uploading selected promotion hero image', error);
    }
  };
  
  // fetch the promotion hero images and upload the image
  export const fetchSelectedPromotionImages = () => async (dispatch) => {
    // dispatch({ type: ActionTypes.FETCH_PROMOTION_IMAGES_START });
  
    try {
      const response = await axiosinstance.get('/api/promotionSelector/getAll');
      //  console.log("responise",response);
      const images = response.data;
        // console.log("Selectedimage ",images);
      dispatch({
        type: ActionTypes.FETCH_SELECTED_PROMOTION_IMAGES_SUCCESS,
        payload: images,
        
      });
      // console.log("imagevvvvvvv"+images);
    } catch (error) {
      dispatch({
        type: ActionTypes.FETCH_SELECTED_PROMOTION_IMAGES_FAILURE,
        payload: error.message || 'Failed to fetch selected promotion images',
      });
    }
  };
  
  
  export const deleteSelectedPromotionImage=(name)=>async(dispatch)=>{
    try {
      const response = await securedInstance.delete(`/api/promotionSelector/${name}`)
      .then((res)=>{
         toast.success("You have successfully deleted ")            
      })
      dispatch({
          type: ActionTypes.DELETE_SELECTED_PROMOTION_IMAGE,
          payload: response
      }) 
  } catch (error) {
    console.log("ERROR IN DELETING THE SELECTING IMAGE",error)
  }
  }; 

// promotion event text
export const fetchpromotionEventTextList = () =>async (dispatch)=>{
  try{
   const response = await axiosinstance.get('/api/promotionEventText/');
   const listData = response.data;
   console.log(response)
   dispatch({
       type: ActionTypes.FETCH_PROMOTION_EVENT_TEXT,
       payload: listData
   })  
   }catch (error){
       console.error('Error fetching promotionEventText data:', error)
   }
   
}

/* UPLOAD NEW promotion event text */
export const uploadNewpromotionEventText = (newpromotionEventTextData) => async (dispatch) => {
   
   try {
       const response = await securedInstance.post('/api/promotionEventText/upload', newpromotionEventTextData)
       const listData = response.data;
       
       dispatch({
           type: ActionTypes.UPLOAD_PROMOTION_EVENT_TEXT,
           payload: listData
       }) 
   } catch (error) {
       console.error('Error adding promotionEventText data:', error)
   }
 };
/* GET promotion event text BY ID */
export const fetchpromotionEventTextById = (id) => async (dispatch)=>{
   try{
       const response = await axiosinstance.get(`/api/promotionEventText/${id}`)
      //  console.log("###########response########",response)
       const listData = response.data
      //  console.log("fetchbyiddata", listData)
   
       dispatch({
           type: ActionTypes.FETCH_PROMOTION_EVENT_TEXT_BY_ID,
           payload: listData
       }) 
      }
      catch (error) {
       console.error('Error fetching programEventText data by id:', error);
     } 
   
}
/* UPDATE promotion event text BY ID */
export const updatepromotionEventTextById = (updatedpromotionEventTextData, id) => async (dispatch) => {
   
   try {
       const response = await securedInstance.put(`/api/promotionEventText/${id}`, updatedpromotionEventTextData) 
       .then((res)=>{
       const listData = response.data
         console.log("promotionEventText loaded", listData)
         toast.success("You have successfully updated promotioneventtext") 
         return listData;
       })
       dispatch({
           type: ActionTypes.EDIT_PROMOTION_EVENT_TEXT,
           payload: response
       }) 
   } catch (error) {
     console.log(error)
   }
 };
/* DELETE promotion event text BY ID */
  export const deletepromotionEventText = (id) => async (dispatch) => {    
   try {
       const response = await securedInstance.delete(`/api/promotionEventText/${id}`)
          .then((res)=>{
            toast.success("you have successfully deleted")
          })
       dispatch({
           type: ActionTypes.DELETE_PROMOTION_EVENT_TEXT,
           payload: response
       })
   } catch (error) {
     console.log(error)
   }
 };

/* FETCH ALL INSTRUCTORS */
export const fetchInstructorList = () => async (dispatch) => {
  try {
    const response = await axiosinstance.get('/api/instructors');

    if (response.status === 200) {
      const listData = response.data;
      // Dispatch the data to the Redux store
      dispatch({
        type: ActionTypes.FETCH_INSTRUCTORS,
        payload: listData,
      });
    } else {
      // Handle non-200 status codes here and log errors to the console
      console.error('Unexpected status code:', response.status);
    }
  } catch (error) {
    // Handle network errors or other exceptions here and log them to the console
    console.error('Error while fetching instructors:', error);
  }
};

/* GET INDIVIDUAL INSTRUCTOR DETAILS BY ID */
export const fetchInstructorById = (id) => async (dispatch) => {
  try {
    const response = await axiosinstance.get(`/api/instructors/${id}`);

    if (response.status === 200) {
      const instructorData = response.data;
      // Dispatch the data to the Redux store
      dispatch({
        type: ActionTypes.FETCH_INSTRUCTOR_BY_ID,
        payload: instructorData,
      });
    } else {
      // Handle non-200 status codes here and log errors to the console
      console.error('Unexpected status code:', response.status);
    }
  } catch (error) {
    // Handle network errors or other exceptions here and log them to the console
    console.error('Error while fetching instructor by ID:', error);
  }
};

export const uploadNewInstructor = (newInstructorData) => async (dispatch) => {
  try {
    const response = await securedInstance.post('/api/instructors', newInstructorData);
    const listData = response.data;
    console.log("Instructors loaded", listData);
    toast.success("You have successfully uploaded instructor");
    dispatch(uploadInstructor(listData));
  } catch (error) {
    console.error(error);
  }
};

export const deleteInstructorByinstructor_name = (instructor_name) => async (dispatch) => {
  try {
    await securedInstance.delete(`/api/instructors/${instructor_name}`);
    toast.success("You have successfully deleted instructor");
    dispatch(deleteInstructor(instructor_name));
  } catch (error) {
    console.error(error);
  }
};

export const updateInstructorById = (updatedInstructorData, id) => async (dispatch) => {
  try {
    const response = await securedInstance.put(`/api/instructors/${id}`, updatedInstructorData);
    const listData = response.data;
    console.log("Instructors loaded", listData);
    toast.success("You have successfully updated instructor");
    dispatch(editInstructor(listData));
  } catch (error) {
    console.error(error);
  }
};

export const deleteInstructor = (index) => ({
  type: ActionTypes.DELETE_INSTRUCTOR,
  payload: index,
});

// Action creators for UploadInstructor.js
export const uploadInstructor = (newInstructor) => ({
  type: ActionTypes.UPLOAD_INSTRUCTOR,
  payload: newInstructor,
});

// Action creators for UpdateInstructorReducer.js
export const editInstructor = (updatedInstructor) => ({
  type: ActionTypes.EDIT_INSTRUCTOR,
  payload: updatedInstructor,
});

 // fetch all online class details

 export const fetchRegistrationList = () => async (dispatch) => {
  try {
    const response = await axiosinstance.get('/api/registration/');
    const registrationOnlineclass = response.data;

    dispatch({
      type: ActionTypes.FETCH_REGISTRATION,
      payload: registrationOnlineclass,
    });
  } catch (error) {
    console.error('Error fetching onilne class data:', error);
  }
};


 //Upload new course
export const uploadRegistrationList = (newOnlineclassData) => async (dispatch) => {
  
try {
    const response = await securedInstance.post('/api/registration/register', newOnlineclassData)
    .then((res)=>{   
        const listData = res.data
      console.log("onlineclass loaded", listData)
      toast.success("You have successfully uploaded onlineclass data")  
        return listData;
    })
    dispatch({
        type: ActionTypes.UPLOAD_ONLINECLASS,
        payload: response
    }) 
} catch (error) {
  console.log(error)
}
};


//delete a the online class
export const deleteonlineclass = (id) => async (dispatch) => {    
try {
    const response = await securedInstance.delete('/api/registration/registration/:id')
    .then((res)=>{
       console.log(`Unit deleted with Id:${id}`)
       toast.success("You have successfully deleted unit")            
    })
    dispatch({
        type: ActionTypes.DELETE_ONLINECLASS,
        payload: response
    }) 
} catch (error) {
  console.log(error);
  toast.error("An error occurred while deleting unit");
}
};

export const fetchRemindList = () => async (dispatch) => {
try {
  const response = await axiosinstance.get('/api/EmailOtp/reminder');
  const registrationRemindme = response.data;

  dispatch({
    type: ActionTypes.FETCH_REMINDME,
    payload: registrationRemindme,
  });
} catch (error) {
  console.error('Error fetching remindme class data:', error);
}
};


export const uploadRemindList = (newRemindmeData) => async (dispatch) => {

try {
  const response = await securedInstance.post('/api/EmailOtp/upload', newRemindmeData)
  .then((res)=>{   
      const listData = res.data
    console.log("remindme data loaded", listData)
    toast.success("You have successfully uploaded remindme data")  
      return listData;
  })
  dispatch({
      type: ActionTypes.UPLOAD_REMINDME,
      payload: response
  }) 
} catch (error) {
console.log(error)
}
};

export const uploadCourseFiles = (formdata) => async (dispatch) => {
  console.log(formdata,"action");
  try{
const response = await axiosinstance.post('/api/material_access/upload',formdata)
.then((res) => {
  const listData=res.data
  console.log("material access data", listData)
  toast.success("You have successfully uploaded materials")  
          return listData;
})
dispatch({
  type: ActionTypes.UPLOAD_MATERIAL_ACCESS,
  payload: response
}) 
  }catch (error){
    console.log(error);
  }
} ;

export const updateCourseFiles = (id,formdata) => async (dispatch) => {
  console.log(formdata,"in action");
  try{
const response = await axiosinstance.put(`/api/material_access/${id}`,formdata)
.then((res) => {
  const listData=res.data
  console.log("material access data", listData)
  toast.success("You have successfully updated materials")  
          return listData;
})
dispatch({
  type: ActionTypes.UPDATE_MATERIAL_ACCESS,
  payload: response
}) 
  }catch (error){
    console.log(error);
  }
} ;
export const fetchMaterialList = () => async (dispatch) => {
  try {
    const response = await axiosinstance.get('/api/material_access');

    if (response.status === 200) {
      const listData = response.data;
      // Dispatch the data to the Redux store
      dispatch({
        type: ActionTypes.FETCH_MATERIALS,
        payload: listData,
      });
    } else {
      // Handle non-200 status codes here and log errors to the console
      console.error('Unexpected status code:', response.status);
    }
  } catch (error) {
    // Handle network errors or other exceptions here and log them to the console
    console.error('Error while fetching courses:', error);
  }
};

export const fetchMaterialWithID = (id) => async (dispatch) => {
  console.log(id,"in action");
  try {
    const response = await axiosinstance.get(`/api/material_access/${id}`);

    if (response.status === 200) {
      const listData = response.data;
      // Dispatch the data to the Redux store
      dispatch({
        type: ActionTypes.FETCH_MATERIALS_BY_ID,
        payload: listData,
      });
    } else {
      // Handle non-200 status codes here and log errors to the console
      console.error('Unexpected status code:', response.status);
    }
  } catch (error) {
    // Handle network errors or other exceptions here and log them to the console
    console.error('Error while fetching courses:', error);
  }
};


export const uploadProcesslist = (formdata) => async (dispatch) => {
    
  try {
      const response = await axiosinstance.post('/api/processlist/upload', formdata)
      .then((res)=>{   
          const listData = res.data
        console.log("process list loaded", listData)
        toast.success("You have successfully uploaded processlist")  
          return listData;
      })
      dispatch({
          type: ActionTypes.UPLOAD_PROCESS_LIST,
          payload: response
      }) 
  } catch (error) {
    console.log(error)
  }
};

export const fetchProcessList = () => async (dispatch) => {
  try {
    const response = await axiosinstance.get(`/api/processlist/getAll`);
    //  console.log("responise",response);
    const responsedata = response.data;
    dispatch({
      type: ActionTypes.FETCH_PROCESS_LIST_SUCCESS,
      payload: responsedata,
      
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteProcesssList = (programId,courseId) => async (dispatch) => {    
  try {
      const response = await securedInstance.delete(`/api/processlist/${programId},${courseId}`)
         .then((res)=>{
           toast.success("you have successfully deleted")
         })
      dispatch({
          type: ActionTypes.DELETE_PROCESS_LIST,
          payload: response
      })
  } catch (error) {
    console.log(error)
  }
};
export const deleteMaterialList = (material_access_id) => async (dispatch) => {    
  try {
      const response = await securedInstance.delete(`/api/material_access/${material_access_id}`)
         .then((res)=>{
           //toast.success("you have successfully deleted")
         })
      dispatch({
          type: ActionTypes.DELETE_MATERIAL_LIST,
          payload: response
      })
  } catch (error) {
    console.log(error)
  }
};

export const deleteOneMaterial = (material_access_id, filePath) => async (dispatch) => {
  console.log(filePath);
  try {
    const response = await securedInstance.put(`/api/material_access/delete/${material_access_id}`, { pathWithoutEndpoint: filePath });
    // If the request is successful, dispatch an action to update the state
    dispatch({
      type: ActionTypes.DELETE_MATERIAL_LIST,
      payload: response.data // Assuming the response contains the updated data after deletion
    });
    // Optionally, display a success message
    toast.success("Material deleted successfully");
  } catch (error) {
    // Handle any errors
    console.log(error);
    // Optionally, display an error message
    toast.error("Error deleting material");
  }
};




export const fetchProcessListByprogramIdANDCourseId = (programId,courseId) => async (dispatch) => {
  try {
    const response = await axiosinstance.get(`/api/processlist/${programId},${courseId}`);
    //  console.log("responise",response);
    const responsedata = response.data;
    dispatch({
      type: ActionTypes.FETCH_PROCESS_LIST_BY_PROGRAMID_COURSEID_SUCCESS,
      payload: responsedata,
      
    });
  } catch (error) {
    console.log(error);
  }
};



export const updateProcessListById = (programId, courseId, formdata) => async (dispatch) => {
  try {
    const response = await securedInstance.put(`/api/processlist/${programId},${courseId}`, formdata)
      .then((res) => { 
        const updatedData = res.data;
        toast.success("You have successfully updated the process list"); 
        return updatedData;
      });

    dispatch({
      type: ActionTypes.EDIT_PROCESS_LIST,
      payload: response
    });
  } catch (error) {
    console.log(error);
  }
};
  // Fetch Realtimeprojects

  export const fetchRealtimeprojects = () =>async(dispatch)=>{
    try{
      const response = await axiosinstance.get('/api/RealtimeProjectImage-aws/');
      const RealtimeProjectsData = response.data;
  console.log( 'fetchRealtimeProjectsData',RealtimeProjectsData);
      dispatch({
        type:ActionTypes.FETCH_REALTIMEPROJECTS,
        payload:RealtimeProjectsData,
      });
    }catch(error){
      console.error('Error fetching realtime projects:', error);
    }
  };

 //fetch realtime projects by id
  export const fetchRealtimeprojectsById =(id)=>async(dispatch)=>{
    try{
           const response=await axiosinstance.get(`/api/Realtimeprojects/${id}`);
           const  RealtimeProjectDetails =response.data;
            dispatch({
              type:ActionTypes.FETCH_REALTIMEPROJECTS_BY_ID,
              payload:RealtimeProjectDetails,

            });
          }catch(error){
            console.error('Error fetching Real time projects:',error);
          }
          };
// upload Realtime project Details
export const uploadRealtimeprojectDatails = (RealtimeProjectDetails) => async (dispatch) => {
  try {
    const response = await axiosinstance.post('/api/Realtimeprojects', RealtimeProjectDetails);
    const listData = response.data;
    console.log("programs loaded", listData);

    dispatch({
      type: ActionTypes.UPLOAD_REAL_TIME_PROJECT_DETAILS_SUCCESS,
      payload: listData,
    });
    toast.success("You have successfully Uploaded")
  } catch (error) {
    console.error(error);
   
  }
};
  //upload Project image to aws
  export const uploadProjectImage=(formData)=>async(dispatch)=>{
    try{
      const response = await axiosinstance.post('/api/RealtimeProjectImage-aws/upload',formData)
      const imageData = response.data.message;
      console.log('imageData',imageData)
     dispatch({
      type:'UPLOAD_PROJECT_IMAGE',
      payload:imageData
     });
  
    }catch(error){
      console.error('Error adding project image',error)
    }
    };
  
    // Fetch ProjectImage/real time project detais
    export const fetchProjectImage=()=>async(dispatch)=>{
      try{
        const response =await axiosinstance.get('/api/RealtimeProjectImage-aws/');
        const imageData=response.data;
        // console.log('projectDetails',imageData)
        dispatch({
          type: ActionTypes.FETCH_PROJECT_IMAGE,
          payload:imageData,
        });
      }catch(error){
        console.error('Error fetching project image details data:',error);
      }
    };
  
    export const deleteProjectImage=(project_name)=>async(dispatch)=>{
      try {
        const response = await securedInstance.delete(`/api/RealtimeProjectImage-aws/${project_name}`)
        .then((res)=>{
           toast.success("You have successfully deleted ")            
        })
        dispatch({
            type: ActionTypes.DELETE_PROJECT_AWS_IMAGE,
            payload: response
        }) 
        console.log('successfully delered ',deleteProjectImage)
    } catch (error) {
      console.log(error)
    }
    }; 
    

    export const updateProjectImageByproject_name = ( updatedProjectData,project_name) => async (dispatch) => {
    
      try {
          const response = await securedInstance.put(`/api/RealtimeProjectImage-aws/${project_name}`,updatedProjectData)
          .then((res)=>{   
              const listData = res.data
            // console.log("user loaded", listData)
              return listData;
          })
          dispatch({
              type: ActionTypes.EDIT_PROJECT_AWS_IMAGE,
            
              payload: response
          }) 
      } catch (error) {
        console.log(error)
      }
    };

    export const fetchProjectImageByprojectname = (project_name) => async (dispatch) => {
      try {
        const response = await axiosinstance.get(`/api/RealtimeProjectImage-aws/${project_name}`);
    
        if (response.status === 200) {
          const listData = response.data;
          // Dispatch the data to the Redux store
          dispatch({
            type: ActionTypes.FETCH_PROJECT_IMAGE_BY_PROJECT_NAME,
            payload: listData,
          });
        } else {
          // Handle non-200 status codes here and log errors to the console
          console.error('Unexpected status code:', response.status);
        }
      } catch (error) {
        // Handle network errors or other exceptions here and log them to the console
        console.error('Error while fetching course by ID:', error);
      }
    };
    
  
    export const updateVisitorCount=(ipAddress)=>async(dispatch)=>{
      try{

   const response= axiosinstance.post('/api/visitorCount/update', {ipAddress} )
 const vistordata= response.data;
//  console.log('Visitor count updated successfully');
  // console.log("visitorCount posted",vistordata)
dispatch({
type:"UPLOAD_VISTOR_DATA",
payload:vistordata  
});
  }
  catch(error ) {
      console.error('Error updating visitor count:', error);
  }
}



export const fetchVisitorCount=()=>async(dispatch)=>{
  try{
    const response=await axiosinstance.get('/api/visitorCount/');
    const responsedata=response.data;
    dispatch({
      type:ActionTypes.FETCH_VISTOR_COUNT,
      payload:responsedata})

    }catch (error){
      console.log(error);
    }
  };

 //upload Project image to aws
 export const uploadCompanyNameLogo=(formData)=>async(dispatch)=>{
  try{
    const response = await axiosinstance.post('/api/companyNameAws/upload',formData)
    const logoData = response.data.message;
    console.log('logoData',logoData)
   dispatch({
    type:'UPLOAD_COMPANY_NAME',
    payload:logoData
   });

  }catch(error){
    console.error('Error adding company image',error)
  }
  };


// Fetch CompanyName/real time project detais
export const fetchCompanyName=()=>async(dispatch)=>{
  try{
    const response =await axiosinstance.get('/api/companyNameAws/');
    const logoData=response.data;
    // console.log('projectDetails',imageData)
    dispatch({
      type: ActionTypes.FETCH_COMPANY_NAME,
      payload:logoData,
    });
  }catch(error){
    console.error('Error fetching Company placement  details data:',error);
  }
};

export const deleteCompanyName=(Company_name)=>async(dispatch)=>{
  try {
    const response = await securedInstance.delete(`/api/companyNameAws/${Company_name}`)
    .then((res)=>{
       toast.success("You have successfully deleted ")            
    })
    dispatch({
        type: ActionTypes.DELETE_COMPANY_NAME_AWS,
        payload: response
    }) 
    console.log('successfully delered ',deleteCompanyName)
} catch (error) {
  console.log(error)
}
}; 



export const fetchCompanyNameByCompany_name = (Company_name) => async (dispatch) => {
  try {
    const response = await axiosinstance.get(`/api/companyNameAws/${Company_name}`);

    if (response.status === 200) {
      const listData = response.data;
      // Dispatch the data to the Redux store
      dispatch({
        type: ActionTypes.FETCH_COMPANY_NAME_BY_COMPANY_NAME,
        payload: listData,
      });
    } else {
      // Handle non-200 status codes here and log errors to the console
      console.error('Unexpected status code:', response.status);
    }
  } catch (error) {
    // Handle network errors or other exceptions here and log them to the console
    console.error('Error while fetching company name by ID:', error);
  }
};


export const updateCompanyNameByCompany_name = ( updatedlogoData ,Company_name) => async (dispatch) => {

  try {
      const response = await securedInstance.put(`/api/companyNameAws/${Company_name}`,updatedlogoData)
      .then((res)=>{   
          const listData = res.data
         console.log("user loaded", listData)
          return listData;
      })
      dispatch({
          type: ActionTypes.EDIT_COMPANY_NAME_AWS,
        
          payload: response
      }) 
  } catch (error) {
    console.log(error)
  }
};
export const uploadbellNofifications=(registerData,number)=>async(dispatch)=>{
  try{
    const response= await axiosinstance.post('/api/bellNotify/upload',registerData,number)
   const data=response.data;
  //  return data;
dispatch({
  type:ActionTypes.UPLOAD_BELL_NOTIFICATION_ENTRY,
  payload:data})

  }
  catch(error){
    console.log("error in uploading",error)

  }
}

export const fetchbellNotificationDataByNumber=()=>async(dispatch)=>{
  try{
    const response=await axiosinstance.get('/api/bellNotify/getByNumber')
    const data=response.data;
    dispatch({
      type:ActionTypes.FETCH_BELL_NOTIFICATION,
      payload:data,
    });
  
  }catch(err){
    console.error('Error fetching notification',err);
  }
}

export const updateBellNotification=(username,number)=>async(dispatch)=>{
  try{
    const response=await securedInstance.put('/api/bellNotify/update',username,number)
    const data =response.data;
    dispatch({
      type:ActionTypes.UPDATE_BELL_NOTIFICATION,
      payload:data,
    })
  }catch(error){
    console.error("failed to update",error)
  }
}

  //upload Instructor Image
   
export const uploadInstructorImage =(formData)=>async (dispatch)=>{
  try{
    const response = await axiosinstance.post('/api/InstructorImgAWS/upload',formData)
    const imageData = response.data.message;
    console.log('logoData',imageData)
    // console.log('company_name',)
   dispatch({
    type:'UPLOAD_INSTRUCTOR_IMAGE',
    payload:imageData
   })
  }catch(error){
    console.error('Erroe adding instructor image',error)
  }
  };
  

  

