import { ActionTypes } from "../constants/action-types";

const initialState={
    logoData:[],
};

const companyNameReducer=(state=initialState,action)=>{
    switch(action.type){
        case ActionTypes.FETCH_COMPANY_NAME:
            return{
                ...state,
                logoData:action.payload,
            };
            default:
                return state;
    }
};
export default companyNameReducer;