import { ActionTypes } from "../constants/action-types";

const initialState = {
  interviewQaData: [],
};

const FetchInterviewQa = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_INTERVIEW_QA:
      return {
        ...state,
        interviewQaData: action.payload,
      };

    default:
      return state;
  }
};

export default FetchInterviewQa;