// reducer.js
import { ActionTypes } from '../constants/action-types';

const initialState = {
  email: null,
  error: null, // Add an error field to store the error message
};

const PostEmailOtp = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_EMAIL_CODE:
      return {
        ...state,
        email: action.payload,
        error: null, // Reset error state on success
      };
    case ActionTypes.SET_EMAIL_CODE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default PostEmailOtp;