import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchEventAdList} from '../redux/actions/Actions';

const EventsAd = () => {
  const dispatch = useDispatch();
  const fetchEventAd = useSelector((state) => state.fetchAllEventsAd.eventsAd);

  useEffect(() => {
    dispatch(fetchEventAdList());
  }, [dispatch]);


  
  return (
    <div className='events-strip-container'>
      <div className='row m-0 justify-content-center text-center bg-warning py-2'>
        <div className='col-lg-12'>
          <div className='marquee-container'>
            <div className='marquee'>
              {fetchEventAd.map((eachEvent) => (
                <Link key={eachEvent?.event_id} to={`latestEvent/${eachEvent?.event_id}`}>
                  <p className='event-item'>{eachEvent?.event_name}</p>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsAd;
