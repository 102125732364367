import React from 'react'
import AdminCp from './AdminCp';
import ExecutorCp from './ExecutorCp';
import { useSelector } from 'react-redux';
const AuthCp = () => {
    const { isAuthenticated, user } = useSelector((state) => state.auth);
    const userId = user?.role_id
    console.log('logged in as',userId)
  return (
    <div className='container'>
    {
        isAuthenticated && userId === 1 ? <AdminCp />
         : (<ExecutorCp />)

      }
    </div>
  )
}

export default AuthCp