import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChannelPartners } from '../../../redux/auth/authActions';
import { approveRequest, rejectRequest } from '../../../redux/auth/authActions';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import PendingRequests from '../../../channelpartners/auth/PendingRequests';
import Button from '@mui/material/Button';

const ChannelPatner = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const channelPartners = useSelector((state) => state.channelPartner.channelPartners);
  const userId = user?.role_id;
  const [data, setData] = useState([]);



  useEffect(() => {
    dispatch(fetchChannelPartners());
  }, [dispatch]);

  const getRowId = (row) => row.cp_id;

  const excludedFields =
    ['password',
      'username',
      'pan_number',
      'aadhar_number',
      'cp_office_address',
      'website_needed',
      'registeration_fees_paid',
      'role_id',
      'modified_date'];

  const getColumns = () => {
    if (channelPartners.length === 0) {
      return [];
    }

    const propertyKeys = Object.keys(channelPartners[0]);

    const columns = [
      ...propertyKeys
        .filter((property) => !excludedFields.includes(property))
        .map((property) => ({
          field: property,
          headerName: property,
          width: 200,
          sortable: false,
          disableColumnMenu: true,
        })),
      {
        field: 'delete',
        headerName: 'Delete',
        width: 120,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleReject(params.row.cp_id)}
          >
            Delete
          </Button>
        ),
      },
    ];

    return columns;
  };

  useEffect(() => {
    fetchChannelPartners();
  }, []);


  const handleApprove = (cpId) => {
    dispatch(approveRequest(cpId));
  };

  const handleReject = (cpId) => {
    dispatch(rejectRequest(cpId));
  };

  return (
    <div>
      <h2 className='container'> Registered Partners</h2>
      <div>

        {isAuthenticated && userId === 1 ? (
          <div>
            <div style={{ marginTop: '80px' }}>
              <DataGrid
                rows={channelPartners}
                columns={getColumns()}
                pageSize={10}
                autoHeight
                checkboxSelection
                getRowId={getRowId}
              />
            </div>
          </div>
        ) : null}
      </div>
      <PendingRequests
        onApprove={handleApprove}
        onReject={handleReject} />
    </div>
  );
};

export default ChannelPatner;