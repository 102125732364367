import { ActionTypes } from "../constants/action-types"

const initialState = {
    tutorialCategoryById: []
}

const FetchTutorialCategoryById = (state=initialState, {type, payload})=>{
    switch(type){
        case ActionTypes.FETCH_TUTORIAL_CATEGORY_BY_ID : return {...state, 
            tutorialCategoryById: payload}           
        default: return state;
    }
    
}


export default FetchTutorialCategoryById;