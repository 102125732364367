
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Grid,  Paper, CircularProgress } from '@mui/material';
import TextfieldCustom from '../../../common/TextfieldCustom';
import { fetchCoursesById,  updateCourseById ,updateCourseImage } from '../../../redux/actions/Actions';


const UpdateCourse = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userId = user?.role_id
  const fetchCourseById = useSelector((state)=> state. fetchCourseById.courseById)
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()
  const [Image, setImage] = useState(null);
  const [Pdf, setPdf] = useState(null);
  useEffect(()=>{
    dispatch(fetchCoursesById(id))
  },  [dispatch,id])
   
  const selectedCourse = fetchCourseById[0]

  const [formData, setFormData] = useState({
    course_name: '',
    instructor_id: null,
    description: '',
    duration: ''
  });

  const { course_name, instructor_id, description, duration } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  
  const handleimageChange = (e) => {
    const selectedimage = e.target.files[0];
    setImage(selectedimage);
  }
  
  const handlePdffileChange= (e) => {
    const selectedfile = e.target.files[0];
    setPdf(selectedfile);
  }
  
  const courseData = {
    course_name: formData.course_name, 
    instructor_id: null,
    description: formData.description, 
    duration: formData.duration
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!Image) {
      dispatch(updateCourseById(courseData, id));
      navigate("/auth/allCourses")
    } else if (Image) {
      dispatch(updateCourseById(courseData, id));
      const formDataWithImage = new FormData();
      formDataWithImage.append("course_image",Image);
      formDataWithImage.append("course_pdf",Pdf);
      dispatch(updateCourseImage(formDataWithImage, id))
      navigate("/auth/allCourses")
    } else {
      return; 
    }
  };

  useEffect(() => {
    if (selectedCourse) {
      setFormData({
        course_name: selectedCourse?.course_name,
        instructor_id: selectedCourse?.instructor_id,
        description: selectedCourse?.description,
        duration: selectedCourse?.duration
      })
    }
  }, [selectedCourse])
  
  return (
    <div>
      <form onSubmit={handleSubmit}>
      <Container maxWidth="sm" className=" d-flex" >
    
    <Grid
      container
      spacing={2}
      direction="column"
      justifyContent="center"
      
    >
       <div style={{ width: "inherit"}}>
      <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
      <h3>Update Course</h3>
      <h6>Course name: {selectedCourse?.course_name}</h6>
      <Button onClick={()=>navigate(-1)}>Back</Button>
      <Grid container  spacing={2} sx={{border: '1px solid rgba(0,0,0,0.125', zIndex:"3"}} style={{padding: "30px"}}>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="course_name" 
             value={course_name} onChange={handleChange} placeholder="Course Name"
             required
             />            
           </Grid>
           <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="description" 
             value={description} onChange={handleChange} placeholder="description"
             required
             />            
           </Grid>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="duration" 
             value={duration} onChange={handleChange} placeholder="duration"
             required
             />            
           </Grid>
           <Grid item xs={6}></Grid>
           <Grid item xs={6}>
      Select Course Image
      <input
        type="file"
        name="course_image"
        accept=".png, .jpg, .jpeg" label="Course image"
         onChange={handleimageChange}
      />
      {Image && (
        <div className="image-info">
          <p>
            {/* <strong>Name: </strong>{Image.name} */}
          </p>
        </div>
      )} 
    </Grid>
    <Grid item xs={6}>
           Upload Syllabus (PDF)
      <input
        type="file"
        name="course_pdf"
        accept=".doc,.docx,.pdf" label="doc file"
         onChange={handlePdffileChange}
      />
      {Image && (
        <div className="image-info">
          <p>
            {/* <strong>Name: </strong>{Pdf.name} */}
          </p>
        </div>
      )} 
    </Grid>
           <Grid item xs={12}>
                 <Button variant="contained" style={{backgroundColor: '#3251A3', borderColor: '#FF5E14'}} 
                 fullWidth type="submit">
                   Update & Save Program
                 </Button>
            </Grid>
      </Grid>
      </Paper>
      </div>
      </Grid>
      </Container>
    </form>      
    </div>
  )
}

export default UpdateCourse
