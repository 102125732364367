import { ActionTypes } from "../constants/action-types";

const initialState = {
  // isSwitchEnabled: false, // Initial state
  showHeroContent:true, // Show hero content
};
// console.log(initialState);

const ToggleSwitchReducer = (state = initialState, action) => {
  switch (action.type) {
    // case ActionTypes.TOGGLE_SWITCH:
    //   return {
    //     ...state,
    //     isSwitchEnabled: action.payload,
    //   };
      case ActionTypes.TOGGLE_HERO_CONTENT:
          return {
            ...state,
            showHeroContent:!state.showHeroContent,
           
          };
          
    // Add other cases if needed
   
    default:
      return state;
  }
  
};

export default ToggleSwitchReducer;
