
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchTestimonials } from "../redux/actions/Actions";

const Testimonials = () => {
  const testimonials = useSelector((state) => state.fetchAllTestimonials.testimonials);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTestimonials());
  }, [dispatch]);

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5, slidesToSlide: 3 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3, slidesToSlide: 3 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  // Shortened truncateText function
  const truncateText = (text, maxLines) => {
    if (typeof text === "string") {
      const words = text.split(" ");
      if (words.length > maxLines * 6) {
        return words.slice(0, maxLines * 4).join(" ") + "...";
      } else {
        return text;
      }
    } else {
      return "";
    }
  };

  // State to track whether to expand the text
  const [expanded, setExpanded] = useState(false);

  // Function to toggle text expansion
  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="Testimonials">
      <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
          <div className="text-center">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Testimonial
            </h6>
            <h1 className="mb-5">Our Students Say!</h1>
          </div>
          <Carousel
            responsive={responsive}
            autoPlay={true}
            swipeable={true}
            draggable={true}
            showDots={false}
            infinite={true}
            partialVisible={false}
            dotListClass="custom-dot-list-style"
            removeArrowOnDeviceType={["tablet", "mobile"]}
          >
            {testimonials.map((eachItem) => (
              <div className="testimonial-item text-center mb-3" key={`${eachItem.name}${eachItem.job}`}>
                <img
                  className="border rounded-circle p-0  mb-2"
                  src={eachItem.testimonial_image_url ? eachItem.testimonial_image_url : eachItem.testimonial_id}
                  alt="upl"
                />
                <h5>{eachItem.candidate_name}</h5>
                {/* <p>{eachItem.testimonial_id}</p> */}
                <p></p>
                <div className="testimonial-text text-center p-0">
                  <div className="testi-text">
                    <p className="mb-0 review">
                      {expanded ? eachItem.feedback : truncateText(eachItem.feedback, 4)}
                      {!expanded && truncateText(eachItem.feedback, 4).length < eachItem.feedback.length && (
                        <span
                          style={{ cursor: "pointer", marginLeft: "4px", fontWeight: "bold" }}
                          onClick={toggleExpansion}
                        >
                          Read more
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;


