import { ActionTypes } from "../constants/action-types"

const initialState = {
  eventAdById: []
}

const FetchEventAdById = (state=initialState, {type, payload})=>{
    switch(type){
        case ActionTypes.FETCH_EVENTAD_BY_ID : 
        return {...state, eventAdById : payload}           
        default: return state;
    }
    
}


export default FetchEventAdById;