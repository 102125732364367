import { ActionTypes } from "../constants/action-types"

const initialState = {
  newCategory:[]

}

export const UploadCategory = (state=initialState, {type, payload})=>{
switch (type){
    case ActionTypes.UPLOAD_CATEGORY: 
    return {...state, newCategory: payload}
    default: return state
}

}

export default UploadCategory