import { ActionTypes } from "../constants/action-types";

const initialState = {
    registrationRemindme: [],
};

const fetchRemindme = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_REMINDME:
      return {
        ...state,
        registrationRemindme: action.payload,
      };

    default:
      return state;
  }
};

export default fetchRemindme;