const Erp = () => {

  return(

  <>
    <div className="container-fluid p-0">
      <h1 className="text-center">ERP</h1>
    </div>
  </>
  );

}

export default Erp;