import { ActionTypes } from "../constants/action-types"

const initialState = {
    materials:[]

}

export const FetchMaterials = (state=initialState, {type, payload})=>{
switch (type){
    case ActionTypes.FETCH_MATERIALS: 
    return {...state, materials: payload}
    case ActionTypes.FETCH_MATERIALS:
        return {
          ...state,
          materials: state.materials.filter((item, index) => index !== payload)
        }
    default: return state
}

}