import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search'; 
import { Grid, TextField, IconButton  } from '@mui/material';  
import axios from 'axios'; // Import Axios
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { deleteonlineclass } from '../../redux/actions/Actions';

const RemindMeClass = () => {
  const [registrations, setRegistrations] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch(); // Initialize dispatch function from useDispatch hook

  useEffect(() => {
    fetchRegistrations();
  }, []);

  const fetchRegistrations = async () => {
    try {
      const response = await axios.get('http://localhost:8000/api/EmailOtp/');
      const updatedRegistrations = response.data.map((registration, index) => ({
        ...registration,
        id: index + 1, // Add unique IDs to each row
      }));
      setRegistrations(updatedRegistrations);
    } catch (error) {
      console.error('Error fetching registrations:', error);
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'name2', headerName: 'Name', width: 200 },
    { field: 'Email', headerName: 'Email', width: 300 },
    { field: 'mobileNumber', headerName: 'Mobile Number', width: 200 },
    // { field: 'course', headerName: 'Course', width: 200 },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 100,
      renderCell: (params) => (
        <IconButton onClick={() => handleDelete(params.row.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const handleDelete = async (id) => {
    try {
      // Dispatch deleteonlineclass action
      dispatch(deleteonlineclass(id));
    } catch (error) {
      console.error('Error deleting registration:', error);
    }
  };

  const filteredRegistrations = registrations.filter((registration) =>
  Object.values(registration).some((value) =>
    value && value.toString().toLowerCase().includes(searchValue.toLowerCase())
  )
);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport
          csvOptions={{
            fileName: 'userDataBase',
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: 400, width: '100%' }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6}>
          <TextField
            label="Search"
            variant="outlined"
            value={searchValue}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
            fullWidth
          />
        </Grid>
      </Grid>
      <DataGrid
        rows={filteredRegistrations}
        columns={columns}
        pageSize={5}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
};

export default RemindMeClass;
