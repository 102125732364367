import { ActionTypes } from "../constants/action-types";

const initialState={
    projectImageStatus:[],
};

export const uploadProjectImage = (state=initialState,{type,payload})=>{
    switch(type){
        case 'UPLOAD_PROJECT_IMAGE':
            return{...state,projectImageStatus:payload};
            default:
                return state;
    }
}