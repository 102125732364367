// import React, { useState } from 'react';
// import { Button, TextField, Checkbox, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, Link } from '@mui/material';
// import { maxWidth } from '@mui/system';
// import { useDispatch, useSelector } from 'react-redux';
// import { register } from '../../redux/auth/authActionsCP';

// const CPRegisterForm = () => {
//   const dispatch = useDispatch();
//   const registrationSuccess = useSelector((state) => state.registrationSuccess);
//   const registrationError = useSelector((state) => state.registrationError);
//   const [formData, setFormData] = useState({
//     Channel_partner_name: '',
//     username: '',
//     password: '',
//     email: '',
//     phone_number: '',
//     pan_number: '',
//     aadhar_number: '',
//     cp_office_address: '',
//     website_needed: 'No',
//     registeration_fees_paid: 'No',
//   });

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     dispatch(register(formData));
//     alert("Registration successful, awaiting approval")
//     window.location.href = '/';   
//   };


//   return (
//     <div className="cp-register-form">

//       <div className='container' style={{ maxWidth: '80%' }}>
//         <h2>Register as Channel Partner</h2>
//         <form onSubmit={handleSubmit} style={{ width: '70%' }}>
//           <TextField
//             label="Your Name"
//             name="Channel_partner_name"
//             value={formData.Channel_partner_name}
//             onChange={handleInputChange}
//             required
//           />
//           <TextField
//             label="Username"
//             name="username"
//             value={formData.username}
//             onChange={handleInputChange}
//             required
//           />
//           <TextField
//             label="Password"
//             type="password"
//             name="password"
//             value={formData.password}
//             onChange={handleInputChange}
//             required
//           />
//           <TextField
//             label="Email Address"
//             type="email"
//             name="email"
//             value={formData.email}
//             onChange={handleInputChange}
//             required
//           />
//           <TextField
//             label="Phone Number"
//             name="phone_number"
//             value={formData.phone_number}
//             onChange={handleInputChange}
//             required
//           />
//           <TextField
//             label="PAN Number"
//             name="pan_number"
//             value={formData.pan_number}
//             onChange={handleInputChange}
//             required
//           />
//           <TextField
//             label="Aadhar Number"
//             name="aadhar_number"
//             value={formData.aadhar_number}
//             onChange={handleInputChange}
//             required
//           />
//           <TextField
//             label="CP Office Address"
//             name="cp_office_address"
//             value={formData.cp_office_address}
//             onChange={handleInputChange}
//             required
//             multiline
//             rows={4}
//           />
//           <FormControl component="fieldset">
//             <FormLabel component="legend">Website Needed</FormLabel>
//             <RadioGroup
//               name="website_needed"
//               value={formData.website_needed}
//               onChange={handleInputChange}
//             >
//               <FormControlLabel
//                 value="Yes"
//                 control={<Radio />}
//                 label="Yes"
//               />
//               <FormControlLabel
//                 value="No"
//                 control={<Radio />}
//                 label="No"
//               />
//             </RadioGroup>
//           </FormControl>
//           <FormControl component="fieldset">
//             <FormLabel component="legend">Registration Fees Paid</FormLabel>
//             <RadioGroup
//               name="registeration_fees_paid"
//               value={formData.registeration_fees_paid}
//               onChange={handleInputChange}
//             >
//               <FormControlLabel
//                 value="Yes"
//                 control={<Radio />}
//                 label="Yes"
//               />
//               <FormControlLabel
//                 value="No"
//                 control={<Radio />}
//                 label="No"
//               />
//             </RadioGroup>
//           </FormControl>
//           <Button type="submit" variant="contained" color="primary" style={{ margin: '5%' }}>
//             Register
//           </Button>
//         </form>
//       </div>

//     </div>
//   );
// };

// export default CPRegisterForm;
import React, {  useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextfieldCustom from '../../common/TextfieldCustom';
import { Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField,Typography } from '@mui/material';
import CompanyPage from './Companypage';

const  CPRegisterForm = () => {
    const [companyName, setCompanyName] = useState('');
    const [category, setCategory] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [pincode, setPincode] = useState('');
    const [country, setCountry] = useState('');
    const [website, setWebsite] = useState('');
    const [phone, setPhone] = useState('');
    const [pan, setPan] = useState('');
    const [adhaar, setAdhaar] = useState('');
    const [errors, setErrors] = useState({});

  
    const navigate = useNavigate()
  
  
    const handleChange = (e) => {
      const { name, value } = e.target;
  
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
  
      switch (name) {
        case 'company_name':
          setCompanyName(value);
          break;
        case 'category':
          setCategory(value);
          break;
        case 'address':
          setAddress(value);
          break;
        case 'city':
          setCity(value);
          break;
        case 'state':
          setState(value);
          break;
        case 'pincode':
          setPincode(value);
          break;
        case 'country':
          setCountry(value);
          break;
        case 'website':
          setWebsite(value);
          break;
        case 'phone':
          setPhone(value);
          break;
        case 'adhaar':
          setAdhaar(value);
          break;
        case 'pan':
          setPan(value);
          break;
        default:
      };
  
    };
    const validateForm = () => {
      const newErrors = {};
  
      // Add validation rules here
      if (!companyName) {
        newErrors.company_name = 'Company Name is required';
      }
  
      if (!category) {
        newErrors.category = 'Type of Company is required';
      }
      if (!address) {
        newErrors.address = 'Company Address is required';
      }
      if (!phone) {
        newErrors.phone = 'Phone Number is required';
      }
      if (!adhaar) {
        newErrors.adhaar = 'Adhaar Number is required';
      }
      if (!pan) {
        newErrors.pan = 'PAN Number is required';
      }
  
      // Add more validation rules for other fields...
  
      setErrors(newErrors);
  
      // Return true if there are no validation errors
      return Object.keys(newErrors).length === 0;
    };
    const handleSubmit = (e) => {
      e.preventDefault();
  
      // Validate the form before submitting
      if (validateForm()) {
        // Your form submission logic here
        console.log('Form submitted successfully');
        navigate("/companypage");
      } else {
        console.log('Form has validation errors. Please fix them.');
      }
    };
    
  
    return (
      <>
        <div className='row m-0 align-items-center justify-content-center'>
         
          <div className='col-lg-12'>
            <form onSubmit={handleSubmit}>
              <Container maxWidth="sm" className=" d-flex p-0" >
  
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  justifyContent="center"
                >
                  <div style={{ width: "inherit" }}>
                    <Paper elevation={2} sx={{ padding: 5 }} style={{backgroundColor:"rgb(217, 254, 255)", marginTop: '50px' }}>
                    <Container>
      <Typography variant="h3" align="center"><h3>Register as Channel Partner</h3></Typography>
    </Container>
                      <Grid container spacing={2} sx={{ border: '1px solid rgba(0,0,0,0.125', zIndex: "3" }} style={{ padding: "30px" }}>
                        <Grid item xs={12}>
                          <TextField
                            type="text"
                            name="company_name"
                            placeholder="Company Name"
                            value={companyName}
                            onChange={handleChange}
                          />
                          {errors.company_name && <span style={{ color: 'red' }}>{errors.company_name}</span>}
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined" required>
                            <InputLabel htmlFor="event-category">Type of Company</InputLabel>
                            <Select
                              label="Category"
                              id="event-category"
                              name="category"
                              value={category}
                              onChange={handleChange}
                            >
                              <MenuItem value="One Person Companies">One Person Companies (OPC)</MenuItem>
                              <MenuItem value="Private Companies">Private Companies</MenuItem>
                              <MenuItem value="Government Companies">Government Companies</MenuItem>
                              <MenuItem value="Partnership">Partnership</MenuItem>
                            </Select>
                            {errors.category && <span style={{ color: 'red' }}>{errors.category}</span>}
                          </FormControl>
                        </Grid>
                        <Grid item container spacing={2} xs={12}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              type="text"
                              name="address"
                              placeholder="Address of company"
                              value={address}
                              onChange={handleChange}
                            />
                            {errors.address && <span style={{ color: 'red' }}>{errors.address}</span>}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              type="text"
                              name="city"
                              placeholder="City"
                              value={city}
                              onChange={handleChange}
                            />
                            {errors.city && <span style={{ color: 'red' }}>{errors.city}</span>}
                          </Grid>
                        </Grid>
                        <Grid item container spacing={2} xs={12}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              type="text"
                              name="state"
                              placeholder="State"
                              value={state}
                              onChange={handleChange}
                            />
                            {errors.state && <span style={{ color: 'red' }}>{errors.state}</span>}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              type="text"
                              name="pincode"
                              placeholder="Pincode"
                              value={pincode}
                              onChange={handleChange}
                            />
                            {errors.pincode && <span style={{ color: 'red' }}>{errors.pincode}</span>}
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            type="text"
                            name="country"
                            placeholder="Country"
                            value={country}
                            onChange={handleChange}
                          />
                          {errors.country && <span style={{ color: 'red' }}>{errors.country}</span>}
                        </Grid>
                        <Grid item container spacing={2} xs={12}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              type="text"
                              name="website"
                              placeholder="website"
                              value={website}
                              onChange={handleChange}
                            />
                            {errors.website && <div className="error" style={{ color: "red", fontSize: "12px", padding: "4px" }}>{errors.website}</div>}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              type="text"
                              name="phone"
                              placeholder="Phone"
                              value={phone}
                              onChange={handleChange}
                            />
                            {errors.phone && <span style={{ color: 'red' }}>{errors.phone}</span>}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              type="text"
                              name="pan"
                              placeholder="PAN/TAN number"
                              value={pan}
                              onChange={handleChange}
                            />
                            {errors.pan && <span style={{ color: 'red' }}>{errors.pan}</span>}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              type="text"
                              name="adhaar"
                              placeholder="Adhaar number"
                              value={adhaar}
                              onChange={handleChange}
                            />
                            {errors.adhaar && <span style={{ color: 'red' }}>{errors.adhaar}</span>}
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Button variant="contained" style={{ backgroundColor: '#3251A3', borderColor: '#FF5E14' }}
                            fullWidth type="submit" >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  </div>
                </Grid>
              </Container>
            </form>
          </div>
        </div>
      </>
    );
  };
  



export default CPRegisterForm;