// JobsReducer.js
import { ActionTypes } from "../constants/action-types";

const initialState = {
    jobDetails: null, 
  };
  
  const jobsByIdReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.FETCH_JOBS_BY_ID:
        return {
          ...state,
          jobDetails: action.payload, 
        };
  
      default:
        return state;
    }
  };
  
  export default jobsByIdReducer;
  