import React from 'react';
import { useSelector } from 'react-redux';
import WelcomeMenus from '../WelcomeMenus';
// import SubscribersList from './SubscribersList';
import NewslettersList from './NewsLettersList';

const SubscriberEnquiry = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userId = user?.role_id;
  console.log('logged in as', userId);

  return (
    <div className='container'>
      {isAuthenticated && (userId === 1 || userId === 3) ? (
        <NewslettersList />
      ) : (  
        <WelcomeMenus />
      )}
    </div>
  );
};

export default SubscriberEnquiry;
