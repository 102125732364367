
import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { fetchEventImages } from '../redux/actions/Actions';

const LandingPage = ({eventData}) => {     
  const formattedEventDate = (new Date(eventData?.event_date)).toLocaleDateString('en-GB');
  
 
  const eventId=eventData?.event_id;
  const dispatch = useDispatch();
  const imageUrl = useSelector((state) => state.fetchEventImageById.imageUrl); // Assuming your Redux state has an 'eventImage' slice with an 'imageUrl' property
  //const imageUrl = '/img/subhero/15.png' // Assuming your Redux state has an 'eventImage' slice with an 'imageUrl' property

  console.log(imageUrl);

  useEffect(() => {
    if (eventId) {
      dispatch(fetchEventImages(eventId)); // dispatch action to fetch event image by ID
    }
  }, [dispatch, eventId]);

   

  //const imgUrl='http://localhost:8000/public/eventimages/event_1695971939381-81848202.png'
 // console.log('Event Id: ',eventId);
  return (
    <div className="card rounded shadow">
      <div className="card-header fs-6" >
        <h4 className="card-title blue-text mb-0">{eventData?.event_heading}</h4>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-7">
            <h6 className="card-subtitle mb-2 blue-text">{eventData?.category}</h6>
            <h6 className="card-subtitle mb-2">{eventData?.event_subheading}</h6>
            <p className="card-text">{eventData?.event_description}</p>
            <p className="card-text">{eventData?.event_text}</p>
            <div className="row mb-2">
              <div className="col-md-6">
              <div className="highlight-box">
                  <strong className="text-warning">Event Type:</strong>{" "}
                  <span className="text-white">{eventData?.event_type}</span>
                </div>
              </div>
              <div className="col-md-6">
              <div className="highlight-box">
                  <strong className="text-warning">Event Date:</strong>{" "}
                  <span className='text-white'>{formattedEventDate}</span>
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
              <div className="highlight-box">
                  <strong className="text-warning">Target Audience:</strong>{" "}
                  <span className="text-white">{eventData?.event_target_audience}</span>
                </div>
              </div>
            </div>
            <p className="card-text">
              <span className="small dark-pink-text">{eventData?.event_hash_tags}</span>
            </p>
            <Link
              to={{
                pathname: `/eventregisterform/${eventData.event_name}`,
                search: `?eventId=${eventId}`,
                 }}              
                 className="btn btn-warning py-md-3 px-md-5 me-3 animated slideInLeft border-radius-btn"
            >
              Register
            </Link>
           
          </div>
          <div className="col-lg-5">
            <img src={imageUrl} alt="Event" className="img-fluid card-img rounded" />
          </div>
        </div>
      </div>
    </div>
  )
  

};

export default LandingPage;
