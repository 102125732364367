import { ActionTypes } from "../constants/action-types";

const initialState = {
  topicsData: [],
};

const FetchTopics = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_TOPICS:
      return {
        ...state,
        topicsData: action.payload,
      };

    default:
      return state;
  }
};

export default FetchTopics;