
// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import axiosinstance from '../../api/axiosinstance';
// import { fetchVisitorCount, updateVisitorCount } from '../redux/actions/Actions';

// const VisitorCount = () => {
//     const dispatch = useDispatch();

//     const [IPcounter, setIPCounter] = useState(null);
//     const [error, setError] = useState(null);
//     const [ipAddress, setIPAddress] = useState(null);
//     const counterdetails=useSelector((state) => state.VistorCountReducer.vistorCount);
//     // console.log("vistorCount......................", counterdetails)

//     useEffect(() => {
//         dispatch( fetchVisitorCount);// Fetch visitor count when component mounts
// fetchIPAddress();
//     dispatch(updateVisitorCount(ipAddress))
//     // Fetch IP address when component mounts
//     setIPCounter(counterdetails.counter)
// });

   
//     const fetchIPAddress = () => {
//         axiosinstance.get('https://api.ipify.org?format=json')
//             .then(response => {
//                 setIPAddress(response.data.ip);
//                 updateVisitorCount(response.data.ip); // Update visitor count after fetching IP address
//             })
//             .catch(error => {
//                 setError('Error fetching IP address');
//             });
//     };

   
//     return (
//         <div className='counter-container' style={{
//             border:'3px solid black',
//             width:'fit-content',
//             padding:'5px 10px 0 10px',
//             backgroundColor:'#0effdb',
//             borderRadius:'1.4rem'
//         }}>
//             {error ? (
//                 <h4>{error}</h4>
//             ) : (
//                 <h5 className='visitcounter'>
//                     Visitor Count : <strong>{IPcounter !== null ? IPcounter : 'Loading...'} </strong>
                
//                 </h5>
//             )}

//         </div>
//     );
// };

// export default VisitorCount;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axiosinstance from '../../api/axiosinstance';
import { fetchVisitorCount, updateVisitorCount } from '../redux/actions/Actions';

const VisitorCount = () => {
    const dispatch = useDispatch();
    const counterdetails = useSelector((state) => state.VistorCountReducer.vistorCount);

    const [IPcounter, setIPCounter] = useState(null);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        // Fetch visitor count when component mounts
        dispatch(fetchVisitorCount());
        // Fetch IP address when component mounts
        fetchIPAddress();
    }, [dispatch]);

    useEffect(() => {
        // Update IP counter when the counter details change
        setIPCounter(counterdetails.counter);
    }, [counterdetails]);

    const fetchIPAddress = () => {
        // axiosinstance.get('https://api.ipify.org?format=json')
        axiosinstance.get('https://api64.ipify.org?format=json')	
            .then(response => {
                const ipAddress = response.data.ip;
                // Update visitor count after fetching IP address
                dispatch(updateVisitorCount(ipAddress));
            })
            .catch(error => {
                setError('Error fetching IP address');
            });
    };

    return (
        <div className='counter-container' style={{
            border: '3px solid black',
            width: 'fit-content',
            padding: '5px 10px 0 10px',
            backgroundColor: '#0effdb',
            borderRadius: '1.4rem'
        }}>
            {error ? (
                <h4>{error}</h4>
            ) : (
                <h5 className='visitcounter'>
                    Visitor Count : <strong>{IPcounter !== null ? IPcounter : 'Loading...'} </strong>
                </h5>
            )}
        </div>
    );
};

export default VisitorCount;


