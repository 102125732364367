import Divider from '@mui/material/Divider';
import React from 'react';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Box, Tooltip } from '@mui/material';
import {
  Dashboard ,
  Group ,
  ArrowRight,
  ArrowDropUp,
  Notifications,
  Payment,
  ExpandLess,
  ExpandMore,
  ImageRounded,
  VerifiedUser,
  Category,
  GolfCourse,
  Event,
  AdsClick,
  Subscriptions,
} from "@mui/icons-material";
import channelPartnerdata from '../../../data/channelPartnerdata.json'
import { Link, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
const ChannelSideBar =({open, handleDrawerClose,theme})=>{
  const {isAuthenticated, user}=useSelector((state)=>state.auth);
  const userId=user?.role_id;
  console.log("userid is:",userId);
  const sidebarList=channelPartnerdata.sidebarmenu;
  const iconList = {
  VerifiedUser,
  Category,
  GolfCourse,
  Event,
  AdsClick,
  MailIcon,
  };
  return(
    <>
      <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
         
          <List>
            {sidebarList.map((text, index) => (
              <Tooltip
              key={text.key}
              title={open ? "" : text.title}
              placement="right"
              enterTouchDelay={0}
              enterDelay={10}
            >
              {/* DELAY NOT HAPPENING */}
              <ListItem key={text.key} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                  component={Link}
                  to={text.link}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {React.createElement(iconList[text.icon])}
                  </ListItemIcon>
                  <ListItemText primary={text.title} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
              </Tooltip>
            ))}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Outlet />
          {/* <DrawerHeader />
          <Typography paragraph>
            Lorem 
          </Typography>
          <Typography paragraph>
            Consequat
          </Typography> */}
        </Box>
    </>
  )
}

export default ChannelSideBar;