import React from 'react'
import { useSelector } from 'react-redux';
import WelcomeMenus from '../../WelcomeMenus';
//import UploadEvent from './UploadEvent';
import EventList from './EventList';
import AdminMenus from '../AdminMenus';

const AllEvents = () => {
    const { isAuthenticated, user } = useSelector((state) => state.auth);
    const userId = user?.role_id
    console.log('logged in as',userId)
  return (
    <div className="container-xxl d-flex justify-content-center align-items-center">
        {userId === 1 ? (isAuthenticated?

    <div className="container">
    <div className='btn-group' role='group' aria-label='Button group'>
            <AdminMenus buttonName='Add Event' pageName='uploadEvent' />
    </div>
          <div className='row dataTable pt-3'>
          <EventList />
          </div>
          </div>:(<WelcomeMenus/>)):userId===3?(isAuthenticated?
        <div className="container">
        {/* <div className='btn-group' role='group' aria-label='Button group'>
                <AdminMenus buttonName='Add Event' pageName='uploadEvent' />
        </div> */}
              <div className='row dataTable pt-3'>
              <EventList />
      </div>
      </div>:<WelcomeMenus/>):(<WelcomeMenus/>)
}
 
  </div>
  );
  
}

export default AllEvents