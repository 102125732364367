import React, { useEffect, useState, useMemo } from 'react';
import {
  DataGrid, GridColDef, GridValueGetterParams, GridToolbarContainer, GridToolbarExport,
  GridToolbarColumnsButton, GridToolbarFilterButton } from '@mui/x-data-grid';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import {getAllEventSubscribers} from '../../redux/actions/Actions';
import { useNavigate } from 'react-router-dom';
import { Grid, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';


const EventSubscriber = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const fetchSubscribersEvent = useSelector((state) => state.fetchEventSubscriberList.subscribersWithEvent);
  console.log('eventsubscriberlist',fetchSubscribersEvent) 

  const userId = user?.role_id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  useEffect(()=>{
    dispatch(getAllEventSubscribers())
  },[])

  function formatDate(dt) {

    const getYear = new Date(dt).getFullYear();
    const getMonth = new Date(dt).getMonth() + 1;
    const getMyDay = new Date(dt).getDate();
    const dateFormat = getMyDay + "/" + getMonth + "/" + getYear;
    return dateFormat;
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/* //<GridToolbarColumnsButton /> */}
        <GridToolbarFilterButton/>
         <GridToolbarExport 
           csvOptions={{
            fileName: 'userDataBase',
            //delimiter: ';',
            utf8WithBom: true,
          }}/>
      </GridToolbarContainer>
    );
  }

  
  const columns = useMemo(
    () => [
      { field: 'id', headerName: 'Id', width: 60, disableColumnMenu: true, sortable: false },
      { field: 'subscriber_name', headerName: 'Name', width: 160, sortable: true, disableColumnMenu: true },
      { field: 'email', headerName: 'Email', width: 160, disableColumnMenu: true, enableFiltering: false },
      { field: 'phone_number', headerName: 'Phone Number', width: 160, disableColumnMenu: true },
      { field: 'event_name', headerName: 'Event name', width: 160, disableColumnMenu: true,filter:false },
      { field: 'registered_date', headerName: 'Date', width: 140, disableColumnMenu: true }, 
      
    ], []
  );

  const rows = useMemo(
    () =>
      data?.map((row, index) => ({
        ...row,
        id: index + 1,
        subscriber_name: row.subscriber_name,
        email: row.email,
        phone_number: row.phone_number,
        event_name: row.event_name, 
       /*  registered_date: formatDate(row.registered_date) */
      })),
    [data]
  );
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("");

  const filteredRows = useMemo(() => {
    let filteredData = rows || [];
  
    // Filter by event name
    if (filterValue) {
      filteredData = filteredData.filter(
        (row) => row.event_name.toLowerCase() === filterValue.toLowerCase()
      );
    }
  
    // Filter by search value
    if (searchValue) {
      filteredData = filteredData.filter((row) =>
        Object.values(row).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    }
  
    return filteredData;
  }, [searchValue, filterValue, rows]);
 

  const getFilterModelParams = () => {
    const options = Array.from(new Set(rows.map((row) => row.event_name)));
  
    return {
      display: (params) => {
        const { api } = params;
        const value = params.value ?? '';
  
        const onFilterChange = (event, newValue) => {
          api.setFilterModel({
            ...api.getFilterModel(),
            items: newValue === '' ? [] : [{ columnField: 'event_name', operatorValue: 'contains', value }],
          });
        };
  
        return (
          <Autocomplete
            value={value}
            options={options}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filter by Program Name"
                variant="outlined"
                onChange={onFilterChange}
              />
            )}
          />
        );
      },
      items: [],
    };
  };
  
 /*  const filteredRows = rows.filter((row) =>
  row.program_name.toLowerCase().includes(filterValue.toLowerCase())
); */

  
  useEffect(() => {
    dispatch(getAllEventSubscribers());
  }, [dispatch,getAllEventSubscribers]);

  useEffect(() => {
    setData(fetchSubscribersEvent);
    
  }, [data,fetchSubscribersEvent]);


  return (
    <div className='container'>
      

      <Grid item xs={5}
        container justify="flex">
        <TextField
          label="Search"
          variant="outlined"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          style={{ marginTop: 0, marginBottom: 16 }} />
          <FormControl variant="outlined" style={{ marginLeft: 16, minWidth: 180 }}>
        <InputLabel>Event Name</InputLabel>
        <Select
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value === "All Events" ? "" : e.target.value)}
          label="Event Name"
          //onClick={}
        >
          <MenuItem value="">All Events</MenuItem>
          {/* Replace the program names from data with actual program names */}
          {data?.map((row) => (
            <MenuItem key={row.event_name} value={row.event_name}>
              {row.event_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      </Grid>

      {

        (
          
          <DataGrid

          
            style={{marginTop: '15px'}}
            rows={filteredRows || []}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            filterModelParams={getFilterModelParams}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            slots={{
              toolbar: CustomToolbar,
              
            }}/>

          
        )}
    </div>
  );
};

export default EventSubscriber;
