import React, { useEffect, useState, useMemo } from 'react';
import {
  DataGrid, GridColDef, GridValueGetterParams, GridToolbarContainer, GridToolbarExport,
  GridToolbarColumnsButton, GridToolbarFilterButton } from '@mui/x-data-grid';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSubscriberById, getAllSubscribers } from '../../redux/actions/Actions';
import { useNavigate } from 'react-router-dom';
import { Grid, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';


const NewslettersList = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const fetchSubscribers = useSelector((state) => state.getAllSubscribersNprogram.subscribersWithProgram);
console.log('resu',fetchSubscribers)
  const userId = user?.role_id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  function formatDate(dt) {
    const getYear = new Date(dt).getFullYear();
    const getMonth = new Date(dt).getMonth() + 1;
    const getMyDay = new Date(dt).getDate();
    const dateFormat = getMyDay + "/" + getMonth + "/" + getYear;
    return dateFormat;
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/* //<GridToolbarColumnsButton /> */}
        <GridToolbarFilterButton/>
         <GridToolbarExport 
           csvOptions={{
            fileName: 'userDataBase',
            //delimiter: ';',
            utf8WithBom: true,
          }}/>
      </GridToolbarContainer>
    );
  }

  
  const columns = useMemo(
    () => [
      { field: 'id', headerName: 'Id', width: 60, disableColumnMenu: true, sortable: true },
       { field: 'subscriber_name', headerName: 'Name', width: 160, sortable: true, disableColumnMenu: true },
      { field: 'email', headerName: 'Email', width: 160, disableColumnMenu: true, enableFiltering: false },
      { field: 'phone_number', headerName: 'Phone Number', width: 160, disableColumnMenu: true },
      // { field: 'program_name', headerName: 'Program Name', width: 160, disableColumnMenu: true,filter:false },
      { field: 'registered_date', headerName: 'Date', width: 140, disableColumnMenu: true },
      {
        field: 'action',
        headerName: 'Actions',
        width: 180,
        sortable: false,
        disableClickEventBubbling: true,

        renderCell: (params) => {
          const onClick = (e) => {
            const currentRow = params.row;
            console.log('clicked User is', currentRow.subscriber_id);
          };
          const handleDelete = (e) => {
            const currentRow = params.row;
            dispatch(deleteSubscriberById(currentRow.subscriber_id));
            console.log('clicked User is', currentRow.subscriber_id);

            setData(prevData => prevData.filter(row => row.subscriber_id !== currentRow.subscriber_id));
          };

          return (
            <Stack direction="row" spacing={2}>
              <IconButton onClick={handleDelete}>
                <Delete />
              </IconButton>
            </Stack>
          );
        },
      }
    ], []
  );

  const rows = useMemo(
    () =>
      data?.map((row, index) => ({
        ...row,
        id: index + 1,
        subscriber_name: row.subscriber_name,
        email: row.email,
        phone_number: row.phone_number,
        program_name: row.subprogram_name, // to display program name use program_name
        registered_date: formatDate(row.registered_date)
      })),
    [data]
  );
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("");

  const filteredRows = rows.filter((row) =>
    Object.values(row).some((value) =>
      value.toString().toLowerCase().includes(searchValue.toLowerCase())
    )
  );

  const getFilterModelParams = () => {
    const options = Array.from(new Set(rows.map((row) => row.program_name)));
  
    return {
      display: (params) => {
        const { api } = params;
        const value = params.value ?? '';
  
        const onFilterChange = (event, newValue) => {
          api.setFilterModel({
            ...api.getFilterModel(),
            items: newValue === '' ? [] : [{ columnField: 'program_name', operatorValue: 'contains', value }],
          });
        };
  
        return (
          <Autocomplete
            value={value}
            options={options}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filter by Program Name"
                variant="outlined"
                onChange={onFilterChange}
              />
            )}
          />
        );
      },
      items: [],
    };
  };
  
 /*  const filteredRows = rows.filter((row) =>
  row.program_name.toLowerCase().includes(filterValue.toLowerCase())
); */

  
  useEffect(() => {
    dispatch(getAllSubscribers());
  }, [dispatch]);

  useEffect(() => {
    setData(fetchSubscribers);
  }, [fetchSubscribers]);

  return (
    <div className='container'>
      

      <Grid item xs={5}
        container justify="flex">
        <TextField
          label="Search"
          variant="outlined"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          style={{ marginTop: 0, marginBottom: 16 }} />
          {/* <FormControl variant="outlined" style={{ marginLeft: 16, minWidth: 180 }}>
        <InputLabel>Program Name</InputLabel>
        <Select
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value)}
          label="Program Name"
          //onClick={}
        >
          <MenuItem value="">All Programs</MenuItem>
          {/* Replace the program names from data with actual program names */}
          {/* {data?.map((row) => (
            <MenuItem key={row.program_name} value={row.program_name}>
              {row.subprogram_name}
            </MenuItem>
          ))}
        </Select
      </FormControl> */} 

      </Grid>

      {(userId === 1 || 3) && 

        (
          
          <DataGrid

          
            style={{marginTop: '15px'}}
            rows={filteredRows || []}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            filterModelParams={getFilterModelParams}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            slots={{
              toolbar: CustomToolbar,
              
            }}/>

          
        )}
    </div>
  );
};

export default NewslettersList;
