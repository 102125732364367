import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Grid,  Paper } from '@mui/material';
import TextfieldCustom from '../../../common/TextfieldCustom';
import { fetchLessonId, updateLessonById } from '../../../redux/actions/Actions';

const UpdateLesson = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userId = user?.role_id
  const fetchLessonById = useSelector((state)=> state.getLessonsId.lessonsId)
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()
   
    useEffect(()=>{
      dispatch(fetchLessonId(id))
   },  [dispatch,id])
  // console.log('selected lesson name by id is', fetchLessonId)
   
   const selectedLesson = fetchLessonById[0]
   
  const [formData, setFormData] = useState({
    course_id: '',  
    lesson_name: '', 
    content: ''
  });

  const {  course_id,lesson_name,content } = formData;  
    
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  
  const lessonData = {
    course_id: formData.course_id,  
    lesson_name:formData.lesson_name , 
    content: formData.content 
  }

   const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateLessonById(lessonData,id));
      navigate("/auth/allLessons")
      // console.log("formdata", lessonData)        
  }; 
  useEffect(()=>{
    if (selectedLesson) {
    setFormData({
    course_id: selectedLesson?.course_id,
    lesson_name: selectedLesson?.lesson_name,
    content: selectedLesson?.content
    })
  }
   }, [selectedLesson])
  
  return (
    <div>
      <form onSubmit={handleSubmit}>
      <Container maxWidth="sm" className=" d-flex" >
    
    <Grid
      container
      spacing={2}
      direction="column"
      justifyContent="center"
      
    >
       <div style={{ width: "inherit"}}>
      <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
      <h3>Update Lesson</h3>
      <h6>Course name: {selectedLesson?.course_id}</h6>
      <Button onClick={()=>navigate(-1)}>Back</Button>
      <Grid container  spacing={2} sx={{border: '1px solid rgba(0,0,0,0.125', zIndex:"3"}} style={{padding: "30px"}}>
          
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="lesson_name" 
             value={lesson_name} onChange={handleChange} placeholder="lesson name"
             required
             />            
           </Grid>
           <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="content" 
             value={content} onChange={handleChange} placeholder="content"
             required
             />            
           </Grid>
           <Grid item xs={12}>
                 <Button variant="contained" style={{backgroundColor: '#3251A3', borderColor: '#FF5E14'}} 
                 fullWidth type="submit">
                   Update & Save Program
                 </Button>
            </Grid>
      </Grid>
      </Paper>
      </div>
      </Grid>
      </Container>
    </form>      
    </div>
  )
}

export default UpdateLesson