import React, { useEffect, useMemo, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Grid, IconButton, Stack, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
 import {fetchCompanyName , deleteCompanyName} from '../../../redux/actions/Actions';
import { Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';



export default function CompanyPlacementTable() {


    const logoData12 = useSelector((state) => state.fetchcompanyname.logoData);

    //  console.log('imagedata12',logoData12)
        const {isAuthenticated,user}=useSelector((state)=>state.auth);
          const userId = user?.role_id
         const dispatch = useDispatch()
        const[dataRows,setDataRows] = useState([]) 
        const[dataColumns,setDataColumns]=useState([])
         const[data,setData] = useState([])
         const navigate = useNavigate()
    
            const columns = useMemo(() => [
                { field: 'id', headerName: 'ID', width: 60 },
                { field: 'Company_name', headerName: 'Company_name', width: 150 },
                { field: 'logo_url', headerName: 'logo_url', width: 550 },
                {
                    field: 'action',
                    headerName: 'Action',
                    width: 250,
                    sortable: false,
                    disableClickEventBubbling: true,
                    renderCell: (params) => {
                        const onClick = (e) => {
                            const currentRow = params.row;
                            navigate(`/auth/updateCompanyNamePlacementTableDetails/${currentRow.Company_name}`);
                        };
            
                        const handleDelete = (e) => {
                            const currentRow = params.row;
                            dispatch(deleteCompanyName(currentRow.Company_name));
                            console.log('clicked project details is', currentRow.Company_name);
                        };
            
                        return (
                            <Stack direction="row" spacing={2}>
                                <IconButton onClick={onClick}>
                                    <Edit />
                                </IconButton>
                                <IconButton onClick={() => handleDelete()}>
                                    <Delete />
                                </IconButton>
                            </Stack>
                        );
                    },
                },
            ], [navigate, dispatch]);
            
             const rows=useMemo(()=>data?.map((row,index)=>{
                return{
                    ...row,
                    id:index + 1,
                    Company_name:row.Company_name,
                    logo_url:row.logo_url,
                }
             }),[data]);
            
            const [searchValue, setSearchValue] = React.useState('');
            const filteredRows =rows.filter((row) =>
                Object.values(row).some(
                    (value) =>
                        value !== null &&
                        value !== undefined &&
                        value.toString().toLowerCase().includes(searchValue.toLowerCase())
                )
            );
        useEffect(()=>{
             dispatch(fetchCompanyName())
        },[dispatch,logoData12]);
        
        useEffect(()=>{
            setData(logoData12)
            setDataColumns(columns)
            setDataRows(rows)
        },[data,logoData12])
        
    
  return (
    <div>
          <div>
      <h2>Company_Placement Table Details</h2>


      <div className='job-applications'>
            <Grid item xs={5}
            >
            <TextField
                label="Search"
                variant="outlined"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                InputProps={{
                    endAdornment: <SearchIcon />,
                }}
                style={{ marginTop: 0, marginBottom: 15 }}
            />
            </Grid>
            {/* <Box sx={{ height: 400, width: '100%' }}> */}
                {
                userId===1 && (
                    <Box sx={{ height: 400, width: '100%' }}>

                <DataGrid
                style={{marginTop:'15px',marginBottom:'0px'}}
                    rows={filteredRows}
                    columns={columns}
                    initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 10 },
                        },
                      }}
                      pageSizeOptions={[5, 10]}
                      checkboxSelection
        
             />
          </Box>)

            }
       </div>


    </div>
    </div>
  )
}
