import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Grid,  Paper } from '@mui/material';
import TextfieldCustom from '../../../common/TextfieldCustom';
import { fetchUnitId, updateUnitById } from '../../../redux/actions/Actions';

const UpdateUnit = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userId = user?.role_id
  const fetchUnitById = useSelector((state)=> state.getUnitsId.unitsId)
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()
   
    useEffect(()=>{
      dispatch(fetchUnitId(id))
   },  [dispatch,id])
  // console.log('selected Unit name by id is', fetchUnitId)
   
   const selectedUnit = fetchUnitById[0]
   
  const [formData, setFormData] = useState({
    lesson_id: '',  
    unit_name: '', 
    content: ''
   
  });

  const {  lesson_id,unit_name,content } = formData;  
    
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  
  const unitData = {
    lesson_id: formData.lesson_id,  
    unit_name:formData.unit_name , 
    content: formData.content 
 
  }

   const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUnitById(unitData,id));
      navigate("/auth/allUnits")
      // console.log("formdata", unitData)        
  };  
  useEffect(()=>{
    if (selectedUnit) {
    setFormData({
    lesson_id: selectedUnit?.lesson_id,
    unit_name: selectedUnit?.unit_name,
    content: selectedUnit?.content
    
    })
  }
   }, [selectedUnit])
  
  return (
    <div>
      <form  onSubmit={handleSubmit} >
      <Container maxWidth="sm" className=" d-flex" >
    
    <Grid
      container
      spacing={2}
      direction="column"
      justifyContent="center"
      
    >
       <div style={{ width: "inherit"}}>
      <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
      <h3>Update Unit</h3>
      <h6>Lesson name: {selectedUnit?.lesson_id}</h6>
      <Button onClick={()=>navigate(-1)}>Back</Button>
      <Grid container  spacing={2} sx={{border: '1px solid rgba(0,0,0,0.125', zIndex:"3"}} style={{padding: "30px"}}>
          
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="unit_name" 
             value={unit_name} onChange={handleChange} placeholder="unit name"
             required
             />            
           </Grid>
           <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="content" 
             value={content} onChange={handleChange} placeholder="content"
             required
             />            
           </Grid>
           <Grid item xs={12}>
                 <Button variant="contained" style={{backgroundColor: '#3251A3', borderColor: '#FF5E14'}} 
                 fullWidth type="submit">
                   Update & Save Program
                 </Button>
            </Grid>
      </Grid>
      </Paper>
      </div>
      </Grid>
      </Container>
    </form>      
    </div>
  )
}

export default UpdateUnit