import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Grid, Paper } from '@mui/material';
import TextfieldCustom from '../../../common/TextfieldCustom';
import { uploadInstructorImage, uploadNewInstructor } from '../../../redux/actions/Actions';

const UploadInstructorForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Image, setImage] = useState(null);

  const [imageObject, setImageObject] = useState(null);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const [formData, setFormData] = useState({
    instructor_name: '',
    qualification: '',
    course: '',
    experience: '',
  });
  const [errors, setErrors] = useState({
    instructor_name: '',
    qualification: '',
    duration: '',
    experience: ''

  });

  const { instructor_name, qualification, course, experience } = formData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = '';

    // if (name === 'instructor_name' && !/^[a-zA-Z\s]+$/.test(value)) {
    //   error = "Instructor Name can only contain letters and spaces.";
    // } else if (name === 'course' && /\d/.test(value)) {
    //   error = "Course cannot contain numeric values.";
    // } else if (name === 'experience' && !(/(^\d+\s*\+\s*year$)/.test(value))) {
    //   error = "Experience must be a numerical value optionally followed by '+' and 'year'.";
    // }
    
    if (name === 'instructor_name' && /\d/.test(value)) {
      error = "Instructor Name cannot contain numeric values.";
    } else if (name === 'course' && /\d/.test(value)) {
      error = "Course cannot contain numeric values.";
    } else if (name === 'experience' && isNaN(value)) {
      error = "Experience must be a numerical value.";
    }
    // Update errors state
    setErrors({ ...errors, [name]: error });

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const imageData = {
    instructor_name: formData.instructor_name,
    qualification: formData.qualification,
    course: formData.course,
    experience: formData.experience,
  };
  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];

    if (imageFile) {
      const objectURL = URL.createObjectURL(imageFile);
      setImageObject({
        name: imageFile.name,
        size: imageFile.size,
        type: imageFile.type,
        url: objectURL,
      });
    } else {
      console.error('No image file selected');
    }
  };

   
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(uploadNewInstructor(imageData));
    navigate("/auth/allInstructors");
    console.log("formdata", imageData);
    try {
      const formData = new FormData();
      formData.append('instructor_name', instructor_name);
      formData.append('image', e.target.elements.imageInput.files[0]);

      // Dispatch action to upload project details and image
      dispatch(uploadInstructorImage(formData));

      // Reset form state
      setImageObject(null);

      // Set success state to true
      setSubmissionSuccess(true);

      // Navigate to the desired page
      // navigate('/SkilledInstructorsCard', {
      //   state: { imageObject, instructor_name },
      // });
    } catch (error) {
      console.error('Error uploading project details:', error);
    }

};


  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Container maxWidth="sm" className=" d-flex">
          <Grid container spacing={2} direction="column" justifyContent="center">
            <div style={{ width: "inherit" }}>
              <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
                <h3>Upload new Instructor</h3>
                <Button onClick={() => navigate(-1)}>Back</Button>
                <Grid container spacing={2} sx={{ border: '1px solid rgba(0,0,0,0.125', zIndex: "3" }} style={{ padding: "30px" }}>
                  <Grid item xs={6}>
                    <TextfieldCustom
                      type="text"
                      name="instructor_name"
                      value={instructor_name}
                      onChange={handleChange}
                      placeholder="Instructor Name"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextfieldCustom
                      type="text"
                      name="qualification"
                      value={qualification}
                      onChange={handleChange}
                      placeholder="Qualification"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextfieldCustom
                      type="text"
                      name="course"
                      value={course}
                      onChange={handleChange}
                      placeholder="Course"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextfieldCustom
                      type="text"
                      name="experience"
                      value={experience}
                      onChange={handleChange}
                      placeholder="Experience"
                      required
                    />
                  </Grid>

                  <Grid item xs={6}>
           <Button variant="contained" component="label" 
                               fullWidth
                               className="upload-button"
           >
           Upload Image
      <input
        type="file"
        name="imageInput"
        accept=".png, .jpg, .jpeg" label="Course image"
         onChange={handleImageChange}
        style={{ display: 'none' }}
      />
      
    </Button>
    {imageObject && (
                          <div className="image-preview">
                          <p style={{ 
  marginBottom: '10px',
  }}>
                            <strong>Name:</strong> {imageObject.name}
                          </p>
                          <br></br>
                        </div>
    
     )} 
   </Grid>

                  <Grid item xs={12}>
                    <Button variant="contained" style={{ marginTop:'20px', backgroundColor: '#3251A3', borderColor: '#FF5E14' }} fullWidth type="submit">
                      Upload Instructor
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </Grid>
        </Container>
      </form>
    </div>
  );
};

export default UploadInstructorForm;
