import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import jsondata from '../../data/data.json'
import { useDispatch, useSelector } from "react-redux";
import { fetchProgramList } from "../redux/actions/Actions";


const Navbar = () => {
  const fetchPrograms = useSelector((state) => state.fetchAllPrograms.programs)
  const dispatch = useDispatch()
  const [programs, setPrograms] = useState([])
  useEffect(() => {
    dispatch(fetchProgramList())
    //setPrograms(jsondata.Programs)
  }, [dispatch])

  const handlePhoneClick = (phoneNumber) => {
    console.log(`Calling ${phoneNumber}`);
    console.log(`Calling ${phoneNumber}`);
  };


  return (
    <div id="header_section">
      <section
        id="topbar"
        className={`topbar p-1 d-flex align-items-center bg-warning text-dark fw-bold`}
      >
        <div className="container d-flex justify-content-between">
          <div className="contact-info d-flex align-items-center">
            <a
              href="tel:+918431663025"
              className="d-flex align-items-center ms-4 Tollfree text-dark"
              onClick={() => handlePhoneClick('+91 84316 63025')}
            >
              Toll Free <i className="fa fa-phone"></i>+91 84316 63025 / +91 63635 07858
            </a>
            <a
              href="tel:+18482852392"
              className="d-flex align-items-center ms-4 Tollfree text-dark"
              onClick={() => handlePhoneClick('+1 (848) 285-2392')}
            >
              <span>+1 (848) 285-2392</span>
            </a>

          </div>
        </div>
      </section>
      <nav className="navbar navbar-expand-lg bg-white navbar-light pb-3" id="navbar_top">
        <Link to={"/"} className="navbar-brand d-flex align-items-center ">
          <img src='/img/upllogo.png' alt='unlimited power of learning' />
        </Link>
        <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <Link to={"/"} className="nav-item nav-link">Home</Link>
            <Link to="/about" className="nav-item nav-link">About</Link>
            <div className="nav-item dropdown">
              <Link to={"/programs"} className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Programs</Link>
              <div className="dropdown-menu fade-down m-0">
                <Link to={"/programs"} className="dropdown-item" >All Programs</Link>
                {
                  fetchPrograms.map((eachItem) => (
                    <Link to={`/programDetail/${eachItem.program_id}`} className="dropdown-item" key={eachItem.program_id}>{eachItem.program_name}</Link>
                  ))
                }
              </div>
            </div>
            {/* <Link to="/tutorials" className="nav-item nav-link">Tutorials</Link> */}
            {/* <Link to="/interview" className="nav-item nav-link">Interview</Link> */}
            {/* <Link to="/jobs" className="nav-item nav-link">Jobs</Link> */}
            <Link to="/CompanyImage" className="nav-item nav-link">Company Image</Link> 

            <Link to="/contact" className="nav-item nav-link">Contact</Link>
            <Link to="/login" className="nav-item nav-link">Login</Link>
            {/* <Link to={"/obregister"} className="nav-item nav-link">OnBoarding</Link> later change to /channelPartnerRegister */}
            <div className="nav-item dropdown">
              <Link className="nav-link dropdown-toggle" data-bs-toggle="dropdown">OnBoarding</Link>
              <div className="dropdown-menu fade-down m-0">
                <Link to={"/obregister"} className="nav-item nav-link">OnBoarding</Link>
                <a href="https://franchise.uplsnipe.com/" className="nav-item nav-link" target="_blank" rel="noopener noreferrer">Franchisee</a>
                {/* <Link to={"/programs"} className="nav-item nav-link" >Franchisee</Link> */}
              </div>
            </div>
          </div>
          <Link to={"/registerform"} className="btn btn-primary py-4 px-lg-5 d-none d-lg-block">Enroll Now<i className="fa fa-arrow-right ms-3"></i></Link>
        </div>
      </nav>
    </div>
  )
}

export default Navbar;