import { ActionTypes } from "../constants/action-types"

const initialState = {
    InstructorImageStaus:[],

};

export const UploadInstructorImage = (state=initialState, {type, payload})=>{
switch (type){
    case 'UPLOAD_INSTRUCTOR_IMAGE': 
    return {...state, 
        InstructorImageStaus:payload};

    default: return state;
}

}