import React from 'react';

const AboutPage = () => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8 position-relative">
          <img src="img/ZTUVEZ61 1.png" alt="" className="img-fluid rounded" style={{ maxWidth: "100%", height: "auto", borderRadius: "10px" }} />
          {/* <div className="position-absolute top-50 start-50 " style={{ width: "100%", maxWidth: "323px", left: "60%", padding: "20px", borderRadius: "20px", top: "50%", backgroundColor: "#02073F" }}>
            <p className="text-white text-center fw-bold fs-4">We are best for providing good education since 2008!</p>
          </div> */}
        </div>
      </div>

      <div className="row justify-content-center mt-5">
        <div className="col-md-12 text-center">
          <h1 className=" fw-bold" style={{ color: "#02073F", fontSize: "3.5rem" }}>Welcome to Unlimited Power Full Learning (UPL)</h1>
        </div>
      </div>


      <div className="content-center">
        <div className="col-md-12">
          <div style={{ maxWidth: "100%", color: "#3442D9", textAlign: "center", fontFamily: "Raleway", fontSize: "1.25rem", fontWeight: 700, margin: "20px 0", padding: "0 20px" }}>
            Welcome to Unlimited Power Full Learning (UPL), where our mission is to bridge the gap between students and the dynamic expectations of the IT industry. UPL is more than an organization; it's a visionary initiative aimed at overcoming challenges and nurturing a culture of continuous learning. At UPL, we aspire to create an environment that not only meets the evolving needs of the IT sector but also empowers individuals to thrive in this ever-changing landscape
          </div>
        </div>
      </div>
      <div className="row justify-content-between align-items-center" style={{ borderRadius: "30px", background: "var(--dark, #02073F)", marginTop: "40px", padding: "0 5%", maxWidth: "100%" }}>
        <div className="col-auto text-center">
          <div style={{ fontSize: "5vw", fontWeight: 650, color: "#FFFFFF" }}>1.6k+</div>
          <div className="text-white" style={{ fontSize: "1.5vw", fontWeight: 600 }}>Happy Students</div>
        </div>
        <div className="col-auto position-relative d-none d-md-block">
          <svg xmlns="http://www.w3.org/2000/svg" width="4" height="121" viewBox="0 0 4 121" fill="none" className="position-absolute top-50 start-50 translate-middle" style={{ transform: "translate(-50%, -50%)" }}>
            <path d="M1.89404 0.000488281V120.601" stroke="white" strokeWidth="3" />
          </svg>
        </div>
        <div className="col-auto text-center">
          <div style={{ fontSize: "5vw", fontWeight: 650, color: "#FFFFFF" }}>2k+</div>
          <div className="text-white" style={{ fontSize: "1.5vw", fontWeight: 600 }}>Best Courses</div>
        </div>
        <div className="col-auto position-relative d-none d-md-block">
          <svg xmlns="http://www.w3.org/2000/svg" width="4" height="121" viewBox="0 0 4 121" fill="none" className="position-absolute top-50 start-50 translate-middle" style={{ transform: "translate(-50%, -50%)" }}>
            <path d="M1.89404 0.000488281V120.601" stroke="white" strokeWidth="3" />
          </svg>
        </div>
        <div className="col-auto text-center">
          <div style={{ fontSize: "5vw", fontWeight: 650, color: "#FFFFFF" }}>1k+</div>
          <div className="text-white" style={{ fontSize: "1.5vw", fontWeight: 600 }}>Enrolled</div>
        </div>
      </div>
      <div className="row justify-content-center mt-5">
        <div className="col-lg-5">
          <div className="card" style={{ background: "#C1C5F2", height: "100%", borderRadius: "20px" }}>
            <h5 className="card-title text-center" style={{ fontFamily: "Raleway", fontSize: "30px", fontWeight: 500, color: "#FFFFFF", background: "#02073F", padding: "19px", borderRadius: "10px 10px 0 0" }}>Our Mission</h5>
            <div className="card-body" style={{ maxHeight: "358px" }}>
              <p className="card-text" style={{ fontFamily: "Raleway", fontSize: "16.5px", fontWeight: 500, textAlign: "justify", color: "#02073F", padding: "10px 15px" }}>
                Welcome to Unlimited Power Full Learning (UPL), where we bridge the gap between students and the dynamic expectations of the IT industry. UPL is a visionary initiative aimed at overcoming challenges and nurturing a culture of continuous learning, empowering individuals to thrive in this ever-changing landscape. Our commitment to innovation drives us to redefine education, ensuring relevance and excellence.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-5 mt-4 mt-lg-0">
          <div className="card" style={{ background: "#C1C5F2", height: "100%", borderRadius: "20px" }}>
            <h5 className="card-title text-center" style={{ fontFamily: "Raleway", fontSize: "30px", fontWeight: 500, color: "#FFFFFF", background: "#02073F", padding: "19px", borderRadius: "10px 10px 0 0" }}>The UPL Journey</h5>
            <div className="card-body" style={{ maxHeight: "358px" }}>
              <p className="card-text" style={{ fontFamily: "Raleway", fontSize: "16.5px", fontWeight: 500, textAlign: "justify", color: "#02073F", padding: "13px 15px" }}>
                Since our inception in 2010, we have been dedicated to working closely with our partners to implement progressive solutions for the learning community. Our efforts have directly contributed to the development of programs that align with the needs of the region's learners.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="text-center pt-4">
            <div style={{ fontFamily: "Inter", fontSize: "2.25rem", color: "#FD661F", fontWeight: 700, lineHeight: "1.3", textTransform: "capitalize", transform: "rotate(-0.174deg)" }}>
              Why Choose Us
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="179" height="10" viewBox="0 0 179 10" fill="none" style={{ marginLeft: "180px" }}>
              <path d="M1.08301 8.1359C49.6168 0.0469048 116.8 -0.627219 177.917 8.1359" stroke="#3442D9" strokeWidth="2.24694" />
            </svg>
          </div>
        </div>
      </div>
      <div className="row justify-content-center" style={{ paddingTop: "20px" }}>
        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
          <div className="card h-100" style={{ borderRadius: "15px", border: "3px dashed rgba(0, 0, 0, 1" }}>
            <div className="d-flex justify-content-center align-items-center" style={{ marginTop: "20px" }}>
              <img src="img/student.png" alt="" style={{  maxWidth: "200px", maxHeight: "200px", borderRadius: "12px" }} />
            </div>
            <div className="text-center" style={{ paddingTop: "10px", color: "#02073F" }}>
              <h2 className="fw-bold" style={{ fontSize: "28px" }}>Holistic Learning Approach</h2>
              <p className="fw-bold" style={{ fontSize: "18px", color: "#3442D9", padding: "0 20px" }}>We believe in fostering holistic development among learners, going beyond imparting mere knowledge to empower individuals for comprehensive growth</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
          <div className="card  h-100 " style={{ borderRadius: "15px" , border: "3px dashed rgba(0, 0, 0, 1" }}>
            <div className="d-flex justify-content-center align-items-center" style={{ marginTop: "20px" }}>
              <img src="img/student.png" alt="" style={{ maxWidth: "200px", maxHeight: "200px", borderRadius: "12px" }} />
            </div>
            <div className="text-center" style={{ paddingTop: "10px", color: "#02073F" }}>
              <h2 className="fw-bold" style={{ fontSize: "28px" }}>Industry- Relevant Training</h2>
              <p className="fw-bold" style={{ fontSize: "18px", color: "#3442D9", padding: "0 20px" }}>Our extensive courses are designed to meet the current and future needs of the IT industry, ensuring that our students are prepared for the professional landscape.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
          <div className="card  h-100" style={{ borderRadius: "15px" , border: "3px dashed rgba(0, 0, 0, 1" }}>
            <div className="d-flex justify-content-center align-items-center" style={{ marginTop: "20px" }}>
              <img src="img/student.png" alt="" style={{ maxWidth: "200px", maxHeight: "200px", borderRadius: "12px" }} />
            </div>
            <div className="text-center" style={{ paddingTop: "10px", color: "#02073F" }}>
              <h2 className="fw-bold" style={{ fontSize: "28px" }}>Innovation in Education</h2>
              <p className="fw-bold" style={{ fontSize: "18px", color: "#3442D9", padding: "0 20px" }}>UPL is passionately committed to continuously pushing the boundaries of traditional education, incorporating pioneering methods and technologies to enhance the learning experience.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="text-center" style={{ paddingTop: "40px" }}>
              <div className="text-primary" style={{ color: "#3442D9", fontFamily: "Inter", fontSize: "48px", fontWeight: 700, lineHeight: "62.4px", letterSpacing: "-2.88px", textTransform: "capitalize", transform: "rotate(-0.174deg)" }}>
                Our Team
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" width="179" height="10" viewBox="0 0 179 10" fill="none" style={{ marginLeft: "130px" }}>
                <path d="M1.08301 8.1359C49.6168 0.0469048 116.8 -0.627219 177.917 8.1359" stroke="#FD661F" strokeWidth="2.24694" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-start align-items-center" style={{ paddingLeft: "95px", paddingTop: "0px" }}>
          <div className="col-lg-4">
            <div className="d-flex justify-content-center align-items-center" style={{ position: "relative" }}>
              <img src="img/Hero Image 4 1.svg" alt="" className="img-fluid" style={{ width: "300px", height: "410px", borderRadius: "12px" }} />
              <div className="position-absolute start-0 bottom-0" style={{ fontFamily: "Raleway", fontSize: "23px", fontWeight: 600, color: "#3442D9", marginLeft: "15px" }}>
                <span style={{ marginRight: "10px" }}>Social Media:</span>
                <a className="btn btn-outline-primary btn-social mt-3" href="https://www.linkedin.com/in/mallikarjuna-g-d-b4660146" target='_blank' style={{ marginLeft: "8px", top: "3px" }}>
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="text-justify" style={{ fontFamily: "Raleway", fontSize: "23px", fontWeight: 600, color: "#3442D9" }}>
              Our organization is founded and led by a team of seasoned professionals with over two decades of combined academic and industry experience.
              <br />
              G.D. Mallikarjuna, the visionary founder, brings more than 20 years of expertise as a technologist, trainer, and developer. His journey began with a strong academic foundation and has evolved into a multifaceted career in the education sector.
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="d-flex justify-content-between align-items-center text-white rounded-4 p-4" style={{ backgroundColor: "#02073F", fontFamily: "Raleway", fontSize: "20px", fontWeight: 300, marginTop: "40px" }}>
              <div>
                Join us on the journey of unlimited power and full learning, where education meets innovation, and students are prepared for success in the dynamic world of IT.<br />
                For inquiries, visit <span style={{ color: "#FD661F" }}>hr@uplsnipe.com</span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default AboutPage;