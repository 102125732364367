import React, { useState ,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import {useNavigate } from 'react-router-dom';
import { Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select } from '@mui/material';
import TextfieldCustom from '../../../common/TextfieldCustom';
import { uploadNewEvent } from '../../../redux/actions/Actions';
import { uploadEventImage } from '../../../redux/actions/Actions';
import { ToastContainer,toast } from 'react-toastify';
import { fetchEventList } from '../../../redux/actions/Actions';

const UploadEventForm = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [duplicateError, setDuplicateError] = useState(false);

 
  const userId = user?.role_id;
  const [errors, setErrors] = useState({});
  const formRef = useRef(null);
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const fetchEvents = useSelector((state) => state.fetchEventList.events);
  useEffect(() => {
    setData(fetchEvents);
  }, [fetchEvents]);
  const [data, setData] = useState([]);
  const getShortDate = (isoDateString) => {
    const date = new Date(isoDateString);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString('en-US', options);
  };
const FindDuplicateEvents=  () =>{
  let isDuplicateEvent = false ;
  try{
    console.log(data, "Existing Events");
    isDuplicateEvent = data.some((event) =>{
      const eventName = event.RegisteredEvents || [];
      console.log(eventName, "EVENTS");
      for(var i=0; i<data.length;i++){
        console.log(data[i],"list inside loop");
         // Get short date using the getShortDate function
         const eventDateShort = getShortDate(data[i].event_date);
        if(data[i].event_name===event_name && eventDateShort === getShortDate(event_date)){
          console.log(data[i].event_name,"event name");
          console.log(data[i].event_date.slice(0,10),"event name");
          isDuplicateEvent=true;
          return isDuplicateEvent;
        }
      }
      
      return isDuplicateEvent;
    });
  }catch (error){
    console.error('Error fetching Events:', error);
    return isDuplicateEvent;
  };
  return isDuplicateEvent;
}




  const initialFormData = {
    category: '',
    event_name: '',
    event_heading: '',
    event_date: '',
    event_type: '',
    event_subheading: '',
    event_description: '',
    event_target_audience: '',
    // event_register_link: '',
    event_text: '',
    event_hash_tags: '',
  };

 



 const [formData, setFormData] = useState(initialFormData);
 const [file, setFile] = useState(null);

      
 const isValidEventName = (name) => {
  const alphanumericRegex =  /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
  return alphanumericRegex.test(name);
  
  
  
};
const isValidEventHeading = (name) => {
  // Regular expression to accept all combinations of alphabets, special characters, and numbers, as well as alphabets only
  const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
  return alphanumericRegex.test(name);
};
const isValidEventType = (name) => {
  const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
  return alphanumericRegex.test(name);
};
const isValidEventSubHeading = (name) => {
  const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
  return alphanumericRegex.test(name);
};
const isValidEventText = (name) => {
  const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
  return alphanumericRegex.test(name);
};
const isValidEventDescription = (name) => {
  const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
  return alphanumericRegex.test(name);
};
const isValidEventTargetAudience = (name) => {
  const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
  return alphanumericRegex.test(name);
};
// const isValidEventRegisterLink = (name) => {
//   const alphanumericRegex = /^[a-zA-Z0-9]+$/;
//   const numericOnlyRegex = /^[0-9]+$/;
//   return alphanumericRegex.test(name) && !numericOnlyRegex.test(name);
// };
const isValidEventHashTag = (name) => {
  const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
  return alphanumericRegex.test(name);
};

  const {category,event_name,event_heading, event_date, event_type, event_subheading, event_description, 
    event_target_audience, 
  //  event_register_link,
     event_text,event_hash_tags } = formData;

  const handleChange = (e) => {

    //  setFormData({ ...formData, [e.target.name]: e.target.value });
    // setFormData({ ...formData, [e.target.name]: e.target.value });
    const { name, value } = e.target;
  // Check if the selected date is a past date
const selectedDate = new Date(value);
const currentDate = new Date();
if (selectedDate < currentDate) {
  
  // If it's a past date, set the event_date to the current date
  setFormData({ ...formData, [name]: currentDate.toISOString().split('T')[0] });
} else {
  // If it's a future date, update the event_date normally
  setFormData({ ...formData, [name]: value });
}
  
  setDuplicateError(false);
 // const isDuplicate =  FindDuplicateEvents();
 // console.log(isDuplicate,"boolean");
};
  
  

  
  
  
  const eventData = {
      category: formData?.category,
      event_name: formData?.event_name,
      event_heading: formData?.event_heading,
      event_date: formData?.event_date,
      event_type: formData?.event_type,
      event_subheading: formData?.event_subheading,
      event_description:formData?.event_description,
      event_target_audience: formData?.event_target_audience,
     // event_register_link: formData?.event_register_link,
      event_text: formData?.event_text,
      event_hash_tags: formData?.event_hash_tags,
  }

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    //console.log('selected file :',selectedFile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   const isDuplicate =  FindDuplicateEvents();
   console.log(isDuplicate,"boolean");
   if (isDuplicate) {
    setDuplicateError(isDuplicate);
    console.log('Duplicate event found. Please provide a different event name.');
    return;
  }
 
  // Proceed with your form submission logic
  console.log('Submitting the form...');
     // Validation logic for all fields
     const validationChecks = [
      { field: 'event_name', value: event_name, validator: isValidEventName, errorMsg: 'Enter Valid Details' },
      { field: 'event_heading', value: event_heading, validator: isValidEventHeading, errorMsg: 'Enter Valid Details' },
      
      { field: 'event_type', value: event_type, validator: isValidEventType, errorMsg: 'Enter Valid Details' },
      { field: 'event_subheading', value: event_subheading, validator: isValidEventSubHeading, errorMsg: 'Enter Valid Details' },
      { field: 'event_description', value: event_description, validator: isValidEventDescription, errorMsg: 'Enter Valid Details' },
      { field: 'event_target_audience', value: event_target_audience, validator: isValidEventTargetAudience, errorMsg: 'Enter Valid Details' },
      //{ field: 'event_register_link', value: event_register_link, validator: isValidEventRegisterLink, errorMsg: 'Enter Valid Details' },
      { field: 'event_text', value: event_text, validator: isValidEventText, errorMsg: 'Enter Valid Details' },
      { field: 'event_hash_tags', value: event_hash_tags, validator: isValidEventHashTag, errorMsg: 'Enter Valid Details' },
    ];
  
     // Check and update errors for each field
     let hasErrors = false;
     const updatedErrors = {};
 
     validationChecks.forEach(({ field, value, validator, errorMsg }) => {
       if (!value.trim() || !validator(value)) {
         updatedErrors[field] = errorMsg;
         hasErrors = true;
       }
     });
 
     // Update errors state
     setErrors(updatedErrors);
 
     // If errors exist, stop form submission
     if (hasErrors) {
       return;
     }




    if(!error){
      try {
       const response= await dispatch(uploadNewEvent(eventData));
       const eventId=response.data.event_id;
      //  console.log("event_id in submit: ",eventId);
      //  console.log("file in submit: ",file);


        if (eventId && file) {
          const formDataWithImage = new FormData();
          formDataWithImage.append("img", file);
          formDataWithImage.append("event_id", eventId);
          await dispatch(uploadEventImage(formDataWithImage, eventId));
        }
        toast.success('Event uploaded successfully');
        formRef.current.reset();

    navigate('/auth/allEvents');
    setFormData(initialFormData);
    }catch (error) {
      console.error("Error uploading event data or image:", error);
      toast.error('Error uploading event data or image', { position: toast.POSITION.TOP_RIGHT });
      setError("")
    }
  }
  
 
  

  }           
  return(
    <form ref={formRef} onSubmit={handleSubmit}>
    <Container maxWidth="lg" className="d-flex">
    <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignitem="center"
        style={{ marginTop: '40px' }}
    >
        <div style={{ width: 'inherit' }}>
        <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
            <h5>Upload Event Details</h5>
            <Button onClick={() => navigate(-1)}>Back</Button>
            <Grid
            container
            spacing={2}
            justifyContent="center"
            sx={{ border: '1px solid rgba(0,0,0,0.125', zIndex: '3' }}
            style={{ padding: '30px' }}
            >
            <Grid container  spacing={2} sx={{border: '1px solid rgba(0,0,0,0.125', zIndex:"3"}} style={{padding: "30px"}}>
      <input type="file" accept=".png, .jpg, .jpeg" label="Event Image" onChange={handleFileChange} />
      </Grid>
      <Grid container spacing={3}>
            <Grid item xs={3} style={{ marginBottom: '16px' }}>
            <FormControl fullWidth variant="outlined" required>
            <InputLabel htmlFor="event-category">Category</InputLabel>
            <Select
              label="Category"
              id="event-category"
              name="category"
              value={category}
              onChange={handleChange}
            >
              <MenuItem value="Webminars">Webminars</MenuItem>
              <MenuItem value="Workshop">Workshop</MenuItem>
              <MenuItem value="Training">Training</MenuItem>
              <MenuItem value="Mentorship">Mentorship</MenuItem>
            </Select>
          </FormControl>
            </Grid>
            <Grid item xs={3} style={{ marginBottom: '16px' }}>
                <TextfieldCustom
                type="text"
                name="event_name"
                label="Event Name"
                value={event_name}
                onChange={handleChange}
                placeholder="Event Name"
                
                />
                 {errors.event_name && <span style={{ color: 'red' }}>{errors.event_name}</span>} 
                 {duplicateError && (
            <div style={{ color: 'red' }}>This Event Already created.</div>
          )}
            </Grid>
            <Grid item xs={3} style={{ marginBottom: '16px' }}>
                <TextfieldCustom
                type="text"
                name="event_heading"
                value={event_heading}
                onChange={handleChange}
                label="Event Heading"
                placeholder="Event Heading"
                
                />
                {errors.event_heading && <span style={{ color: 'red' }}>{errors.event_heading}</span>}
            </Grid>
            <Grid item xs={3} style={{ marginBottom: '16px' }}>
    <TextfieldCustom
    type="date"
    required
    name="event_date"
    value={event_date}
    onChange={handleChange}
    placeholder="Event Date"
    InputLabelProps={{ shrink: true }}
    inputProps={{
      
        min: new Date().toISOString().split('T')[0], // Disable past dates
      style: { '& input::placeholder': { color: 'lightgray !important', fontWeight: 'lighter !important' } },
      
      
      
    }} 
    
     />
    {duplicateError && (
            <div style={{ color: 'red' }}>Same Event Already created on this date.</div>
          )}
          
</Grid>
            </Grid>
            <Grid container spacing={3}>
            <Grid item xs={3} style={{ marginBottom: '16px' }}>
                <TextfieldCustom
                type="text"
                name="event_type"
                value={event_type}
                onChange={handleChange}
                label="Event Type"
                placeholder="Event Type"
                
                />
                {errors.event_type && <span style={{ color: 'red' }}>{errors.event_type}</span>}
            </Grid>
            <Grid item xs={3} style={{ marginBottom: '16px' }}>
                <TextfieldCustom
                type="text"
                name="event_subheading"
                
                value={event_subheading}
                onChange={handleChange}
                label="Event Subheading"
                placeholder="Event Subheading"
                
                />
                {errors.event_subheading && <span style={{ color: 'red' }}>{errors.event_subheading}</span>}
            </Grid>
            <Grid item xs={3} style={{ marginBottom: '16px' }}>
                <TextfieldCustom
                type="text"
                name="event_text"
                value={event_text}
                onChange={handleChange}
                label="Event Text"
                placeholder="Event Text"
                
                />
                {errors.event_text && <span style={{ color: 'red' }}>{errors.event_text}</span>}
            </Grid>
            <Grid item xs={3} style={{ marginBottom: '16px' }}>
                <TextfieldCustom
                type="text"
                name="event_description"
                
                value={event_description}
                onChange={handleChange}
                label="Event Description"
                placeholder="Event Description"
                
                />
                {errors.event_description && <span style={{ color: 'red' }}>{errors.event_description}</span>}
            </Grid>
            </Grid>
            <Grid container spacing={3}>
            <Grid item xs={3} style={{ marginBottom: '16px' }}>
                <TextfieldCustom
                type="text"
                name="event_target_audience"
                
                value={event_target_audience}
                onChange={handleChange}
                label="Target Audience"
                placeholder="Event Target Audience"
                
                />
                {errors.event_target_audience && <span style={{ color: 'red' }}>{errors.event_target_audience}</span>}
            </Grid>
            {/* <Grid item xs={3} style={{ marginBottom: '16px' }}>
                <TextfieldCustom
                type="text"
                name="event_register_link"
                value={event_register_link}
                onChange={handleChange}
                label="Register Link"
                placeholder="Event Register Link"
                
                />
                {errors.event_register_link && <span style={{ color: 'red' }}>{errors.event_register_link}</span>}
            </Grid> */}
            <Grid item xs={3} style={{ marginBottom: '16px' }}>
                <TextfieldCustom
                type="text"
                name="event_hash_tags"
                
                value={event_hash_tags}
                onChange={handleChange}
                placeholder="Event Hash Tags"
                />
                {errors.event_hash_tags && <span style={{ color: 'red' }}>{errors.event_hash_tags}</span>}
            </Grid>
            </Grid>
            <Grid item xs={4}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#3251A3', borderColor: '#FF5E14' }}
                  fullWidth
                  type="submit"
                >
                  Upload Event
                </Button>
              </Grid>
        </Grid> 
    </Paper>
    </div>
    </Grid>
    </Container>
    

    </form>
);
}

export default UploadEventForm
