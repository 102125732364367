
import { ActionTypes } from "../constants/action-types";

const initialState ={
    events:[]
}
export const FetchEventList= (state=initialState,action)=>{
    switch(action.type){
        case ActionTypes.FETCH_EVENT_LIST:
            return{...state,events:action.payload};
        default:
            return state;

    }
}