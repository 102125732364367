// authTypes.js

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const FETCH_CHANNEL_PARTNERS_SUCCESS = 'FETCH_CHANNEL_PARTNERS_SUCCESS';
export const FETCH_CHANNEL_PARTNERS_FAILURE = 'FETCH_CHANNEL_PARTNERS_FAILURE';
export const APPROVE_REQUEST_SUCCESS = 'APPROVE_REQUEST_SUCCESS';
export const APPROVE_REQUEST_FAILURE = 'APPROVE_REQUEST_FAILURE';
export const REJECT_REQUEST_SUCCESS = 'REJECT_REQUEST_SUCCESS';
export const REJECT_REQUEST_FAILURE = 'REJECT_REQUEST_FAILURE';
export const FETCH_PENDING_REQUESTS_SUCCESS = 'FETCH_PENDING_REQUESTS_SUCCESS';
export const FETCH_PENDING_REQUESTS_FAILURE = 'FETCH_PENDING_REQUESTS_FAILURE';
// export const UPLOAD_EVENT_SUCCESS ='UPLOAD_EVENT_SUCCESS';
// export const UPLOAD_EVENT_FAILURE ='UPLOAD_EVENT_FAILURE';
// export const UPLOAD_EVENT_IMG='UPLOAD_EVENT_IMG';
