import React from 'react'
import Dashboard from './Dashboard'

const AdminCp = () => {
  return (
    <div className='container'>
        <Dashboard />
    </div>
  )
}

export default AdminCp