


import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Grid, Paper } from '@mui/material';
import TextfieldCustom from '../../../common/TextfieldCustom';
import { fetchCompanyName,  fetchCompanyNameByCompany_name,  updateCompanyNameByCompany_name } from '../../../redux/actions/Actions';

const UpdateCompanyNamePlacementTableDetails = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userId = user?.role_id;


  const company12
=useSelector((state)=>state.getCompanyNameBycompanyname.CompanyNameByCompanyName);
console.log("helooo",company12);

  const dispatch = useDispatch();
  const { Company_name } = useParams();
  const navigate = useNavigate();

  const selectedCompanyName = company12[0];
 console.log("html",selectedCompanyName);


 console.log("hdjkd",Company_name);

  useEffect(() => {
    dispatch(fetchCompanyNameByCompany_name(Company_name));
  },[dispatch,Company_name]);



  const [formData, setFormData] = useState({
    Company_name: '',
     logo_url: '',

  });

  useEffect(() => {
    if (selectedCompanyName) {
      setFormData({
        Company_name: selectedCompanyName?.Company_name || '',
         logo_url: selectedCompanyName?.logo_url || '',

      });
    }
  }, [selectedCompanyName]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedCompanyData = {

      Company_name: formData.Company_name,
      logo_url: formData.logo_url,
    };
    dispatch(updateCompanyNameByCompany_name(updatedCompanyData, Company_name));
    navigate("/auth/companyPlacementTable");
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Container maxWidth="sm" className="d-flex">
          <Grid
            container
            spacing={2}
            direction="column"
            justifyContent="center"
          >
            <div style={{ width: "inherit" }}>
              <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
                <h3>Update PLACEMENT details</h3>
                <h6>Company_name : {selectedCompanyName?.Company_name}</h6>
                <Button onClick={() => navigate(-1)}>Back</Button>
                <Grid container spacing={2} sx={{ border: '1px solid rgba(0,0,0,0.125)', zIndex: "3" }} style={{ padding: "30px" }}>
                  <Grid item xs={6}>
                    <TextfieldCustom
                      type="text"
                      name="Company_name"
                      value={formData.Company_name}
                      onChange={handleChange}
                      placeholder="Company_name "
                      required
                    />
                  </Grid>

                   <Grid item xs={6}>
                    <TextfieldCustom
                      type="text"
                      name="logo_url"
                      value={formData.logo_url}
                      onChange={handleChange}
                      placeholder="logo_url"
                      required
                    />
                  </Grid> 
                  <Grid item xs={12}>
                    <Button variant="contained" style={{ backgroundColor: '#3251A3', borderColor: '#FF5E14' }}
                      fullWidth type="submit">
                      Update & Save Placement Details
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </Grid>
        </Container>
      </form>
    </div>
  );
}

export default UpdateCompanyNamePlacementTableDetails;
