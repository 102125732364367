import { ActionTypes } from "../constants/action-types";

const initialState={
    job_applicationsData:[],
};

const job_applicationSReducer=(state=initialState,action)=>{
    switch(action.type){
        case ActionTypes.FETCH_JOB_APPLICATIONS:
            return{
                ...state,
                job_applicationsData:action.payload,
            };
            default:
                return state;
    }
};
export default job_applicationSReducer;