import React from "react";
import { useSelector } from "react-redux";
import WelcomeMenus from "../../WelcomeMenus";
import FaqList from "./FaqList";
import AdminMenus from "../AdminMenus";
import { Box } from "@mui/material";

const AllFaqs = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userId = user?.role_id;
  console.log("logged in as", userId);
  return (

        <div className="container-xxl">
          { userId === 1 ? (isAuthenticated ?

          <div className="container">
          <div className='mb-3'>
            <div className="btn-group" role="group" aria-label="Button group">
              <AdminMenus buttonName="Add Faq" pageName="addNewFaq" />
            </div>
          </div>
            <div>
            <Box sx={{ width: '100%' }}>
              <FaqList />
            </Box>
            </div>
          </div>
              :<WelcomeMenus/>): userId===3?(isAuthenticated?
                <div className="container">
                <div className='mb-3'>
                  {/* <div className="btn-group" role="group" aria-label="Button group">
                    <AdminMenus buttonName="Add Faq" pageName="addNewFaq" />
                  </div> */}
                </div>
                  <div>
                  <Box sx={{ width: '100%' }}>
                    <FaqList />
                  </Box>
        </div>

      </div>:<WelcomeMenus/>):(<WelcomeMenus/>)
}
</div>

  );
};
export default AllFaqs;
