import { ActionTypes } from "../constants/action-types";

const initialState={
    job_aws_applicationsData:[],
};

const job_aws_applicationSReducer=(state=initialState,action)=>{
    switch(action.type){
        case ActionTypes.FETCH_JOB_AWS_APPLICATIONS:
            return{
                ...state,
                job_aws_applicationsData:action.payload,
            };
            default:
                return state;
    }
};
export default job_aws_applicationSReducer;