import { ActionTypes } from "../constants/action-types";

const initialState = {
  updateInstructor: [],
};

export const UpdateInstructorReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.EDIT_INSTRUCTOR:
      return { ...state, updateInstructor: payload };
    default:
      return state;
  }
};
