import React, { useState,useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect} from 'react';
import { useNavigate} from 'react-router-dom';
import { Button, Container, FormControl, Grid, InputLabel,Input, MenuItem, Paper, Select,Typography } from '@mui/material';
import TextfieldCustom from '../../../common/TextfieldCustom';
//import { setEventRowData } from '../../../redux/actions/Actions';
//import { fetchEventImages } from '../../../redux/actions/Actions';
//import { getImageName } from '../../../redux/actions/Actions';
import { updateEventRequest } from '../../../redux/actions/Actions';
import { toast } from 'react-toastify';

const UpdateEventForm = () => {
  //const { isAuthenticated, user } = useSelector((state) => state.auth);
  //const userId = user?.role_id;
 const selectedEvent = useSelector((state) => state.eventRowReducer);
 //const imageName = useSelector((state) => state.eventImageReducer.imageName);
const id=selectedEvent.event_id;
const formRef = useRef(null);
//const [error, setError] = useState("");
//const [selectedImageName, setSelectedImageName] = useState(imageName);
//console.log('image',imageName);
  const navigate = useNavigate()
  const [duplicateError, setDuplicateError] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});


// useEffect(() => {
//     if (id) {
//       dispatch(getImageName(id)); // dispatch action to fetch event image by ID
//     }
//   });
  const initialFormData = {
    category: '',
    event_name: '',
    event_heading: '',
    event_date: '',
    event_type: '',
    event_subheading: '',
    event_description: '',
    event_target_audience: '',
    //event_register_link: '',
    event_text: '',
    event_hash_tags: '',
  };

 const [formData, setFormData] = useState(selectedEvent||initialFormData);
 //const [file, setFile] = useState(null);


  const {category,event_name,event_heading, event_date, event_type, event_subheading, event_description, 
    event_target_audience, 
    //event_register_link, 
    event_text,event_hash_tags } = formData;

  const handleChange = (e) => {
    // setFormData({ ...formData, [e.target.name]: e.target.value });
    const { name, value } = e.target;
  // Check if the selected date is a past date
const selectedDate = new Date(value);
const currentDate = new Date();
if (selectedDate < currentDate) {
  
  // If it's a past date, set the event_date to the current date
  setFormData({ ...formData, [name]: currentDate.toISOString().split('T')[0] });
} else {
  // If it's a future date, update the event_date normally
  setFormData({ ...formData, [name]: value });
}
  
  setDuplicateError(false);
 // const isDuplicate =  FindDuplicateEvents();
 // console.log(isDuplicate,"boolean");
};
  
  

  
  //  const handleFileChange = (e) => {
  //   const selectedFile = e.target.files[0];
  //   setFile(selectedFile);
    
  // setSelectedImageName(selectedFile.name); 
  // console.log('selected file :',selectedFile);
  // };
  const isValidEventName = (name) => {
    const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
  return alphanumericRegex.test(name);
  };
  const isValidEventHeading = (name) => {
    const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
  return alphanumericRegex.test(name);
  };
  const isValidEventType = (name) => {
    const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
  return alphanumericRegex.test(name);
  };
  const isValidEventSubHeading = (name) => {
    const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
  return alphanumericRegex.test(name);
  };
  const isValidEventText = (name) => {
    const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
  return alphanumericRegex.test(name);
  };
  const isValidEventDescription = (name) => {
    //const EventDescriptionRegex =/^[A-Za-z0-9\s]+$/;
    const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
  return alphanumericRegex.test(name);
  };
  const isValidEventTargetAudience = (name) => {
    const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
    return alphanumericRegex.test(name);
  };
  // const isValidEventRegisterLink = (name) => {
  //   const alphanumericRegex = /^[a-zA-Z0-9]+$/;
  //   const numericOnlyRegex = /^[0-9]+$/;
  //   return alphanumericRegex.test(name) && !numericOnlyRegex.test(name);
  // };
  const isValidEventHashTag = (name) => {
    const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
  return alphanumericRegex.test(name);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationChecks = [
      { field: 'event_name', value: event_name, validator: isValidEventName, errorMsg: 'Enter Valid Details' },
      { field: 'event_heading', value: event_heading, validator: isValidEventHeading, errorMsg: 'Enter Valid Details' },
      
      { field: 'event_type', value: event_type, validator: isValidEventType, errorMsg: 'Enter Valid Details' },
      { field: 'event_subheading', value: event_subheading, validator: isValidEventSubHeading, errorMsg: 'Enter Valid Details' },
      { field: 'event_description', value: event_description, validator: isValidEventDescription, errorMsg: 'Enter Valid Details' },
      { field: 'event_target_audience', value: event_target_audience, validator: isValidEventTargetAudience, errorMsg: 'Enter Valid Details' },
      //{ field: 'event_register_link', value: event_register_link, validator: isValidEventRegisterLink, errorMsg: 'Enter Valid Details' },
      { field: 'event_text', value: event_text, validator: isValidEventText, errorMsg: 'Enter Valid Details' },
      { field: 'event_hash_tags', value: event_hash_tags, validator: isValidEventHashTag, errorMsg: 'Enter Valid Details' },
    ];
  
     // Check and update errors for each field
     let hasErrors = false;
     const updatedErrors = {};
 
     validationChecks.forEach(({ field, value, validator, errorMsg }) => {
       if (!value.trim() || !validator(value)) {
         updatedErrors[field] = errorMsg;
         hasErrors = true;
       }
     });
 
     // Update errors state
     setErrors(updatedErrors);
 
     // If errors exist, stop form submission
     if (hasErrors) {
       return;
     }




    if (id) {
      try {
        dispatch(updateEventRequest(id, formData));
        toast.success(`${event_name} updated successfully`);
        formRef.current.reset();
        navigate('/auth/allEvents');
        setFormData(initialFormData);
      } catch (error) {
        console.error("Error updating event:", error);
        toast.error(`Error updating ${event_name} data or image`);
        setError("")
      }
    }
  }
  useEffect(()=>{
    if (selectedEvent) {
    setFormData({
        category: selectedEvent?.category,
        event_name: selectedEvent?.event_name,
        event_heading: selectedEvent?.event_heading,
        event_date: selectedEvent?.event_date,
        event_type: selectedEvent?.event_type,
        event_subheading: selectedEvent?.event_subheading,
        event_description:selectedEvent?.event_description,
        event_target_audience: selectedEvent?.event_target_audience,
        event_register_link: selectedEvent?.event_register_link,
        event_text: selectedEvent?.event_text,
        event_hash_tags: selectedEvent?.event_hash_tags,

    })
  }
   }, [selectedEvent])
          
  return(
    <form ref={formRef} onSubmit={handleSubmit}>
    <Container maxWidth="lg" className="d-flex">
    <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignitem="center"
        style={{ marginTop: '40px' }}
    >
        <div style={{ width: 'inherit' }}>
        <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
            <h5>Update Event Details</h5>
            <Button onClick={() => navigate(-1)}>Back</Button>
           <Grid
            container
            spacing={2}
            justifyContent="center"
            sx={{ border: '1px solid rgba(0,0,0,0.125', zIndex: '3' }}
            style={{ padding: '30px' }}
            >
                 
           <Grid container spacing={3}>
            <Grid item xs={3} key="category" style={{ marginBottom: '16px' }}>
            <FormControl fullWidth variant="outlined" required>
            <InputLabel htmlFor="event-category">Category</InputLabel>
            <Select
              label="Category"
              id="event-category"
              name="category"
              value={category}
              onChange={handleChange}
            >
              <MenuItem value="Webminars">Webminars</MenuItem>
              <MenuItem value="Workshop">Workshop</MenuItem>
              <MenuItem value="Training">Training</MenuItem>
              <MenuItem value="Mentorship">Mentorship</MenuItem>
            </Select>
          </FormControl>
            </Grid>
            <Grid item xs={3} key="eventName"style={{ marginBottom: '16px' }}>
                <TextfieldCustom
                type="text"
                name="event_name"
                label="Event Name"
                value={event_name}
                onChange={handleChange}
                placeholder="Event Name"
                required
                
                />
                 {errors.event_name && <span style={{ color: 'red' }}>{errors.event_name}</span>} 
            </Grid>
            <Grid item xs={3} key="eventHeading" style={{ marginBottom: '16px' }}>
                <TextfieldCustom
                type="text"
                name="event_heading"
                value={event_heading}
                onChange={handleChange}
                label="Event Heading"
                placeholder="Event Heading"
                
                />
                {errors.event_heading && <span style={{ color: 'red' }}>{errors.event_heading}</span>}
         
            </Grid>
            <Grid item xs={3} key="eventDate" style={{ marginBottom: '16px' }}>
    <TextfieldCustom
    type="date"
    name="event_date"
    value={event_date}
    onChange={handleChange}
    placeholder="Event Date"
    InputLabelProps={{ shrink: true }}
    inputProps={{
      style: { '& input::placeholder': { color: 'lightgray !important', fontWeight: 'lighter !important' } },
    }}  />
</Grid>
            </Grid>
            <Grid container spacing={3}>
            <Grid item xs={3} key="eventType" style={{ marginBottom: '16px' }}>
                <TextfieldCustom
                type="text"
                name="event_type"
                value={event_type}
                onChange={handleChange}
                label="Event Type"
                placeholder="Event Type"
                
                />{errors.event_type && <span style={{ color: 'red' }}>{errors.event_type}</span>}
           
            </Grid>
            <Grid item xs={3} key="eventSubheading"style={{ marginBottom: '16px' }}>
                <TextfieldCustom
                type="text"
                name="event_subheading"
                required
                value={event_subheading}
                onChange={handleChange}
                label="Event Subheading"
                placeholder="Event Subheading"
                
                />
                 {errors.event_subheading && <span style={{ color: 'red' }}>{errors.event_subheading}</span>}
           
            </Grid>
            <Grid item xs={3} key="eventText" style={{ marginBottom: '16px' }}>
                <TextfieldCustom
                type="text"
                name="event_text"
                value={event_text}
                onChange={handleChange}
                label="Event Text"
                placeholder="Event Text"
                
                />
                {errors.event_text && <span style={{ color: 'red' }}>{errors.event_text}</span>}
          
            </Grid>
            <Grid item xs={3} key="eventDescription" style={{ marginBottom: '16px' }}>
                <TextfieldCustom
                type="text"
                name="event_description"
                required
                value={event_description}
                onChange={handleChange}
                label="Event Description"
                placeholder="Event Description"
                
                /> {errors.event_description && <span style={{ color: 'red' }}>{errors.event_description}</span>}
       
            </Grid>
            </Grid>
            <Grid container spacing={3}>
            <Grid item xs={3} key="eventTargetAudience" style={{ marginBottom: '16px' }}>
                <TextfieldCustom
                type="text"
                name="event_target_audience"
                required
                value={event_target_audience}
                onChange={handleChange}
                label="Target Audience"
                placeholder="Event Target Audience"
                
                /> {errors.event_target_audience && <span style={{ color: 'red' }}>{errors.event_target_audience}</span>}
          
            </Grid>
            {/* <Grid item xs={3} key="eventRegisterLink" style={{ marginBottom: '16px' }}>
                <TextfieldCustom
                type="text"
                name="event_register_link"
                value={event_register_link}
                onChange={handleChange}
                label="Register Link"
                placeholder="Event Register Link"
                
                />
            </Grid> */}
            <Grid item xs={3} key="eventHashTags" style={{ marginBottom: '16px' }}>
                <TextfieldCustom
                type="text"
                name="event_hash_tags"
                required
                value={event_hash_tags}
                onChange={handleChange}
                placeholder="Event Hash Tags"
                />{errors.event_hash_tags && <span style={{ color: 'red' }}>{errors.event_hash_tags}</span>}
          
            </Grid>
            </Grid>
            <Grid>
            </Grid>
            <Grid item xs={4} key="eventSubmit">
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#3251A3', borderColor: '#FF5E14' }}
                  fullWidth
                  type="submit"
                >
                  Update Event
                </Button>
              </Grid>
        </Grid> 
    </Paper>
    </div>
    </Grid>
    </Container>
    

    </form>
);
}

export default UpdateEventForm;
