import React from 'react';
import { useSelector } from 'react-redux';
import WelcomeMenus from '../../WelcomeMenus';
import AdminMenus from '../AdminMenus';
import InstructorList from './InstructorList';

const AllInstructors = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userId = user?.role_id;
  console.log('logged in as', userId);

  return (
    isAuthenticated && userId === 1 ? (
      <div className="container-xxl ">
        <div className="container">
          <div className='btn-group' role='group' aria-label='Button group'>
            <AdminMenus buttonName='Add Instructor' pageName='addNewInstructor' />
          </div>
          <div className='row dataTable pt-3'>
            <InstructorList />
          </div>
        </div>
      </div>
    ) : (
      <WelcomeMenus />
    )
  );
}

export default AllInstructors;
