import { ActionTypes } from "../constants/action-types"

const initialState = {
    eventSubscriber:[],
    registrationError: null
  };
  
  export const EventSubscriber = (state = initialState, action) => {
    switch (action.type) {
      case 'EVENT_REGISTRATION_SUCCESS':
        return {
          ...state,
          eventSubscriber:action.payload,
          registrationError: null,
        };
      case 'EVENT_REGISTRATION_FAILURE':
        return {
          ...state,
          registrationError: action.payload, // Store the error message
        };
      default:
        return state;
    }
  };
  