import { ActionTypes } from "../constants/action-types";
const initialState = { 
  imageName: null,
};
  
  const deleteEventImageReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.DELETE_EVENTIMAGE_BY_ID:
        return { ...state, imageName: action.payload };
      default:
        return state;
    }
  };
  
  export default deleteEventImageReducer;