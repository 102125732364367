import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Container, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select } from '@mui/material';
import { uploadNewProgram } from '../../../redux/actions/Actions';
import TextfieldCustom from '../../../common/TextfieldCustom';
import { toast } from 'react-toastify';
import { useRef } from 'react';
import { uploadProgramImage } from '../../../redux/actions/Actions';
import { color } from '@mui/system';

const UploadProgramForm = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userId = user?.role_id
  const [error, setError] = useState("");
  const [Tileimage, setTileimage] = useState(null);
  const [Bannerimage, setBannerimage] = useState(null);
  const formRef = useRef(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    program_name: '',
    duration: '',
    cost: '',
    target_audience: '',
    program_details:'',
    program_coverage:'',
    outcome:'',
    career_opportunities: '',
    registration_charge: '',
    gst:''
  });

  const { program_name, 
    duration, 
    cost, 
    target_audience,
    program_details, 
    program_coverage, 
    outcome,
    career_opportunities,
    registration_charge, gst} = formData;

    const handletileChange = (e) => {
      const selectedTileimage = e.target.files[0];
      setTileimage(selectedTileimage);
    }

    const handleBannerChange = (e) => {
      const selectedBannerimage = e.target.files[0];
      setBannerimage(selectedBannerimage);
      console.log(selectedBannerimage,'banner');
    }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  
  const programData = {
    program_name: formData.program_name, 
    duration: formData.duration,
    cost: formData.cost, 
    target_audience: formData.target_audience,
    program_details: formData.program_details,
    program_coverage: formData.program_coverage,
    outcome: formData.outcome,
    career_opportunities: formData.career_opportunities, 
    registration_charge: formData.registration_charge, 
    gst: formData.gst
  }
  const isValidprogramName = (name) => {
    const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
  return alphanumericRegex.test(name);
  };
  const isValidDuration = (name) => {
    const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
    return alphanumericRegex.test(name);
  };
  const isValidCost = (name) => {
    const numericOnlyRegex = /^[0-9.]+$/;
    return numericOnlyRegex.test(name);
  };
  const isValidTargetAudience = (name) => {
    const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
  return alphanumericRegex.test(name);
  };
  const isValidProgramDetails = (name) => {
    const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
    return alphanumericRegex.test(name);
  };
  const isValidProgramCoverage = (name) => {
    const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
  return alphanumericRegex.test(name);
  };
  const isValidOutcome = (name) => {
    const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
    return alphanumericRegex.test(name);
  };
  const isValidCureerOppotunity = (name) => {
    const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
  return alphanumericRegex.test(name);
  };
  const isValidRegistrationCharges = (name) => {
    const numericOnlyRegex = /^[0-9.]+$/;
    return numericOnlyRegex.test(name);
  };
  const isValidGST = (name) => {
    const numericOnlyRegex = /^[0-9.]+$/;
    return numericOnlyRegex.test(name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

     // Validation logic for all fields
     const validationChecks = [
      { field: 'program_name', value: program_name, validator: isValidprogramName, errorMsg: 'Enter Valid Details' },
      { field: 'duration', value: duration, validator: isValidDuration, errorMsg: 'Enter Valid Details' },
      
      { field: 'cost', value: cost, validator: isValidCost, errorMsg: 'Enter Valid Details' },
      { field: 'target_audience', value: target_audience, validator: isValidTargetAudience, errorMsg: 'Enter Valid Details' },
      { field: 'program_details', value: program_details, validator: isValidProgramDetails, errorMsg: 'Enter Valid Details' },
      { field: 'program_coverage', value: program_coverage, validator: isValidProgramCoverage, errorMsg: 'Enter Valid Details' },
      { field: 'outcome', value: outcome, validator: isValidOutcome, errorMsg: 'Enter Valid Details' },
      { field: 'career_opportunities', value: career_opportunities, validator: isValidCureerOppotunity, errorMsg: 'Enter Valid Details' },
      { field: 'registration_charge', value: registration_charge, validator: isValidRegistrationCharges, errorMsg: 'Enter Valid Details' },
      { field: 'gst', value: gst, validator: isValidGST, errorMsg: 'Enter Valid Details' },

    ];
  
     // Check and update errors for each field
     let hasErrors = false;
     const updatedErrors = {};
 
     validationChecks.forEach(({ field, value, validator, errorMsg }) => {
       if (!value.trim() || !validator(value)) {
         updatedErrors[field] = errorMsg;
         hasErrors = true;
       }
     });
 
     // Update errors state
     setErrors(updatedErrors);
 
     // If errors exist, stop form submission
     if (hasErrors) {
       return;
     }


if(!error){
try {
  console.log(programData,'program id');
  const response=await dispatch(uploadNewProgram(programData));
  //console.log(response.data.program_id,':Data');
  const programId=response.data.program_id;
  console.log(programId,'program id on submit');
  console.log(Tileimage," tile image");
  if(programId && Tileimage){
    const formDataWithImage = new FormData();
    formDataWithImage.append("tile_image", Tileimage);
    formDataWithImage.append("banner_image", Bannerimage);
    formDataWithImage.append("program_id", programId);
    await dispatch(uploadProgramImage(formDataWithImage,programId));

  }
  toast.success('Program uploaded successfully');
  //formRef.current.reset();

  navigate("/auth/allPrograms")
  console.log("formdata", programData)  
}catch (error){
  console.error("Error uploading Program data or image:", error);
  toast.error('Error uploading program data or image', { position: toast.POSITION.TOP_RIGHT });
  setError("")
}
}    
  };
  return (
    <div>
      
      <form onSubmit={handleSubmit}>
      <Container maxWidth="sm" className=" d-flex" >
    
    <Grid
      container
      spacing={2}
      direction="column"
      justifyContent="center"
      
    >
       <div style={{ width: "inherit"}}>
      <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
      <h3>Upload new Program</h3>
      <Button onClick={()=>navigate(-1)}>Back</Button>
      <Grid container  spacing={2} sx={{border: '1px solid rgba(0,0,0,0.125', zIndex:"3"}} style={{padding: "30px"}}>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="program_name" 
             value={program_name} onChange={handleChange}
              placeholder="Program Name*"
             
             />      
              {errors.program_name && <span style={{ color: 'red' }}>{errors.program_name}</span>}       
           </Grid>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="duration" 
             value={duration} onChange={handleChange} placeholder="Duration*"
             
             
             />         
              {errors.duration && <span style={{ color: 'red' }}>{errors.duration}</span>}    
           </Grid>
           <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="cost" 
             value={cost} onChange={handleChange} placeholder="Cost*"
             
             />     
             {errors.cost && <span style={{ color: 'red' }}>{errors.cost}</span>}        
           </Grid>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="target_audience" 
             value={target_audience} onChange={handleChange} placeholder="Target Audience*"
             
             />        
             {errors.target_audience && <span style={{ color: 'red' }}>{errors.target_audience}</span>}     
           </Grid>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="program_details" 
             value={program_details} onChange={handleChange} placeholder="Program Details"
             
             />         
             {errors.program_details && <span style={{ color: 'red' }}>{errors.program_details}</span>}    
           </Grid>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="program_coverage" 
             value={program_coverage} onChange={handleChange} placeholder="Program Coverage"
             
             />            
             {errors.program_coverage && <span style={{ color: 'red' }}>{errors.program_coverage}</span>} 
           </Grid>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="outcome" 
             value={outcome} onChange={handleChange} placeholder="Outcome"
             
             />         
             {errors.outcome && <span style={{ color: 'red' }}>{errors.outcome}</span>}    
           </Grid>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="career_opportunities" 
             value={career_opportunities} onChange={handleChange} placeholder="Career Opportunities"
             
             />            
             {errors.career_opportunities && <span style={{ color: 'red' }}>{errors.career_opportunities}</span>} 
           </Grid>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="registration_charge" 
             value={registration_charge} onChange={handleChange} placeholder="Registration Charge"
             
             />            
             {errors.registration_charge && <span style={{ color: 'red' }}>{errors.registration_charge}</span>} 
           </Grid>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="gst" 
             value={gst} onChange={handleChange} placeholder="GST"
             
             />            
             {errors.gst && <span style={{ color: 'red' }}>{errors.gst}</span>} 
           </Grid>
           <Grid item xs={6}>
           {/* <Button variant="contained" component="label" > */}
       Tile Image
      <input
        type="file"
        required
        name="tile_image"
        accept=".png, .jpg, .jpeg" label="tile image"
         onChange={handletileChange}
        //style={{ display: 'none' }}
      />
      
    {/* </Button> */}
    
   </Grid>
   <Grid item xs={6}>
    </Grid>
   <Grid item xs={6}>
           {/* <Button variant="contained" component="label" > */}
       Banner Image
      <input
        type="file"
        name="banner_image"
        required
        accept=".png, .jpg, .jpeg" label="bannr image"
        onChange={handleBannerChange}
        //style={{ display: 'none' }}
      />
      
    {/* </Button>
     {Bannerimage && ( 
      <div className="image-info">
        <p style={{  position: 'absolute',
  marginLeft: '177px',
  marginTop: '-30px',
  }}
  >
          <strong>Name: </strong>{
          Bannerimage.name
          }
        </p>
      </div>
     )}  */}
   </Grid>
           <Grid item xs={12}>
                 <Button variant="contained" style={{backgroundColor: '#3251A3', borderColor: '#FF5E14'}} 
                 fullWidth type="submit">
                   Upload Program
                 </Button>
            </Grid>
      </Grid>
      </Paper>
      </div>
      </Grid>
      </Container>
    </form>      
    </div>
  )
}

export default UploadProgramForm
