import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchPopularCourseList } from '../redux/actions/Actions';
import { useSelector, useDispatch } from 'react-redux';

const Popular = () => {
   const PopularCourseList = useSelector((state) => state.fetchAllPopularCourseList.PopularCourselist);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(fetchPopularCourseList());
  // }, []);

  if (PopularCourseList.length === 0) {
    return null;
  } else if (PopularCourseList.length > 0) {
    return (
      <>
        <div className="container-xxl py-5">
          <div className="container">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
              <h6 className="section-title bg-white text-center text-primary px-3">Courses</h6>
              <h1 className="mb-5">Popular Courses</h1>
            </div>

            <div className="equal-height-item">
              <div className="row g-4 justify-content-center">
                {PopularCourseList.map((eachItem) => (
                  <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s" key={eachItem.course_id}>
                    <div className="course-item rounded p-4 " style={{ backgroundColor: '#c3e0ea' }}>
                      <div
                        className="position-relative overflow-hidden popularimg-wrapper"
                        style={{
                          transition: 'transform 0.3s ease-in-out',
                        }}
                        onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1) rotateY(360deg)')}
                        onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1) rotateY(0deg)')}
                      >
                        <img
                          className="img-fluid"
                          src={`/img/courses/${eachItem?.course_name
                            .replaceAll('( ', '')
                            .replaceAll('/', '')
                            .replaceAll(')', '')
                            .replaceAll('#', '')
                            .replaceAll('.', '')}.png`}
                          alt={eachItem?.course_name}
                        />
                      </div>

                      <div className="text-center p-4 pb-0">
                        <h3 className="mb-0">Rs. {eachItem.cost}</h3>
                        <div className="mb-3">
                          <small className="fa fa-star text-primary"></small>
                          <small className="fa fa-star text-primary"></small>
                          <small className="fa fa-star text-primary"></small>
                          <small className="fa fa-star text-primary"></small>
                          <small className="fa fa-star text-primary"></small>
                          <small>(123)</small>
                        </div>
                        <h5 className="mb-4">{eachItem.course_name}</h5>
                        <div className="popular_description">
                          <h7 className="mb-4">{eachItem.description}</h7>
                        </div>
                        {/* Move the buttons below the description */}
                        <div className="w-100 d-flex justify-content-center mt-3">
                          <Link
                            to="/programs"
                            className="btn btn-sm btn-primary px-3 me-2"
                            style={{ borderRadius: '30px' }}
                          >
                            Read More
                          </Link>
                          <Link
                            to="/registerform"
                            className="btn btn-sm btn-primary px-3"
                            style={{ borderRadius: '30px' }}
                          >
                            Join Now
                          </Link>
                        </div>
                      </div>

                      <div className="ROW_COLUMN">
                        <div className="d-flex border-top">
                          <small className="flex-fill text-center border-end py-2">
                            <i className="fa fa-user-tie text-primary me-2"></i>
                            {eachItem.instructor}
                          </small>
                          <small className="flex-fill text-center border-end py-2">
                            <i className="fa fa-clock text-primary me-2"></i>
                            {eachItem.duration}
                          </small>
                          <small className="flex-fill text-center py-2">
                            <i className="fa fa-user text-primary me-2"></i>30 Students
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default Popular;
