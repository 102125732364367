import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Grid, Paper, FormControl, InputLabel, MenuItem, Select, CircularProgress, Snackbar } from '@mui/material';
import { fetchProgramList, fetchCourseList, fetchProgramById, uploadCourseFiles } from '../../../redux/actions/Actions';
import TextfieldCustom from '../../../common/TextfieldCustom';

const UploadCourseMaterial = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [showMaxFilesMessage, setShowMaxFilesMessage] = useState(false);
  const fetchCourses = useSelector((state) => state.fetchAllCourses.courses);
  const fetchMaterials = useSelector((state) => state.fetchMaterials.materials);
  const fetchPrograms = useSelector((state) => state.fetchAllPrograms.programs);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [formData, setFormData] = useState({
    course_id: '',
    selectedImages: [], // Array to store selected images
  });
  
  const [showDuplicateMessage, setShowDuplicateMessage] = useState(false);

  useEffect(() => {
    dispatch(fetchProgramList());
    dispatch(fetchCourseList());
  }, [dispatch]);
  
 
// Function to handle course selection
const handleCourseChange = (e) => {
  const selectedCourseId = e.target.value;

  // Check if the selected course ID already exists in the fetchMaterials
  const courseExists = fetchMaterials.some(material => material.course_id === selectedCourseId);
  
  if (courseExists) {
    setShowDuplicateMessage(true);
    return;
  }
  // If the course is not a duplicate, update the formData with the selected course ID
  setFormData({ ...formData, course_id: selectedCourseId });
};

  const handleDeleteFile = (index) => {
    const updatedFileList = [...formData.selectedImages];
    updatedFileList.splice(index, 1);
    setFormData({ ...formData, selectedImages: updatedFileList });
  };

  // Function to handle image selection
  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);
    setFormData((prevFormData) => ({
      ...prevFormData,
    }));
  };
  
  // Function to add selected images to the list
  const handleAddImages = () => {
    const fileInput = document.getElementById('imageUpload');
    const newSelectedImages = Array.from(fileInput.files);

    if (formData.selectedImages.length + newSelectedImages.length > 10) {
      setShowMaxFilesMessage(true); // Display message
      return;
    }

    // Filter out duplicates
    const uniqueNewSelectedImages = newSelectedImages.filter(newImage => {
      return !formData.selectedImages.some(existingImage => existingImage.name === newImage.name);
    });

    if (uniqueNewSelectedImages.length < newSelectedImages.length) {
      setShowDuplicateMessage(true);
    }

    // Reset the file input
    fileInput.value = null;

    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedImages: [...prevFormData.selectedImages, ...uniqueNewSelectedImages],
    }));
  };
  

  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loader
    const formImageData = new FormData();
    formImageData.append('course_id', formData.course_id); // Access course_id from state
    
    // Loop through selectedImages array and append each file individually
    formData.selectedImages.forEach(file => {
      formImageData.append('files', file);
    });

    try {
      // Dispatch action to upload images
      await dispatch(uploadCourseFiles(formImageData));
      setLoading(false); // Stop loader
      navigate("/auth/AllMaterials")
    } catch (error) {
      setLoading(false); // Stop loader in case of error
      // Handle error
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Container maxWidth="md" className="d-flex">
          <Grid container spacing={10} direction="column" justifyContent="center">
            <div style={{ width: "inherit" }}>
              <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
                <h3>Upload Course Materials</h3>
                <Button onClick={() => navigate(-1)}>Back</Button>
                <Grid container spacing={4} sx={{ border: '1px solid rgba(0,0,0,0.125)', zIndex: "3" }} style={{ padding: "30px" }}>
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <InputLabel id="course-name-label">Course name</InputLabel>
                      <Select
                        labelId="course-name-label"
                        id="course-name-label"
                        value={formData.course_id}
                        onChange={handleCourseChange}
                        required 
                      >
                        {fetchCourses.map((course) => (
                          <MenuItem key={course.course_id} value={course.course_id}>
                            {course.course_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
           
                  <Grid item xs={8} style={{display:'flex'}}>
                    
                      Select file
                      <input
                        id="imageUpload"
                        name='files'
                        type="file"
                        accept=".pdf,"
                        multiple
                        onChange={handleImageChange}
                        
                      />
                    
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button onClick={handleAddImages}>Add File</Button>
                  </Grid>
                  <Grid item xs={12}>
                  <div>
      {showMaxFilesMessage && (
        <p style={{ color: 'red' }}>Cannot select more than 10 files</p>
      )}
      {/* Rest of your component */}
    </div>
                    <ol>
                      {formData.selectedImages.map((image, index) => (
                        <div key={index}>
                          <li>{image && image.name ? image.name : `Image ${index + 1}`} <Button onClick={() => handleDeleteFile(index)}><img src={process.env.PUBLIC_URL + '/img/delete.png'} alt="png" style={{height:'20px',width:'20px'}}/></Button></li>
                        </div>
                      ))}
                    </ol>
                  </Grid>
          
                  <Grid item xs={12}>
                  <Button
  variant="contained"
  disabled={loading || formData.selectedImages.length === 0} // Disable if no files selected
  style={{ backgroundColor: '#3251A3', borderColor: '#FF5E14' }}
  fullWidth
  type="submit"
>
  {loading ? <CircularProgress size={24} /> : 'Upload Materials'}
</Button>

                  </Grid>
                </Grid>
              </Paper>
            </div>
          </Grid>
        </Container>
      </form>
      <Snackbar
        open={showDuplicateMessage}
        autoHideDuration={6000}
        onClose={() => setShowDuplicateMessage(false)}
        message="Duplicate entry have been excluded."
      />
    </div>
  );
}

export default UploadCourseMaterial;
