import { ActionTypes } from '../constants/action-types';

const initialState = {
  promotionHeroImageStatus: [],
};

export const uploadPromotionHeroImageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.UPLOAD_PROMOTION_HERO_IMAGE:
      return {
        ...state,
        promotionHeroImageStatus: payload
      };
    default:
      return state;
  }
};
