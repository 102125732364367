
const ProjectManagement = () => {

  return (
    <>
      <div className="container-fluid p-0">
        <h1 className="text-center">Project Management</h1>
      </div>
    </>

  );

}

export default ProjectManagement;