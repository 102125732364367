import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import OnlineClassRegistration from './OnlineClassRegistration';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Demo() {
  const DemoData = [
    { course: 'HTML' }, { course: 'CSS' }, { course: 'JAVA' }, { course: 'JAVASCRIPT' }, { course: 'React JS' }, { course: 'Vue' },
    { course: 'DevOps' }, { course: 'Python' }, { course: 'Machine learning' }, { course: 'Angluar JS' }, { course: 'ASPN.NET' },
    { course: 'Software Testing' }, { course: 'SQL' }, { course: 'C#' }, { course: 'C++' },
    { course: 'Django' },{ course: 'Type script' },{ course: 'Data science' }
  ];

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
  }, []);

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
        <DialogTitle>
          Let's book a <span style={{ fontWeight: 'bold', color: 'rgba(14, 31, 166, 0.8)' }}>Free Demo</span> session for you
          <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{ position: 'absolute', top: '8px', right: '8px', color: 'rgba(14, 31, 166, 0.8)' }}
        >
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Select Courses
          </Typography>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {DemoData.map((course, index) => (
              <div key={index} style={{ border: '1px solid #ccc', padding: '12px', marginBottom: '6px', marginRight: '6px', width: '150px', borderRadius: '14px', boxShadow: '0px 0px 15px #3251A3', backgroundColor: 'rgba(223, 247, 250, 0.8)' }}>
                <Button as={Link} to={`/components/Homepage/OnlineClassRegistration/${course.course}`} style={{ backgroundColor: 'transparent', color: 'inherit',width: '100%',outline: 'none',padding: 0,textDecoration: 'none',border: 'none', }} >{course.course}</Button>
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
