import React, { useEffect, useState,useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  fetchLessons, deleteLessonById } from '../../../redux/actions/Actions';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Delete, Edit } from '@mui/icons-material';
import { IconButton, Stack ,Grid,TextField} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';

const LessonList = () => {
    const fetchLesson = useSelector((state)=> state.getLessons.lessons)
    const { isAuthenticated, user } = useSelector((state) => state.auth);
    const userId = user?.role_id
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const[dataRows,setDataRows] = useState([])
    const[dataColumns,setDataColumns]=useState([])
    const[data,setData] = useState([])
    const [disableActions, setDisableActions] = useState(true);
    const userData =(userId===1||user?.role_name==="admin");
    // console.log("user data ",userData);

    function formatDate(dt){
      const getYear = new Date(dt).getFullYear()
      const getMonth = new Date(dt).getMonth() + 1
      const getMyDay = new Date(dt).getDate()
      const dateFormat = getMyDay + "/" + getMonth + "/" + getYear
      return dateFormat;
  }

    const columns = useMemo(() => {
        const commonColumns = [       
         { field: 'id', headerName: 'Id', width: 60, disableColumnMenu: true, sortable: false },
        { field: 'course_name', headerName: 'Course Name', width: 160, disableColumnMenu: true },
       { field: 'lesson_name', headerName: 'Lesson Name', width: 160, disableColumnMenu: true },
        { field: 'content', headerName: 'Content', width: 160, disableColumnMenu: true },
      ];
    
      const userSpecificColumns = userData
        ? [
      
        {
            field: 'action',
            headerName: 'Actions',
            width: 180,
            sortable: false,
            disableClickEventBubbling: true,
            
            renderCell: (params) => {
                const onClick = (e) => {
                  const currentRow = params.row;
                  navigate(`/auth/updateLesson/${currentRow.lesson_id}`)
                }; 
                 const handleDelete = () =>{
                    const currentRow = params.row;
                    dispatch(deleteLessonById(currentRow.lesson_id))
                    setData(data.filter((row) => row.lesson_id !== currentRow.lesson_id));
                    console.log('clicked Lesson is', currentRow.lesson_id)
                } 
                
                return (
                  <Stack direction="row" spacing={2}>
                     <IconButton onClick={onClick}>
                        <Edit />
                    </IconButton> 
                    <IconButton onClick={handleDelete}>
                        <Delete />
                    </IconButton>
                  </Stack>
                );
            },
          },
        ]
      : [];
  
    return [...commonColumns, ...userSpecificColumns];
  }, [userId, data, dispatch, navigate]);

       
      const rows = useMemo(
        () => data?.map((row, index) => {
        return {
          ...row,
          id: index + 1,
          course_id: row.course_id,
          lesson_name: row.lesson_name,
          content: row.content,
        }
      }),[data]);
     
      const [searchValue, setSearchValue] = useState("");
  
      const filteredRows = rows.filter((row) =>
       Object.values(row).some((value) =>
       value !== null &&
       value !== undefined &&
       value.toString().toLowerCase().includes(searchValue.toLowerCase())
       )
     );
  

  useEffect(() => {
    dispatch(fetchLessons())
  }, [ dispatch,fetchLesson]);

  //console.log('lessons', fetchlessons)

  useEffect(()=>{
    setData(fetchLesson)
    setDataColumns(columns)
    setDataRows(rows)
}, [data,fetchLesson])

  return (
    <div className='lesson-list'>
       <Grid item xs={5}
        container justify="flex">
        <TextField
          label="Search"
          variant="outlined"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          style={{ marginTop: 0, marginBottom:15 }} />
   
      </Grid>
      {
             (
              <Box sx={{ height: 400, width: '100%' }}>
              <DataGrid 
              style={{marginTop: '15px',marginBottom: '0px'}}
              rows={filteredRows }
              columns={dataColumns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
           /> </Box>)
        }
    </div>
  )
}

export default LessonList;