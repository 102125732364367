

import React, { useState, useRef, useMemo } from 'react';
import { Button, Container, Grid, Paper, Typography } from '@mui/material';
import TextfieldCustom from '../../../common/TextfieldCustom';
import './RealtimeProject.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { uploadProjectImage } from '../../../redux/actions/Actions';
import JoditEditor from 'jodit-react'; // Import JoditEditor

function RealtimeProjectForm() {
  const [project_name, setProject_name] = useState('');
  const [project_descrption, setProject_description] = useState('');
  const [imageObject, setImageObject] = useState(null);
  const [errors, setErrors] = useState({});
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];

    if (imageFile) {
      const objectURL = URL.createObjectURL(imageFile);
      setImageObject({
        name: imageFile.name,
        size: imageFile.size,
        type: imageFile.type,
        url: objectURL,
      });
    } else {
      console.error('No image file selected');
    }
  };

  const validateForm = () => {
    const updatedErrors = {};
    let hasErrors = false;

    if (!project_name.trim()) {
      updatedErrors.project_name = 'Project name is required';
      hasErrors = true;
    }

    if (!project_descrption.trim()) {
      updatedErrors.project_descrption = 'Project description is required';
      hasErrors = true;
    }

    if (!imageObject) {
      updatedErrors.image = 'Image is required';
      hasErrors = true;
    }

    setErrors(updatedErrors);
    return !hasErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append('project_name', project_name);
      formData.append('project_descrption', project_descrption);
      formData.append('image', e.target.elements.imageInput.files[0]);

      // Dispatch action to upload project details and image
      dispatch(uploadProjectImage(formData));

      // Reset form state
      setProject_name('');
      setProject_description('');
      setImageObject(null);

      // Set success state to true
      setSubmissionSuccess(true);

      // Navigate to the desired page
      navigate('/auth/realtimeProjectDetails', {
        state: { imageObject, project_name },
      });
    } catch (error) {
      console.error('Error uploading project details:', error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Container maxWidth="md" className="form-container">
          <Paper elevation={4} className="paper-container">
            <div className="paper">
              <Typography variant="h5" className="form-title">
                Realtime Project Name Uploader
              </Typography>
              <br />
              <Grid container spacing={4}>
                <Grid item xs={10}>
                  <TextfieldCustom
                    type="text"
                    name="projectName"
                    placeholder="Project Name"
                    value={project_name}
                    onChange={(e) => setProject_name(e.target.value)}
                    error={Boolean(errors.projectName)}
                    helperText={errors.projectName}
                  />
                </Grid>

                <Grid item xs={10}>
                  <JoditEditor

                    value={project_descrption}
                    onBlur={(newContent) => setProject_description(newContent)}
                  />
                </Grid>

                <Grid item xs={10}>
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    className="upload-button"
                  >
                    Upload Image
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleImageChange}
                      name="imageInput" // Add this line
                    />
                  </Button>
                  {errors.image && (
                    <p className="error-message">{errors.image}</p>
                  )}
                  {imageObject && (
                    <div className="image-preview">
                      <p>
                        <strong>Name:</strong> {imageObject.name}
                      </p>
                    </div>
                  )}
                </Grid>
                <Grid item xs={10}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    className="submit-button"
                  >
                    Submit
                  </Button>
                  {submissionSuccess && (
                    <p className="success-message">Submission successful!</p>
                  )}
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Container>

      </form>

    </div>
  );
}

export default RealtimeProjectForm;
