import React, { useEffect,useState } from "react";
import { Grid, Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleHeroContent, toggleSwitchAction, uploadNewpromotionEventText,deletepromotionEventText, uploadPromotionHeroImage, updatepromotionEventTextById, fetchpromotionEventTextList, fetchpromotionEventTextById } from "../../../redux/actions/Actions";
import {  FormControlLabel, Checkbox } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const PromotionFormController = () => {
  const showHeroContent = useSelector((state) => state.ToggleHeroContent.showHeroContent); 
// console.log('hreo in admin',showHeroContent);
  const navigate = useNavigate();
  const [image_name, setImage_name] = useState('');
  const [ImageError, setImageError] = useState("");
  const [imageObject, setImageObject] = useState(null);
   // const selectedEvent
   const selectEvent=useSelector((state)=> state.promotioneventtext.promotioneventtextData)
  
  
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(fetchpromotionEventTextList())
  },[dispatch])
  
  const selectedEvent=selectEvent[0]
  console.log('selectedevent',selectedEvent)
  const [formData, setFormData] = useState({
    header: '',
    paragraph: '',
    
  
});
const{header,paragraph}=formData



  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    const acceeptedimageType = [".png", ".jpeg",".jpg"];
    const imageType = imageFile.name
      .substring(imageFile.name.lastIndexOf("."))
      .toLowerCase();
    if (acceeptedimageType.includes(imageType)) {
      const objectURL = URL.createObjectURL(imageFile);
      setImageObject({
        name: imageFile.name,
        size: imageFile.size,
        type: imageFile.type,
        url: objectURL,
      });
      setImageError("");

    } else {
      setImageObject(null);
      setImageError("invalid image formate please select .png ,jpg or.jpeg");    }
  };
const handleToggleHeroContent=() => {
  var text = document.getElementById("text");
  dispatch(toggleHeroContent());
};
// console.log("toggledata",currentActivePromotionEvent.handleToggleHeroContent);
// console.log('toggle', toggleHeroContent);
  const handleOnSubmit = async (e) => {
    e.preventDefault();

    if (imageObject && image_name) {
      const formData = new FormData();
      formData.append('image', e.target.elements.imageInput.files[0]);
      formData.append('image_name', image_name);

      dispatch(uploadPromotionHeroImage(formData));
    }
    setImage_name('');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSecondSubmit = async (e) => {
    e.preventDefault();
    console.log('formdata5',formData)
    dispatch(uploadNewpromotionEventText({"header":`${formData.header}`,"paragraph":`${formData.paragraph}`}))

    // Add your second submit logic here
  };

  const handleDelete =(e) =>{
    dispatch(deletepromotionEventText(1))
  }
  
  
  
  const handleEdit = (e) => {
    dispatch(updatepromotionEventTextById({
      "header":header,
      "paragraph":paragraph,
      
    },1));
  };

  

  
useEffect(()=>{setFormData({header:selectedEvent?.header,paragraph:selectedEvent?.paragraph})},[selectedEvent])
console.log('formData',formData)
    
    
  return (
<Grid container className="container admin-controller" style={{ borderColor: 'black solid 2px' }}>
<form onSubmit={handleOnSubmit} className="uploader-form">
  <div className="uploader-container">
    <div><strong>Promotion Hero Image Uploader</strong></div>
    
   
    <TextField label="Image Name" type="text" value={image_name} onChange={(e) => setImage_name(e.target.value)} />
    <div className="image-name-uploader">
    <Button style={{marginTop:"10px"}}
    component="label"
      variant="contained"
      startIcon={<CloudUploadIcon />}>
  Upload file
      <input
        type="file"
        accept=".png, .jpeg ,.jpg"
        id="imageInput"
        style={{display:'none'}}
        onChange={handleImageChange}
      />
      
    </Button>
    {ImageError && (
              <div style={{ color: "red", marginTop: "5px" }}>{ImageError}</div>
            )}
    {imageObject && (
      <div className="image-info">
        <p className="image-info2"style={{ border:"2px solid black", position: 'absolute', marginLeft: '177px',marginTop:'-30px'}}>
        <strong>Name: </strong>
          {imageObject.name}
        </p>
      </div>
    )}
          </div>

   
    <Button style={{marginTop:"8px" ,border:"2px solid black", boxShadow:'3px 3px black'}}  {...(imageObject ? { type: "submit" } : { type: "" })}>Submit</Button>
  </div>
  <div className="promo-hero-selection-image-container">
    <div><strong>Select the file to display in UI</strong></div>
    <Button style={{marginTop:"8px" ,border:"2px solid black", boxShadow:'3px 3px black'}} onClick={() => navigate(`/auth/PromotionHeroAWSImgaeselction`)}>
      <strong>Selection Page</strong>
    </Button>
  </div>
  <div className="event-visibility-check-container">
    <div><strong>Event text visibility</strong></div>
    <FormControlLabel
      control={
        <Checkbox 
          id="defaultCheck1"
          checked={showHeroContent}
          onChange={() => handleToggleHeroContent()}
        />
      }
      label={`Event Text ${showHeroContent ? 'Invisible' : 'Visible'}`}
    />
  <div>
              <TextField
                 label="Heading"
                 type="text"
                name="header"
                value={header}
                onChange={handleChange}
              
                
              /><br></br>
              <TextField
                label="Paragraph"
                multiline
                rows={4}
                name="paragraph"
                value={paragraph}
                onChange={handleChange}
              /><br></br>
                
            </div>
          
           <div style={{ display: "flex", gap: "8px" }}>
    <Button style={{marginTop:"8px" ,border:"2px solid black", boxShadow:'3px 3px black'}} onClick={handleSecondSubmit} >Second Submit</Button>
  
   <Button style={{marginTop:"8px" ,border:"2px solid black", boxShadow:'3px 3px black'}} onClick={handleDelete} >Delete</Button>
   <Button style={{marginTop:"8px" ,border:"2px solid black", boxShadow:'3px 3px black'}} onClick={handleEdit} >Edit</Button>  
  </div>
  </div>
</form>

</Grid>
);
};

export default PromotionFormController;