import { ActionTypes } from "../constants/action-types";
const initialState={
    vistorCount:[]
};

export const vistorcountreducer =(state=initialState, action)=>{
    switch(action.type){
        case ActionTypes.FETCH_VISTOR_COUNT:
        return {
            ...state,
            vistorCount: action.payload
        };
        default: return state;
    }
}

