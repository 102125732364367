import { ActionTypes } from "../constants/action-types";

const initialState = {
  instructors: [],
};

export const FetchInstructors = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_INSTRUCTORS:
      return { ...state, instructors: payload };
    case ActionTypes.DELETE_INSTRUCTOR:
      return {
        ...state,
        instructors: state.instructors.filter((item, index) => index !== payload),
      };
    default:
      return state;
  }
};
