
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, FormControl, Grid ,Paper, Select } from '@mui/material';
import TextfieldCustom from '../../../common/TextfieldCustom';
import {fetchProjectImageByprojectname, updateProjectImageByproject_name } from '../../../redux/actions/Actions';



const  UpdateRealtimeProjectDetails=() =>{
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userId = user?.role_id
  const fetchProjectImageByprojectname12 = useSelector((state)=>state.getProjectImageByproject_name.ProjectImageByproject_name);
       console.log("######",fetchProjectImageByprojectname12)
  const dispatch = useDispatch()
  const { project_name } = useParams()
  const navigate = useNavigate()
   

  const selectedProjectImage = fetchProjectImageByprojectname12[0]


    useEffect(()=>{
      dispatch( fetchProjectImageByprojectname(project_name))
      console.log('xxxx',project_name);
   },  [dispatch,project_name])
   console.log('selected projectImage by id is', fetchProjectImageByprojectname)
   
   

  const [formData, setFormData] = useState({
    // project_name: '',
    // instructor_id: null,
    project_descrption 
    : '',
   
  });



  const {
    // project_name,
    project_descrption, 
    
   } = formData;

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };
  
  const ProjectData = {
    project_name: formData.project_name, 
    instructor_id: null,
    project_descrption: formData.project_descrption, 
  
  }

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   dispatch(updateProjectImageByproject_name(ProjectData,project_name));
  //     navigate("/auth/realtimeProjectDetails")
  //     console.log("formdata", ProjectData)        
  // };

  // useEffect(()=>{
  //   if (selectedProjectImage) {
  //   setFormData({
  //     project_name: selectedProjectImage?.project_name,
  //   instructor_id: selectedProjectImage?.instructor_id,
  //   project_descrption: selectedProjectImage?.project_descrption,

  //   })
  // }
  //  }, [selectedProjectImage])


  useEffect(() => {
    if (selectedProjectImage) {
      setFormData({
        project_descrption: selectedProjectImage?.project_descrption || '',
      });
    }
  }, [selectedProjectImage]);

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const updatedProjectData = {
      project_name: project_name,
      project_descrption: formData.project_descrption,
    };
    // Dispatch action to update project details
    dispatch(updateProjectImageByproject_name(updatedProjectData,project_name));
    console.log("Error updating project:", updateProjectImageByproject_name);
    navigate("/auth/realtimeProjectDetails");
  };
  return (
    <div>
<form onSubmit={handleSubmit}>
      <Container maxWidth="sm" className=" d-flex" >
    
    <Grid
      container
      spacing={2}
      direction="column"
      justifyContent="center"
      
    >
       <div style={{ width: "inherit"}}>
      <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
      <h3>Update ProjectDetails</h3>
      <h6>project_name : {selectedProjectImage?.project_name}</h6>
      <Button onClick={()=>navigate(-1)}>Back</Button>
      <Grid container  spacing={2} sx={{border: '1px solid rgba(0,0,0,0.125', zIndex:"3"}} style={{padding: "30px"}}>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="project_name" 
             value={project_name} onChange={handleChange} placeholder="Project Name"
             required
             />            
           </Grid>
           <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="project_descrption" 
             value={project_descrption} onChange={handleChange} placeholder="description"
             required
             />            
           </Grid>
           <Grid item xs={12}>
                 <Button variant="contained" style={{backgroundColor: '#3251A3', borderColor: '#FF5E14'}} 
                 fullWidth type="submit">
                   Update & Save  ProjectDetails
                 </Button>
            </Grid>
      </Grid>
      </Paper>
      </div>
      </Grid>
      </Container>
    </form>      
    </div>
  )
}
export default UpdateRealtimeProjectDetails
