// authReducer.js

import {
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT,
    FETCH_PENDING_REQUESTS_SUCCESS,
    FETCH_PENDING_REQUESTS_FAILURE,
  } from './authTypes';

  
  const initialState = {
    isAuthenticated: false,
    pendingRequests: [],
    user: null,
    error: null,
    token: null
  };
  
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case REGISTER_SUCCESS:
      case LOGIN_SUCCESS:
        return {
          ...state,
          isAuthenticated: true,
          user: action.payload,
          error: null,
        };
      case REGISTER_FAILURE:
      case LOGIN_FAILURE:
        return {
          ...state,
          isAuthenticated: false,
          user: null,
          error: action.payload,
        };
      case LOGOUT:
        return {
          ...state,
          isAuthenticated: false,
          user: null,
          error: null,
        };
        case FETCH_PENDING_REQUESTS_SUCCESS:
      return {
        ...state,
        pendingRequests: action.payload,
        error: null,
      };
    case FETCH_PENDING_REQUESTS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
      default:
        return state;
    }
  };
  
  export default authReducer;
  