import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Box,Container, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import './promoHeroIMGselection.css';

import {
 
  deletePromotionImage,
  deleteSelectedPromotionImage,
  fetchPromotionImages,
  fetchSelectedPromotionImages,
  uploadSelectedPromotionHeroImage,
} from "../../../redux/actions/Actions";

function PromotionHeroAWSImgaeselction() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const images = useSelector(
    (state) => state.promotionheroimagebyId.promotionImages
  );
  const selectedimage = useSelector(
    (state) => state.selectedPromotionImage.selectedPromotionImages
  );

  useEffect(() => {
    dispatch(fetchPromotionImages());
    dispatch(fetchSelectedPromotionImages());

  }, [dispatch]);
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [activeData, setActiveData] = useState(false);
  const [checkbox,setCheckedbox]=useState(false);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleAddImage = (e, index) => {
    const isChecked = document.getElementById(`myCheckbox-${index}`).checked;
    setActiveData(isChecked);
    
    if (isChecked) {
      setSelected((oldData) => [...oldData, e.target.value]);
      dispatch(uploadSelectedPromotionHeroImage({ "image_name": e.target.value }));
      setCheckedbox(isChecked)
    } else {
      setSelected((prevSelected) =>
        prevSelected.filter((value) => value !== e.target.value)

      );
      setCheckedbox(isChecked)

    }
  };
console.log('selectec............',selected)
  

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const deleteSelectedImage = (name) => {
    dispatch(deleteSelectedPromotionImage(name));
  
  }
  const handleDeleteImage = (e) => {
    dispatch(deletePromotionImage(images[currentImageIndex]?.image_id))
    console.log("image_id deleted"+`${images[currentImageIndex]?.image_id}`);
    setShowModal(false);
  };


  return (
    <div className="container" style={{ padding: "10px",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "10px 10px 10px ", }}>
      <div className="heading-promotion-hero-images-selection">
        <Typography style={{ textAlign: "center" }} variant="h4">
          Promotion Hero Image Controller
        </Typography>
        <Button
          type="button"
          style={{
            marginTop: "5px",
            border: "2px solid whitesmoke",
            boxShadow: "3px 3px black",
            backgroundColor: "black",
            color: "white",
          }}
          variant="contained"
          color="primary"
          onClick={() => navigate(-1)}
        >
          <strong>Back</strong>
        </Button>
      </div>
      <div className="partition-main-container" style={{display:"flex" }}>
        <Box>
          <Box sx={{ marginTop: "10px" }}>
            <Typography variant="h6">
              <b>Cloud Uploaded images</b>
            </Typography>
        
            <Typography variant="h7">
              <b>Note:</b>Click on Image for Selection to Display in Home Page
            </Typography><br/>
            <Typography variant="h7">
              <b>Note:</b>Delete button for deleting image from cloud
            </Typography>
            <Box className="hero-common-bg">
              <Box className="row m-0">
                {images.map((image, index) => (
                  <ul key={index}className="col-md-4 mb-3" >
                    <li   >
                      <input
                        type="checkbox"
                     
                        id={`myCheckbox-${index}`}
                        value={`${image.image_name}`}
                        className="myCheckbox"
                        onChange={(e) => handleAddImage(e, index)}
                      />
                      <label htmlFor={`myCheckbox-${index}` } style={{border:'4px solid black'}}>
                      {/* <span>{checkboxHeroData?"selected":"deselected"}</span> */}
                        <img
                          src={image.image_URLs}
                          alt={`Promotion Image ${image.image_name}`}
                          className="img-fluid"
                          // onClick={() => handleImageClick(index)}
                        />
                        <div className="text" style={{ padding: "5px" }}>
                          {/* <Typography sx={{ fontSize: "0.9rem" }}>
                            {`Id = ${image.image_id}`}
                          </Typography> */}
                          <Typography sx={{ fontSize: "0.9rem" }}>
                            {`${image.image_name}`}
                          </Typography>
                          <div className="button-container">
                            <Button
                              style={{ backgroundColor: "white", color: "red" }}
                              onClick={() => handleImageClick(index)}
                            >
                               <DeleteIcon/>
                            </Button>
                          </div>
                        </div>
                      </label>
                    </li>
                  </ul>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      <div className="selection-side-bar" style={{border:"2px solid black",width:"fit-content" ,padding:"5px"}}>
        <h5><b>selected image name</b></h5>
        {selectedimage.map((image, index) => (
              <Box 
              key={index}
                sx={{ overflow: "hidden" }}
                className="image-container"
                style={{ border: "1px solid black", padding: "2px" }}
              >
           
                <div className="text" style={{ padding: "5px" }}>
                  <Typography
                    sx={{ fontSize: "0.9rem" ,marginTop:"0.5rem"}}
                  ><b>{` ${image.image_name}`}</b></Typography>

                  <Button
                    style={{ backgroundColor:"white", color: "red"}}
                    onClick={() => deleteSelectedImage(`${image.image_name}`)}
                  >
                    <DeleteIcon/>
                  </Button>
                </div>
              </Box>
            ))}
      </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size="lg"
        style={{ position: "relative", marginRight: "40% !important" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Details of Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={images[currentImageIndex]?.image_URLs}
            alt={`Promotion Image ${images[currentImageIndex]?.image_name}`}
            className="img-fluid"
          />
          {`Id = ${images[currentImageIndex]?.image_id}`},<tr></tr>{" "}
          {`Name = ${images[currentImageIndex]?.image_name}`}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDeleteImage}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PromotionHeroAWSImgaeselction;