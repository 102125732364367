import { ActionTypes } from "../constants/action-types";

const initialState={

    job_application_DocFileStatus:[],
};

export const uploadJob_application_DocFile=(state=initialState,{
    type,payload
})=>{
    switch(type){
        case 'UPLOAD_JOB_APPLICATION_DOCFILE':
            return {...state,job_application_DocFileStatus:payload};
            default:
                return state;
    }
}
