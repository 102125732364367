import { ActionTypes } from "../constants/action-types"

const initialState = {
    updateTutorialTopic:[]

}

export const UpdateTutorialTopicReducer = (state=initialState, {type, payload})=>{
switch (type){
    case ActionTypes.EDIT_TUTORIAL_TOPIC: 
    return {...state, updateTutorialTopic: payload}
    default: return state
}

}