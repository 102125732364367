import { ActionTypes } from "../constants/action-types";

const initialState = {
  faq: [],
};

export const FetchFaq = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_FAQ:
      return { ...state, faq: payload }
      case ActionTypes.DELETE_FAQ:
        return {
          ...state,
          faq: state.faq.filter((item) => item !== payload)
        }
    default:
      return state;
  }
};
