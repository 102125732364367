import { ActionTypes } from "../constants/action-types"

const initialState = {
    updateTutorialTechnology:[]

}

export const UpdateTutorialTechnologyReducer = (state=initialState, {type, payload})=>{
switch (type){
    case ActionTypes.EDIT_TUTORIAL_TECHNOLOGY: 
    return {...state, updateTutorialTechnology: payload}
    default: return state
}

}