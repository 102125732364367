import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOtpFromBackend } from '../redux/actions/Actions'; // Import the action creator
import {
  Button,
  Container,
  Grid,
  Paper,
  TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const VerificationCode = () => {
  const dispatch = useDispatch();
  const backendOtp = useSelector((state) => state.otp_id.otp_id); // Extracting otp_id from the state object

  const navigate = useNavigate();

  const [code, setCode] = useState(['', '', '', '']);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    // Fetch the OTP from the backend when the component mounts
    dispatch(fetchOtpFromBackend());

  }, []);

  const handleChange = (e, index) => {
    const value = e.target.value;
  
    if (/^[a-zA-Z\d]+$/.test(value) && value.length === 1) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
  
      if (index < 3 && value !== '') {
        const nextInput = document.getElementById(`code-input-${index + 1}`);
        if (nextInput && !newCode[index + 1]) {
          nextInput.focus();
        }
      }
  
      const isFilled = newCode.every((digit) => digit !== '');
      setIsSubmitDisabled(!isFilled);
    }
  };
  

  const handleBackspace = (e, index) => {
    if (e.key === 'Backspace') {
      const newCode = [...code];
      newCode[index] = '';
      setCode(newCode);

      if (index > 0) {
        const prevInput = document.getElementById(`code-input-${index - 1}`);
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userCode = code.join('');

    if (userCode === backendOtp) {
      // Verification code is correct, navigate to the next page (e.g., ChangePassword)
      navigate('/changepassword');
    } else {
      // Invalid verification code, show an alert
      alert('Invalid verification code. Please try again.');
    }
  };

  return (
    <>
      <div className="row m-0 align-items-center justify-content-center">
        <div className="col-lg-5">{/* Add any necessary images or UI elements */}</div>
        <div className="col-lg-7">
          <Container maxWidth="sm" className="d-flex">
            <Grid container spacing={2} direction="column" justifyContent="center">
              <div style={{ width: 'inherit' }}>
                <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ border: '1px solid rgba(0,0,0,0.125', zIndex: '3' }}
                    style={{ padding: '30px' }}
                  >
                    {code.map((digit, index) => (
                      <Grid item xs={3} key={index}>
                        <TextField
                          type="text"
                          id={`code-input-${index}`}
                          value={digit}
                          onChange={(e) => handleChange(e, index)}
                          onKeyDown={(e) => handleBackspace(e, index)}
                          placeholder="•"
                          fullWidth
                          inputProps={{ maxLength: 1 }}
                        />
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: '#3251A3', borderColor: '#FF5E14' }}
                        fullWidth
                        onClick={handleSubmit}
                        disabled={isSubmitDisabled} // Disable the button when necessary
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
};

export default VerificationCode;