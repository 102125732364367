import { ActionTypes } from "../constants/action-types";

const initialState = {
    PopularCourselist: []
};

export const PopularCourselist = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.FETCH_POPULAR_COURSES:
            return { ...state, PopularCourselist: payload }; // Use PopularCourselist here
        case ActionTypes.DELETE_POPULAR_COURSES:
            return {
                ...state,
                PopularCourselist: state.PopularCourselist.filter((item, index) => index !== payload)
            };
        default:
            return state;
    }
};
