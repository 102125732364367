import { ActionTypes } from "../constants/action-types"

const initialState = {
  categoryById: []
}

const FetchCategoryById = (state=initialState, {type, payload})=>{
    switch(type){
        case ActionTypes.FETCH_CATEGORY_BY_ID : return {...state, 
            categoryById: payload}           
        default: return state;
    }
    
}


export default FetchCategoryById;