// eventImageReducer.js
import { ActionTypes } from "../constants/action-types";


const initialState = {
  imageName: null,
};

const eventImageReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_IMAGE_NAME':
      return { ...state, imageName: action.payload };

    // Handle other action types if needed

    default:
      return state;
  }
};

export default eventImageReducer;
