
import { ActionTypes } from "../constants/action-types";

const initialState = {};

const eventUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_EVENT_BY_ID':
      return state; 
    
    default:
      return state;
};
}

export default eventUpdateReducer;
