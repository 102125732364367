import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select } from '@mui/material';
import TextfieldCustom from '../../../common/TextfieldCustom';
import { fetchProgramId } from '../../../redux/actions/Actions';
import { updateProgramById, updateProgramImage } from '../../../redux/actions/Actions';

const UpdateProgram = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userId = user?.role_id
  const fetchProgramsById = useSelector((state)=> state.getProgramsId.programsId)
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()
  const [errors, setErrors] = useState({});
  const [error, setError] = useState("");
  const [Tileimage, setTileimage] = useState(null);
  const [Bannerimage, setBannerimage] = useState(null);
    useEffect(()=>{
      dispatch(fetchProgramId(id))
   },  [dispatch,id])
   console.log('selected course name by id is', fetchProgramsById)
   
   const selectedProgram = fetchProgramsById[0]
   

  const [formData, setFormData] = useState({
    program_name: '',
    duration: '',
    cost: '',
    target_audience: '',
    program_details:'',
    program_coverage:'',
    outcome:'',
    career_opportunities: '',
    registration_charge: '',
    gst:''
  });

  const { program_name, 
    duration, 
    cost, 
    target_audience,
    program_details, 
    program_coverage, 
    outcome,
    career_opportunities,
    registration_charge, gst} = formData;

    // const [image_data , setimage_data] = useState({
    //   tile_image:'',
    //   banner_image:''
    // });
    // const {tile_image , banner_image} = image_data;

    const handletileChange = (e) => {
     // setimage_data((previmage_data) => ({ ...previmage_data, tile_image: selectedTileimage }));
     
      const selectedTileimage = e.target.files[0];
      setTileimage(selectedTileimage);
     
    }
   // console.log("tileImage",Tileimage);
    const handleBannerChange = (e) => {
      //setimage_data((previmage_data) => ({ ...previmage_data, banner_image: selectedBannerimage }));
      const selectedBannerimage = e.target.files[0];
      setBannerimage(selectedBannerimage);
    }

//  const program_image = {
//   tile_image: {
//     fieldname: 'tile_image',
//     image: image_data.tile_image
//   },
//   banner_image: {
//     fieldname: 'banner_image',
//     image: image_data.banner_image
//   }
// };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  
  const programData = {
    program_name: formData.program_name, 
    duration: formData.duration,
    cost: formData.cost, 
    target_audience: formData.target_audience,
    program_details: formData.program_details,
    program_coverage: formData.program_coverage,
    outcome: formData.outcome,
    career_opportunities: formData.career_opportunities, 
    registration_charge: formData.registration_charge, 
    gst: formData.gst
  }


  const isValidprogramName = (name) => {
    const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
    return alphanumericRegex.test(name);
  };
  const isValidDuration = (name) => {
    const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
    return alphanumericRegex.test(name);
  };
  const isValidCost = (name) => {
    const numericOnlyRegex = /^[0-9.]+$/;
    return numericOnlyRegex.test(name);
  };
  const isValidTargetAudience = (name) => {
    const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
    return alphanumericRegex.test(name);
  };
  const isValidProgramDetails = (name) => {
    const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
    return alphanumericRegex.test(name);
  };
  const isValidProgramCoverage = (name) => {
    const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
    return alphanumericRegex.test(name);
  };
  const isValidOutcome = (name) => {
    const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
    return alphanumericRegex.test(name);
  };
  const isValidCureerOppotunity = (name) => {
    const alphanumericRegex = /^(?=.*[a-zA-Z])[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]{3,}$/;
    return alphanumericRegex.test(name);
  };
  const isValidRegistrationCharges = (name) => {
    const numericOnlyRegex = /^[0-9.]+$/;
    return numericOnlyRegex.test(name);
  };
  const isValidGST = (name) => {
    const numericOnlyRegex = /^[0-9.]+$/;
    return numericOnlyRegex.test(name);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationChecks = [
      { field: 'program_name', value: program_name, validator: isValidprogramName, errorMsg: 'Enter Valid Details' },
      { field: 'duration', value: duration, validator: isValidDuration, errorMsg: 'Enter Valid Details' },
      
      { field: 'cost', value: cost, validator: isValidCost, errorMsg: 'Enter Valid Details' },
      { field: 'target_audience', value: target_audience, validator: isValidTargetAudience, errorMsg: 'Enter Valid Details' },
      { field: 'program_details', value: program_details, validator: isValidProgramDetails, errorMsg: 'Enter Valid Details' },
      { field: 'program_coverage', value: program_coverage, validator: isValidProgramCoverage, errorMsg: 'Enter Valid Details' },
      { field: 'outcome', value: outcome, validator: isValidOutcome, errorMsg: 'Enter Valid Details' },
      { field: 'career_opportunities', value: career_opportunities, validator: isValidCureerOppotunity, errorMsg: 'Enter Valid Details' },
      { field: 'registration_charge', value: registration_charge, validator: isValidRegistrationCharges, errorMsg: 'Enter Valid Details' },
      { field: 'gst', value: gst, validator: isValidGST, errorMsg: 'Enter Valid Details' },

    ];
  
     // Check and update errors for each field
     let hasErrors = false;
     const updatedErrors = {};
 
     validationChecks.forEach(({ field, value, validator, errorMsg }) => {
       if (!value.trim() || !validator(value)) {
         updatedErrors[field] = errorMsg;
         hasErrors = true;
       }
     });
 
     // Update errors state
     setErrors(updatedErrors);
 
     // If errors exist, stop form submission
     if (hasErrors) {
       return;
     }

     if(!Tileimage && !Bannerimage)
     {
      dispatch(updateProgramById(programData,id));
      navigate("/auth/allPrograms")
     }
   // console.log(Tileimage,Bannerimage,"tile and banner images");
    
      // Append images to FormData
     else if(Tileimage && Bannerimage){
      dispatch(updateProgramById(programData,id));
  const formDataWithImage = new FormData();
  formDataWithImage.append("tile_image", Tileimage);
  formDataWithImage.append("banner_image", Bannerimage);
    // Include image IDs in the URL
     dispatch(updateProgramImage(formDataWithImage,id));
     navigate("/auth/allPrograms")
      }
      else if (Tileimage || Bannerimage){
        alert("please select both images");
        //return;
      }
      else {
        return; 
      }
      //navigate("/auth/allPrograms")
     
      //console.log("formdata", programData)        
  };
  useEffect(()=>{
    setFormData({
      program_name: selectedProgram?.program_name,
    duration: selectedProgram?.duration,
    cost: selectedProgram?.cost,
    target_audience: selectedProgram?.target_audience,
    program_details:selectedProgram?.program_details,
    program_coverage:selectedProgram?.program_coverage,
    outcome:selectedProgram?.outcome,
    career_opportunities: selectedProgram?.career_opportunities,
    registration_charge: selectedProgram?.registration_charge,
    gst:selectedProgram?.gst

    })
     // Set Tileimage and Bannerimage with fetched URLs
    //  setTileimage(selectedProgram?.tile_image_url);
    //  setBannerimage(selectedProgram?.banner_image_url);
 }, [selectedProgram]);
  
  
  return (
    <div>
      <form onSubmit={handleSubmit}>
      <Container maxWidth="sm" className=" d-flex" >
    
    <Grid
      container
      spacing={2}
      direction="column"
      justifyContent="center"
      
    >
       <div style={{ width: "inherit"}}>
      <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
      <h3>Update Program</h3>
      <h6>Program name: {selectedProgram?.program_name}</h6>
      <Button onClick={()=>navigate(-1)}>Back</Button>
      <Grid container  spacing={2} sx={{border: '1px solid rgba(0,0,0,0.125', zIndex:"3"}} style={{padding: "30px"}}>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="program_name" 
             value={program_name} onChange={handleChange} placeholder="Program Name*"
             
             />      
             {errors.program_name && <span style={{ color: 'red' }}>{errors.program_name}</span>}             
           </Grid>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="duration" 
             value={duration} onChange={handleChange} placeholder="Duration*"
             
             />       
              {errors.duration && <span style={{ color: 'red' }}>{errors.duration}</span>}    

           </Grid>
           <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="cost" 
             value={cost} onChange={handleChange} placeholder="Cost*"
             
             />       
             {errors.cost && <span style={{ color: 'red' }}>{errors.cost}</span>}        
              
           </Grid>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="target_audience" 
             value={target_audience} onChange={handleChange} placeholder="Target Audience*"
             
             />        
              {errors.target_audience && <span style={{ color: 'red' }}>{errors.target_audience}</span>}     
            
           </Grid>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="program_details" 
             value={program_details} onChange={handleChange} placeholder="Program Details"
             
             />       
             {errors.program_details && <span style={{ color: 'red' }}>{errors.program_details}</span>}    
              
           </Grid>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="program_coverage" 
             value={program_coverage} onChange={handleChange} placeholder="Program Coverage"
             
             />    
             {errors.program_coverage && <span style={{ color: 'red' }}>{errors.program_coverage}</span>} 
               
           </Grid>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="outcome" 
             value={outcome} onChange={handleChange} placeholder="Outcome"
             
             />   
              {errors.outcome && <span style={{ color: 'red' }}>{errors.outcome}</span>}    
                   
           </Grid>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="career_opportunities" 
             value={career_opportunities} onChange={handleChange} placeholder="Career Opportunities"
             
             />  
             {errors.career_opportunities && <span style={{ color: 'red' }}>{errors.career_opportunities}</span>} 
                    
           </Grid>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="registration_charge" 
             value={registration_charge} onChange={handleChange} placeholder="Registration Charge"
             
             /> 
              {errors.registration_charge && <span style={{ color: 'red' }}>{errors.registration_charge}</span>} 
                      
           </Grid>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="gst" 
             value={gst} onChange={handleChange} placeholder="GST"
             
             />     
             {errors.gst && <span style={{ color: 'red' }}>{errors.gst}</span>} 
              
           </Grid>
          
           <Grid item xs={6}>
          
       Tile Image
      <input
        type="file"
        //required
        name="tile_image"
        accept=".png, .jpg, .jpeg" label="tile image"
         onChange={handletileChange}
       // style={{ display: 'none' }}
      />
      
   
   </Grid>
   <Grid item xs={6}>
    </Grid>
   <Grid item xs={6}>
         
       Banner Image
      <input
        type="file"
        name="banner_image"
        //required
        accept=".png, .jpg, .jpeg" label="bannr image"
        onChange={handleBannerChange}
        //style={{ display: 'none' }}
      />
      
   
   </Grid>
           <Grid item xs={12}>
                 <Button variant="contained" style={{backgroundColor: '#3251A3', borderColor: '#FF5E14'}} 
                 fullWidth type="submit">
                   Update & Save Program
                 </Button>
            </Grid>
      </Grid>
      </Paper>
      </div>
      </Grid>
      </Container>
    </form>      
    </div>
  )
}

export default UpdateProgram