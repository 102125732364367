
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Container, Grid, Paper, FormControl, InputLabel, MenuItem, Select, CircularProgress } from '@mui/material';
import TextfieldCustom from '../../../common/TextfieldCustom';
import { uploadNewCourse, fetchProgramList } from '../../../redux/actions/Actions';
import { uploadCourseImage,createNewProgramCourse } from '../../../redux/actions/Actions';


const UploadCourseForm = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userId = user?.role_id
  const [Image, setImage] = useState(null);
  const [Pdf, setPdf] = useState(null);
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    course_name: '', 
    instructor_id: null, 
    description: '', 
    duration: '', 
    start_date: null, 
    end_date: null
  });
  const [errors, setErrors] = useState({
    course_name: '',
    description: '',
    duration: ''
  });
  const { course_name, instructor_id, description, duration, start_date, end_date } = formData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Validation logic
    let error = '';
    if (name === 'course_name' && /^\d+$/.test(value)) {
      error = "Course Name cannot contain numeric values.";
    } else if (name === 'description' && /^\d+$/.test(value)) {
      error = "Description cannot contain numeric values.";
    } else if (name === 'duration' && /[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      error = "Duration cannot contain special characters.";
    }
    // Update errors state
    setErrors({ ...errors, [name]: error });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  
  const courseData = {
    course_name: formData.course_name, 
    instructor_id: null, 
    description: formData.description, 
    duration: formData.duration, 
    start_date: null, 
    end_date: null
  }
 const handleimageChange = (e) => {
  const selectedimage = e.target.files[0];
  setImage(selectedimage);
 }
  
 const handlePdffileChange= (e) => {
  const selectedfile = e.target.files[0];
  setPdf(selectedfile);
 }
  
  const fetchPrograms = useSelector((state) => state.fetchAllPrograms.programs);
  const [programId, setProgramId] = useState("");
  const [programSelected, setProgramSelected] = useState(false);
 const handleProgramChange = (e) => {
   setProgramId(e.target.value);
   console.log("Program id:"+e.target.value);
   setFormData({ ...formData, program_id: e.target.value });
   setProgramSelected(true); 
 }; 

 const [loading, setLoading] = useState(false);

 const handleSubmit = async (e) => {
  e.preventDefault();
  if (Object.values(errors).some(error => error)) {
    return;
  }
  setLoading(true); // Start loader
  const courseData = { ...formData, program_id: programId};
  const response=await dispatch(uploadNewCourse(courseData));
  const course_id=response.data.course_id;

  const programCourseData = {
    program_id: programId,
    course_id: course_id
  
  };
  const formDataWithImage = new FormData();
   
    formDataWithImage.append("course_image", Image);
    formDataWithImage.append("course_pdf",Pdf);
    formDataWithImage.append("course_id", course_id);
    formDataWithImage.append("program_id", programId);
    await dispatch(uploadCourseImage(formDataWithImage,course_id));
    await dispatch(createNewProgramCourse(programCourseData))
    console.log("formdata", formDataWithImage)    
    navigate("/auth/allCourses")
    console.log("formdata", courseData)        
    setLoading(false); // Stop loader
};

 useEffect(() => {
   dispatch(fetchProgramList());
 }, [dispatch]);
  
  return (
    <div>
      <form onSubmit={handleSubmit}>
      <Container maxWidth="sm" className=" d-flex" >
    <Grid
      container
      spacing={2}
      direction="column"
      justifyContent="center"
      
    >
       <div style={{ width: "inherit"}}>
      <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
      <h3>Upload new Course</h3>
      <Button onClick={()=>navigate(-1)}>Back</Button>
      <Grid container  spacing={2} sx={{border: '1px solid rgba(0,0,0,0.125', zIndex:"3"}} style={{padding: "30px"}}>
           {/* Dropdown to select program */}
           <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="program-id-label">Program</InputLabel>
              <Select
                labelId="program-id-label"
                id="program-id-label"
                //value={programId}
                //defaultValue={programId}
                label="Program"
                 onChange={handleProgramChange}
                value={formData.program_id}
                // onChange={(e) => setFormData({ ...formData, program_id: e.target.value })}
                required 
              >
                {fetchPrograms.map((program) => (
                  <MenuItem key={program.program_id} value={program.program_id}>
                    {program.program_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="course_name" 
             value={course_name} onChange={handleChange} placeholder="Course Name"
             required
             /> 
             {errors.course_name && <div style={{ color: 'red' }}>{errors.course_name}</div>}            
           </Grid>
      {/*   <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name=" instructor_id" 
             value={ instructor_id} onChange={handleChange} placeholder=" instructor_id"
             required
             />            
           </Grid>    */}          
           <Grid item xs={6}>
             <textarea
             type="text"
             name="description" 
             value={description} onChange={handleChange} placeholder="Description"
             />
             {errors.description && <div style={{ color: 'red' }}>{errors.description}</div>}            
           </Grid>
          <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="duration" 
             value={duration} onChange={handleChange} placeholder="Duration"
             required
             />
             {errors.duration && <div style={{ color: 'red' }}>{errors.duration}</div>}            
           </Grid>         
           <Grid item xs={6}>
           
           Select Course Image
      <input
        type="file"
        name="course_image"
        accept=".png, .jpg, .jpeg" label="Course image"
         onChange={handleimageChange}
        required
      />
      
   
   </Grid>
   <Grid item xs={6}></Grid>
   <Grid item xs={6}>
          
       Upload Syllabus(PDF) 
      <input
        type="file"
        name="course_pdf"
        accept=".doc,.docx,.pdf" label="doc file"
         onChange={handlePdffileChange}
        required
      />
   </Grid>
           <Grid item xs={12}>
                 <Button variant="contained" style={{backgroundColor: '#3251A3', borderColor: '#FF5E14'}} 
                 fullWidth type="submit" disabled={loading}>
                   {loading ? <CircularProgress size={24} /> : 'Upload Course'}
                 </Button>
            </Grid>
      </Grid>
      </Paper>
      </div>
      </Grid>
      </Container>
    </form>      
    </div>
  )
}

export default UploadCourseForm
