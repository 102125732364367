import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import './details.css';
export default function Projectlistdetails() {
  const location = useLocation();
  const projectDetails = location.state && location.state.project_name;
  console.log('pavitra12', projectDetails);

  const RealtimeprojectsData = useSelector((state) => state.Realtimeprojects.RealtimeprojectsData);
  console.log('RealtimeprojectsData', RealtimeprojectsData);

  if (!projectDetails) {
    return <div>No project details available</div>;
  }

  // Find the project object in RealtimeprojectsData that matches the projectDetails
  const selectedProject = RealtimeprojectsData.find(
    (project) => project.project_name === projectDetails
  );
  console.log('0116',selectedProject)

  if (!selectedProject) {
    return <div>Project not found</div>;
  }

  return (
    <div className="container-xxl py-5" id='about'>
    <div className="container">
        <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{minHeight: "400px"}}>
                <div className="position-relative h-100">
                     <img className="img-fluid position-absolute w-100 h-100" src={selectedProject.image_url} alt={selectedProject.project_name} style={{objectFit: "cover"}} /> 
                    {/* <img src={selectedProject.image_url} alt={selectedProject.project_name} /> */}

                </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                <h6 className="section-title bg-white text-start text-primary pe-3">Project Details</h6>
                <h1 className="mb-4">{selectedProject.project_name}</h1>
                <div className="mb-4">
                {/* {selectedProject.project_descrption} */}
                <div dangerouslySetInnerHTML={{ __html: selectedProject.project_descrption }} />
                </div>
            </div>
        </div>
    </div>
</div>      

  );
}
