import React, { useEffect, useState,useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deletePrerequisiteById, fetchPrerequisiteList } from '../../../redux/actions/Actions';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Delete, Edit } from '@mui/icons-material';
import { Box, Grid,  IconButton, TextField , Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const PrerequisiteList = () => {
    const fetchPrerequisite = useSelector((state)=> state.fetchAllPrerequisites.prerequisites)
    const { isAuthenticated, user } = useSelector((state) => state.auth);
    const userId = user?.role_id
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const[dataRows,setDataRows] = useState([])
    const[dataColumns,setDataColumns]=useState([])
    const[data,setData] = useState([])
    const userData =(userId===1||user?.role_name==="admin");
    // console.log("user data ",userData);

    const columns =useMemo(
      () =>{ const commonColumns=[ 
        { field: 'id', headerName: 'Id', width: 60, disableColumnMenu: true, sortable: false },
        { field: 'course_id', headerName: 'Course Id', width: 60, disableColumnMenu: true},
       /*  { field: 'prerequisite_course_id', headerName: 'Prerequisite Course Id', width: 160, disableColumnMenu: true }, */
       { field: 'prerequisite_name', headerName: 'Prerequisite Name', width: 160, disableColumnMenu: true },
        { field: 'content', headerName: 'Content', width: 160, disableColumnMenu: true },
      ];
      const userSpecificColumns=userData?[ 
        
        {
            field: 'action',
            headerName: 'Actions',
            width: 180,
            sortable: false,
            disableClickEventBubbling: true,
            
            renderCell: (params) => {
                 const onClick = (e) => {
                  const currentRow = params.row;
                  navigate(`/auth/updatePrerequisite/${currentRow.prerequisite_id}`)
                }; 
                 const handleDelete = (e) =>{
                    const currentRow = params.row;
                    dispatch(deletePrerequisiteById(currentRow.prerequisite_id))
                    // console.log('clicked Prerequisite is', currentRow.prerequisite_id)
                }  
                
                return (
                  <Stack direction="row" spacing={2}>
                      <IconButton onClick={onClick}>
                        <Edit />
                    </IconButton> 
                    <IconButton onClick={()=>handleDelete()}>
                        <Delete />
                    </IconButton> 
                  </Stack>
                );
            },
          }
        ]
        :[];
        return [...commonColumns,...userSpecificColumns];
      },[userId,data,dispatch,navigate]);


        const rows = useMemo(
        () =>data?.map((row, index) => {
        return {
          ...row,
          id: index + 1,
          course_id: row.course_id,
         /*  prerequisite_course_id: row.prerequisite_course_id,  */
          prerequisite_name: row.prerequisite_name,
          content: row.content,
        }
      }),[data]);

      const [searchValue, setSearchValue] = useState("");
  
      const filteredRows = rows.filter((row) =>
       Object.values(row).some((value) =>
       value !== null &&
       value !== undefined &&
       value.toString().toLowerCase().includes(searchValue.toLowerCase())
       )
     );   

  useEffect(() => {
    dispatch(fetchPrerequisiteList())
  }, [ dispatch,fetchPrerequisite]);

  // console.log('Prerequisites', fetchPrerequisite)

  useEffect(()=>{
    setData(fetchPrerequisite)
    setDataColumns(columns)
    setDataRows(rows)
}, [data,fetchPrerequisite])

  return (
    <div className='prerequisite-list'>
      <Grid item xs={5}
        container justify="flex">
          <TextField
            label="Search"
            variant="outlined"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
            style={{ marginTop: 0, marginBottom: 15 }}
          />
         </Grid>

         {
          (
          <Box sx={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={filteredRows}
            columns={dataColumns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
          />
        </Box>
      ) }</div>
    
  );
}
export default PrerequisiteList;