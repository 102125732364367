import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UploadInstructorForm from './UploadInstructorForm';
import WelcomeMenus from '../../WelcomeMenus';

const AddNewInstructor = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userId = user?.role_id;
  console.log('logged in as', userId);

  return (
    <div>
      {userId === 1 ? (
        <UploadInstructorForm />
      ) : (
        <>
          <h2>Admin Only can Add Instructors</h2>
          <WelcomeMenus />
        </>
      )}
    </div>
  );
};

export default AddNewInstructor;
