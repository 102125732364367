import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchFaqList } from "../redux/actions/Actions";
import { Box, Button, Stack } from "@mui/material";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import './faq.css'; // Import the CSS file

const theme = createTheme();

theme.typography.h5 = {
  fontSize: "1rem",
  "@media (min-width:600px)": {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.2rem",
  },
};

const Faq = () => {
  const fetchFaq = useSelector((state) => state.fetchAllFaq.faq);
  // console.log("faqdataaaaaaaaaaaaaaaaa", fetchFaq);

  const [itemsToShow, setItemsToShow] = useState(5);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFaqList());
  }, [dispatch]);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleLoadMore = () => {
    // Increase the number of items to show when the "Load More" button is clicked
    setItemsToShow(itemsToShow + 5); // You can adjust the number based on your design
  };

  const handleUnload = () => {
    // Decrease the number of items to show when the "Unload" button is clicked
    setItemsToShow(Math.max(5, itemsToShow - 5)); // Ensure a minimum of 5 items are shown
  };
  // const faqdata = jsondata.FaqData;
  //fetching the data with is_active 1
  const activeFetchFaq = fetchFaq.filter((item) => item.is_active === 1)
  // console.log("activefetchfaq_________",activeFetchFaq)
  return (
    <ThemeProvider theme={theme}>
      <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">

        <div className="container">

        <div className="d-flex justify-content-center align-items-center">

        <div className="szuki" style={{marginRight:"40px"}}>
          <img className="ztuvez" src="/img/ZTUVEZ61 1.png" alt="" style={{ objectFit: "cover"}} />
          </div>
          <div>

          <div className="text-center py-2">

          <div className="label">
<div className="text-wrapper" style={{color: '#FD661F', fontSize: 39, fontFamily: 'Poppins', fontWeight: '700', wordWrap: 'break-word'}}>Quick Answers</div>
</div>
<br></br>

<h6  style={{color: '#FD661F', fontSize: 39, fontFamily: 'Poppins', fontWeight: '700', wordWrap: 'break-word'}}>Frequently Asked Questions
            </h6>
          </div>
          <br></br>

          <div class="accordion accordion-flush" id="accordionFlushExample">
  {activeFetchFaq.slice(0, itemsToShow)?.map((eachItem, index) => (
    eachItem.is_active === 1 && (
      <div class="accordion-item" key={index}>

        <h2 class="accordion-header" id={`flush-heading${index}`}>
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#flush-collapse${index}`}
            aria-expanded="false"
            aria-controls={`flush-collapse${index}`}
          >
            {eachItem.heading}
          </button>
        </h2>
        <div
          id={`flush-collapse${index}`}
          class="accordion-collapse collapse"
          aria-labelledby={`flush-heading${index}`}
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            {eachItem.details}
            <Link to={eachItem.link}>
              &nbsp;{eachItem.linkContent}
            </Link>
          </div>
        </div>
      </div>
    )
  ))}
</div>


          {/* {activeFetchFaq.slice(0, itemsToShow)?.map(
            (eachItem) =>
              eachItem.is_active === 1 && (
                <Stack spacing={3}>
                <Accordion
                  expanded={expanded === eachItem.faq_id}
                  key={eachItem.faq_id}
                  onChange={handleChange(eachItem.faq_id)}
                  sx={{ marginBottom: '8px' }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className="accordian-heading" variant="h5">
                      {eachItem.heading}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      className="accordian-details"
                      color="text-secondary"
                    >
                      {eachItem.details}
                      <Link to={eachItem.link}>
                        &nbsp;{eachItem.linkContent}
                      </Link>
                    </Typography>
                  </AccordionDetails>
                </Accordion></Stack>
              )
          )} */}

          <Box display="flex" justifyContent="center" marginTop={2}>
          {itemsToShow < activeFetchFaq.length && (
           
              <Button
                variant="contained"
                size="small"
                sx={{ backgroundColor: '#f0fbfc',color: 'black' }}
                style={{ textTransform: "none", padding: "5px" }}
                onClick={handleLoadMore}
              >
                <KeyboardDoubleArrowDownIcon style={{ marginRight: "6px" }} />
                See More
              </Button>
           
          )}
          {itemsToShow > 5 && (
            
              <Button
                variant="contained"
                sx={{ backgroundColor: '#f0fbfc',color: 'black' }}
                size="small"
                style={{ textTransform: "none", padding: "5px", marginLeft: '8px' }}
                onClick={handleUnload}
              >
                <KeyboardDoubleArrowUpIcon style={{ marginRight: "6px" }} />
                See Less
              </Button>
            
          )}
          </Box>
        </div>
      </div>
      </div>
      </div>

    </ThemeProvider>
  );
};

export default Faq;
