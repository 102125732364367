import React, { useState, useEffect } from 'react';
import './project.css';
import { useLocation, useNavigate } from 'react-router-dom';
 import

 {fetchRealtimeprojects} from '../redux/actions/Actions'
import { useDispatch, useSelector } from 'react-redux';

export default function ProjectList() {
  const [isRotating, setIsRotating] = useState(true);
  const [imageObjects, setImageObjects] = useState([]);
  const dispatch = useDispatch();
  const [selectedProject, setSelectedProject] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

    const RealtimeprojectsData = useSelector((state)=>state.Realtimeprojects.RealtimeprojectsData);
    console.log('RealtimeprojectsData',RealtimeprojectsData);

    useEffect(()=>{
      dispatch(fetchRealtimeprojects());
    },[dispatch]);
  useEffect(() => {
    if (location.state && location.state.imageObjects) {
      setImageObjects(location.state.imageObjects);
    }
  }, [location.state]);
  
  useEffect(() => {
    const boxElement = document.querySelector('.box');

    if (boxElement) {
      boxElement.style.animationPlayState = isRotating ? 'running' : 'paused';
    }

    return () => {
      if (boxElement) {
        boxElement.style.animationPlayState = 'running';
      }
    };
  }, [isRotating]);

  const handleStopRotation = (project_name) => {
    setIsRotating(!isRotating);
    setSelectedProject(project_name);
    setShowModal(true);

    navigate('/Realtimeproject/:id', { state: { project_name} });
    console.log('1234',project_name);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    console.log('1234', selectedProject);
  };

  return (
    <div className='container'>
      <br />
      <div className='text-center wow fadeInUp' data-wow-delay='0.1s'>
        <h4 className='section-title bg-white text-left text-primary px-3'>Realtime Project List</h4>
      </div>

      <div className={`container-xxl projectlist-page py-5`} style={{ textAlign: 'center' }}>
      <div className='box'>
  {(() => {
    const displayedImages = [];
    for (let i = 0; i < Math.min(60, RealtimeprojectsData.length); i++) {
      const image = RealtimeprojectsData[i];
      displayedImages.push(
        <span key={i} style={{ '--i': i + 1 }} onClick={() => handleStopRotation(image.project_name)}>
          <img src={image.image_url} alt={image.image_name} />
        </span>
      );
    }
    return displayedImages;
  })()}
</div>
      </div>
    </div>
  );
}
