import { ActionTypes } from "../constants/action-types"

const initialState = {
    newOnlineclass:[]

}

export const UploadOnlineclass = (state=initialState, {type, payload})=>{
switch (type){
    case ActionTypes.UPLOAD_ONLINECLASS: 
    return {...state, newOnlineclass: payload}
    default: return state;
}

};