import React, { useEffect, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Grid, Item, AccordionActions } from "@mui/material";
import { Card, CardContent, Typography } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import jsondata from "../../../data/data.json";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { fetchCategoriesList, fetchTopicsList,fetchInterviewQaList, fetchLinkedinUrlList } from "../../redux/actions/Actions";
import { useSelector, useDispatch } from 'react-redux';
import { FaLinkedin } from 'react-icons/fa';


const InterviewDetails = () => {


  const fetchCategories = useSelector((state) => state.fetchAllCategories.categoriesData)
  const fetchInterviewQa = useSelector((state) => state.fetchAllInterviewQa.interviewQaData)
  const fetchTopics = useSelector((state) => state.fetchAllTopics.topicsData)
  const fetchLinkedinUrl = useSelector((state) => state.fetchAllLinkedinUrl.linkedinUrlData)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategoriesList());
    dispatch(fetchTopicsList());
    dispatch(fetchInterviewQaList());
    dispatch(fetchLinkedinUrlList());
  }, [dispatch]);


  // for displaying dynamically

  const { category_id } = useParams();
  const selectedCategory = fetchCategories.find((category) => category.category_id === parseInt(category_id));
  const filteredTopics = fetchTopics.filter((topic) => topic.category_id === parseInt(category_id));

  // modal

  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setModalOpen(true);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleCloseModal = () => {
    setModalOpen(false);
  }


  // level buttons filter

  const [selectedButton, setSelectedButton] = useState("beginner");

  const filteredAnswersByLevels = fetchInterviewQa.filter((interviewQa) => {
    if(selectedButton === "beginner") {
      return interviewQa.level === "Beginner";
    } else if (selectedButton === "intermediate") {
      return interviewQa.level === "Intermediate";
    } else if (selectedButton === "advanced") {
      return  interviewQa.level === "Advanced";
    }
    return true;
  });

  const handleButtonClick = (button) => {
    setSelectedButton(button);
  };


  // qa filter according to the selected topic

  const [selectedTopic, setSelectedTopic] = useState(filteredTopics.length > 0 ? filteredTopics[0].topic_id : null);

  useEffect(() => {
    if (filteredTopics.length > 0 && selectedTopic === null) {
      setSelectedTopic(filteredTopics[0].topic_id);
    }
  }, [filteredTopics, selectedTopic]);

  const combinedFilteredAnswers = filteredAnswersByLevels.filter((interviewQa) => {
    if (selectedTopic) {
      return interviewQa.topic_id === selectedTopic;
    }
    return true;
  });


  const handleTopicSelect = (topicId) => {
    setSelectedTopic(topicId);
  }

  // previous and next page filters
  const handlePreviousTopic = () => {
    const currentIndex = filteredTopics.findIndex((topic) => topic.topic_id === selectedTopic);
    if (currentIndex > 0) {
      setSelectedTopic(filteredTopics[currentIndex - 1].topic_id);
    }
  };

  const handleNextTopic = () => {
    const currentIndex = filteredTopics.findIndex((topic) => topic.topic_id === selectedTopic);
    if (currentIndex < filteredTopics.length - 1) {
      setSelectedTopic(filteredTopics[currentIndex + 1].topic_id);
    }
  };

  // answer expansion

  const [expandedAnswers, setExpandedAnswers] = useState([]);

  const toggleExpand = (qaId) => {
    if (expandedAnswers.includes(qaId)) {
      setExpandedAnswers(expandedAnswers.filter((id) => id !== qaId));
    } else {
      setExpandedAnswers([...expandedAnswers, qaId]);
    }
  };

  // 


  return (
    <div>



      <div>
        <h1 style={{ textAlign: "center"}}>{selectedCategory?.category_name} Interview Questions</h1>
      </div>

      <div className="containerIntrview">
        <div className="container-fluid">
          <div className="row">

            <div className="col-sm-12 col-md-2">
              <Card>
                <CardContent>                 
                  <h5>Topics</h5>
                    {filteredTopics.map((topic) => (
                      <Link
                        key={topic.topic_id}
                        onClick={() => handleTopicSelect(topic.topic_id)}
                        className="interview-topics"
                      >
                        <p
                          style={{
                            fontSize: "15px",
                            marginBottom: "5px",
                            color: selectedTopic === topic.topic_id ? "blue" : "black"
                          }}
                        >{topic.topic_name}</p>
                      </Link>
                    ))}
                </CardContent>
              </Card>
            </div>


            <div className="col-sm-12 col-md-7">
              <div className="d-flex justify-content-between mb-2" style={{ marginBottom: "7px"}}>
                <button id="button1" type="button" className={`btn btn-primary flex-fill mx-1 ${selectedButton === "beginner" ? "active" : ""}`} onClick={() => handleButtonClick("beginner")}>Beginner</button>
                <button id="button2" type="button" className={`btn btn-warning flex-fill mx-1 ${selectedButton === "intermediate" ? "active" : ""}`} onClick={() => handleButtonClick("intermediate")}>Intermediate</button>
                <button id="button3" type="button" className={`btn btn-success flex-fill mx-1 ${selectedButton === "advanced" ? "active" : ""}`} onClick={() => handleButtonClick("advanced")}>Advanced</button>
              </div>


              <div className="d-flex justify-content-between mb-2">
                <button type="button" className="btn btn-primary" onClick={handlePreviousTopic}>
                  <ArrowBackIosNewIcon/>
                  Previous
                </button>
                <button type="button" className="btn btn-primary" onClick={handleNextTopic}>
                  Next
                  <ArrowForwardIosIcon/>
                </button>
              </div>

              {combinedFilteredAnswers.map((interviewQa) => (
                <div key={interviewQa.qa_id} style={{ marginBottom: "7px" }}>
                  <Card className="mb-3">
                    <div className="order-2 order-md-1 d-flex align-items-center">
                      <div className="mr-3 linkedin-container">
                        <Link to={interviewQa.linkedin_url} target="_blank">
                          <CardMedia
                            component="img"
                            alt="user image"
                            src={interviewQa.interview_image_url ? interviewQa.interview_image_url : interviewQa.prepared_by}
                            className="rounded-circle"
                            style={{ width: "85px", height: "70px", display: "flex", marginLeft: "10px" }}
                          />
                          <FaLinkedin className='fab fa-linkedin-in linkedin-icon' />
                        </Link>
                      </div>
                      <div>
                        <CardContent>
                          <h6 style={{ margin: '0', marginBottom: '5px' }}>{interviewQa.question}</h6>
                          <p className="text-secondary" style={{ margin: '0', marginBottom: '5px' }}>Prepared by {interviewQa.prepared_by}</p>
                          <p style={{ margin: '0' }}>
                            {expandedAnswers.includes(interviewQa.qa_id) ? interviewQa.answer : interviewQa.answer.slice(0, 100)}
                            {interviewQa.answer.length > 100 && (
                              <span style={{ cursor: 'pointer', color: 'grey' }} onClick={() => toggleExpand(interviewQa.qa_id)}>
                                {expandedAnswers.includes(interviewQa.qa_id) ? ' Less' : ' ...More'}
                              </span>
                            )}
                          </p>
                        </CardContent>
                      </div>
                    </div>
                  </Card>
                </div>
              ))}
            </div>



            <div className="col-sm-12 col-md-3">
              {fetchCategories.map((category) => (
                <div 
                  style={{ marginBotton: "5px"}} item xs={12} sm={6} md={3}
                  key={category.category_id}
                >
                  <Link>
                    <Card>
                      <CardMedia
                        component="img"
                        alt="add image"
                        height="120"
                        width="90"
                        src={category.category_image_url ? category.category_image_url : category.category_name}
                      />
                      <p style={{textAlign: "center"}}>{category.category_name}</p>
                    </Card> 
                  </Link>
                </div>
              ))}
            </div>

          </div>
        </div>
      </div>

    </div>
  );
}

export default InterviewDetails