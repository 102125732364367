/*import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Grid, Paper } from '@mui/material';
import TextfieldCustom from '../../../common/TextfieldCustom';
import { fetchInstructorById, updateInstructorById } from '../../../redux/actions/Actions';

const UpdateInstructor = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userId = user?.role_id;
  const fetchInstructorById = useSelector((state) => state.fetchInstructorById.instructorById);
  console.log("##############", fetchInstructorById);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchInstructorById(id));
  }, [dispatch, id]);
 
  const selectedInstructor = fetchInstructorById[0];

  const [formData, setFormData] = useState({
    instructor_name: '',
    qualification: '',
    course: '',
    experience: '',
  });

  const { instructor_name, qualification, course, experience } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const instructorData = {
    instructor_name: formData.instructor_name,
    qualification: formData.qualification,
    course: formData.course,
    experience: formData.experience,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateInstructorById(instructorData, id));
    navigate("/auth/allInstructors");
    console.log("formdata", instructorData);
  };

  useEffect(() => {
    if (selectedInstructor) {
      setFormData({
        instructor_name: selectedInstructor?.instructor_name,
        qualification: selectedInstructor?.qualification,
        course: selectedInstructor?.course,
        experience: selectedInstructor?.experience,
      });
    }
  }, [selectedInstructor]);
  if (!selectedInstructor) {
    return <div>Loading...</div>;
  }
  
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Container maxWidth="sm" className=" d-flex">
          <Grid
            container
            spacing={2}
            direction="column"
            justifyContent="center"
          >
            <div style={{ width: "inherit" }}>
              <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
                <h3>Update Instructor</h3>
                <h6>Instructor name: {selectedInstructor?.instructor_name}</h6>
                <Button onClick={() => navigate(-1)}>Back</Button>
                <Grid container spacing={2} sx={{ border: '1px solid rgba(0,0,0,0.125', zIndex: "3" }} style={{ padding: "30px" }}>
                  <Grid item xs={6}>
                    <TextfieldCustom
                      type="text"
                      name="instructor_name"
                      value={instructor_name}
                      onChange={handleChange}
                      placeholder="Instructor Name"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextfieldCustom
                      type="text"
                      name="qualification"
                      value={qualification}
                      onChange={handleChange}
                      placeholder="Qualification"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextfieldCustom
                      type="text"
                      name="course"
                      value={course}
                      onChange={handleChange}
                      placeholder="Course"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextfieldCustom
                      type="text"
                      name="experience"
                      value={experience}
                      onChange={handleChange}
                      placeholder="Experience"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" style={{ backgroundColor: '#3251A3', borderColor: '#FF5E14' }} fullWidth type="submit">
                      Update & Save Instructor
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </Grid>
        </Container>
      </form>
    </div>
  );
};

export default UpdateInstructor;*/


import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Grid, Paper } from '@mui/material';
import TextfieldCustom from '../../../common/TextfieldCustom';
import { fetchInstructorById as fetchInstructorByIdAction, updateInstructorById } from '../../../redux/actions/Actions';

const UpdateInstructor = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userId = user?.role_id;
  const instructorDetails = useSelector((state) => state.fetchInstructorById.instructorById);
  console.log("##############", instructorDetails);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchInstructorByIdAction(id));
  }, [dispatch, id]);
 
  const selectedInstructor = instructorDetails[0];

  const [formData, setFormData] = useState({
    instructor_name: '',
    qualification: '',
    course: '',
    experience: '',
  });

  const { instructor_name, qualification, course, experience } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const instructorData = {
    instructor_name: formData.instructor_name,
    qualification: formData.qualification,
    course: formData.course,
    experience: formData.experience,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateInstructorById(instructorData, id));
    navigate("/auth/allInstructors");
    console.log("formdata", instructorData);
  };

  useEffect(() => {
    if (selectedInstructor) {
      setFormData({
        instructor_name: selectedInstructor?.instructor_name,
        qualification: selectedInstructor?.qualification,
        course: selectedInstructor?.course,
        experience: selectedInstructor?.experience,
      });
    }
  }, [selectedInstructor]);
  
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Container maxWidth="sm" className=" d-flex">
          <Grid
            container
            spacing={2}
            direction="column"
            justifyContent="center"
          >
            <div style={{ width: "inherit" }}>
              <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
                <h3>Update Instructor</h3>
                <h6>Instructor name: {selectedInstructor?.instructor_name}</h6>
                <Button onClick={() => navigate(-1)}>Back</Button>
                <Grid container spacing={2} sx={{ border: '1px solid rgba(0,0,0,0.125', zIndex: "3" }} style={{ padding: "30px" }}>
                  <Grid item xs={6}>
                    <TextfieldCustom
                      type="text"
                      name="instructor_name"
                      value={instructor_name}
                      onChange={handleChange}
                      placeholder="Instructor Name"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextfieldCustom
                      type="text"
                      name="qualification"
                      value={qualification}
                      onChange={handleChange}
                      placeholder="Qualification"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextfieldCustom
                      type="text"
                      name="course"
                      value={course}
                      onChange={handleChange}
                      placeholder="Course"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextfieldCustom
                      type="text"
                      name="experience"
                      value={experience}
                      onChange={handleChange}
                      placeholder="Experience"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" style={{ backgroundColor: '#3251A3', borderColor: '#FF5E14' }} fullWidth type="submit">
                      Update & Save Instructor
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </Grid>
        </Container>
      </form>
    </div>
  );
};

export default UpdateInstructor;
