import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getEmailId, registerSubsciber } from '../redux/actions/Actions'
import { Box, Button, Grid, Modal, TextField } from '@mui/material'
import { register } from '../redux/auth/authActions'
import { Link } from 'react-router-dom'
import PopUpModal from './PopUpModal'
import FormValidator from "../common/FormValidator";
import FooterDetails from"../../data/FooterDetails.json";

const Footer = () => {
  const [mail, setMail] = useState('')
  const [name, setName] = useState('')
  const dispatch = useDispatch()
  const [formData, setFormData] = useState([])
  const [mobile, setMobile] = useState('')
  const [deviceFlag, setDeviceFlag] = useState(1)
  const [open, setOpen] = useState(false);
  const [mailError, setMailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showModal, setShowModel]=useState(false);
  const [selecteddata, setSelecteddata] = useState({});
  const footerData=FooterDetails.Footerdetails;

  // console.log("myyyyyyyyyyyyyyyyyyy",footerData);
  // const fetchSubscribers = useSelector((state) => state.getAllSubscribersNprogram.subscribersWithProgram);
  //  const [duplicateError, setDuplicateError] = useState(false);
  const [errors, setErrors] = useState({
    subscriber_name: "",
    email: "",
    phone_number: "",
    // Add more fields here if needed
  });
  const validatename = (name) => {
    const namePattern = /^[a-zA-Z]+$/;
    return namePattern.test(name);
  };


  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const validateMobile = (mobile) => {
    return /^\d{10}$/.test(mobile);
  };

  const handleMailChange = (e) => {
    const newValue = e.target.value;
    const newErrors = { ...errors };
    setMail(newValue);


    if (isFormSubmitted) {
      if (newValue.trim() === '') {
        setMailError('This field is required');
        // You can add this line to delete the error from newErrors
        delete newErrors['mail'];
      } else if (!validateEmail(newValue)) {
        setMailError('Invalid Email');
        // You can add this line to delete the error from newErrors
        delete newErrors['mail'];
      } else {
        setMailError('');
      }
    } else {
      setMailError('');
    }
  };


  const handleMobileChange = (e) => {
    const newValue = e.target.value;
    const newErrors = { ...errors };
    setMobile(newValue);


    if (isFormSubmitted) {
      if (newValue.trim() === '') {
        setMobileError('This field is required');
        // Delete the error from newErrors
        delete newErrors['mobile'];
      } else if (!validateMobile(newValue)) {
        setMobileError('Phone number should have exactly 10 digits');
        // Delete the error from newErrors
        delete newErrors['mobile'];
      } else {
        setMobileError('');
      }
    } else {
      setMobileError('');
    }
  };

  const handleNameChange = (e) => {
    const newValue = e.target.value;
    const newErrors = { ...errors };
    setName(newValue);


    if (isFormSubmitted) {
      if (newValue.trim() === '') {
        setNameError('This field is required');
        // Delete the error from newErrors
        delete newErrors['name'];
      } else if (!validatename(newValue)) {
        setNameError('invalid username');
        // Delete the error from newErrors
        delete newErrors['name'];
      } else {
        setNameError('');
      }
    } else {
      setNameError('');
    }
  };




  //pop up modal
  const handleOpen = () => {
    // setIsFormSubmitted(true);

    if (mail !== '' && mobile !== '') {
      if (!validateEmail(mail)) {
        setMailError('Invalid Email');
      } else if (!validateMobile(mobile)) {
        setMobileError('Phone number should have exactly 10 digits');
      } else {
        setOpen(true);
      }
    }
  };
  const handleClose = () => setOpen(false);

  const visitorData = {
    subscriber_name: name,
    email: mail,
    password: "qwe",
    phone_number: mobile,
    role_id: "2"
  }
  useEffect(() => {
    setFormData(visitorData)
  }, [visitorData])





  const handleSubmit = (e) => {
    e.preventDefault();


    if (mail === '' || mobile === '' || name === '') {
      if (mail === '') {
        setMailError('Email is required');
      } else {
        setMailError('');
      }

      if (mobile === '') {
        setMobileError('Mobile number is required');
      } else {
        setMobileError('');
      }

      if (name === '') {
        setNameError('Name is required');
      } else {
        setNameError('');
      }
    } else {
      // Both email and mobile are provided. Now, validate them.
      if (!validateEmail(mail)) {
        setMailError('Invalid Email');
      } else if (!validateMobile(mobile)) {
        setMobileError('Phone number should have exactly 10 digits');
      } else if (!validatename(name)) {
        setNameError('Invalid UserName');
      } else {
        // No errors, dispatch and reset the form.
        dispatch(registerSubsciber(formData));
        setMail('');
        setMobile('');
        setName('');
      }
    }
  };
  const mobileDevice = (/iphone|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase()));
  // const phoneNumber = '+91 84316 63025';
  const phoneNumber = '918431663025';

  const handleClick = () => {
    // const url = `https://web.whatsapp.com/send?phone=${phoneNumber}`
    const url = ` https://wa.me/${phoneNumber}/?text=HELLO`

    window.open(url, '_blank');
  };
  const handleClick2 = () => {
    const apiUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}=Hi,%20I%20would%20like%20to%20get%20more%20aboutUpl`
    window.open(apiUrl, '_blank');
  };

  useEffect(() => {
    if (mobileDevice) {
      setDeviceFlag(0)
      //console.log('device is mobile')
    } else {
      setDeviceFlag(1)
      //console.log('device is large')
    }

  }, [mobileDevice])

  const handleLinkClick = () => {
    // Scroll to the top of the page.
    window.scrollTo(0, 0);
  };
  const handleOpenModal =(data)=>{
  console.log("cliick name",data)
setSelecteddata(data)
    setShowModel(true)
  }

 const handleCloseModal=()=>{
 setShowModel(false )

  }
  return (
    <>
      <div className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-2 col-md-6">
              <h4 className="text-white mb-3">Quick Link</h4>
              <Link className="btn btn-link" to="/about" onClick={handleLinkClick}>About Us</Link>
              <Link className="btn btn-link" to="/programs" onClick={handleLinkClick}>Programs</Link>
              <Link className="btn btn-link" to="/jobs">Jobs Page</Link>
              {/* <Link className="btn btn-link" to="programs">Downloads</Link> */}
              <Link className="btn btn-link" to="/">FAQs & Help</Link>
              {/* <button className="btn btn-link"  onClick={()=>(handleOpenModal("Disclaimer"))}> Disclaimer</button>
              <button className="btn btn-link"  onClick={()=>(handleOpenModal("privacy policy"))}> privacy policy</button>
              <button className="btn btn-link"  onClick={()=>(handleOpenModal("terms_and_conditions"))}> terms and conditions</button> */}


{
  footerData.map((data,index)=>(
  <button key={index} className="btn btn-link"  onClick={()=>(handleOpenModal(data))}> {`${data.name}`}</button> 

   ) )
}
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="text-white mb-3">Contact</h4>
                  <h5 className="text-white mb-3">India</h5>
                  <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>#123, 70Th Cross
                    2Nd Floor, SVA Arcade
                    5Th Block, Rajaji Nagara
                    Bengaluru - 560010
                  </p>
                  <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+91 8431 6630 25  /</p>
                  <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+91 6363 5078 58</p>
                  <p className="mb-2"><i className="fa fa-envelope me-3"></i>hr@uplsnipe.com</p>
                  <div className="d-flex pt-2">
                    {/* <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-twitter"></i></a> */}
                    <a className="btn btn-outline-light btn-social" href="https://www.facebook.com/profile.php?id=100091713887746" target='_blank'><i className="fab fa-facebook-f"></i></a>
                    <a className="btn btn-outline-light btn-social" href="https://youtube.com/@uplsnipe" target='_blank'><i className="fab fa-youtube" ></i></a>
                    <a className="btn btn-outline-light btn-social" href="https://www.linkedin.com/company/upl-snipe/" target='_blank'><i className="fab fa-linkedin-in" ></i></a>
                    <a className="btn btn-outline-light btn-social" href="https://www.instagram.com/uplsnipe/?igshid=NjIwNzIyMDk2Mg%3D%3D" target='_blank'><i className="fab fa-instagram"></i></a>
                  </div>
                </div>
                <div className="mt-4 col-md-6">
                  <h4 className="text-white mb-3"></h4>
                  <h5 className="text-white mb-3">U.S.A</h5>
                  <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>Edison , NJ -08817, USA.</p>
                  <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+1 (848) 285-2392</p>
                  <p className="mb-2"><i className="fa fa-envelope me-3"></i>hr@uplsnipe.com</p>
                </div>
              </div>
            </div>
            <div className="col">
              <h4 className="text-white mb-3">Newsletter</h4>
              <p>Subscribe here to get Latest courses and offers</p>
              <div className="position-relative mx-auto row" >
                <form onSubmit={handleSubmit}>
                  <Box mb={2}>
                    <div className='conatiner'>
                      <div className='column'>
                        <Grid container spacing={4}>
                          <Grid item md={10} xs={12} sm={12}>
                            <TextField

                              name="Enter Your Name"
                              placeholder='Enter Your Name'
                              value={name}
                              onChange={handleNameChange}
                              style={{ backgroundColor: "white" }}
                            />
                            {nameError && (<div style={{ color: "red" }}>{nameError}</div>)}

                          </Grid>
                          <Grid item md={10} xs={12} sm={12}>
                            <TextField
                              // type="email"
                              // required
                              name="Enter Your Email id"
                              placeholder='Enter Your Email id'
                              value={mail}
                              onChange={handleMailChange}
                              style={{ backgroundColor: "white" }}
                            />
                            {mailError && (<div style={{ color: "red" }}>{mailError}</div>)}
                          </Grid>
                          <Grid item md={10} xs={12} sm={12}>
                            <TextField
                              type="tel"
                              // required
                              name="Enter Your Mobile Number"
                              placeholder='Enter Mobile Number'
                              value={mobile}
                              onChange={handleMobileChange}
                              style={{ backgroundColor: "white" }}
                            />
                            {mobileError && (<div style={{ color: "red" }}>{mobileError}</div>)}
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Box>
                  <PopUpModal
                    buttonname={"Subscribe"}
                    buttonClassName="btn btn-primary py-2 mx-3 top-0 end-0 mt-2 me-2"
                    buttonType="submit"
                    open={open}
                    setOpen={setOpen}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
        <p className="copyright">Powered by Snipe Tech Pvt Ltd</p>
      </div>
      <div className="wtsapp">
        <div className='btn-1 ' onClick={(deviceFlag === 1) ? handleClick : handleClick2}>
          <i className='bi bi-whatsapp'></i>
        </div>
      </div>

      <Modal open={showModal} aria-labelledby="modal-title" aria-describedby="modal-description">
    <div style={{ 
        position: 'absolute',
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)', 
        width: "90%", 
        padding:'15px',
        borderRadius:'1.2rem',
        // maxWidth: "600px", // Set maximum width to maintain responsiveness
        backgroundColor: "white", 
        boxShadow: 24,
        // Add media query for smaller screens
        '@media (max-width: 768px)': {
            width: '80%', // Adjust width for smaller screens
        }
    }}>
        <div style={{
            borderRadius: "2rem", 
            padding: '25px',
            maxHeight: '70vh', // Limit the height and enable vertical scrolling
            overflowY: 'auto', // Ensure vertical scrolling
        }}>
            <h2 id="modal-title" style={{ color: "black", textDecoration: "underline" }}>
                <b>{selecteddata.name}</b>
            </h2>
            <p id="modal-description">
                <>
                    {selecteddata.description && selecteddata.description.map((item, index) => ( // Added null check here
                        <ul key={index}>
                            <li>{item}</li> {/* Render each item from the description array */}
                        </ul>
                    ))}
                </>
            </p>
        </div>
        <Button style={{ border: '3px solid black', marginTop: '15px' }} onClick={handleCloseModal}>Close</Button>
    </div>
</Modal>
    </>
  )
}

export default Footer