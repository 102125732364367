import { ActionTypes } from "../constants/action-types"

const initialState = {
  eventId: null,
  error: null,
};

export const uploadEvent= (state = initialState, action) => {

  switch (action.type) {
    case 'UPLOAD_EVENT_SUCCESS':
      //console.log('Event ID in reducer:', action.payload);

      return {
        ...state,
        eventId: action.payload,
        error: null,
      };
    case 'UPLOAD_EVENT_FAILURE':
      return {
        ...state,
        eventId: null,
        error: 'Error uploading event',
      };
    default:
      return state;
  }
};
