import React from 'react'
import Dashboard from './Dashboard';
import WelcomeMenus from './WelcomeMenus';
import { useSelector } from 'react-redux';

const ExecutorCp = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userId = user?.role_id
  console.log('logged in as',userId)
  return (
    <div>
         
          {
            isAuthenticated && userId === 3 ? <Dashboard />
             : (<WelcomeMenus />)
          }
    </div>
  )
}

export default ExecutorCp