import React, { useEffect, useState } from 'react';
import { Button, Container, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select } from '@mui/material';
import TextfieldCustom from '../../../common/TextfieldCustom';
import PopUpModal from '../../../common/PopUpModal';
import { useDispatch, useSelector } from 'react-redux';
import { uploadJobDetails ,uploadCompanyLogo} from '../../../redux/actions/Actions';




function JobUploaderForm() {


  const [company_name, setcompany_name] = useState('');
  const [company_description, setcompany_description] = useState('');
  const [job_title, setjob_title] = useState('');
  const [job_description, setjob_description] = useState('');
  const [experience_req, setexperience_req] = useState('');
  const [salary, setsalary] = useState('');
  const [location, setlocation] = useState('');
  const [contact_email, setcontact_email] = useState('');
  const [contact_phone, setcontact_phone] = useState('');


  const [errors, setErrors] = useState({}); // State to manage errors

  const dispatch = useDispatch();
  const [imageObject, setImageObject] = useState(null);
  const handleImageChange = (e) => {
const imageFile = e.target.files[0];
   //setSelectedImage(imageFile);
   if (imageFile) {
     const objectURL = URL.createObjectURL(imageFile);
     setImageObject({
       name: imageFile.name,
     size: imageFile.size,
       type: imageFile.type,
       url: objectURL,
     });
   }
   else {
    console.error('No image file selected');
  }

 };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Update state based on the field name
    switch (name) {
      case 'company_name':
        setcompany_name(value);
        break;
      case 'company_description':
        setcompany_description(value);
        break;
      case 'job_title':
        setjob_title(value);
          break;
      case 'job_description':
         setjob_description(value);
            break;
      case 'experience_req':
          setexperience_req(value);
              break;
       case 'salary':
          setsalary(value);
              break;
      case 'location':
          setlocation(value);
              break;
      case 'contact_email':
          setcontact_email(value);
              break;  
      case 'contact_phone':
           setcontact_phone(value);
                break;       

      default:
        break;
    }

    // Clear error for the updated field, if it exists
  if (errors.hasOwnProperty(name)) {
    const updatedErrors = { ...errors };
    delete updatedErrors[name];
    setErrors(updatedErrors);
  }
      };

  //  const isValidInput = (input, regex) => regex.test(input.trim());


  // Function to validate company name format (alphabets and numbers only)
  const isValidCompanyName = (name) => {
    const companyNameRegex =/^[A-Za-z0-9\s]+$/;
    return companyNameRegex.test(name);
  };
 
  // Function to validate company Description format (alphabets and numbers only)
  const isValidCompanyDescription = (name) => {
    const companyNameRegex = /^[A-Za-z0-9\s]+$/;
    return companyNameRegex.test(name);
  };

  // Function to validate Job Title format (alphabets and numbers only)
  const isValidJobTitle = (name) => {
    const JobTitleRegex = /^[A-Za-z0-9\s]+$/;
    return JobTitleRegex.test(name);
  };

   // Function to validate Job Description format (alphabets and numbers only)
   const isValidJobDescription = (name) => {
    const JobDescriptionRegex = /^[A-Za-z0-9\s]+$/;
    return JobDescriptionRegex.test(name);
  };

  // Function to validate Experience format (alphabets and numbers only)
  const isValidExperience = (name) => {
    const experienceRegex = /^\d+$/;
    return experienceRegex.test(name);
  };

  // Function to validate Salary format (alphabets and numbers only)
  const isValidSalary = (name) => {
    const SalaryRegex = /^\d+$/;
    return SalaryRegex.test(name);
  };
 
  // Function to validate Location format (alphabets and numbers only)
  const isValidLocation = (name) => {
    const LocationRegex =  /^[A-Za-z]+$/;
    return LocationRegex.test(name);
  };

  
  // Function to validate Email format (alphabets and numbers only)
  const isValidEmail = (name) => {
    const EmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return EmailRegex.test(name);
  };

  // Function to validate phone format (alphabets and numbers only)
  const isValidPhone = (name) => {
    const phoneRegex = /^\d{10}$/;
  return phoneRegex.test(name);
  };

  const handleSubmit = (e) => {
  
    e.preventDefault();
 
     
    // Validation logic for all fields
    const validationChecks = [
      { field: 'company_name', value: company_name, validator: isValidCompanyName, errorMsg: 'Enter Valid Details' },
      { field: 'company_description', value: company_description, validator: isValidCompanyDescription, errorMsg: 'Enter Valid Details' },
      { field: 'job_title', value: job_title, validator: isValidJobTitle, errorMsg: 'Enter Valid Details' },
      { field: 'job_description', value: job_description, validator: isValidJobDescription, errorMsg: 'Enter Valid Details' },
      { field: 'experience_req', value: experience_req, validator: isValidExperience, errorMsg: 'Enter Valid Details' },
      { field: 'salary', value: salary, validator: isValidSalary, errorMsg: 'Enter Valid Details' },
      { field: 'location', value: location, validator: isValidLocation, errorMsg: 'Enter Valid Details' },
      { field: 'contact_email', value: contact_email, validator: isValidEmail, errorMsg: 'Enter Valid Details' },
      { field: 'contact_phone', value: contact_phone, validator: isValidPhone, errorMsg: 'Enter Valid Details' },
    ];
  
     // Check and update errors for each field
     let hasErrors = false;
     const updatedErrors = {};
 
     validationChecks.forEach(({ field, value, validator, errorMsg }) => {
       if (!value.trim() || !validator(value)) {
         updatedErrors[field] = errorMsg;
         hasErrors = true;
       }
     });
 
     // Update errors state
     setErrors(updatedErrors);
 
     // If errors exist, stop form submission
     if (hasErrors) {
       return;
     }
 
     // Create jobDetails object...
     const jobDetails = {
        company_name,
        company_description,
        job_title,
        job_description,
        experience_req,
        salary,
        location,
        contact_email,
        contact_phone,
      };
 
     // Dispatch action to upload job details
     dispatch(uploadJobDetails(jobDetails));
 
      // If an image is selected, upload it
      if (imageObject && company_name) {
        const formData = new FormData();
        formData.append('logo', e.target.elements.imageInput.files[0]);
        formData.append('company_name', company_name); 
  
        // Dispatch action to upload company logo
      dispatch(uploadCompanyLogo(formData));
      }
  
     // Reset form state
      setcompany_name('');
      setcompany_description('');
      setjob_title('');
      setjob_description('');
      setexperience_req('');
      setsalary('');
      setlocation('');
      setcontact_email('');
      setcontact_phone('');
  
};




  return (
    <div>
      <form onSubmit={handleSubmit} >
      <Container maxWidth="sm" className=" d-flex"  style={{ marginLeft: '160px' }}  >
      <Grid
      container
      spacing={2}
      direction="column"
      justifyContent="center"
      
    >
      <div style={{ width: "150%", }}>
      <Paper elevation={3} sx={{ padding: 5  }} style={{ marginTop: '50px' }}>
      <h3 style={{ marginLeft: '230px' }}>Job Details Uploader</h3>
   
      <Grid container  spacing={3} sx={{border: '1px solid rgba(0,0,0,0.125', zIndex:"3"}} style={{padding: "30px"}}>
      <Grid item xs={4}>
                    <TextfieldCustom
                    type="text"
                    name="company_name"
                    placeholder='Company Name'
                    value={company_name}
                    onChange={handleChange}
                    
                    />
                     {errors.company_name && <span style={{ color: 'red' }}>{errors.company_name}</span>}      
                  </Grid>
                  <Grid item xs={4}>
                    <TextfieldCustom
                    type="text"
                    name="company_description"
                    placeholder='Company Description'
                    value={company_description}
                    onChange={handleChange}
                    
                    />  
                    {errors.company_description && <span style={{ color: 'red' }}>{errors.company_description}</span>}        
                  </Grid>
                  <Grid item xs={4}>
                    <TextfieldCustom
                    type="text"
                    name="job_title"
                    placeholder='Job Title'
                    value={job_title}
                    onChange={handleChange}
                    
                    />   
                     {errors.job_title && <span style={{ color: 'red' }}>{errors.job_title}</span>}        
                  </Grid>
                  <Grid item xs={4}>
                    <TextfieldCustom
                    type="text"
                    name="job_description"
                    placeholder="Job Description"
                    value={job_description}
                    onChange={handleChange}
                    
                    />   
                       {errors.job_description && <span style={{ color: 'red' }}>{errors.job_description}</span>}         
                  </Grid>

                  <Grid item xs={4}>
                    <TextfieldCustom
                    type="text"
                    name="experience_req"
                    placeholder="Experience Req"
                    value={experience_req}
                    onChange={handleChange}
                    
                    />  
                    {errors.experience_req && <span style={{ color: 'red' }}>{errors.experience_req}</span>}        
                  </Grid>

                  <Grid item xs={4}>
                    <TextfieldCustom
                    type="text"
                    name="salary"
                    placeholder="Salary"
                    value={salary}
                    onChange={handleChange}
                    
                    /> 
                     {errors.salary && <span style={{ color: 'red' }}>{errors.salary}</span>}            
                  </Grid>
                  
                  <Grid item xs={4}>
                    <TextfieldCustom
                    type="text"
                    name="location"
                    placeholder="Location"
                    value={location}
                    onChange={handleChange}
                    
                    />  
                    {errors.location && <span style={{ color: 'red' }}>{errors.location}</span>}         
                  </Grid>
                  <Grid item xs={4}>
                    <TextfieldCustom
                    type="text"
                    name="contact_email"
                    placeholder="Contact Email"
                    value={contact_email}
                    onChange={handleChange}
                    
                    />  
                     {errors.contact_email && <span style={{ color: 'red' }}>{errors.contact_email}</span>}            
                  </Grid>
                  <Grid item xs={4}>
                    <TextfieldCustom
                    type="text"
                    name="contact_phone"
                    placeholder="Contact Phone"
                    value={contact_phone}
                    onChange={handleChange}
                    
                    />  
                    {errors.contact_phone && <span style={{ color: 'red' }}>{errors.contact_phone}</span>}          
                  </Grid>
                  <Grid container spacing={6} sx={{ marginTop: '0px' }}>
  <Grid item xs={4}>
    <Button variant="contained" component="label" sx={{ float: 'left' ,marginLeft:'25px' }}>
      Upload Image
      <input
        type="file"
        id="imageInput"
        style={{ display: 'none' }}
        onChange={handleImageChange}
      />
    </Button>

    {imageObject && (
      <div className="image-info">
        <p style={{  position: 'absolute',
  marginLeft: '177px',
  marginTop: '10px',
  }}>
          <strong>Name: </strong>{imageObject.name}
        </p>
      </div>
    )}
  </Grid>
</Grid>
                    
                  <Grid item xs={6}>
                 <Button variant="contained" style={{backgroundColor: '#3251A3', borderColor: '#FF5E14', marginLeft:'180px'}} 
                 fullWidth type="submit">
                   Job Upload
                 </Button>
            </Grid>

        </Grid>
        </Paper>
      </div>
      </Grid>
        </Container>
      </form>
    </div>
  )
  }

export default JobUploaderForm;