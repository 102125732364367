import { ActionTypes } from '../constants/action-types';

const initialState = {
  otp_id: [],
  
};

const FetchOtp = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_OTP_SUCCESS:
      return {
        ...state,
        otp_id: action.payload,
        
      };
    default:
      return state;
  }
};

export default FetchOtp;