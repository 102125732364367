import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import Img1 from '../Homepage/onlineclassimage/thri2.avif';
import Img2 from '../Homepage/onlineclassimage/malli.avif';
import Img3 from '../Homepage/onlineclassimage/pav.jpg';
import Img4 from '../Homepage/onlineclassimage/char.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import RemindMe from './RemindMe';

const courses = [
  {
    title: 'JAVA - Backend Development',
    img: Img1,
    description1: 'Intermediate and Advance Topics | OOPS | DataStructure |ETC',
    description2: 'By Industrial Expert "Thriveni"',
    instructor: 'Thriveni',
  },
  {
    title: 'FULL-STACK Development',
    img: Img2,
    description1: 'FRONTEND & BACKEND Development Topics',
    description2: 'By "Mallikarjuna"',
    instructor: 'Mallikarjuna',
  },
  {
    title: 'Figma & Web flow, UI UX Design',
    img: Img3,
    description1: 'PROTOTYPING | DRAFTING | ADVANCE FIGMA TOPICS |ETC',
    description2: 'By "Pavan"',
    instructor: 'Pavan',
  },
  {
    title: 'JAVASCRPIT, REACT & NODE',
    img: Img4,
    description1: 'Basic, Intermediate and Advance Topics',
    description2: 'By "Charan"',
    instructor: 'Charan',
  },
];

const Onlineclasses = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px' }}>
      {/* Your introductory section here */}
      <div style={{ border: '1px solid #ccc', padding: '12px', marginBottom: '12px', width: '70%', borderRadius: '14px', boxShadow: '0px 0px 10px #3251A3' }}>
        <h2 style={{ textAlign: 'left', marginBottom: '10px' }}>
          Achieve more with UPL, Get Free online counselling.
          <Button as={Link} to="/components/Homepage/Demo" style={{ border: '1px solid #3251A3', color: 'white' }}>
            Book a Demo Now
          </Button>
        </h2>
      </div>

      {/* Online Classes Section */}
      <div style={{ border: '1px solid #ccc', padding: '12px', marginBottom: '6px', width: '83%', borderRadius: '14px', boxShadow: '0px 0px 15px #3251A3', marginRight: '80px' }}>
        <h4 style={{ textDecoration: 'underline', textDecorationColor: '#3251A3', textAlign: 'center' }}>ONLINE CLASS</h4>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {courses.map((course, index) => (
            <div key={index} style={{ flex: '0 0 24%', border: '1px solid #ccc', padding: '12px', borderRadius: '14px', boxShadow: '0px 0px 15px #3251A3', marginBottom: '0px', marginRight: '5px' }}>
              <h5 style={{ textAlign: 'center' }}>{course.title}</h5>
              <img src={course.img} className="img-fluid rounded-top" alt="img not displayed" style={{ width: '100%', height: '60%', objectFit: 'cover' }} />
              <div style={{ border: '1px solid #ccc', padding: '12px', marginBottom: '1px', width: '95%', borderRadius: '14px', boxShadow: '0px 0px 15px #3251A3', backgroundColor: 'rgba(153, 169, 225, 0.8)' }}>
                <div style={{ fontSize: '.7rem' }}>{course.description1}</div>
                <div style={{ fontSize: '.8rem' }}>By {course.instructor}</div>
                <Button type="button" className="btn btn-outline-primary btn-sm" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Link to="/components/Homepage/RemindMe" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <FontAwesomeIcon icon={faBell} style={{ marginRight: '5px' }} />
                    Remind Me
                  </Link>
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Onlineclasses;
