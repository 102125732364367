import { ActionTypes } from "../constants/action-types"

const initialState = {
    prerequisites:[]

}

export const FetchPrerequisites = (state=initialState, {type, payload})=>{
switch (type){
    case ActionTypes.FETCH_PREREQUISITE: 
    return {...state, prerequisites: payload}
     case ActionTypes.DELETE_PREREQUISITE:
         return {
           ...state,
           prerequisites: state.prerequisites.filter((item, index) => index !== payload)
         } 
    default: return state
}

}