import { ActionTypes } from "../constants/action-types";

const initialState={
    RealtimeprojectDetails:null,
};
const RealtimeprojectsByIdReducer=(state=initialState,action)=>{
    switch(action.type){
        case ActionTypes.FETCH_REALTIMEPROJECTS_BY_ID:
            return{
                ...state,
                RealtimeprojectDetails:action.payload,
            };
            default:
                return state;
    }
};
 export default RealtimeprojectsByIdReducer;