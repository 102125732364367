import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'; 
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from 'react-toastify'; 

import './jobs.css';
import { fetchJobDetailsById,uploadJob_application_DocFile } from '../redux/actions/Actions'; 

const JobDetailsPage = () => {
  const { id } = useParams();
  const [name, setName] = useState('');
  const [Email_id, setEmail] = useState('');
  const [userResponsibility, setUserResponsibility] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState('');
  const[job_tittle,setJob_tittle]=useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
  
    // Check if a file is selected
    if (!selectedFile) {
      setFile(null);
      setFileError('Please select a file.');
      return;
    }
  
    // Check if the file type is accepted
    const acceptedFileTypes = ['.pdf', '.doc', '.docx'];
    const fileType = selectedFile.name.substring(selectedFile.name.lastIndexOf('.')).toLowerCase();
  
    if (!acceptedFileTypes.includes(fileType)) {
      setFile(null);
      setFileError('Invalid file type. Please select a .pdf, .doc, or .docx file.');
      return;
    }
  
    // File is valid, update state
    setFile(selectedFile);
    setFileError('');
    console.log('data',selectedFile);

  };
  
  
  const validateInputs = () => {
    let isValid = true;

    if (name.trim() === '') {
      setNameError('Name is required');
      isValid = false;
    } else {
      setNameError('');
    }

    if (Email_id.trim() === '') {
      setEmailError('Email is required');
      isValid = false;
    } else if (!isValidEmail(Email_id)) {
      setEmailError('Invalid email address');
      isValid = false;
    } else {
      setEmailError('');
    }
    if (!file) {
      setFileError(' choose the file.');
      isValid = false;
    } else {
      setFileError('');
    }
    return isValid;
  };

  const isValidEmail = (value) => {
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };


  useEffect(() => {
    dispatch(fetchJobDetailsById(id))
      .then((data) => {
      })
      .catch((error) => {
        console.error('Error fetching job details:', error);
      });
  }, [dispatch, id]);

  const jobDetails = useSelector((state) => state.jobsById.jobDetails);

  if (!jobDetails) {
    return <div>Loading...</div>;
  }

  const formData = {
    name: name,
    job_tittle:jobDetails.job_title,
    Email_id: Email_id,

  };
  console.log(formData);


  const handleApply = () => {
    applyForJob({ jobId: jobDetails.id, name, Email_id, userResponsibility });
    if (validateInputs()) {



      const docFileData = new FormData();
      docFileData.append('name', name);
      docFileData.append('Email_id', Email_id);
      docFileData.append('job_tittle', jobDetails.job_title);
      docFileData.append('DocFile', file);
  
console.log('xyz',docFileData);
      dispatch(uploadJob_application_DocFile(docFileData));

      toast.success('Application submitted successfully!');
    
    }
  
  };
  
  const applyForJob = ({ jobId, name, email, userResponsibility }) => {
    if (!name || !email || !userResponsibility) {
      return;
    }
  }

  return (
    <div className='job-details-page-container'>
    <div className="job-details-container">
      <div className='job-application-card'>
        
        <CardMedia className='jobs-card-media'
          component="img"
          alt={jobDetails.company_name}
          height="200"
          image={jobDetails.company_logo_url}
        />
                  <Typography variant="h4" >
            {jobDetails.company_name}
          </Typography>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            {jobDetails.job_title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Job ID: {jobDetails.id}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Location: {jobDetails.location}
          </Typography>
          <Typography variant="body2" paragraph>
            {jobDetails.job_description}
          </Typography>
          <Typography variant="body2">
            Experience Required: {jobDetails.experience_req}
          </Typography>
          <div className="apply-form">
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            setNameError(''); // Clear the error message when the user starts typing
          }}
          error={!!nameError}
  helperText={nameError}
  style={{ marginBottom: '16px' }}
        />
        <TextField
          label="Email Address"
          variant="outlined"
          fullWidth
          value={Email_id}
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError(''); // Clear the error message when the user starts typing
          }}
          error={!!emailError}
  helperText={emailError}
  style={{ marginTop: '16px' }}
        />
        <input
          type="file"
          accept=".pdf,.doc,.docx" 
          onChange={handleFileChange}
          style={{ marginTop: '10px' }}
        />
        {fileError && <div style={{ color: 'red', marginTop: '5px' }}>{fileError}</div>}

              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px', gap:'10px' }}>
                <Link to="/jobs">
                  <Button
                    variant="outlined"
                    color="primary"
                  >
                    Back
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleApply}
                >
                  Submit
                </Button>
              </div>
      </div>
        </CardContent>
      </div>  
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} />
    </div>
    </div>
  );
      };

export default JobDetailsPage;