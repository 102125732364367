import { Grid, CardContent, AccordionSummary, Accordion, AccordionDetails } from '@mui/material';
import Card from '@mui/material/Card';
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import jsondata from "../../../data/data.json"
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { useNavigate } from 'react-router-dom';
import { fetchCategoriesList } from '../../redux/actions/Actions';

const Interview = () => {

  // fromm backend
  const fetchCategories = useSelector((state) => state.fetchAllCategories.categoriesData)


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategoriesList());
  }, [dispatch]);




  // from json data

  const [technologies, setTechnologies] = useState([]);
  const [sections, setSections] = useState([]);
  
  useEffect(() => {
    setTechnologies(jsondata.TechIcons);
    setSections(jsondata.InterviewSidebarData);
  }, []);



  // for selected topic

  const [selectedName, setSelectedName] = useState("Programming");
  const [filteredSection, setFilteredSections] = useState([]);

  useEffect(() => {
    if(selectedName) {
      const filtered = sections.filter(
        (eachSection) => eachSection.Name === selectedName
      );
      setFilteredSections(filtered);
    } else {
      setFilteredSections(sections)
    }
  }, [selectedName]);

  const handleNameSelect = (section) => {
    setSelectedName(section);
  }



  


  
  return(
    <>
      <div className="container-fluid p-0">
        <h1 className="text-center">Interview Q&A</h1>
        <div className="row m-0">
          <div className="col-lg-3 p-0">
          <div className="container-xl hoverable-card">

            <Card>
              <CardContent>
                {sections.map((eachSection) => (
                  <Link
                    to={eachSection.path}
                    key={eachSection.id}
                    onClick={() => handleNameSelect(eachSection.name)}
                    className="interview-topics"
                  >
                    <h5 
                      style={{ 
                      color: selectedName === eachSection.name ? "blue" : "black", 
                      marginBottom: "5px",
                      }}
                    >{eachSection.name}</h5>
                  </Link>
                ))}
              </CardContent>
            </Card>
              
          </div>
          </div>
          <div className="col-lg-9 p-0">
          <div className="hero-register-form-wrapper">


            <Grid container spacing={1}>
              {fetchCategories.map((category) => (
                <Grid 
                  item xs={12} sm={6} md={3}
                  key={category.category_id}
                  style={{marginBottom: "5px"}}
                >
                  <Link to={`/interview/${category.category_id}`}>
                    <div>
                      <Card className="interview-addcard">
                        <CardMedia
                          component="img"
                          height="100"
                          width="100"
                          alt="tech image"
                          src={category.category_image_url ? category.category_image_url : category.category_name}
                        />
                        <p style={{ textAlign: "center"}} >{category.category_name}</p>
                      </Card>
                    </div>
                  </Link>
                </Grid>
              ))}
            </Grid>

          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Interview;


