import React, { useState,useEffect } from 'react'
import { Outlet, Link } from "react-router-dom";
import MuiDrawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import CategoryIcon from "@mui/icons-material/Category";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import RepeatOnIcon from "@mui/icons-material/RepeatOn";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import {
  Avatar,
  Box,
  Collapse,
  ListItemAvatar,
  Tooltip,
  Typography
} from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';
import IconButton from "@mui/material/IconButton";
import {
  Dashboard ,
  Group ,
  ArrowRight,
  ArrowDropUp,
  Notifications,
  Payment,
  ExpandLess,
  ExpandMore,
  ImageRounded,
  VerifiedUser,
  Category,
  GolfCourse,
  LocalLibrary,
  AdUnits,
  RepeatOn,
  Event,
  AdsClick,
  Subscriptions,
} from "@mui/icons-material";
import AdminData from "../../../data/Admindata.json";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import GroupIcon from '@mui/icons-material/Group';
import EventIcon from '@mui/icons-material/Event';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DomainIcon from '@mui/icons-material/Domain';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import WorkIcon from '@mui/icons-material/Work';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LanguageIcon from '@mui/icons-material/Language';
import Wc from '@mui/icons-material/Wc';

const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const SideBarCp = ({ open, handleDrawerClose,theme, onSelectMenuItem}) => {
  const{isAuthenticated,user}=useSelector((state)=>state.auth);
const userId=user?.role_id; 
  const [items, setItems] = useState();
  const[iconList,seticonList]=useState();
const [displayImg, setdisplayImg] = useState("");
const isSmallScreen = window.innerWidth < 600;
const navigate = useNavigate();
const sidebarlist = AdminData.adminsidebarmenu;
const executorSidebar=AdminData.executorsidebarmenu;
const [selectedMenuItem, setSelectedMenuItem] = useState("");
const [showCookieBar, setShowCookieBar] = useState(true);

const adminicons = {
  VerifiedUser,
  Category,
  GolfCourse,
  LocalLibrary,
  AdUnits,
  RepeatOn,
  Category,
  Event,
  AdsClick,
  ImageRounded,
  MailIcon,
  QuestionAnswerIcon,
  WorkIcon,
  AdminPanelSettingsIcon,
  LanguageIcon, 
  Wc,
  FormatListBulletedIcon,

};
const executoricons={
GroupIcon,
FormatListBulletedIcon,
EventIcon,
DomainIcon,
ConnectWithoutContactIcon,
CategoryIcon,
    GolfCourseIcon,
    LocalLibraryIcon,
    AdUnitsIcon,
    RepeatOnIcon,
    AdsClickIcon,
    ImageRoundedIcon,
    MailIcon,
    QuestionAnswerIcon,
    WorkIcon,
    AdminPanelSettingsIcon
}


const handleMenuItemClick = (menu) => {
setSelectedMenuItem(menu);
onSelectMenuItem(menu);

};
//console.log("selected",selectedMenuItem)
useEffect(()=>{
  if(userId===1){
    setItems(sidebarlist);
     seticonList(adminicons);
  }else if(userId===3){
    setItems(executorSidebar);
    seticonList(executoricons);
  }
},[userId,items,executorSidebar,sidebarlist])
// console.log("sidebar ",items)
// console.log("iconlist",iconList);
function changeBackground(e){
  //e.target.style.background='red'
  e.target.style.transform= 'scale(1.2)'; 
  }
  function zoomIn(element) {
    element.target.style.transition = 'transform 0.3s ease-in-out';
    element.target.style.transform = 'scale(1.2)';
  }
  
  function zoomOut(element) {
    element.target.style.transition = 'transform 0.3s ease-in-out';
    element.target.style.transform = 'scale(1)';
  }



  return (
    <div className='container right-continer' id='right-container'>
        <Drawer variant="permanent" open={open}>
        <div className='sidebar-menu'>
        <DrawerHeader> 
          <IconButton onClick={handleDrawerClose} color='#fff'>
            {open ? (theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />) : null}
          </IconButton>
        </DrawerHeader>
       
        <List>
          {items?.map((menu, index) => (
            <Tooltip
            key={menu.key}
            title={open ? "" : menu.title}
            placement="right"
            enterTouchDelay={0}
            enterDelay={500}
          >
            <ListItem key={menu.key} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                component={Link}
                to={menu.link}
                onClick={() => handleMenuItemClick (menu.title)}
              >
                <ListItemIcon  onMouseOver = {zoomIn} onMouseOut={zoomOut}
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: 'white',
                  }}
                >
                  
                  {React.createElement(iconList[menu?.icon])}
                </ListItemIcon>
                <ListItemText primary={menu.title} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </Tooltip>  
          ))
          }
        </List>
        </div>
      </Drawer>
     
      <Box
        component="main"
        // className="sidemuicontainer dashboard-margintop"
        className={`sidemuicontainer dashboard-margintop ${open ? 'sidebar-open' : ''}`}
        // where the 'sidebar-open' class is added when the sidebar is open. The actual styling associated with these classes  defined in CSS or stylesheets
              
      >
        {/* <DrawerHeader /> */}  {/* if any UI issues. enable this */}
        <Outlet />
      </Box>
      
    </div>
  )
}

export default SideBarCp