import React, { useEffect, useState } from 'react'
import { Button, Container, Grid, Paper, Typography } from '@mui/material';
import TextfieldCustom from '../../../common/TextfieldCustom';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {  uploadCompanyNameLogo } from '../../../redux/actions/Actions'; // Import your action creator
 function CompanyNameFrom() {
  // const [Company_id, setCompany_id] = useState('');

  const [Company_name, setCompany_name] = useState('');
  const [imageObject, setImageObject] = useState(null);
  const [errors, setErrors] = useState({});
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
const[companies,setCompanies]=useState([]);



  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      const objectURL = URL.createObjectURL(imageFile);
      setImageObject({
        name: imageFile.name,
        size: imageFile.size,
        type: imageFile.type,
        url: objectURL,
      });
    } else {
      console.error('No image file selected');
    }
  };
  const validateForm = () => {
    const updatedErrors = {};
    let hasErrors = false;

    if (!Company_name.trim()) {
      updatedErrors.Company_name = 'Company_name  is required';
      hasErrors = true;
    }

    if (!imageObject) {
      updatedErrors.image = 'Image is required';
      hasErrors = true;
    }

    setErrors(updatedErrors);
    return !hasErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    

     try {
      
      const formData = new FormData();
      formData.append('Company_name', Company_name);
      formData.append('logo', e.target.elements.imageInput.files[0]);

      // Dispatch action to upload  image
     dispatch(uploadCompanyNameLogo(formData));

      setCompany_name('');
      setImageObject(null);

      
    setErrors({});
    setSubmissionSuccess(true); 
    
          navigate('/auth/companyPlacementTable', {
        state: { imageObject, Company_name },
      }); 
    } catch (error) {
      console.error('Error uploading project details:', error);
    }
  
  };


  return (
    <div>
      <h1>CompanyNameFrom</h1>
      <div>

      <form onSubmit={handleSubmit}>
        <Container maxWidth="md" className="form-container">
          <Paper elevation={4} className="paper-container">
            <div className="paper">
              <Typography variant="h5" className="form-title">
                company  Name logo Uploder
              </Typography>
              <br />
              <Grid container spacing={4}>
                <Grid item xs={10}>
                  <TextfieldCustom
                    type="text"
                    name="Company_name"
                    placeholder="Company_name "
                    value={Company_name}
                    onChange={(e) => setCompany_name(e.target.value)}
                    error={Boolean(errors.Company_name)}
                    helperText={errors.Company_name}

                    // onChange={(e) => setCompany_name(e.target.value)}
                    // error={Boolean(errors.Company_name)}
                    // helperText={errors.Company_name}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    className="upload-button"
                  >
                    Upload Image
                    <input
                     id="imageInput"
                      type="file"
                      style={{ display: 'none' }}
                      accept=".png, .jpg, .jpeg"
                      onChange={handleImageChange}
                      name="logo" // Add this line
                    />
                  </Button>
                  {errors.image && (
                    <p className="error-message">{errors.image}</p>
                  )}

                  {imageObject && (
                    <div className="image-preview">
                      <p>
                        <strong>Name:</strong> {imageObject.name}
                      </p>
                    </div>
                  )}
                </Grid>
                <Grid item xs={10}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    className="submit-button"
                  >
                    Submit
                  </Button>
                  {errors.submission && (
                      <p className="error-message">{errors.submission}</p>
                    )}

                  {submissionSuccess && (
                    <p className="success-message">Submission successful!</p>
                  )}
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Container>

      </form>

      </div>
    </div>
  )
}
export default CompanyNameFrom;