import React, { useEffect, useMemo, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Grid, IconButton, Stack, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
 import { deleteJobApplicationsById, deletejobawsapplicationByName12, fetch_job_aws_applications, fetchjob_applications } from '../../../redux/actions/Actions';
import { Delete } from '@mui/icons-material';

const UserJobapplicationDetailsTable = () => {
    // Sample data for demonstratio


 const fetch_job_aws_applications11=useSelector((state)=>state.Jobawsapplication.job_aws_applicationsData)

const {isAuthenticated,user}=useSelector((state)=>state.auth);
  const userId = user?.role_id
 const dispatch = useDispatch()
const[dataRows,setDataRows] = useState([]) 
const[dataColumns,setDataColumns]=useState([])
 const[data,setData] = useState([])


    const columns = useMemo(()=>[
        { field: 'id', headerName: 'ID', width: 60 },
        { field: 'name', headerName: 'name', width: 150 },
        { field: 'Email_id', headerName: 'Email id', width: 150 },
        { field: 'job_tittle', headerName: 'job_tittle', width: 200 },

        { field: 'DocFolder', headerName: 'DocFolder', width: 150 },
        <br></br>,
        {
            field: 'action',
            headerName: 'Action',
            width: 200,
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const handleDelete=(e)=>{
                    const currentRow=params.row;
                    dispatch(deletejobawsapplicationByName12(currentRow.name));
                    console.log('cliked job applications is',currentRow.name)

                }
                return(
                    <Stack direction="row" spacing={2}>
                       <IconButton onClick={()=>handleDelete()}>
                        <Delete/>
                        </IconButton> 
                    </Stack>
                )
        },
        },
    ],[]);
     const rows=useMemo(()=>data?.map((row,index)=>{
        return{
            ...row,
            id:index + 1,
            name:row.name,
            Email_id:row.Email_id,
            job_tittle:row.job_tittle,
            docfile:row.docfile,
        }
     }),[data]);
    
    const [searchValue, setSearchValue] = React.useState('');
    const filteredRows =rows.filter((row) =>
        Object.values(row).some(
            (value) =>
                value !== null &&
                value !== undefined &&
                value.toString().toLowerCase().includes(searchValue.toLowerCase())
        )
    );
useEffect(()=>{
     dispatch(fetch_job_aws_applications())
},[dispatch,fetch_job_aws_applications11]);

useEffect(()=>{
    setData(fetch_job_aws_applications11)
    setDataColumns(columns)
    setDataRows(rows)
},[data,fetch_job_aws_applications11])

    return (
        <div> <h1>User_Jobapplication_DetailsTable</h1>
          <div className='job-applications'>
            <Grid item xs={5}
            >
            <TextField
                label="Search"
                variant="outlined"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                InputProps={{
                    endAdornment: <SearchIcon />,
                }}
                style={{ marginTop: 0, marginBottom: 15 }}
            />
            </Grid>
            {/* <Box sx={{ height: 400, width: '100%' }}> */}
                {
                userId===1 && (
                    <Box sx={{ height: 400, width: '100%' }}>

                <DataGrid
                style={{marginTop:'15px',marginBottom:'0px'}}
                    rows={filteredRows}
                    columns={columns}
                    initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 5 },
                        },
                      }}
                      pageSizeOptions={[5, 10]}
                      checkboxSelection
        
             />
          </Box>)

            }
       </div>
        </div>
           );
};

export default UserJobapplicationDetailsTable;


