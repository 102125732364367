
import React, { useEffect, useState } from 'react'
import { Form, Link, useNavigate, useParams, } from 'react-router-dom'
import { fetchMaterialList } from '../redux/actions/Actions';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Stack, formControlClasses } from "@mui/material";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

import { saveAs } from "file-saver";
import { height } from '@mui/system';
const theme = createTheme();

theme.typography.h5 = {
  fontSize: "1rem",
  "@media (min-width:100%)": {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.2rem",
  },
};
const MaterialDetails = () => {
   const fetchCourses = useSelector((state)=> state.fetchMaterials.materials)
   console.log(fetchCourses,"trending courses");
  // const navigate = useNavigate()
    const [item, setItem] = useState([]);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchMaterialList())
   setItem(fetchCourses)
    
   }, [ dispatch]);

  const [itemsToShow, setItemsToShow] = useState(5);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleLoadMore = () => {
    // Increase the number of items to show when the "Load More" button is clicked
    setItemsToShow(itemsToShow + 5); // You can adjust the number based on your design
  };

  const handleUnload = () => {
    // Decrease the number of items to show when the "Unload" button is clicked
    setItemsToShow(Math.max(5, itemsToShow - 5)); // Ensure a minimum of 5 items are shown
  };
 // const activeFetchFaq = fetchCourses.filter((item) => item.is_active === 1)
  return (
    <ThemeProvider theme={theme} >
      
    <div className="container-xxxl py-10 wow fadeInUp" data-wow-delay="0.1s">
    
      <div className="container">
    
      <div className="" style={{ width: '100%' }}>
      
        <div>
        
        <div className="accordion accordion-flush" id="accordionFlushExample">
        <div style={{ fontFamily: "Inter", fontSize: "2.25rem", color: "#3251a3", fontWeight: 700, lineHeight: "1.3", textTransform: "capitalize", transform: "rotate(-0.174deg)",paddingLeft:"400px",paddingRight:"400px", alignContent: "center" }}>
            Material Access
            </div>
            {fetchCourses.map((eachItem, index) => (
  <div className="accordion-item" key={index}>
    <h2 className="accordion-header" id={`flush-heading${index}`}>
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#flush-collapse${index}`}
        aria-expanded="false"
        aria-controls={`flush-collapse${index}`}
      >
        {eachItem.course_name}
      </button>
    </h2>
    <div
      id={`flush-collapse${index}`}
      className="accordion-collapse collapse"
      aria-labelledby={`flush-heading${index}`}
      data-bs-parent="#accordionFlushExample"
    >
    <div className="accordion-body">
  {eachItem.pdf_file_path && (
    <div style={{ display: 'inline-block', marginRight: '20px' }}>
      <Link target='_blank' to={eachItem.pdf_file_path}>
        Brochure
      </Link>
    </div>
  )}
  {eachItem.material_file_paths && eachItem.material_file_paths.length > 0 && (
    <ul style={{ display: 'inline-block', margin: 0, padding: 0 }}>
      {eachItem.material_file_paths.map((file, fileIndex) => (
        <li key={fileIndex} style={{ listStyle: 'none', marginRight: '20px' }}>
          <Link target='_blank' to={file}>
          {file.split('/').pop().replace('.pdf', '')} {file.filename}
          </Link>
        </li>
      ))}
    </ul>
  )}
</div>


    </div>
  </div>
))}


</div>

        <Box display="flex" justifyContent="center" marginTop={2}>
        {itemsToShow < fetchCourses.length && (
         
            <Button
              variant="contained"
              size="small"
              sx={{ backgroundColor: '#f0fbfc',color: 'black' }}
              style={{ textTransform: "none", padding: "5px" }}
              onClick={handleLoadMore}
            >
              <KeyboardDoubleArrowDownIcon style={{ marginRight: "6px" }} />
              See More
            </Button>
         
        )}
        {itemsToShow > 5 && (
          
            <Button
              variant="contained"
              sx={{ backgroundColor: '#f0fbfc',color: 'black' }}
              size="small"
              style={{ textTransform: "none", padding: "5px", marginLeft: '8px' }}
              onClick={handleUnload}
            >
              <KeyboardDoubleArrowUpIcon style={{ marginRight: "6px" }} />
              See Less
            </Button>
          
        )}
        </Box>
      </div>
    </div>
    </div>
    </div>

  </ThemeProvider>
  )
  }
export default MaterialDetails
