import { ActionTypes } from "../constants/action-types"

const initialState = {
    subscribersWithEvent:[]
}

export const GetEventSubscriberList = (state=initialState, {type, payload})=>{
switch (type){
    case ActionTypes.GET_EVENT_SUBSCRIBER: 
    return {...state, subscribersWithEvent: payload}

    default: return state
}

}