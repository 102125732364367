import React, { useEffect, useState,useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  fetchCourseList,deleteCourseById } from '../../../redux/actions/Actions';
import { useNavigate } from 'react-router-dom';
import { DataGrid} from '@mui/x-data-grid';
import { Delete, Edit } from '@mui/icons-material';
import { Box, Grid,TextField, IconButton, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';


const CourseList = () => {
    const fetchCourses = useSelector((state)=> state.fetchAllCourses.courses)
    const { isAuthenticated, user } = useSelector((state) => state.auth);
    const userId = user?.role_id
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const[dataRows,setDataRows] = useState([])
    const[dataColumns,setDataColumns]=useState([])
    const[data,setData] = useState([])
    const userData =(userId===1||user?.role_name==="admin");
    // console.log("user data ",userData);
    const fetchPrograms = useSelector((state) => state.fetchAllPrograms.programs);
    function formatDate(dt){
      const getYear = new Date(dt).getFullYear()
      const getMonth = new Date(dt).getMonth() + 1
      const getMyDay = new Date(dt).getDate()
      const dateFormat = getMyDay + "/" + getMonth + "/" + getYear
      return dateFormat;
  }

    const columns = useMemo(
      () =>{const commonColumns=[
        { field: 'id', headerName: 'Id', width: 60, disableColumnMenu: true, sortable: false },
        { field: 'program_name', headerName: 'Program Name', width: 160, disableColumnMenu: true },
        { field: 'course_name', headerName: 'Course Name', width: 160, disableColumnMenu: true },
       /*  { field: 'instructor_id', headerName: 'Instructor Id', width: 80, sortable: false, disableColumnMenu: true }, */
        { field: 'description', headerName: 'Description', width: 160, disableColumnMenu: true },
        { field: 'duration', headerName: 'Duration', width: 160, disableColumnMenu: true },
      ];
      const userSpecificColumns = userData
?[
        
        {
            field: 'action',
            headerName: 'Actions',
            width: 180,
            sortable: false,
            disableClickEventBubbling: true,
            
            renderCell: (params) => {
                const onClick = (e) => {
                  const currentRow = params.row;
                  navigate(`/auth/updateCourse/${currentRow.course_id}`)
                };
                 const handleDelete = (e) =>{
                    const currentRow = params.row;
                    dispatch(deleteCourseById(currentRow.course_id))
                     setData(data.filter((row) => row.course_id !== currentRow.course_id));
                    console.log('clicked Course is', currentRow.course_id)
                } 
                
                return (
                  <Stack direction="row" spacing={2}>
                    <IconButton onClick={onClick}>
                        <Edit />
                    </IconButton>
                    <IconButton onClick={()=>handleDelete()}>
                        <Delete />
                    </IconButton>
                  </Stack>
                );
            },
          }
        ]: []
        return [...commonColumns, ...userSpecificColumns];
      }, [userId, data, dispatch, navigate]);
  
      const rows =useMemo(
        () =>  data?.map((row, index) => {
        return {
          ...row,
          id: index + 1,
          course_name: row.course_name,
          program_name: row.program_name,
         /*  instructor_id: row.instructor_id, */
          description: row.description,
          duration: row.duration,
        }
      }),[data]);

      const [searchValue, setSearchValue] = useState("");
  
      const filteredRows = rows.filter((row) =>
       Object.values(row).some((value) =>
       value !== null &&
       value !== undefined &&
       value.toString().toLowerCase().includes(searchValue.toLowerCase())
       )
     );     
 
  useEffect(() => {
    dispatch(fetchCourseList())
  }, [ dispatch,fetchCourses]);
  useEffect(() => {
    const coursesWithProgramName = fetchCourses.map(course => ({
      ...course,
      program_name: fetchPrograms.find(program => program.program_id === course.program_id)?.program_name
    }));
    setData(coursesWithProgramName);
    setDataColumns(columns);
    setDataRows(rows);
  }, [fetchCourses, fetchPrograms]);
  //console.log('courses', fetchCourses)

  useEffect(()=>{
    setData(fetchCourses) 
    setDataColumns(columns)
    setDataRows(rows)
}, [data,fetchCourses])

  return (
    <div className='course-list'>

                <Grid item xs={5}
                  container justify="flex">
                  <TextField
                    label="Search"
                    variant="outlined"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    InputProps={{
                      endAdornment: <SearchIcon />,
                    }}
                    style={{ marginTop: 0, marginBottom:15 }} />
            
                </Grid>
        {
            // userId === 1 && (
              (userId === 1 || userId===3)&&(

              <Box sx={{ height: 400, width: '100%' }}> 
                <DataGrid
                    rows={filteredRows}
                    columns={dataColumns}
                    initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5},
                    },
                    }}
                    pageSizeOptions={[5, 10]}
                    checkboxSelection
                />
                </Box> )
        }
    </div>
  )
}

export default CourseList