// rootReducer.js

import { combineReducers } from 'redux';
import authReducer from './auth/authReducer.js';
import { FetchPrograms } from './reducers/FetchPrograms.js';
import FetchProgramById from './reducers/FetchProgramById.js';
import { FetchCourses } from './reducers/FetchCourses.js';
import FetchCourseById from './reducers/FetchCourseById.js';
import GetMailReducer from './reducers/GetMailReducer.js';
import { GetVisitors } from './reducers/GetVisitors.js';
import { GetRole } from './reducers/GetRole.js';
import { GetUsers } from './reducers/GetUsers.js';
import { UploadProgram } from './reducers/UploadProgram.js';
import { UpdateProgramReducer } from './reducers/UpdateProgramReducer.js';
import GetVisitorById from './reducers/GetVisitorById.js';
import GetUserById from './reducers/GetUserById.js';
import { UpdateUserReducer } from './reducers/UpdateUserReducer.js';
import FetchProgramId from './reducers/FetchProgramId.js';
import FetchSubProgramId from './reducers/FetchSubProgramId.js';
import { FetchProgramWithCourse } from './reducers/FetchProgramWithCourse.js';
import { UpdateUserStatus } from './reducers/UpdateUserStatus.js';
import { GetStatusList } from './reducers/GetStatusList.js';
import GetUserByStatus from './reducers/GetUserByStatus.js';
import GetVisitorsByStatus from './reducers/GetVisitorsByStatus.js';
import { ProgramCourseCombination } from './reducers/ProgramCourseCombination.js';
import { SubscriberReducer } from './reducers/SubscriberReducer.js';
import { GetLessons } from './reducers/GetLessons.js';
import { GetUnits } from './reducers/GetUnits.js';
import { GetCourseWithLesson } from './reducers/GetCourseWithLesson.js';
import { GetUnitsByLesson } from './reducers/GetUnitsByLesson.js';
import { GetUnitByCourse } from './reducers/GetUnitByCourse.js';
import { GetPrequisiteByCourse } from './reducers/GetPrequisiteByCourse.js';
import { GetLessonByCourseId } from './reducers/GetLessonByCourseId.js';
import { GetActivePromotionEvent } from './reducers/GetActivePromotionEvent.js';
import { GetSubscriberList } from './reducers/GetSubscriberList.js';
import { UploadCourse } from './reducers/UploadCourse.js';
import { UpdateCourseReducer } from './reducers/UpdateCourseReducer.js';
import { UploadLesson } from './reducers/UploadLesson.js';
import { UploadUnit } from './reducers/UploadUnit.js';
import FetchLessonId from './reducers/FetchLessonId.js';
import { UploadPrerquisite } from './reducers/UploadPrerquisite.js';
import { UploadImage } from './reducers/UploadImage.js';
import { uploadCompanyLogo } from './reducers/UploadCompanyLogo.js';
import { UploadImageProgram } from './reducers/UploadImageProgram.js';
import { UploadImageCourse } from './reducers/UploadImageCourse.js';
import { GetImageList } from './reducers/GetImageList.js';
import { GetImageProgram } from './reducers/GetImageProgram.js';
import { GetImageCourse } from './reducers/GetImageCourse.js';
import { CreateProgramCourse } from './reducers/CreateProgramCourse.js';
import { GetImageCourseForProgramDetail } from './reducers/GetImageCourseForProgramDetail.js';
import { SendEmail } from './reducers/SendEmail.js';
import GetUserByEmail from './reducers/GetUserByEmail.js';
import { GetImageTable } from './reducers/GetImageTable.js';
import { UpdateLessonReducer } from './reducers/UpdateLessonReducer.js';
import FetchUnitId from './reducers/FetchUnitId.js';
import { UpdateUnitReducer } from './reducers/UpdateUnitReducer.js';
import { FetchPrerequisites } from './reducers/FetchPrerequisites.js';
import FetchPrerequisiteById from './reducers/FetchPrerequisiteById.js';
import FetchEventsAd from './reducers/FetchEventsAd.js'; 
import FetchEventAdById from './reducers/FetchEventsAdById.js';
import { EventSubscriber } from './reducers/EventSubscriber.js';
import { FetchEvents } from './reducers/FetchEvents.js';
import{FetchEventById} from './reducers/FetchEventById.js';
import GetEventByStatus from './reducers/GetEventByStatus.js';
import fetchTestimonials from './reducers/FetchTestimonials.js';
import { FetchFaq } from "./reducers/FetchFaq.js";
import { UploadFaq } from './reducers/UploadFaq.js';
import { FetchFaqById } from './reducers/FetchFaqById.js';
import { UpdateFaqById } from './reducers/UpdateFaqById.js';
import JobReducer from './reducers/JobReducer';
import jobsByIdReducer from './reducers/JobByIdReducer.js';
import eventImagesReducer from './reducers/FetchEventImageById.js';
import channelPartnerReducer from './auth/cpReducer.js'
import { GetEventSubscriberList } from './reducers/GetEventSubscriberList.js';
import { uploadEvent } from './reducers/UploadEvent.js';
import { uploadEventImg } from './reducers/UploadEventImg.js';
import { FetchEventList } from './reducers/FetchEventList.js';
import fetchRemindme from './reducers/FetchRemindme.js';
import { deleteOnlineClass} from './reducers/deleteonlineclass.js';

import { PopularCourselist } from './reducers/PopularCourseList.js';
import { eventRowReducer } from './reducers/eventRowReducer.js';
import eventImageReducer from './reducers/EventImageReducer.js';
import eventUpdateReducer from './reducers/EventUpdateReducer.js';
import deleteEventReducer from './reducers/EventDeleteReducer.js';
import deleteEventImageReducer from './reducers/EventImageDeleteReducer.js';
import {FetchTutorialCategories} from './reducers/FetchTutorialCategories.js';
import FetchTutorialCategoryById  from './reducers/FetchTutorialCategoryById.js';
import {UpdateTutorialCategoryReducer} from './reducers/UpdateTutorialCategoryReducer.js';
import { UploadTutorialCategory } from './reducers/UploadTutorialCategory.js';
import {FetchTutorialTechnologies} from './reducers/FetchTutorialTechnologies.js';
import FetchTutorialTechnologyById  from './reducers/FetchTutorialTechnologyById.js';
import {UpdateTutorialTechnologyReducer} from './reducers/UpdateTutorialTechnologyReducer.js';
import { UploadTutorialTechnology } from './reducers/UploadTutorialTechnology.js';
import {FetchTutorialTopics} from './reducers/FetchTutorialTopics.js';
import FetchTutorialTopicById  from './reducers/FetchTutorialTopicById.js';
import {UpdateTutorialTopicReducer} from './reducers/UpdateTutorialTopicReducer.js';
import { UploadTutorialTopic } from './reducers/UploadTutorialTopic.js';
import FetchCategories from './reducers/FetchCategories.js';
import UploadCategory from './reducers/UploadCategory.js';
import FetchTopics from './reducers/FetchTopics.js';
import UploadTopic from './reducers/UploadTopic.js';
import FetchInterviewQa from './reducers/FetchInterviewQa.js'
import UpdateInterviewQa from './reducers/UpdateInterviewQa.js';
import UploadInterviewQa from './reducers/UploadInterviewQa.js';
import FetchLinkedinUrl from './reducers/FetchLinkedinUrl.js';
import FetchInterviewQaById from './reducers/FetchInterviewQaById.js';
import FetchCategoryById from './reducers/FetchCategoryById.js';
import UpdateCategory from './reducers/UpdateCategory.js';
import { uploadPromotionHeroImageReducer } from './reducers/UploadPromotionHeroImgAWsReducer.js';
import { promotionImagesReducer } from './reducers/promotionImagesReducer.js';
import ToggleSwitchReducer from './reducers/ToggleSwitchReducer.js';
import FetchOtp from './reducers/FetchOtp.js'
import PostEmailOtp from './reducers/PostEmailOtp.js'
import Job_applicationsReducer from './reducers/Job_applicationsReducer';
import { uploadJob_application_DocFile } from './reducers/UploadJob_application_DocFile.js';
import JobawsapplicationReducer from './reducers/JobawsapplicationReducer.js';
import { selectedPromotionImageReducer } from './reducers/selectedPromotionImageReducer.js';
import fetchRegistration from './reducers/FetchRegistration.js';
import { UploadOnlineclass } from './reducers/UploadOnlineclass.js';
import {PromotionEventTextReducer }from './reducers/PromotionEventTextReducer';
import { FetchInstructors } from './reducers/FetchInstructor.js';
import FetchInstructorById from './reducers/FetchInstructorById.js';
import { UploadInstructor } from './reducers/UploadInstructor.js';
import { UpdateInstructorReducer } from './reducers/UpdateInstructor.js';
import { ProcessListReducer } from './reducers/ProcessListReducer.js';
import { ProcessListprogramIdandcourseIdReducer } from './reducers/ProcessListprogramIdandcourseIdReducer.js';

import RealtimeprojectReducer from './reducers/RealtimeprojectReducer';
import RealtimeprojectsByIdReducer from './reducers/RealtimeprojectByIdReducer.js';
import { uploadProjectImage } from './reducers/UploadProjectImage.js';
import FetchProjectImage from'./reducers/FetchProjectImage.js';
import FetchProjectImageByprojectname from './reducers/FetchProjectImageByprojectname.js';
import FetchProgramCourseFiles from './reducers/FetchProgramCourseFiles.js';
import { vistorcountreducer } from './reducers/vistorCountReducer.js';
import { UploadInstructorImage } from './reducers/UploadInstructorImage.js';
import FetchCompanyName from'./reducers/FetchCompanyName.js';
import FetchCompanyNameByCompanyName from './reducers/FetchCompanyNameByCompanyName.js'
import { getBellNotificationDataBYNoReducer } from './reducers/getNotificationByNumberReducer.js';
import { FetchMaterials } from './reducers/FetchMaterials.js';
import { FetchMaterialById } from './reducers/FetchMaterialById.js';

const rootReducer = combineReducers({
  auth: authReducer,
  fetchAllPrograms : FetchPrograms,
  fectchProgramById: FetchProgramById,
  fetchAllCourses: FetchCourses,
  fetchCourseById: FetchCourseById,
  getMailId: GetMailReducer,
  getAllVisitors: GetVisitors,
  getVisitorsById: GetVisitorById,
  getAllRoles: GetRole,
  getAllUsers: GetUsers,
  getUsersById: GetUserById,
  addNewProgram: UploadProgram,
  editProgram:UpdateProgramReducer,
  editUser:UpdateUserReducer,
  getProgramsId: FetchProgramId,
  fetchSubProgramId: FetchSubProgramId,
  getProgramsWithCourse: FetchProgramWithCourse,
  getAllStatusList: GetStatusList,
  editUserStatus: UpdateUserStatus,
  getUserByStatus:GetUserByStatus,
  getVisitorsByStatus:GetVisitorsByStatus,
  programCourseCombo:ProgramCourseCombination,
  subscriberRegister: SubscriberReducer,
  getAllSubscribersNprogram: GetSubscriberList,
  getLessons: GetLessons,
  getUnits: GetUnits,
  getUnitsByLesson:GetUnitsByLesson,
  getCourseWithLesson:GetCourseWithLesson,
  getUnitsByCourse:GetUnitByCourse,
  getPrerequisiteByCourse:GetPrequisiteByCourse,
  getLessonsByCourseId:GetLessonByCourseId,
  fetchActivePromotionEvent:GetActivePromotionEvent,
  addNewCourse: UploadCourse,
  editCourse:UpdateCourseReducer,
  addNewLesson:UploadLesson,
  addNewUnit:UploadUnit,
  getLessonsId: FetchLessonId,
  uploadNewProgramCourse: CreateProgramCourse,
  addNewPrerequisite:UploadPrerquisite,
  addNewImage: UploadImage,
  addImageProgram:UploadImageProgram,
  addImageCourse:UploadImageCourse,
  loadImageList:GetImageList,
  loadImageTable: GetImageTable,
  loadImageProgram:GetImageProgram,
  loadImageCourse:GetImageCourse,
  loadImageCourseForProgramDetail:GetImageCourseForProgramDetail,
  emailNotification:SendEmail,
  getUsersByEmail:GetUserByEmail,
  // getUsersByEmail:GetUserByEmail,
  editLesson:UpdateLessonReducer,
  getUnitsId: FetchUnitId,
  editUnit:UpdateUnitReducer,
  fetchAllPrerequisites: FetchPrerequisites,
  getPrerequisiteById: FetchPrerequisiteById,
  fetchAllEventsAd: FetchEventsAd,
  fetchAllEventAdById: FetchEventAdById,
  eventSubscriberRegister:EventSubscriber,
  fetchEvents:FetchEvents,
  fetchEventById:FetchEventById,
  getEventByStatus:GetEventByStatus,
  fetchEventSubscriberList:GetEventSubscriberList,
  fetchAllTestimonials: fetchTestimonials,
  fetchAllFaq:FetchFaq,
  addNewFaq:UploadFaq,
  getFaqById:FetchFaqById,
  editFaqById:UpdateFaqById,
  jobs:JobReducer,
  jobsById:jobsByIdReducer,
  fetchEventImageById:eventImagesReducer,
  channelPartner: channelPartnerReducer,
  uploadEvent:uploadEvent,
  uploadEventImg:uploadEventImg,
  fetchEventList:FetchEventList,
  fetchRegistration: fetchRegistration,
  UploadOnlineclass: UploadOnlineclass,
  
  fetchAllPopularCourseList: PopularCourselist,
  eventRowReducer:eventRowReducer,
  eventImageReducer:eventImageReducer,
  uploadCompanyLogo:uploadCompanyLogo,
  eventUpdateReducer:eventUpdateReducer,
  deleteEventReducer:deleteEventReducer,
  deleteEventImageReducer:deleteEventImageReducer,
  fetchAllTutorialCategories: FetchTutorialCategories,
  fetchTutorialCategoryById: FetchTutorialCategoryById,
  editTutorialCategory: UpdateTutorialCategoryReducer,
  addTutorialCategory: UploadTutorialCategory,
  fetchAllTutorialTechnologies: FetchTutorialTechnologies,
  fetchTutorialTechnologyById: FetchTutorialTechnologyById,
  editTutorialTechnology: UpdateTutorialTechnologyReducer,
  addTutorialTechnology: UploadTutorialTechnology,
  fetchAllTutorialTopics: FetchTutorialTopics,
  fetchTutorialTopicById: FetchTutorialTopicById,
  editTutorialTopic: UpdateTutorialTopicReducer,
  addTutorialTopic: UploadTutorialTopic,
  fetchAllCategories: FetchCategories,
  uploadNewCategory: UploadCategory,
  getCategoryById: FetchCategoryById,
  updateCtageory: UpdateCategory,
  fetchAllTopics: FetchTopics,
  uploadNewTopic: UploadTopic,
  fetchAllInterviewQa: FetchInterviewQa,
  fetchInterviewQaById: FetchInterviewQaById,
  uploadNewInterviewQa: UploadInterviewQa,
  UpdateInterviewQa: UpdateInterviewQa,
  fetchAllLinkedinUrl: FetchLinkedinUrl,
  addTutorialTopic: UploadTutorialTopic,
  UploadPromotionHeroImgAWsReducer:uploadPromotionHeroImageReducer,
  promotionheroimagebyId:promotionImagesReducer,
  ToggleSwitchAction:ToggleSwitchReducer,
ToggleHeroContent:ToggleSwitchReducer,
ToggleHeroContent:ToggleSwitchReducer,
otp_id:FetchOtp,
email:PostEmailOtp,

uploadJob_application_DocFile:uploadJob_application_DocFile,
job_applications:Job_applicationsReducer,
Jobawsapplication:JobawsapplicationReducer,
selectedPromotionImage:selectedPromotionImageReducer,
promotioneventtext:PromotionEventTextReducer,

  fetchInstructors: FetchInstructors,
  fetchInstructorById: FetchInstructorById,
  uploadInstructor: UploadInstructor,
  updateInstructor: UpdateInstructorReducer,
  deleteOnlineClass:deleteOnlineClass,
  fetchRemindme:fetchRemindme,
  processlist:ProcessListReducer,
  processlistIDdata:ProcessListprogramIdandcourseIdReducer,

  Realtimeprojects:RealtimeprojectReducer,
  RealtimeprojectsById:RealtimeprojectsByIdReducer,

  uploadProjectImage:uploadProjectImage,
fetchprojectimage:FetchProjectImage,

fetchProjectImageByprojectname:FetchProjectImageByprojectname,

// editProjectAwsImage: UpdateProjectAwsImageReducer,
getProjectImageByproject_name: FetchProjectImageByprojectname,
fetchProgramCourseFiles: FetchProgramCourseFiles,
VistorCountReducer: vistorcountreducer,
uploadInstructorImage:UploadInstructorImage,
fetchcompanyname:FetchCompanyName,
getCompanyName:FetchCompanyName,
 fetchCompanyNameBycompanyname:FetchCompanyNameByCompanyName,
 getCompanyNameBycompanyname:FetchCompanyNameByCompanyName,
 bellNotificationReducer:getBellNotificationDataBYNoReducer,
fetchMaterials: FetchMaterials,
fetchMaterialById:FetchMaterialById,
});

export default rootReducer;
