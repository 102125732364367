import React from 'react';
import WelcomeMenus from '../../WelcomeMenus';
import AdminMenus from '../AdminMenus';
import { useSelector } from 'react-redux';
import ProcessList from './ProcessList';
const AllProcessList =()=>{
    const { isAuthenticated, user } = useSelector((state) => state.auth);
    const userId = user?.role_id
    console.log('logged in as',userId)
    return(
        <div className="container-xxl ">
              { userId===1 && isAuthenticated ?

    <div className="container">
    <div className='btn-group' role='group' aria-label='Button group'>
            <AdminMenus buttonName='Add Process' pageName='addNewProcess' />
    </div>
          <div className='row dataTable pt-3'>
          <ProcessList />
          </div>
          </div>:<WelcomeMenus/>  }
        </div>
    )
}
export default AllProcessList;