import React, { useEffect, useState } from 'react'
import { saveAs } from "file-saver";
import TextfieldCustom from '../common/TextfieldCustom'
import { Button, Container, Grid, Paper, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getEmailId, getProgramCourseCombo,fetchProgramById ,fetchProgramCourseImageById} from '../redux/actions/Actions';
import { register } from '../redux/auth/authActions';
import { useLocation ,useParams} from 'react-router-dom';
import GoBackLink from '../common/GoBackLink';

const DownloadLesson = () => {
  const location = useLocation()
  const [pdfFilePath, setPdfFilePath] = useState('');
  const [item, setItem] = useState({});
  const fetchProgramsById = useSelector((state)=> state.fectchProgramById.programById)
  const fetchProgramsCourseFiles = useSelector((state) => state.fetchProgramCourseFiles.programCourseFilesById)
  const programCourseId = useSelector((state) => state.programCourseCombo.programCourseCombination); /* program course combo  */
  const [courseInterested, setCourseInterested] = useState(location.state.interestedCourse)
  const [courseInterestedId, setCourseInterestedId] = useState(location.state.interestedCourseId)
  const [id,setId] = useState(location.state.Program_id)
  //console.log(id,courseInterestedId,"id");
  const [programInterestedId, setProgramInterestedId] = useState(location.state.programInterestedId)
  const[programInterested, setProgramInterested] = useState(location.state.programInterested)
    const[mail,setMail] = useState('')
    const[mobile,setMobile] = useState('')
    const[formData, setFormData] = useState([])
    const [isSubmitted, setIsSubmitted] = useState(false);
    console.log(fetchProgramsCourseFiles, "course_id");
    const dispatch = useDispatch()
    useEffect(()=>{
      dispatch(fetchProgramCourseImageById(id,courseInterestedId))
   },  [dispatch,id,courseInterestedId])
   useEffect(() => {
    if (fetchProgramsCourseFiles) {
        // Map through each item in the array
        fetchProgramsCourseFiles.map(item => {
            // Access pdf_file_path from each item
            const pdfFilePath = item.pdf_file_path;
            //console.log(pdfFilePath,"pdf");
            // Further processing if needed
            return null; // or return any value you need
        });
        setItem(fetchProgramsCourseFiles);
    }
}, [fetchProgramsCourseFiles]);
useEffect(() => {
  if (fetchProgramsCourseFiles.length > 0) {
      fetchProgramsCourseFiles.map(item => {
          const pdfFilePath = item.pdf_file_path;
          console.log(pdfFilePath);
          // Set the pdfFilePath in state
          setPdfFilePath(pdfFilePath);
          return null;
      });
  }
}, [fetchProgramsCourseFiles]);
  console.log('item',item);
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);

    // Validate email
    const isEmailValid = /^\S+@\S+\.\S+$/.test(mail);

    // Validate phone number
    const isPhoneNumberValid = /^[0-9]{10}$/.test(mobile);

    // If email and phone number are valid, submit the form
    if (isEmailValid && isPhoneNumberValid) {
      const visitorData = {
        username: "",
        email: mail,
        password: "qwe",
        phone_number: mobile,
        role_id: "2",
        program_course_id: programCourseId[0]?.program_course_id,
        course_id: courseInterestedId
      };

     console.log(programCourseId, "course_id");

      dispatch(getEmailId(visitorData));
      saveAs(pdfFilePath);
//console.log(courseInterested,programInterested ,programCourseId);
      // Reset form fields and submission state
      setMail('');
      setMobile('');
      setIsSubmitted(false);
    }
  };

  return (
    <>
    <div className="container-xxl py-5">
        <div className="container">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h6 className="section-title bg-white text-center text-primary px-3">{`You are at: ${programInterested} / ${courseInterested}`}</h6>
                <h1 className="mb-5">Enter and Download full portions</h1>

                <form onSubmit={handleSubmit}>
                  <GoBackLink />
                <Container maxWidth="sm" className=" d-flex" >    
                    <Grid
                      container
                      spacing={2}
                      direction="column"
                      justifyContent="center"
                      
                    >
                        <div style={{ width: "inherit"}}>
                        <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
                        <Grid container  spacing={2} sx={{border: '1px solid rgba(0,0,0,0.125', zIndex:"3"}} style={{padding: "30px"}}>
                          <Grid item xs={12}>
                          <TextField 
                            type="email"
                            
                            name="Enter Your Email id"
                            placeholder='Enter Your Email id'
                            value={mail}
                            onChange={(e)=>setMail(e.target.value)}
                            />
                            {isSubmitted && !/^\S+@\S+\.\S+$/.test(mail) && <span style={{ color: "red" }}>Invalid email format</span>}
                          </Grid>
                          <Grid item xs={12}>
                          <TextField 
                            type="tel"
                            
                            name="Enter Your Mobile Number"
                            placeholder='Enter Your Mobile Number'
                            value={mobile}
                            onChange={(e)=>setMobile(e.target.value)}
                            />
                            {isSubmitted && !/^[0-9]{10}$/.test(mobile) && <span style={{ color: "red" }}>Phone number should have exactly 10 digits</span>}
                          </Grid>
                          <Grid item xs={12}>
                            <Button className="btn btn-primary py-2 mx-3 top-0 end-0 mt-2 me-2" type='sybmit' >Download Syllabus</Button>
                          </Grid>
                        </Grid>
                        </Paper>
                        </div>
                      </Grid>
                  </Container>
                    
                    
                    
                </form>
            </div>
            
        </div>
    </div>
    </>
  )
}

export default DownloadLesson