import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import WelcomeMenus from '../WelcomeMenus';
import DataTabs from './visitors/DataTabs';

const ExecutorDashboard = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userId = user?.role_id

  return (
    <div className='right-side-main-container'>
      {
        isAuthenticated  && userId==3 ?(<DataTabs/>) : (
          <WelcomeMenus />
        )

      }
      
    </div>
  )
}

export default ExecutorDashboard
