import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPendingRequests } from '../../redux/auth/authActions';

const PendingRequests = ({ pendingRequests, onApprove, onReject }) => {
  const dispatch = useDispatch();
  const filteredRequests = useSelector((state) => state.auth.pendingRequests);

  useEffect(() => {
    dispatch(fetchPendingRequests());
  }, [dispatch]);

  return (
    <div className='container'>
      <h2>Pending Registrations</h2>
      {filteredRequests.map((request) => (
        <div className='container' key={request.cp_id}>
          <p>{request.Channel_partner_name}</p>
          <Button variant="contained" color="primary" onClick={() => onApprove(request.cp_id)} style={{margin:'2px'}}>
            Approve
          </Button>
          <Button variant="contained" color="secondary" onClick={() => onReject(request.cp_id)} style={{margin:'2px'}}>
            Reject
          </Button>
        </div>
      ))}
    </div>
  );
};

export default PendingRequests;
