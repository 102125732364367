import React from "react";

function FormValidator({ name, value, errors, isSubmitted }) {
  if (typeof value !== "string" ) {
    return null; // Return null if the value is not a string
  }

  if (value.trim() === "" && isSubmitted) {
    return <span style={{ color: "red" }}>This field is required</span>;
  }

   
  if (name === "username") {
    if (!/^(?=.*[A-Za-z])[A-Za-z0-9@#$%^&_*]+$/.test(value)) {
      return <span style={{ color: "red" }}>Invalid email format</span>;
    }
  } 
  else if (name === "password") {
    if (!/^(?=.*[A-Z])(?=.*[!@#$%^&*()_\-+=<>?/[\]{}|])(?=.*[0-9]).{8,}$/.test(value)) {
      return <span style={{ color: "red" }}>Invalid password : <br></br>
      <ul><li>At least one uppercase letter</li> <li>At least one special character</li><li>At least one digit</li> <li>Minimum length of 8 characters</li></ul></span>;
    }
  }
  else if (name === "email") {
    if (!/^\S+@\S+\.\S+$/.test(value)) {
      return <span style={{ color: "red" }}>Invalid email format</span>;
    }
  } else if (name === "phone_number") {
    if (!/^[0-9]+$/.test(value)) {
      return <span style={{ color: "red" }}>Phone number should only contain numbers</span>;
    } else if (value.length !== 10) {
      return <span style={{ color: "red" }}>Phone number should have exactly 10 digits</span>;
    }
  }
  else if (name === "programId") {
    if (value.trim() === "") {
      return <span style={{ color: "red" }}>Program is required</span>;
    }
  } else if (name === "subProgramId") {
    if (value.trim() === "") {
      return <span style={{ color: "red" }}>Subprogram is required</span>;
    }
  }

  // Return null if no errors were found
  return null;
}


export default FormValidator;
