import { ActionTypes } from "../constants/action-types"

const initialState = {
    updateUnit:[]

}

export const UpdateUnitReducer = (state=initialState, {type, payload})=>{
switch (type){
    case ActionTypes.EDIT_UNIT: 
    return {...state, updateUnit: payload}
    default: return state
}

}