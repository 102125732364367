import { ActionTypes } from "../constants/action-types";
const initialState={
    RealtimeprojectsData:[],
};

const RealtimeprojectsReducer=(state=initialState,action)=>{
    switch(action.type){
        case ActionTypes.FETCH_REALTIMEPROJECTS:
            return{
                ...state,
                RealtimeprojectsData:action.payload,
            };
            default:
                return state;
    }
};
export default RealtimeprojectsReducer;