import React, { useEffect, useMemo, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Grid, IconButton, Stack, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
 import {fetchProjectImage ,deleteProjectImage} from '../../../redux/actions/Actions';
import { Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';


const RealtimeProjectDetils =()=> {
     const imageData = useSelector((state) => state.fetchprojectimage.imageData);

// console.log('imagedata12',imageData)
    const {isAuthenticated,user}=useSelector((state)=>state.auth);
      const userId = user?.role_id
     const dispatch = useDispatch()
    const[dataRows,setDataRows] = useState([]) 
    const[dataColumns,setDataColumns]=useState([])
     const[data,setData] = useState([])
     const navigate = useNavigate()

        const columns = useMemo(() => [
            { field: 'id', headerName: 'ID', width: 60 },
            { field: 'project_name', headerName: 'Project Name', width: 150,height:450 },
            { 
                field: 'project_descrption', 
                headerName: 'Project Description', 
                renderCell: (params) => (
                    <div dangerouslySetInnerHTML={{ __html: params.row.project_descrption }} />
                ),
                width: 200 ,height:650
            },
            
            { field: 'image_url', headerName: 'Image URL', width: 450 },
            {
                field: 'action',
                headerName: 'Action',
                width: 200,
                sortable: false,
                disableClickEventBubbling: true,
                renderCell: (params) => {
                    const onClick = (e) => {
                        const currentRow = params.row;
                        navigate(`/auth/updateRealtimeProjectDetails/${currentRow.project_name}`);
                    };
        
                    const handleDelete = (e) => {
                        const currentRow = params.row;
                        dispatch(deleteProjectImage(currentRow.project_name));
                        console.log('clicked project details is', currentRow.project_name);
                    };
        
                    return (
                        <Stack direction="row" spacing={2}>
                            <IconButton onClick={onClick}>
                                <Edit />
                            </IconButton>
                            <IconButton onClick={() => handleDelete()}>
                                <Delete />
                            </IconButton>
                        </Stack>
                    );
                },
            },
        ], [navigate, dispatch]);
        
         const rows=useMemo(()=>data?.map((row,index)=>{
            return{
                ...row,
                id:index + 1,
                project_name:row.project_name,
                project_descrption:row.project_descrption,
                // job_tittle:row.job_tittle,
                image_url:row.image_url,
            }
         }),[data]);
        
        const [searchValue, setSearchValue] = React.useState('');
        const filteredRows =rows.filter((row) =>
            Object.values(row).some(
                (value) =>
                    value !== null &&
                    value !== undefined &&
                    value.toString().toLowerCase().includes(searchValue.toLowerCase())
            )
        );
    useEffect(()=>{
         dispatch(fetchProjectImage())
    },[dispatch,imageData]);
    
    useEffect(()=>{
        setData(imageData)
        setDataColumns(columns)
        setDataRows(rows)
    },[data,imageData])
    
    
  return (
    <div>
      <h4>Realtime Project Details</h4>


      <div className='job-applications'>
            <Grid item xs={5}
            >
            <TextField
                label="Search"
                variant="outlined"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                InputProps={{
                    endAdornment: <SearchIcon />,
                }}
                style={{ marginTop: 0, marginBottom: 15 }}
            />
            </Grid>
            {/* <Box sx={{ height: 400, width: '100%' }}> */}
                {
                userId===1 && (
                    <Box sx={{ height: 400, width: '100%' }}>

                <DataGrid
                style={{marginTop:'15px',marginBottom:'0px'}}
                    rows={filteredRows}
                    columns={columns}
                    initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 10 },
                        },
                      }}
                      pageSizeOptions={[5, 10]}
                      checkboxSelection
        
             />
          </Box>)

            }
       </div>


    </div>
  )
}
export default RealtimeProjectDetils