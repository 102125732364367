import React, { useEffect, useState ,useMemo} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteUserById, fetchUserByStatus, getUsersList } from '../../../redux/actions/Actions';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

const UserList = (props) => {
    const fetchUsersList = useSelector((state)=> state.getAllUsers.allUsers)

    const fetchUserListByStatus = useSelector((state)=> state.getUserByStatus.userByStatus)
    const { isAuthenticated, user } = useSelector((state) => state.auth);
    const userId = user?.role_id
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const[userStatus,setUserStatus]=useState('')
    const[data,setData] = useState([])

    const[dataRows,setDataRows] = useState([])
    const[dataColumns,setDataColumns]=useState([])
  // console.log('all users',fetchUsersList)

     function formatDate(dt){
        const getYear = new Date(dt).getFullYear()
        const getMonth = new Date(dt).getMonth() + 1
        const getMyDay = new Date(dt).getDate()
        const dateFormat = getMyDay + "/" + getMonth + "/" + getYear
        return dateFormat;
    }

    const handleDelete = (id) =>{
        dispatch(deleteUserById(id))
        console.log('clicked User is', id)
    }
   // console.log('users by status', fetchUserListByStatus)

const columns = useMemo(
  () => [
    { field: 'id', headerName: 'Id', width: 60, disableColumnMenu: true, sortable: false },
    { field: 'role', headerName: 'Role', width: 80, sortable: false, disableColumnMenu: true },
    { field: 'email', headerName: 'Email', width: 160, disableColumnMenu: true },
    { field: 'phone_number', headerName: 'Phone Number', width: 160, disableColumnMenu: true },
    // { field: 'program_name', headerName: 'Program Name', width: 160, disableColumnMenu: true },
    { field: 'status', headerName: 'Status', width: 140, disableColumnMenu: true, sortable: false },
    { field: 'date', headerName: 'Date', width: 140, disableColumnMenu: true },
    {
        field: 'action',
        headerName: 'Actions',
        width: 180,
        sortable: false,
        disableClickEventBubbling: true,
        
        renderCell: (params) => {
            const onClick = (e) => {
              const currentRow = params.row;
              //return alert(JSON.stringify(currentRow, null, 4));
              navigate(`/Auth/updateUser/${currentRow.user_id}`)
            };
            const handleDelete = (e) =>{
                const currentRow = params.row;
                dispatch(deleteUserById(currentRow.user_id)) 
                console.log(`clicked User is${currentRow.user_id}:${currentRow.email}`)
            }
            
            return (
              <Stack direction="row" spacing={2}>
                <IconButton onClick={onClick}>
                    <Edit />
                </IconButton>
                {
                  params.row?.role_id !== 1 ? 
                  <IconButton onClick={()=>handleDelete()} >
                  <Delete />  
                </IconButton> : 
                <IconButton disabled>
                  <Delete />
              </IconButton>
                }
              </Stack>
            );
        },
      }
  ], []);
  const rows =useMemo(
    () => data?.map((row, index) => {
    return {
      ...row,
      id: index + 1,
      role: row.role_name,
      email: row.email,
      phone_number: row.phone_number,
      program_name: row.subprogram_name,
      status: row.status,
      date: formatDate(row.modify_date)

    }
  }),[data]);
 
  useEffect(()=>{
    setUserStatus(props.status)
    dispatch((getUsersList()))
    dispatch(fetchUserByStatus(userStatus))
 },  [dispatch,fetchUsersList])

  useEffect(()=>{
    if(userStatus === 'all'){
        setData(fetchUsersList)
    }else{
        setData(fetchUserListByStatus)
    }
    setDataColumns(columns)
    setDataRows(rows)

}, [userStatus,fetchUsersList,userStatus])

  return (
    <div className='user-list'>
        
        {
             (
                
                        <DataGrid
                            rows={dataRows}
                            columns={dataColumns}
                            initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                            }}
                            pageSizeOptions={[5, 10]}
                            checkboxSelection
                        />
                    
            )
        }
    </div>
  )
}

export default UserList