import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select } from '@mui/material';
import { deleteProgramById, fetchProgramList, deleteProgramImageById } from '../../../redux/actions/Actions';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';



const ProgramsList = () => {
    const fetchPrograms = useSelector((state)=> state.fetchAllPrograms.programs)
    const { isAuthenticated, user } = useSelector((state) => state.auth);
    //console.log('all programs',fetchPrograms)
    const userId = user?.role_id;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [currentProgramIndex, setCurrentProgramIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [selectedProgram, setSelectedProgram] = useState(null);

    useEffect(() => {
        dispatch(fetchProgramList());
    }, [dispatch]);

    useEffect(() => {
        setData(fetchPrograms);
    }, [fetchPrograms]);

    // const handleDelete = async (id) => {
    //     try {
          
    //      const response = await dispatch(deleteProgramById(id)); 
    //     console.log(response.status,"error data");
    //       await dispatch(fetchProgramList());
    //       setData((prevData) => prevData.filter((program) => program.program_id !== id));
      
    //       toast.success(`Program Deleted Successfully`);
    //     } catch (error) {
    //       console.error('Error deleting Program:', error);
    //       toast.error(`Error deleting Program`);
    //     }
    //   };
    const handleDelete = async (id) => {
        try {
          const response = await dispatch(deleteProgramById(id));
      
          if (response.status === 200) {
            // Success: program deleted
            console.log('Program deleted successfully');
            await dispatch(fetchProgramList());
            setData((prevData) => prevData.filter((program) => program.program_id !== id));
            toast.success(`Program Deleted Successfully`);
          } else if (response.status === 400) {
            // Error: cannot delete program with related data
            console.error('Unable to delete program; it is currently in use.');
            toast.error('Unable to delete program; it is currently in use.');
          } else {
            // Handle other status codes if needed
            console.error('Unexpected status code:', response.status);
            toast.error('Unexpected error');
          }
        } catch (error) {
          // Handle the error thrown by the deleteProgramById action
          console.error('Error deleting Program:', error);
          toast.error('Error deleting Program');
        }
      };
      

    const viewmore = (index) => {
        setCurrentProgramIndex(index);
        setSelectedProgram(fetchPrograms[index]);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


  return (
    <div>
        {
            userId === 1 || userId===3 ? (
                <table className="table table-striped">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Program Name</th>
                    <th scope="col">Registeration Charge</th>
                    <th scope="col">Program Details</th>
                    <th scope="col">More About Program</th>
                            {userId === 1 && <th scope="col" colSpan={2}>Actions</th>}

                    </tr>
                </thead>
                <tbody>
                    {
                        fetchPrograms.map((eachItem, index)=>(
                            <tr key={index}>
                            <th scope="row">{index+1}</th>
                            <td> {eachItem.program_name} </td>
                            <td>{eachItem.registration_charge}</td>
                            <td>{eachItem.program_details}</td>
                            <td><Button onClick={() => viewmore(index)}>View</Button></td>
                            {userId===1? <td> <Button onClick={()=>navigate(`/auth/updateProgram/${eachItem.program_id}`)}>Edit</Button> </td>:"" }
                            {userId===1? <td> <Button onClick={()=>handleDelete(eachItem.program_id)}>Delete</Button> </td>:"" }

                            </tr>
                        ))
                    }
                </tbody>
                </table>
             ) : null}

             <Modal open={showModal} onClose={handleCloseModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                 <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width:'fit-content', backgroundColor:"white", boxShadow: 24, p: 4, padding:'5px' ,fontSize:'1.5rem' }}>
                     <h2 id="modal-modal-title" style={{textAlign:'center'}}>Program Details</h2>
                     <p id="modal-modal-description">
                         {selectedProgram && (
                             <>
                                 <tr><strong>Program Name:        </strong>{selectedProgram.program_name}</tr>
                                 <tr><strong>Duration:            </strong>{selectedProgram.duration}</tr>
                                 <tr><strong>Cost:                </strong> {selectedProgram.cost}</tr>
                                 <tr><strong>Target Audiance:     </strong>{selectedProgram.target_audience}</tr>
                                 <tr><strong>Program coverage:    </strong>{selectedProgram.program_coverage}</tr>
                                 <tr><strong>Career opertunity:   </strong> {selectedProgram.career_opportunities}</tr>
                                 <tr><strong>Registration Charge: </strong> {selectedProgram.registration_charge}</tr>
 
                             </>
                         )}
                     </p>
                     <Button style={{border:'3px solid black' ,backgroundColor:'red',color:'white'}} onClick={handleCloseModal}><strong>Close</strong></Button>
                 </div>
             </Modal>
    </div>
  );
}

export default ProgramsList;