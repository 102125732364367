import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/auth/authActions';
import zIndex from '@mui/material/styles/zIndex';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import { fetchbellNotificationDataByNumber, updateBellNotification } from '../../redux/actions/Actions';
import { Modal } from 'react-bootstrap';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
const userMenus = [
  {
  menu: "Your Account",
  link: "/#"
  },
  {
    menu: "Profile",
    link: "/#"
  },
  {
    menu: "Dashboard",
    link: "/#"
  }    
]
function TopbarCp({ selectedMenuItem  }) {
  /* admin data from DB */
  const { loginDetails, user} = useSelector((state) => state.auth)
  const fetchUsersList = useSelector((state)=> state.getAllUsers.allUsers)
  const bellnotificationData=useSelector((state)=>state.bellNotificationReducer.bellNotification);
  //  console.log("bellnotification",bellnotificationData,"....length",bellnotificationData.length,"usernmae",bellnotificationData.username);
  
  const history = useNavigate()
  const dispatch = useDispatch();  
  const [displayImg, setdisplayImg] = useState("");
  const [regestercounter,setRegestercounter]=useState();
  const [showModal,setShowModal]=useState(false);

  const handlelogout = (e) =>{
    e.preventDefault();
    
    dispatch(logout());
    history('/')
   // console.log("header nav click fn")
  }

  useEffect(()=>{
    const fetchProfilePicById = async () =>{
      try{
        setdisplayImg("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png")
      }catch (err){
          console.log(err)
       }
    }
    fetchProfilePicById();
    
    
    }, []) 

  /* MUI variables */
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOnChange = () => {
    setShowModal(true)
    
  };
  
  useEffect(() => {
    dispatch(fetchbellNotificationDataByNumber());
  
    const intervalId = setInterval(() => {
      dispatch(fetchbellNotificationDataByNumber());
    }, 5000); 
  
    return () => clearInterval(intervalId);
  }, [dispatch]);
  
  useEffect(() => {
    if (bellnotificationData.length <= 0) {
      setRegestercounter(false);
    } else {
      setRegestercounter(true);
    }
  });
  
  const handleCloseModal=()=>{
    setShowModal(false)
    bellnotificationData.forEach(element => {
      dispatch(updateBellNotification({ "username": element.username, "number": 0 }));
    });
  }
  return (
    <div className='topbar-cp' style={{width:'100%'}}>
          <div className='selected-menu'>
                      <Typography sx={{marginLeft:'10px'}} className='selected-menu-text'>
                    {selectedMenuItem} 
                    </Typography>
                    </div>
        <AppBar position="static"  >
          <Container maxWidth="xl" sx={{marginRight:'10px'}}>
            <Toolbar disableGutters >

              <Box sx={{ flexGrow: -1 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Typography sx={{ fontWeight: 'bold', color: '#FFFFFF',fontSize:'large', marginRight: '10px' }}>
                    Welcome {user?.username}
                  </Typography>
                    <Avatar alt="User Profile" src={displayImg} />
                  </IconButton>
                </Tooltip>
                <div onClick={handleOnChange} style={{ display: 'flex', float: 'inline-end', marginLeft: '30px' ,cursor:'pointer'}}>
  <AddAlertIcon className='bell-icon-admin' style={{ position: 'absolute',fontSize:'2.2rem' }} />
  {regestercounter ? <div className='counterForBell' style={{ position: 'relative',marginLeft:'20px' }}></div> : ""}
</div>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {/* {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))} */}

                  {userMenus.map((eachMenu) => (
                      <MenuItem key={eachMenu.menu} onClick={handleCloseUserMenu}>
                        {/* <Typography textAlign="center">{setting}</Typography> */}
                        <Typography>
                          <Link to={eachMenu.link}>{eachMenu.menu}</Link>
                        </Typography>
                      </MenuItem>
                  ))}
         
                  <MenuItem onClick={handlelogout}sx={{ fontSize: '1.5rem', fontWeight: 400,  color: '#06BBCC' }}>Logout</MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        <Modal show={showModal}>
  <div className="modal" style={{display:'flex',width:"fit-content",alignItems:"center",flexDirection:'column',marginLeft:'26%',marginTop:'15%',background:'#05ffcb',borderRadius:'2rem',height:'auto',padding:'5px'}}>
    <h1>Details of new register</h1>
    <div className="container-body"style={{}}>
      <table className="table table-dark table-hover">
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Event Name</th>
            <th>Program Name</th>
            <th>Subprogram Name</th>
          </tr>
        </thead>
        <tbody>
          {bellnotificationData.map((data, index) => (
            <tr key={index}>
              <td>{data.username}</td>
              <td>{data.email}</td>
              <td>{data.phone_number}</td>
              <td>{data.event_name || "-"}</td>
              <td>{data.program_name || "-"}</td>
              <td>{data.subprogram_name || "-"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    {/* Add close button with handleCloseModal */}
    <button style={{color:'white',background:"#1378fa",width:'10rem',borderRadius:'0.5rem'}} onClick={handleCloseModal}>Close</button>
  </div>
  </Modal>
    </div>
  );
}
export default TopbarCp