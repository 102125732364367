
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Grid, Paper, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { uploadNewCourse, fetchProgramList, fetchCourseList, uploadProcesslist, fetchProgramById } from '../../../redux/actions/Actions';
import TextfieldCustom from '../../../common/TextfieldCustom';

const UploadProcessList = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const fetchCourses = useSelector((state) => state.fetchAllCourses.courses);
  // console.log('Fetching courses', fetchCourses);
  const fetchPrograms = useSelector((state) => state.fetchAllPrograms.programs);
  // console.log('FetchPPPPPPPPP', fetchPrograms);
  const fetchprocessList=useSelector((state)=>state.processlist.processlistdata)
const [error,setError]=useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [formData, setFormData] = useState({
    // program_name:'',
    course_name: '', 
    program_id: '',
    course_id: '',
    process_list: [],
  });

  useEffect(() => {
    dispatch(fetchProgramList());
    dispatch(fetchCourseList());
    
    const timingForFetch=setInterval(()=>{
      dispatch(fetchCourseList())
      dispatch(fetchProgramList())
    },2000)
    return ()=>clearInterval(timingForFetch);
  }, [dispatch]);
  


  const handleProgramChange = (e) => {
    const programId = e.target.value;
    // console.log(`Program ${programId}`);
    
    dispatch(fetchProgramById(programId))

    setFormData({ ...formData, program_id: programId });
  }; 
  const fetchProgramsById = useSelector((state)=> state.fectchProgramById.programById)

  // console.log('fetchProgramsById...................',fetchProgramsById);

  const handleCourseChange = (e) => {
    const selectedCourseId = e.target.value;
    setFormData({ ...formData, course_id: selectedCourseId});
  };

  const handleProcessChange = (e) => {
    setFormData({ ...formData, newProcess: e.target.value });
  };

  const handleAddProcess = () => {
    if (formData.newProcess.trim() !== '') {
      setFormData({ ...formData, process_list: [...formData.process_list, formData.newProcess], newProcess: '' });
    }
  };

  const handleDeleteProcess = (index) => {
    const updatedProcessList = [...formData.process_list];
    updatedProcessList.splice(index, 1);
    setFormData({ ...formData, process_list: updatedProcessList });
  };

  
  const handleSubmit = (e) => {
    e.preventDefault();
    const isDuplicatePair  = fetchprocessList.some((program)=>program.program_id===formData.program_id && program.course_id===formData.course_id)
    // console.log(",,,,,,,,,,,,,,,,,",formData)
    if(isDuplicatePair){
      setError('Program and course combination already exists.');

    }else{
    console.log(",,,,,,,,,,,,,,,,,",formData)
    dispatch(uploadProcesslist(formData));
    // navigate("/auth/allCourses");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Container maxWidth="sm" className="d-flex">
          <Grid container spacing={2} direction="column" justifyContent="center">
            <div style={{ width: "inherit" }}>
              <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
                <h3>Upload process list</h3>
                <Button onClick={() => navigate(-1)}>Back</Button>
                <Grid container spacing={2} sx={{ border: '1px solid rgba(0,0,0,0.125)', zIndex: "3" }} style={{ padding: "30px" }}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="program-id-label">Program</InputLabel>
                      <Select
                        labelId="program-id-label"
                        id="program-id-label"
                        value={formData.program_id}
                        onChange={handleProgramChange}
                        required 
                      >
                        {fetchPrograms.map((program) => (
                          <MenuItem key={program.program_id} value={program.program_id}>
                            {program.program_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="course-name-label">Course name</InputLabel>
                      <Select
                        labelId="course-name-label"
                        id="course-name-label"
                        value={formData.course_id}
                        onChange={handleCourseChange}
                        required 
                      >
                        {fetchProgramsById.map((course) => (
                          <MenuItem key={course.course_id} value={course.course_id}>
                            {course.course_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} style={{display:'flex'}}>
                    <TextField
                      fullWidth
                      id="process-list"
                      label="Enter Process List"
                      value={formData.newProcess}
                      onChange={handleProcessChange}
                    />
                    <Button  onClick={handleAddProcess}>Add</Button>
                  </Grid>
                  <Grid item xs={12}>
                    <ul>
                      {formData.process_list.map((process, index) => (
                        <ul key={index}>
                        <li>
                            {process} <Button onClick={() => handleDeleteProcess(index)}>Delete</Button>
                        </li>
                        </ul>
                      ))}
                    </ul>
                  </Grid>
                  {error && <div className='Error' style={{color:'red'}}>{error}</div>}

                  <Grid item xs={12}>
                    <Button variant="contained" style={{ backgroundColor: '#3251A3', borderColor: '#FF5E14' }} fullWidth type="submit">
                      Upload process list
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </Grid>
        </Container>
      </form>      
    </div>
  );
}

export default UploadProcessList;


