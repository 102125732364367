
import { ActionTypes } from "../constants/action-types";

const initialState = {
  promotioneventtextData: [],
};

 export const PromotionEventTextReducer = (state = initialState, {type,payload}) => {
  switch (type) {
    case ActionTypes.FETCH_PROMOTION_EVENT_TEXT_BY_ID:
      return{...state,promotioneventtextData:payload}
      case ActionTypes.FETCH_PROMOTION_EVENT_TEXT:
      return{...state,promotioneventtextData:payload}
      case ActionTypes.EDIT_PROMOTION_EVENT_TEXT:
      return{...state,promotioneventtextData:payload}
      case ActionTypes.DELETE_PROMOTION_EVENT_TEXT:
      return {
        ...state,
        promotioneventtextData: state.promotioneventtextData.filter((item,index)=>index !==payload)
      }

    default:
      return state;
  }
};

