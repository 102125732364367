import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select } from '@mui/material';
import { fetchMaterialList, deleteMaterialList,deleteOneMaterial } from '../../../redux/actions/Actions';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const MaterialList = () => {
    const fetchCourses = useSelector((state) => state.fetchMaterials.materials);
    const { isAuthenticated, user } = useSelector((state) => state.auth);
    const userId = user?.role_id;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState([]);

    useEffect(() => {
        dispatch(fetchMaterialList());
    }, [dispatch]);

    useEffect(() => {
        setData(fetchCourses);
    }, [fetchCourses]);

    const handleDeleteRow = async (material_access_id) => {
        try {
            await dispatch(deleteMaterialList(material_access_id));
            await dispatch(fetchMaterialList());
            toast.success(`Material Deleted Successfully`);
        } catch (error) {
            console.error('Error deleting material:', error);
            toast.error(`Error deleting material`);
        }
    };

    const handleDeleteFile = async (material_access_id, filePath) => {
        const parts = filePath.split('/');
        const pathWithoutEndpoint = parts.slice(4).join('/'); // Remove single quotes

        try {
            const formImageData = new FormData();
            formImageData.append('pathWithoutEndpoint', pathWithoutEndpoint); // Append pathWithoutEndpoint as key
            await dispatch(deleteOneMaterial(material_access_id, pathWithoutEndpoint)); // Pass formImageData
            await dispatch(fetchMaterialList());
           // toast.success(`File Deleted Successfully`);
        } catch (error) {
            console.error('Error deleting file:', error);
            toast.error(`Error deleting file`);
        }
    };

    return (
        <div>
            {userId === 1 || userId === 3 ? (
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Course Name</th>
                            <th scope="col">Files</th>
                            {userId === 1 && <th scope="col">Actions</th> }
                            <th></th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        {fetchCourses.map((eachItem, index) => (
                            <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{eachItem.course_name}</td>
                                {/* Display files */}
                                <td>
                                    {eachItem.material_file_paths.map((filePath, fileIndex) => (
                                        <div key={`${index}-${fileIndex}`} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                            <div style={{ marginRight: '15px' }}>
                                                <a href={filePath} target="_blank" rel="noopener noreferrer">
                                                    {filePath.split('/').pop().replace('.pdf', '')}
                                                </a>
                                            </div>
                                            {userId === 1 && (
                                                <div>
                                                    <Button onClick={() => handleDeleteFile(eachItem.material_access_id, filePath)}>Delete File</Button>
                                        
                                             </div>
                                            )}
                                        </div>
                                    ))}
                                </td>
                                {/* End of files display */}
                                {userId === 1 && (
                                   <> <td>
                                        <Button onClick={() => handleDeleteRow(eachItem.material_access_id)}>Delete Row</Button>
                                           </td> 
                                           <td> 
                                           <Button onClick={()=>navigate(`/auth/AddFile/${eachItem.material_access_id}`)}>Add File </Button>
                                           </td>
                                       </>
                                
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : null}
            {/* Additional components */}
        </div>
    );
};

export default MaterialList;
