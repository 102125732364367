import React, {useEffect} from "react";
import LandingPage from "./LandingPage";
import { useParams } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { fetchEventListId } from "../redux/actions/Actions";

const LatestEvent = () =>{
    const {id} = useParams();
    const fetchEventAdId = useSelector((state)=>state.fetchAllEventAdById.eventAdById);
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(fetchEventListId(id))
     },  [dispatch,id]);
    // console.log('eventlist from db', fetchEventAdId);
    // console.log('event name from db',fetchEventAdId.event_name);



    return (
        <div className="container rounded-pill">
          <LandingPage eventData={fetchEventAdId} />
        </div>

      );


}

export default LatestEvent;
