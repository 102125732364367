import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  TextField,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'; // Import icons for visibility toggle

const ChangePassword = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  const { newPassword, confirmPassword } = formData;
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add validation logic to ensure the new password and confirm password match.
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match. Please try again.");
      return;
    }

    // Implement logic to update the user's password here.
    // You can make an API call or perform any necessary actions.
    // After successfully changing the password, navigate the user back to the login page.
    navigate('/login');
  };

  return (
    <>
      <div className='row m-0 align-items-center justify-content-center'>
        <div className='col-lg-5'>
          <img src='img/change-password-page.png' alt='change password page image' className='img-fluid' />
        </div>
        <div className='col-lg-7'>
          <Container maxWidth='sm' className=' d-flex'>
            <Grid
              container
              spacing={2}
              direction='column'
              justifyContent='center'
            >
              <div style={{ width: 'inherit' }}>
                <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
                  <Grid container spacing={2} sx={{ border: '1px solid rgba(0,0,0,0.125', zIndex: '3' }} style={{ padding: '30px' }}>
                    <Grid item xs={12}>
                      <TextField
                        type={showPassword ? 'text' : 'password'}
                        name='newPassword'
                        required
                        value={newPassword}
                        onChange={handleChange}
                        placeholder='New Password'
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleTogglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        type={showPassword ? 'text' : 'password'}
                        name='confirmPassword'
                        required
                        value={confirmPassword}
                        onChange={handleChange}
                        placeholder='Confirm Password'
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleTogglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant='contained'
                        style={{ backgroundColor: '#3251A3', borderColor: '#FF5E14' }}
                        fullWidth
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;