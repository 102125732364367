import { ActionTypes } from "../constants/action-types"

const initialState = {
  unitsId: []
}

const FetchUnitId = (state=initialState, {type, payload})=>{
    switch(type){
        case ActionTypes.FETCH_UNIT_ID : return {...state, 
            unitsId: payload}           
        default: return state;
    }
    
}


export default FetchUnitId;