import React, { useState, useEffect } from "react";
import { Card, CardContent ,Select, MenuItem} from "@mui/material";
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTutorialTopicsList, fetchTutorialTechnologiesList } from "../redux/actions/Actions";
import { color } from "@mui/system";
import { red } from "@mui/material/colors";

const TutorialDetail = () => {
  const fetchTutorialTopics = useSelector((state) => state.fetchAllTutorialTopics.tutorialTopics);
  const fetchTutorialTechnologies = useSelector((state) => state.fetchAllTutorialTechnologies.tutorialTechnologies);
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTutorialTopicsList());
    dispatch(fetchTutorialTechnologiesList());
  }, [dispatch]);

  const { technology_id } = useParams();
  const filteredTopics = fetchTutorialTopics.filter((tutorial_topic) => tutorial_topic.technology_id === parseInt(technology_id));

  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedTechnology, setSelectedTechnology] = useState(null);

  const handleTechnologySelect = (technologyId) => {
    setSelectedTechnology(technologyId);
    setSelectedTopic(null); // Reset selected topic when a new technology is selected
  };

  const handleTopicSelect = (topicId) => {
    setSelectedTopic(topicId);
  }

  const renderDescription = () => {
    const topic = fetchTutorialTopics.find((topic) => topic.topic_id === selectedTopic);
    return topic ? topic.description : 'No description available';
  }

  return (
    
<div className="containerInterview">
      <br></br>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-2">
           
                <Card>
                  <CardContent>
                <h5>Topics</h5>
                <Select
                  value={selectedTopic}
                  onChange={(e) => handleTopicSelect(e.target.value)}
                  displayEmpty
                  style={{ marginBottom: '20px', fontFamily: 'poppins' }}
                >
                  <MenuItem value={null} disabled>
                    Select Topic
                  </MenuItem>
                  {filteredTopics.map((tutorial_topic) => (
                    <MenuItem key={tutorial_topic.topic_id} value={tutorial_topic.topic_id}>
                      {tutorial_topic.topic_name}
                      
                    </MenuItem>
                  ))}
                </Select>
              </CardContent>
            </Card>
          </div>
          {/* The rest of your component */}
          <div className="col-sm-12 col-md-10">
          
            <Card className="mb-3">
              <CardContent>
                
                <p style={{ margin: '0',fontFamily: "poppins"}}>
                  {selectedTopic !== null ? renderDescription() : 'Select a topic'}
                </p>
                
              </CardContent>
            </Card>
          </div>
          </div>
        </div>
      </div>
  
  );
}
        
          
        
  
        
export default TutorialDetail;
