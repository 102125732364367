import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Grid,  Paper } from '@mui/material';
import TextfieldCustom from '../../../common/TextfieldCustom';
import { fetchPrerequisiteById, updatePrerequisiteById } from '../../../redux/actions/Actions';

const UpdatePrerequisite = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userId = user?.role_id
  const fetchPrerequisitesById = useSelector((state)=> state.getPrerequisiteById.prerequisiteById)
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()
   
    useEffect(()=>{
      dispatch(fetchPrerequisiteById(id))
   },  [dispatch,id])
  // console.log('selected Prerequisite name by id is', fetchPrerequisiteById)
   
   const selectedPrerequisite = fetchPrerequisitesById[0]
   
  const [formData, setFormData] = useState({
    course_id: '',  
   /*  prerequisite_course_id: '',  */
    prerequisite_name: '',
    content:''
   
  });

  const { course_id, prerequisite_course_id,  prerequisite_name,content } = formData;  
    
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  
  const prerequisiteData = {
    course_id: formData.course_id,  
    /* prerequisite_course_id: formData.prerequisite_course_id,  */
    prerequisite_name: formData.prerequisite_name,
    content:formData.content
  }

   const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updatePrerequisiteById(prerequisiteData,id));
      navigate("/auth/allPrerequisites")
      // console.log("formdata", prerequisiteData)        
  };  
  useEffect(()=>{
    if (selectedPrerequisite) {
    setFormData({
      course_id: selectedPrerequisite?.course_id,  
      /* prerequisite_course_id: selectedPrerequisite?.prerequisite_course_id,  */
      prerequisite_name: selectedPrerequisite?.prerequisite_name,
      content:selectedPrerequisite?.content
    })
  }
   }, [selectedPrerequisite])
  
  return (
    <div>
      <form  onSubmit={handleSubmit} >
      <Container maxWidth="sm" className=" d-flex" >
    
    <Grid
      container
      spacing={2}
      direction="column"
      justifyContent="center"
      
    >
       <div style={{ width: "inherit"}}>
      <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
      <h3>Update Prerequisite</h3>
      <h6>Course name: {selectedPrerequisite?.course_id}</h6>
      <Button onClick={()=>navigate(-1)}>Back</Button>
      <Grid container  spacing={2} sx={{border: '1px solid rgba(0,0,0,0.125', zIndex:"3"}} style={{padding: "30px"}}>
          
      {/*     <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="prerequisite_course_id" 
             value={prerequisite_course_id} onChange={handleChange} placeholder="prerequisite course id"
             required
             />            
           </Grid> */}
           <Grid item xs={6}>
           <TextfieldCustom
             type="text"
             name="prerequisite_name" 
             value={prerequisite_name} onChange={handleChange} placeholder="prerequisite name"
             required
             />            
           </Grid>
           <Grid item xs={6}>
             <TextfieldCustom
             type="text"
             name="content" 
             value={content} onChange={handleChange} placeholder="content"
             required
             />            
           </Grid>
           <Grid item xs={12}>
                 <Button variant="contained" style={{backgroundColor: '#3251A3', borderColor: '#FF5E14'}} 
                 fullWidth type="submit">
                   Update & Save Prerequisite
                 </Button>
            </Grid>
      </Grid>
      </Paper>
      </div>
      </Grid>
      </Container>
    </form>      
    </div>
  )
}

export default UpdatePrerequisite