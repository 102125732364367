import { ActionTypes } from "../constants/action-types"

const initialState = {
    updateInterviewQa:[]

}

export const UpdateInterviewQa = (state=initialState, {type, payload})=>{
switch (type){
    case ActionTypes.UPDATE_INTERVIEW_QA: 
    return {...state, updateInterviewQa: payload}
    default: return state
}

}

export default UpdateInterviewQa