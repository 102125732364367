

import { Row, Col, Card } from 'react-bootstrap';
 import './Gallery.css';
import {fetchCompanyName} from '../redux/actions/Actions' ;

 import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const CompanyImageGallery = () => {


  const [imageObjects, setImageObjects] = useState([]);
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

    const fetchcompanynamexyz = useSelector((state)=>state.fetchcompanyname.logoData);
    console.log('logoData.........',fetchcompanynamexyz);

    useEffect(()=>{
      dispatch(fetchCompanyName());
    },[dispatch]);

  useEffect(() => {
    if (location.state && location.state.fetchcompanynamexyz) {
      setImageObjects(location.state.fetchcompanynamexyz);
    }
  }, [location.state]);

 console.log("xyz",fetchcompanynamexyz);

  return (
    <div className="Section">
 <h1 className="gallery-title">Placements</h1>
<div className="Section">
             <p className='Content'><b>UPL_SNIPE</b> has successfully  partnered with organizations of all sizes and across various industries  around the world.  Our ongoing work and research within these  organizations provides us with unique insights into the modern  workforce.
If your organization appears on our list of clients  served, or if you are a subsidiary or wholly-owned by an organization on  our list, please contact us. Your organization may have a licensing  agreement in place with us or be eligible for a discount.</p> 
</div>

      <div className="gallery-wrapper">

      <div className="gallery">
        {chunkArray(fetchcompanynamexyz, 5).map((row, rowIndex) => (
          <Row key={rowIndex} className="gallery-row">
            {row.map((logo, index) => (
              <Col key={index} xs={12} sm={6} md={4} lg={2} xl={2}>
                <div className='section1'>
                   <img src={logo.logo_url} alt={logo.Company_name} className="img-fluid" /> 
                </div>
              </Col>
            ))}
          </Row>
        ))}
      </div>
      </div>
    </div>
  );
};
export default CompanyImageGallery;

function chunkArray(array, chunkSize) {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunkedArray.push(array.slice(i, i + chunkSize));
  }
  return chunkedArray;

}
