import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInstructorList } from '../redux/actions/Actions';
import { Card, CardContent, Typography, Box } from '@mui/material';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const SkilledInstructorsCard = () => {
  const skilledInstructorsData = useSelector((state) => state.fetchInstructors.instructors);
  const dispatch = useDispatch();
// console.log('data',skilledInstructorsData)
  useEffect(() => {
    dispatch(fetchInstructorList());
  }, [dispatch]);
  
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div>

      <Carousel responsive={responsive} 
            autoPlay={false}
            swipeable={true}
            draggable={true}
            showDots={false}
            infinite={true}
            centerMode={true}
            partialVisible={false}
            dotListClass="custom-dot-list-style"
            removeArrowOnDeviceType={["tablet", "mobile"]}>
      {skilledInstructorsData.map((instructor) => (
      <div key={instructor.instructor_id} style={{ marginBottom: '20px', marginTop: '30px' }}>

<div style={{ textAlign: 'center' }}>
  <img
    src={`https://ap-south-1.linodeobjects.com/upl-snipe/${instructor.image_url}`}
    alt={instructor.instructor_name}
    style={{
      width: '240px', /* Set the desired width */
      height: '180px', /* Set the desired height */
      objectFit: 'cover', /* Ensure the image covers the entire space */
       borderRadius: '10%' ,
       padding: '5px',
       marginTop:"40px",
       border: '1.5px solid black', /* Add border color */
       boxShadow: '0 0 10px rgba(0, 0, 1, 0.2)' ,
       marginLeftt: '0' // Ensure no right margin

 
    }}
  />

      <div 
  key={instructor.id} 
  className="classes.card"
  style={{
    width: 280,
    height: 180,
   backgroundColor: 'lightGreen',
  //'   backgroundColor: '#9ACD32',

    margin: '5px',
    padding: '5px',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)', /* Add shadow for a lifted effect */
    transition: 'transform 0.3s ease-in-out', /* Add transition for smooth hover effect */
  }}
  onMouseEnter={(e) => { e.target.style.transform = 'scale(1.05)' }} /* Scale up on hover */
  onMouseLeave={(e) => { e.target.style.transform = 'scale(1)' }} /* Reset scale on mouse out */
> 

        <CardContent>
          <Box component="div"
          style={{
            backgroundColor: '#F5F5DC',
           color: '#000',
            padding: '1px',
            borderRadius: '15px',
            marginBottom: '2px',
            alignItems: 'center',
          }}
          >{instructor.experience}</Box>
          <Typography variant="h5" component="h2" color="brown" className="classes.title" >
            {instructor.instructor_name}
          </Typography>
          <Typography variant="body2" color="text.Secondary" component="p" className="classes.text">
            {instructor.course}
          </Typography>
          <Typography variant="h6" color="text.Secondary" component="p" className="classes.text">
            {instructor.qualification}
          </Typography>
        </CardContent>
        </div>
        </div>

      {/* </Card>  */}
      </div>
      ))}
      </Carousel>
    </div>
  ); 
};
export default SkilledInstructorsCard;
