import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const OnlineClassEnquiry = () => {
  return (
    <div style={{ margin: 0, padding: 0, fontFamily: 'Arial, sans-serif' }}>
      <div
        style={{
          position: 'fixed',
          bottom: '57%',
          right: '53%',
          padding: '20px',
          backgroundColor: '#0b346b',
          borderRadius: '15px',
        }}
      >
        <Button
          style={{
            margin: '10px',
            padding: '10px 20px',
            backgroundColor: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            display: 'block',
          }}
        ><Link to="../DemoClassRegistration" >
          Demo-Class Enquire</Link>
        </Button>
        <Button
          style={{
            margin: '10px',
            padding: '10px 20px',
            backgroundColor: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            display: 'block',
          }}
        >
          <Link to="../RemindMeclass" >Remind-Me Enquire</Link>
        </Button>
      </div>
    </div>
  );
};

export default OnlineClassEnquiry;

