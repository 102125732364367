const CloudTechnologies = () => {

  return(
    <>
      <div className="container-fluid p-0">
        <h1 className="text-center">Cloud Technologies</h1>
      </div>
    </>
  )

}

export default CloudTechnologies;