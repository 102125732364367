import React from 'react'
import { useEffect, useState } from 'react';
import axiosinstance from '../../api/axiosinstance';
import HeroPromotionContent from './HeroPromotionContent'
import HeroPromotionForm from './HeroPromotionForm'
import { useDispatch, useSelector } from 'react-redux';
import { fetchSelectedPromotionImages, fetchpromotionEventTextById,getActivePromotionEvent } from '../redux/actions/Actions';
import { fetchPromotionImages } from "../redux/actions/Actions";
import { Link } from 'react-router-dom';
import { Grid, Box, Button } from '@mui/material';
import { width } from '@mui/system';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";


const HeroPromotion = () => {
  const images = useSelector((state) =>state.selectedPromotionImage.selectedPromotionImages);
  // console.log("image data",images);
  const currentActivePromotionEvent = useSelector((state)=> state.fetchActivePromotionEvent.activePromotionEvent)
    const currenteventtext=useSelector((state)=> state.promotioneventtext.promotioneventtextData)
    // console.log('eventtext',currenteventtext)
    
    const showHeroContent = useSelector((state) => state.ToggleHeroContent.showHeroContent); // Use Redux state
    // console.log('hreo',showHeroContent); 
    const dispatch = useDispatch()
    const[activePromotion,setActivePromotion] = useState([])
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    // console.log("images of  hero promotion",images)

  useEffect(()=>{   
    dispatch(getActivePromotionEvent())
       //setActivePromotion(currentActivePromotionEvent) 
       dispatch(fetchpromotionEventTextById(1))
  }, [dispatch])
  
  useEffect(() => {
     dispatch(fetchPromotionImages());
     dispatch(fetchSelectedPromotionImages())
  }, [dispatch]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    return () => {
        clearInterval(timer);
      };
    }, [images.length]);

    
      const goToNext = (direction) => {
        if (direction === 'left') {
          setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
        } else if (direction === 'right') {
          setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }
      };

  const bgImageStyle = {
    backgroundImage: `url("${images[currentImageIndex]?.promotion_hero_images_urls}")`,
    backgroundPosition: 'center center',
     backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
  
  };

  // console.log('current promotion is', currentActivePromotionEvent)
 
  const containerClassName = `container-fluid  p-1 homeHero ${currentImageIndex !== 0 ? 'fade-transition' : ''}`;

  return (
    <div className={containerClassName} style={bgImageStyle}>
    <div className="main_container-bg"  sx={{ height: '100%', width: '100%' }}>
      <Box className='' >
        <Grid container spacing={2}>
          <Grid item lg={8} sx={{ p: 0, position: 'unset' }}>
            <Box sx={{ width: '100%', height: '100%' }}>
              {(!showHeroContent) && <HeroPromotionContent data={currenteventtext} />}
            </Box>
          </Grid>
          <Grid item lg={4} sx={{ p: 0 }} >
            <div className='hero-register-form-wrapper'>
              <HeroPromotionForm />
            </div>
          </Grid>
        </Grid>
        {/* <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button className='button animated slideInLeft' component={Link} to="/registerform" variant="contained" sx={{ py: '2', px: '3', me: '3', backgroundColor:"red !important"}}>
            Register
          </Button>
          <Button className='button animated slideInRight'  component={Link} to="/login" variant="contained" sx={{ py: '2', px: '3', backgroundColor:"blue !important" }}>
            Login
          </Button>
        </Box> */}
      </Box>
      </div>
      <div className="button-container bg-info">
      <button type="button" className="btn float-start" style={{ border:"4px solid white",padding:"4px"}} onClick={() => goToNext('left')}><ChevronLeftIcon/></button>
       <button type="button" className="btn float-end" style={{border:"4px solid white",padding:"4px"}} onClick={() => goToNext('right')}><ChevronRightIcon/></button>
       
    
    </div>
    </div>

  )
}

export default HeroPromotion