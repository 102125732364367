import React from 'react'
import { useSelector } from 'react-redux'

import WelcomeMenus from '../../WelcomeMenus';
import UploadFaqForm from './UploadFaqForm';

const AddNewFaq = () => {
    const { user } = useSelector((state) => state.auth);
    const userId = user?.role_id
    console.log('logged in as', userId)
  return (
    <div>
        {
            userId === 1 ? <UploadFaqForm />        
            : (
            <>
            <h2>Admin Only can Add Lessons</h2>
            <WelcomeMenus />
            </>
            )
        }
      
    </div>
  )
}

export default AddNewFaq