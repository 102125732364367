import { ActionTypes } from "../constants/action-types"

const initialState = {
    tutorialTechnologies:[]

}

export const FetchTutorialTechnologies = (state=initialState, {type, payload})=>{
switch (type){
    case ActionTypes.FETCH_TUTORIAL_TECHNOLOGY: 
    return {...state, tutorialTechnologies: payload}
    case ActionTypes.DELETE_TUTORIAL_TECHNOLOGY:
        return {
          ...state,
          courses: state.tutorialTechnologies.filter((item, index) => index !== payload)
        }
    default: return state
}

}