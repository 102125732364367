import {ActionTypes} from "../constants/action-types"

const initialState ={
    companyLogoStatus:[],
};

export const uploadCompanyLogo =(state =initialState,{type,payload})=>{
    switch(type){
        case 'UPLOAD_COMPANY_LOGO':
            return {...state,
            companyLogoStatus:payload};
            default:
                return state;
    }
}