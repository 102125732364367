import { ActionTypes } from "../constants/action-types"

// Initial state for the event images
const initialState = {
  imageUrl: null,
  loading: false,
  error: null,
};

// Event Images Reducer
const eventImagesReducer = (state = initialState, action) => {

  switch (action.type) {
    
    case ActionTypes.FETCH_EVENT_IMAGES_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.FETCH_EVENT_IMAGES_SUCCESS:
      const imageUrl = action.payload.imageUrl;
      console.log('URL', imageUrl);
      return {
        ...state,
        imageUrl: action.payload.imageUrl,
        loading: false,
        error: null,
      };
    case ActionTypes.FETCH_EVENT_IMAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default eventImagesReducer;
