import { ActionTypes } from "../constants/action-types";

const initialState = {
  categoriesData: [],
};

const FetchCategories = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_CATEGORIES:
      return {
        ...state,
        categoriesData: action.payload,
      };

    default:
      return state;
  }
};

export default FetchCategories;