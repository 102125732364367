import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchFaqById, updateFaqById } from "../../../redux/actions/Actions";
import { toast } from "react-toastify";

const UpdateFaq = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const faqFetchById = useSelector((state) => state.getFaqById.faqById);
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchFaqById(id));
  }, [dispatch, id]);

  const selectedFaq = faqFetchById[0];

  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    heading: "",
    details: "",
    link: "",
    linkContent: "",
    is_active: false,
  });

  const { heading, details, link, linkContent, is_active } = formData;

  const faqData = {
    heading: formData.heading,
    details: formData.details,
    link: formData.link,
    linkContent: formData.linkContent,
    is_active: formData.is_active,
  };

  useEffect(() => {
    if (selectedFaq) {
      setFormData({
        heading: selectedFaq?.heading,
        details: selectedFaq?.details,
        link: selectedFaq?.link,
        linkContent: selectedFaq?.linkContent,
        is_active: selectedFaq?.is_active,
      });
    }
  }, [selectedFaq]);

  const handleCheckboxChange = (event) => {
    const newValue = event.target.checked ? 1 : 0;
    setFormData({ ...formData, is_active: newValue });
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (inputValue.length > getMaxLength(e.target.name)) {
      setError(
        `${e.target.name} should not exceed ${getMaxLength(
          e.target.name
        )} characters & ${inputValue.length} is the length`
      );
    } else {
      setError("");
    }
  };

  const getMaxLength = (field) => {
    const maxLengths = {
      heading: 255,
      details: 1000,
    };
    return maxLengths[field] || 255;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!error) {
      dispatch(updateFaqById(faqData, id));
      navigate("/auth/allFaqs");
      toast.success("faq submitted");
    } else {
      toast.error("Form submission failed. Please try again.");
    }
  };

  return (
    <Container maxWidth="sm" sx={{ display: "flex" }}>
      <Paper elevation={2} sx={{ padding: "20px" }}>
        <form onSubmit={handleSubmit}>
          <Typography variant="h6" gutterBottom>
            Update Faq
          </Typography>
          <Button onClick={() => navigate(-1)}>Back</Button>
          <Grid
            container
            spacing={2}
            sx={{ border: "1px solid rgba(0,0,0,0.125", padding: '30px' }}
          >
            <Grid item xs={12}>
              <TextField
                type="text"
                label="Heading"
                multiline
                rows={2}
                placeholder="Enter the question"
                name="heading"
                value={heading || ""}
                onChange={(e) => handleChange(e)}
                error={Boolean(error)}
                helperText={error}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Details"
                multiline
                rows={3}
                placeholder="Enter the description"
                name="details"
                value={details || ""}
                onChange={(e) => handleChange(e)}
                error={Boolean(error)}
                helperText={error}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Link"
                name="link"
                placeholder="Enter the Link"
                value={link || ""}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="LinkContent"
                name="linkContent"
                value={linkContent || ""}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(is_active)}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="isActive"
                  name="is_active"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                primary
                fullWidth
                type="submit"
              >
                Update & Save Faq
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default UpdateFaq;
