import { ActionTypes } from "../constants/action-types";

const initialState = {
    events: []
}

export const FetchEvents = (state = initialState, {type, payload}) => {
    switch (type) {
        case ActionTypes.FETCH_EVENTS:
            return {...state,events:payload}
        case ActionTypes.DELETE_EVENT:
            return {
              ...state,
              events: state.events.filter((item, index) => index !== payload)
                }
        default:
            return state;
    }
};


