
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import TopbarCp from '../common/auth/TopbarCp';
import SideBarCp from '../common/auth/SideBarCp';
import { useLocation } from 'react-router-dom';
import AdminDashBoard from './admin/AdminDashBoard';
import { useSelector } from 'react-redux';
import React,{useState,useEffect}from 'react';
import ExecutorDashboard from './salesExecutors/ExecutorDashboard';
import { Grid } from '@mui/material';

const drawerWidth = 305;

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== 'open',
// })(({ theme, open }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   transition: theme.transitions.create(['width', 'margin'], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(['width', 'margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: open ? drawerWidth :0, // Adjusted marginLeft when the drawer is open
  
  // ...(open && {
  //   width: `calc(100% - ${drawerWidth}px)`,
  //   transition: theme.transitions.create(['width', 'margin'], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // }),

  ...(open
    ? {
      width: `calc(100% - ${drawerWidth}px)`,
      zIndex: theme.zIndex.drawer -1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }
  : {
      width: `calc(100% - 50px)`, // Default width when the drawer is closed
      zIndex: theme.zIndex.drawer -1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    })
}));
export default function Dashboard() {
  const location = useLocation()
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const initialShowTopBar = localStorage.getItem('showTopBar') !== 'false';
  const [showTopBar, setShowTopBar] = useState(initialShowTopBar);

  useEffect(() => {
    localStorage.setItem('showTopBar', showTopBar); 
  }, [showTopBar]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const{isAuthenticated,user}=useSelector((state)=>state.auth);
  const userId=user?.role_id;
  const [selectedMenuItem, setSelectedMenuItem] = useState("");
  const [data, setData] = useState([]);
  
  const handleSelectMenuItem = (menu) => {
    setSelectedMenuItem(menu);
  };
//console.log("dashboard",selectedMenuItem);

  return (
    <Box  className='top-side-wrapper'>
      <CssBaseline />
      {showTopBar && (
      <AppBar position="fixed" open={open} className='topbar-cp-wrapper'sx={{
        
      }} >
        <Toolbar className='topbar-cp-toolbar'>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
          
              ...(open && { display: 'none' }),
            }}
          >
            
            {theme.direction === 'rtl' ? <ChevronRightIcon className='text-dark' /> : <ChevronLeftIcon className='text-dark' />}
          </IconButton>
          <TopbarCp  selectedMenuItem={selectedMenuItem} />
        </Toolbar>
      </AppBar>
      )}
      <SideBarCp open={open} handleDrawerClose={handleDrawerClose} theme={theme} onSelectMenuItem={handleSelectMenuItem} />
      <Grid container spacing={2} className='container dashboard'  >
      <Grid item xs={12}>
      {
    (location.pathname === "/AdminCp") ? 
        (userId === 1) ? <AdminDashBoard /> : ""
    : (location.pathname === "/ExecutorCp") && (userId === 3) ? 
        <ExecutorDashboard /> 
    : ""
}
</Grid>
</Grid>
    </Box>
  );
}