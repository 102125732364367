import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { getRoles, getUserById, getVisitorById, updateUserById } from '../../../redux/actions/Actions';
import TextfieldCustom from '../../../common/TextfieldCustom';
import FormValidator from '../../../common/FormValidator'; // Import the FormValidator component


const statusList = ['Registered', 'Indiscussion', 'Joined', 'Rejected', 'Staff']

const UpdateUserForm = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    phone_number: '',
  });

  const [userStatus, setUserStatus] = useState('');
  const [isValid, setIsValid] = useState(false); // State to track form validity
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedUser = useSelector((state) => state.getUsersById.userById[0]);

  useEffect(() => {
    dispatch(getUserById(id));
    dispatch(getRoles());
  }, [dispatch, id]);

  useEffect(() => {
    setFormData({
      username: selectedUser?.username || '',
      email: selectedUser?.email || '',
      phone_number: selectedUser?.phone_number || '',
    });
    setUserStatus(selectedUser?.status || '');
  }, [selectedUser]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleStatusChange = (e) => {
    setUserStatus(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid) { // Check if all validations are cleared
      const updatedData = {
        username: formData.username,
        email: formData.email,
        phone_number: formData.phone_number,
        status: userStatus,
      };
      dispatch(updateUserById(updatedData, id));
      navigate("/auth/AdminDashboard");
      console.log("formdata", updatedData);
    } else {
      console.log("Form validation failed. Cannot submit.");
    }
  };

  useEffect(() => {
    const validateForm = () => {
      // Validation logic
      // You can add more validations here if needed
      const validUsername = /^[A-Za-z0-9@#$%^&_*]+$/.test(formData.username);
      const validEmail = /^\S+@\S+\.\S+$/.test(formData.email);
      const validPhoneNumber = /^[0-9]+$/.test(formData.phone_number) && formData.phone_number.length === 10;
      setIsValid(validUsername && validEmail && validPhoneNumber); // Update isValid state based on validations
    };
    validateForm();
  }, [formData]);
  
    return (
      <div>
      <form onSubmit={handleSubmit}>
        <Container maxWidth="sm" className=" d-flex" >
      
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        
      >
         <div style={{ width: "inherit"}}>
        <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
        <h3>Update User Info</h3>
        <Button onClick={()=>navigate(-1)}>Back</Button>
        <Grid container  spacing={2} sx={{border: '1px solid rgba(0,0,0,0.125', zIndex:"3"}} style={{padding: "30px"}}>
        <Grid item xs={12}>
                    <TextfieldCustom
                      type="text"
                      name="username" 
                      value={formData.username} 
                      onChange={handleChange} 
                      placeholder="Username"
                     
                    />
                    <FormValidator name="username" value={formData.username} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextfieldCustom
                      type="email"
                      name="email" 
                      value={formData.email} 
                      onChange={handleChange} 
                      placeholder="Email"
                     
                    />
                    <FormValidator name="email" value={formData.email} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextfieldCustom
                      type="tel"
                      name="phone_number" 
                      value={formData.phone_number} 
                      onChange={handleChange} 
                      placeholder="Phone Number"
                    
                    />
                    <FormValidator name="phone_number" value={formData.phone_number} />
                  </Grid>
             
             <Grid item xs={12}>
             <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userStatus}
                  defaultValue={userStatus}
                  label="Status"
                  onChange={handleStatusChange}
                  >
                      {
                          statusList.map((eachStatus,i)=>(
                              <MenuItem key={i} value={eachStatus}>{eachStatus}</MenuItem>
                          ))
                      }
                  </Select>
              </FormControl>        
             </Grid>
             <Grid item xs={12}>
                   <Button variant="contained" style={{backgroundColor: '#3251A3', borderColor: '#FF5E14'}} 
                   fullWidth type="submit">
                     Update User
                   </Button>
                 </Grid>
        </Grid>
        </Paper>
        </div>
        </Grid>
        </Container>
      </form>
      </div>
    );
}

export default UpdateUserForm 
