import { ActionTypes } from "../constants/action-types";

const initialState = {
    deletedOnlineClass: null, // Assuming you want to keep track of the deleted online class
    error: null // Optional: to handle errors during the deletion process
};

export const deleteOnlineClass = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.DELETE_ONLINECLASS:
            return {
                ...state,
                deletedOnlineClass: payload, // Assuming the payload contains information about the deleted online class
                error: null // Reset error state upon successful deletion
            };
        case ActionTypes.DELETE_ONLINECLASS_ERROR:
            return {
                ...state,
                error: payload // Update error state in case of deletion error
            };
        default:
            return state;
    }
};
