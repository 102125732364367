
import { ActionTypes } from '../constants/action-types';

const initialState = {
  eventsAd: [],
  
};

const FetchEventsAd = (state = initialState, {type, payload}) => {
  switch (type) {
    case ActionTypes.FETCH_EVENT_AD:
        return { ...state,eventsAd:payload};

    default:
      return state;
  }
};

export default FetchEventsAd;
