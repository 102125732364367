import React from 'react'
import { useSelector } from 'react-redux';
import WelcomeMenus from '../../WelcomeMenus';
import AdminMenus from '../AdminMenus';
import CourseList from './CourseList';

const AllCourses = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
    const userId = user?.role_id
    console.log('logged in as',userId)

  return (
      <div className="container-xxl ">
              { userId===1?(isAuthenticated ?

    <div className="container">
    <div className='btn-group' role='group' aria-label='Button group'>
            <AdminMenus buttonName='Add Course' pageName='addNewCourse' />
    </div>
          <div className='row dataTable pt-3'>
          <CourseList />
          </div>
          </div>:<WelcomeMenus/>  ): userId===3?(isAuthenticated ? 
        <div className="container">
  {/* <div className='btn-group' role='group' aria-label='Button group'>
      <AdminMenus buttonName='Add Course' pageName='addNewCourse' />
      </div> */}
          <div className='row dataTable pt-3'>
          <CourseList />
          </div>
      </div>:<WelcomeMenus/>  
      ):(<WelcomeMenus/>)
      
      
      }
      </div>

  );
}

export default AllCourses