import { ActionTypes } from "../constants/action-types"

const initialState = {
    uploadNewTutorialCategory:[]

}

export const UploadTutorialCategory = (state=initialState, {type, payload})=>{
switch (type){
    case ActionTypes.UPLOAD_TUTORIAL_CATEGORY: 
    return {...state, uploadNewTutorialCategory: payload}
    default: return state
}

}