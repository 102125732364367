import axiosinstance from '../../../api/axiosinstance'
import { toast } from "react-toastify"
// authActions.js

import axios from 'axios';
import {
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  FETCH_CHANNEL_PARTNERS_SUCCESS,
  FETCH_CHANNEL_PARTNERS_FAILURE,
  APPROVE_REQUEST_SUCCESS,
  APPROVE_REQUEST_FAILURE,
  REJECT_REQUEST_SUCCESS,
  REJECT_REQUEST_FAILURE,
  FETCH_PENDING_REQUESTS_SUCCESS,
  FETCH_PENDING_REQUESTS_FAILURE,
  UPLOAD_EVENT_IMG
} from './authTypes';
import securedInstance from '../../../api/securedInstance';

// Register user
export const register = (userData) => async (dispatch) => {
  
  const headers = {
    'Content-Type': 'application/json',
    };
  try {
    const res = await axiosinstance.post('/api/auth/register', userData,{headers});
  dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    //console.log('Registered As',res.config.data);
    toast.success("You have Registered Successfully")
  } catch (error) {
    toast.error(error.response.data.error + " "+ 'Please TRY again')
    dispatch({
      type: REGISTER_FAILURE,
      payload: error.response.data.error,
    });
  }
};

// Login user
export const login = (userData) => async (dispatch) => {
   try {
    const res = await securedInstance.post('/api/auth/login', userData);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    // console.log('Logged in As',res.data);
    toast.success("You have successfully LoggedIn")
  } catch (error) {
    console.log('Message',error.response.data)
    toast.error(error.response.data.error)
    dispatch({
      type: LOGIN_FAILURE,
      payload: error.response.data.error,
    });
  }
};

// Logout user
export const logout = () => {
  return {
    type: LOGOUT,
  };
};


// export const fetchPendingRequestsSuccess = (data) => ({
//   type: FETCH_PENDING_REQUESTS_SUCCESS,
//   payload: data,
// });

// export const fetchPendingRequestsFailure = (error) => ({
//   type: FETCH_PENDING_REQUESTS_FAILURE,
//   payload: error,
// });

// export const fetchPendingRequests = () => async (dispatch) => {
//   try {
//     const response = await fetch('http://localhost:8000/api/channelPartner'); 
//     if (response.ok) {
//       const data = await response.json();
//       const pendingRequests = data.filter((request) => request.status === 0);
//       dispatch(fetchPendingRequestsSuccess(pendingRequests));
//     } else {
//       dispatch(fetchPendingRequestsFailure('Error fetching pending registration requests'));
//     }
//   } catch (error) {
//     dispatch(fetchPendingRequestsFailure('Error fetching pending registration requests'));
//   }
// };

//Fetching cp
export const fetchChannelPartnersSuccess = (data) => ({
  type: FETCH_CHANNEL_PARTNERS_SUCCESS,
  payload: data,
});

export const fetchChannelPartnersFailure = (error) => ({
  type: FETCH_CHANNEL_PARTNERS_FAILURE,
  payload: error,
});

export const fetchChannelPartners = () => async (dispatch) => {
  try {
    const response = await fetch('http://localhost:8000/api/channelPartner');
    if (response.ok) {
      const data = await response.json();
      dispatch(fetchChannelPartnersSuccess(data));
    } else {
      dispatch(fetchChannelPartnersFailure('Error fetching Channel Partners'));
    }
  } catch (error) {
    dispatch(fetchChannelPartnersFailure('Error fetching Channel Partners'));
  }
};

export const approveRequestSuccess = (message) => ({
  type: APPROVE_REQUEST_SUCCESS,
  payload: message,
});

export const approveRequestFailure = (error) => ({
  type: APPROVE_REQUEST_FAILURE,
  payload: error,
});

export const rejectRequestSuccess = (message) => ({
  type: REJECT_REQUEST_SUCCESS,
  payload: message,
});

export const rejectRequestFailure = (error) => ({
  type: REJECT_REQUEST_FAILURE,
  payload: error,
});

export const approveRequest = (cpId) => async (dispatch) => {
  try {
    const response = await fetch(`http://localhost:8000/api/CPauth/approveRequest/${cpId}`, {
      method: 'PUT',
    });
    if (response.ok) {
      dispatch(approveRequestSuccess('Request approved successfully.'));
      // You can dispatch fetchChannelPartners here if you want to update the list.
    } else {
      const error = await response.json();
      dispatch(approveRequestFailure(error));
    }
  } catch (error) {
    dispatch(approveRequestFailure('An error occurred while approving the request.'));
  }
};

export const rejectRequest = (cpId) => async (dispatch) => {
  try {
    const response = await fetch(`http://localhost:8000/api/CPauth/rejectRequest/${cpId}`, {
      method: 'DELETE',
    });
    if (response.ok) {
      dispatch(rejectRequestSuccess('Request rejected successfully.'));
      // You can dispatch fetchChannelPartners here if you want to update the list.
    } else {
      const error = await response.json();
      dispatch(rejectRequestFailure(error));
    }
  } catch (error) {
    dispatch(rejectRequestFailure('An error occurred while rejecting the request.'));
  }
};


export const fetchPendingRequests = () => async (dispatch) => {
  try {
    const response = await fetch('http://localhost:8000/api/channelPartner');
    if (response.ok) {
      const data = await response.json();
      dispatch({
        type: FETCH_PENDING_REQUESTS_SUCCESS,
        payload: data.filter((request) => request.status === 0),
      });
    } else {
      dispatch({
        type: FETCH_PENDING_REQUESTS_FAILURE,
        error: 'Error fetching pending registration requests',
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_PENDING_REQUESTS_FAILURE,
      error: 'Error fetching pending registration requests',
    });
  }
};

