import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from '@mui/x-data-grid';
import Papa from 'papaparse';
import { GetApp, Print } from '@mui/icons-material';
import { Edit, Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getVisitorByStatus, getVisitors } from '../../../redux/actions/Actions';
import saveAs from 'file-saver'; 

const statusList = ['Registered', 'Pending', 'Completed'];

const Visitors = (props) => {
  const fetchVisitors = useSelector((state) => state.getAllVisitors.visitors);
  const fetchVisitorsByStatus = useSelector((state) => state.getVisitorsByStatus.visitorByStatus);
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [userStatus, setUserStatus] = useState('');
  const [data, setData] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const userId = user?.role_id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function formatDate(dt) {
    const getYear = new Date(dt).getFullYear()
    const getMonth = new Date(dt).getMonth() + 1
    const getMyDay = new Date(dt).getDate()
    const dateFormat = getMyDay + "/" + getMonth + "/" + getYear
    return dateFormat;
  }

  const handleStatusChange = (e) => {
    setUserStatus(e.target.value)
  }

  const columns = [
    { field: 'id', headerName: 'Id', width: 60, disableColumnMenu: true, sortable: false },
    { field: 'role', headerName: 'Role', width: 60, sortable: false, disableColumnMenu: true },
    { field: 'email', headerName: 'Email', width: 160, disableColumnMenu: true },
    { field: 'phone_number', headerName: 'Phone Number', width: 160, disableColumnMenu: true },
    { field: 'program_name', headerName: 'Program Name', width: 160, disableColumnMenu: true },
    { field: 'status', headerName: 'Status', width: 140, disableColumnMenu: true, sortable: false },
    { field: 'date', headerName: 'Date', width: 140, disableColumnMenu: true },
    {
      field: 'action',
      headerName: 'Actions',
      width: 180,
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: (params) => {
        const onClick = (e) => {
          const currentRow = params.row;
          navigate(`/auth/updateVisitor/${currentRow.user_id}`)
        };

        return (
          <Stack direction="row" spacing={2}>
            <IconButton onClick={onClick}>
              <Edit />
            </IconButton>
          </Stack>
        );
      },
    }
  ];
  const rows = data?.map((row, index) => {
    return {
      ...row,
      id: index + 1,
      role: row.role_name,
      email: row.email,
      phone_number: row.phone_number,
      program_name: row.subprogram_name,
      status: row.status,
      date: formatDate(row.modify_date)

    }
  });

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = dataRows.filter((row) => {
    if (!row || !row.email || !row.phone_number || !row.role) {
      return false; 
    }
    const searchValue = searchQuery.toLowerCase();
    return (
      row.email.toLowerCase().includes(searchValue) ||
      row.phone_number.toLowerCase().includes(searchValue) ||
      row.role.toLowerCase().includes(searchValue)
    );
  });

  // Function to export CSV
  const handleExportCSV = () => {
    // Convert your filtered data to CSV format
    const csvData = filteredData.map((row) => ({
      Id: row.id,
      Role: row.role,
      Email: row.email,
      PhoneNumber: row.phone_number,
      ProgramName: row.program_name,
      Status: row.status,
      Date: row.date,
    }));

    // Create a Blob containing the CSV data
    const csvText = Papa.unparse(csvData); // Ensure you import Papa from 'papaparse'
    const blob = new Blob([csvText], { type: 'text/csv;charset=utf-8' });

    // Save the Blob as a file
    saveAs(blob, 'visitors.csv');
  };

  function NoRowsOverlay() {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No rows in DataGrid
        <pre>(rows=&#123;[]&#125;)</pre>
      </Stack>
    );
  }

  function NoResultsOverlay() {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No results in DataGrid
        <pre>(rows=&#123;rowData&#125;)</pre>
        But local filter returns no result
      </Stack>
    );
  }

  useEffect(() => {
    setUserStatus(props.status);
    dispatch(getVisitorByStatus(userStatus));
    dispatch(getVisitors());
  }, [dispatch, fetchVisitors]);

  useEffect(() => {
    if (userStatus === 'all') {
      setData(fetchVisitors);
    } else {
      setData(fetchVisitorsByStatus);
    }
    setDataColumns(columns);
    setDataRows(rows);
  }, [userStatus, fetchVisitors]);

  return (
    <>
     {userId === 3 ? (
        <>
          <div style={{ display: 'flex', alignItems: 'center',justifyContent: 'space-between'}}>
            <TextField
              label="Search"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ width: '300px' }}
            />
            <IconButton
              onClick={handleExportCSV}
              style={{ color: '#007aff' }}
            >
              <GetApp />
            </IconButton>
          </div>
          <DataGrid
            rows={dataRows}
            columns={dataColumns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
          />
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default Visitors;
