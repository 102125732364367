import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Paper, TextField, Button, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setEmailCode } from '../redux/actions/Actions';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const dispatch = useDispatch();


  const handleGetVerificationCode = async () => {
    setError(null);
    if (!validateEmail(email)) {
      setError('Please enter a valid email.');
      return;
    }

    try {
      const response = await dispatch(setEmailCode(email));
      
      if (response && response.status >= 400) {
        setError(response.error.message || 'An error occurred.');
      } else {
        navigate('/verificationcode', { state: { email } });
      }
    } catch (error) {
      console.error('Error occurred while dispatching setEmailCode:', error);
      setError('An error occurred.'); // Set a generic error message
    }
    
    
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  return (
    <div className="row m-0 align-items-center justify-content-center">
      <div className="col-lg-5">{/* Your image */}</div>
      <div className="col-lg-7">
        <Container maxWidth="sm" className="d-flex">
          <Grid container spacing={2} direction="column" justifyContent="center">
            <div style={{ width: 'inherit' }}>
              <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
                <Grid
                  container
                  spacing={2}
                  sx={{ border: '1px solid rgba(0,0,0,0.125', zIndex: '3' }}
                  style={{ padding: '30px' }}
                >
                  <Grid item xs={12}>
                    <TextField
                      type="email"
                      name="email"
                      // required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter Email"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: '#3251A3', borderColor: '#FF5E14' }}
                      fullWidth
                      onClick={handleGetVerificationCode}
                    >
                      Get Verification Code
                    </Button>
                  </Grid>
                  {error && (
                    <Grid item xs={12}>
                      <Alert severity="error">{error}</Alert>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </div>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default ForgotPassword;