import { ActionTypes } from "../constants/action-types";

const initialState = {
  processperticulardata: [],
};

export const ProcessListprogramIdandcourseIdReducer = (state = initialState, action) => {
  switch (action.type) {

    case ActionTypes.FETCH_PROCESS_LIST_BY_PROGRAMID_COURSEID_SUCCESS:
      return {
        ...state,
        processperticulardata: action.payload,
        
        
      };
  
    default:
      return state;
  }
};
