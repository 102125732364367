import { ActionTypes } from "../constants/action-types";

const initialState = {
  promotionImages: [],
};

export const promotionImagesReducer = (state = initialState, action) => {
  switch (action.type) {
    // case ActionTypes.FETCH_PROMOTION_IMAGES_START:
    // // case ActionTypes.UPLOAD_PROMOTION_IMAGE_START:
    //   return {
    //     ...state,
    //     loading: true,
    //     error: null,
    //   };

    case ActionTypes.FETCH_PROMOTION_IMAGES_SUCCESS:
      return {
        ...state,
        promotionImages: action.payload,
        
        
      };
      // console.log(promotionImages)
// 
    // case ActionTypes.UPLOAD_PROMOTION_IMAGE_SUCCESS:
    //   // You might want to update the state with the newly uploaded image details
    //   return {
    //     ...state,
    //     promotionImages: [...state.promotionImages, action.payload],
    //     loading: false,
    //   };

    // case ActionTypes.FETCH_PROMOTION_IMAGES_FAILURE:
    // // case ActionTypes.UPLOAD_PROMOTION_IMAGE_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };

    // // Add other cases for different action types as needed

    default:
      return state;
  }
};
// export default promotionImagesReducer;