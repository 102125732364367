import { ActionTypes } from "../constants/action-types";

const initialState = {
  materialById: []
};

export const FetchMaterialById = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_MATERIALS_BY_ID:
      return { ...state, materialById: action.payload };
    default:
      return state;
  }
};

