
import { ActionTypes } from "../constants/action-types"

const initialState = {
    ProjectImageByproject_name: []
}

const FetchProjectImageByprojectname = (state=initialState, {type, payload})=>{
    switch(type){
        case ActionTypes.FETCH_PROJECT_IMAGE_BY_PROJECT_NAME : return {...state, 
            ProjectImageByproject_name: payload}           
        default: return state;
    }
    
}


export default FetchProjectImageByprojectname;