import { ActionTypes } from "../constants/action-types";


const initialState={
    bellNotification:[]
}

export const getBellNotificationDataBYNoReducer=(state=initialState,action)=>{
    switch(action.type)
    {
        case ActionTypes.FETCH_BELL_NOTIFICATION:
        return  {
                ...state,
                bellNotification:action.payload,
            }
  
  
       default:return state;  
  
        }
}