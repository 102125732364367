import { ActionTypes } from "../constants/action-types"

const initialState = {
    updateLesson:[]

}

export const UpdateLessonReducer = (state=initialState, {type, payload})=>{
switch (type){
    case ActionTypes.EDIT_LESSON: 
    return {...state, updateLesson: payload}
    default: return state
}

}