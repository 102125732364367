import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../redux/auth/authActions";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  fetchProgramById,
  fetchProgramId,
  fetchProgramList,
  getRoles,
  fetchSubProgramId,
  sendEmailNotification,
  getUserById,
  findUserByEmail,
  registerSubsciber,
} from "../../redux/actions/Actions";
import PopUpModal from "../../common/PopUpModal";
import TextfieldCustom from "../../common/TextfieldCustom";
import axios from "axios";
import FormValidator from "../../common/FormValidator";
import JoinImg from "../images/Join.jpg";

const OBRegister = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const fetchUsersById = useSelector((state) => state.getUsersById.userById);
  const allRole = useSelector((state) => state.getAllRoles.roles);

  const fetchUserByEmail = useSelector(
    (state) => state.getUsersByEmail.userByEmail
  );
  const [isRegister, setIsRegister] = useState(false);
  const [lastRegisterUser, setLastRegisterUser] = useState("");

  const [roleId, setRoleId] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [item, setItem] = useState([]);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    email: "",
  });

  const { username, password, email } = formData;
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    email: "",
  });

  const getSubprogramId = useSelector(
    (state) => state.fetchSubProgramId.subprogramId
  ); /* subprogram dropdownlist as per program id */
  //console.log("Redux State:", fetchSubProgramId)

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const handleRegister = async () => {
        try {
          await axios.post('http://localhost:8000/api/register', { username, email, password });
          console.log('Registration successful');
        } catch (error) {
          console.error('Error registering user: ' + error.message);
        }
      };
    
  const handleChange = (e) => {
    const { name, value } = e.target;
    const newErrors = { ...errors };
    delete newErrors[name];

    setErrors(newErrors);
    setFormData({ ...formData, [name]: value });
  };

  const emailData = {
    emailId: lastRegisterUser,
  };
  const emailData2 = {
    userId: fetchUserByEmail[0]?.user_id,
    emailId: fetchUserByEmail[0]?.email,
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the form fields
    const validationErrors = {
      username: FormValidator({ name: "username", value: username, errors }),
      password: FormValidator({ name: "password", value: password, errors }),
      email: FormValidator({ name: "email", value: email, errors }),
    };

    // Check if there are any validation errors
    const isFormValid = Object.values(validationErrors).every(
      (error) => !error
    );

    if (isFormValid) {
      // Reset form fields and errors
      setFormData({
        username: "",
        password: "",
        email: "",
      });

      setErrors({
        username: "",
        password: "",
        email: "",
      });

      // Navigate to the next page
      navigate("/cpregister");
    } else {
      // If there are validation errors, update the errors state
      setErrors(validationErrors);
    }
  };

  const handleRoleChange = (e) => {
    //console.log('roleId', e.target.value)
    setRoleName(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  //console.log('role id is', roleName)
  useEffect(() => {
    dispatch(getRoles());
    setRoleId(allRole);
  }, [dispatch, allRole]);

  useEffect(() => {
    dispatch(fetchProgramList());
  }, [dispatch]);

  useEffect(() => {
    if (formData !== "") {
      //setLastRegisterUser(formData.email)
      console.log("email data", emailData);
      console.log("email data with user id", emailData2);
      console.log("user by email", fetchUserByEmail);
      if (isRegister) {
        console.log("clicked register", isRegister);
        setTimeout(() => {
          dispatch(findUserByEmail(lastRegisterUser));
          console.log("email data isregister true", emailData2);
          console.log("user by email", fetchUserByEmail);
          dispatch(sendEmailNotification(emailData));
        }, 0);
      } else {
        console.log("else,initial", isRegister);
      }
    }

    return () => {
      setIsRegister(false);
    };
  }, []);

  return (
    <>
      <div className="row m-0 align-items-center justify-content-center">
        <div className="col-lg-6 ">
          <img
            src={JoinImg}
            alt="register page"
            className="img-fluid"
            style={{ marginLeft: "15rem" }}
          />
        </div>
        <div className="col-lg-6">
          <form onSubmit={handleSubmit}>
            <Container maxWidth="sm" className=" d-flex p-0">
              <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="center"
              >
                <div style={{ width: "inherit" }}>
                  <Paper
                    elevation={2}
                    sx={{ padding: 5 }}
                    style={{
                      backgroundColor: "rgb(217, 254, 255)",
                      marginTop: "50px",
                    }}
                  >
                    <h3 id="reg-heading">
                      <b>Register for Onboarding</b>
                    </h3>
                    <Grid
                      container
                      spacing={2}
                      sx={{ border: "1px solid rgba(0,0,0,0.125", zIndex: "3" }}
                      style={{ padding: "30px" }}
                    >
                      <Grid item xs={12} md={6}>
                        <TextfieldCustom
                          type="text"
                          name="username"
                          value={username}
                          onChange={handleChange}
                          placeholder="Username"
                        
                        />

                        {errors.username && (
                          <div
                            className="error"
                            style={{
                              color: "red",
                              fontSize: "12px",
                              padding: "4px",
                            }}
                          >
                            {errors.username}
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextfieldCustom
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={password}
                          onChange={handleChange}
                          placeholder="Password"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  style={{
                                    backgroundColor: "#D5FFD0",
                                    color: "black",
                                  }}
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {errors.password && (
                          <div
                            className="error"
                            style={{
                              color: "red",
                              fontSize: "12px",
                              padding: "4px",
                            }}
                          >
                            {errors.password}
                          </div>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <TextfieldCustom
                          // type="email"
                          name="email"
                          value={email}
                          onChange={handleChange}
                          placeholder="Email"
                        />
                        {errors.email && (
                          <div
                            className="error"
                            style={{
                              color: "red",
                              fontSize: "12px",
                              padding: "4px",
                            }}
                          >
                            {errors.email}
                          </div>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "#3251A3",
                            borderColor: "#FF5E14",
                          }}
                          fullWidth
                          type="submit"
                          onClick={handleRegister}
                        >
                          Register
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => navigate("/login")}
                        >
                          Login If you have account
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              </Grid>
            </Container>
          </form>
        </div>
      </div>
    </>
  );
};

export default OBRegister;
// import React, { useState } from 'react';
// import axios from 'axios';

// const OBRegister = () => {
//   const [username, setUsername] = useState('');
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');

//   const handleRegister = async () => {
//     try {
//       await axios.post('http://localhost:3001/register', { username, email, password });
//       console.log('Registration successful');
//     } catch (error) {
//       console.error('Error registering user: ' + error.message);
//     }
//   };

//   return (
//     <div>
//       <h1>Register</h1>
//       <form>
//         <label>
//           Username:
//           <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
//         </label>
//         <br />
//         <label>
//           Email:
//           <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
//         </label>
//         <br />
//         <label>
//           Password:
//           <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
//         </label>
//         <br />
//         <button type="button" onClick={handleRegister}>
//           Register
//         </button>
//       </form>
//     </div>
//   );
// }

// export default OBRegister;
