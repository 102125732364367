import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProcessListByprogramIdANDCourseId } from "../redux/actions/Actions";

const ProcessListCommon = (props) => {
  const processlistByID = useSelector((state) => state.processlistIDdata.processperticulardata);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProcessListByprogramIdANDCourseId(props.programdata, props.processdata?.course_id));
    console.log(props.programdata, props.processdata?.course_id)
  }, [dispatch]); // Empty dependency array ensures the effect runs only once when the component mounts

   console.log("process>>>>>>>>>>>", processlistByID);
  
  // console.log("123454646446>>>>>>>>>", processlistByID?.process_list);

  // const processList = processlistByID.results[0]?.process_list || [];
  const processList = processlistByID&& processlistByID.length > 0
  ? JSON.parse(processlistByID[0].process_list)
  : [];


  console.log("process list:", processList);

  return (
    <div className='process-list'>
    <h3>Process List</h3>
    <div className="row pre-req-bg justify-content-center">
      {processList.map((list, index) => (
        <ul key={index} id={index}>
          <li>&#x2022; {list}</li>
        </ul>
      ))}
    </div>
  </div>
  );
};

export default ProcessListCommon;
