import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FormValidator from "../common/FormValidator"; // Import the FormValidator component
import { register } from "../redux/auth/authActions";

import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";

import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PopUpModal from "../common/PopUpModal";
import TextfieldCustom from "../common/TextfieldCustom";
import {
  fetchProgramById,
  fetchProgramId,
  fetchProgramList,
  getProgramCourseCombo,
  registerSubsciber,
  fetchSubProgramId,
  uploadbellNofifications,

} from "../redux/actions/Actions";

const HeroPromotionForm = () => {
  const fetchPrograms = useSelector(
    (state) => state.fetchAllPrograms.programs
  ); /* programs dropdown */
  const fetchProgramsById = useSelector(
    (state) => state.fectchProgramById.programById
  ); /* courses dropdownlist as per program id */
  const programCourseId = useSelector(
    (state) => state.programCourseCombo.programCourseCombination
  ); /* program course combo  */
  const subprogramId = useSelector((state) => state.fetchSubProgramId.subprogramId); /* subprogram dropdownlist as per program id */
  // console.log("Redux State:", fetchSubProgramId)
  const [item, setItem] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phone_number: "",
    program_id: "",
    course_id: "",
    role_id: "",
  });

  const { username, email, phone_number } = formData;
  const [programId, setProgramId] = useState("");
  const [courseId, setCourseId] = useState("");
  const [subProgramId, setSubProgramId] = useState("");

  const [errors, setErrors] = useState({
    username: "",
    email: "",
    phone_number: "",
    // Add more fields here if needed
  });

  const handleProgramChange = (e) => {
    setProgramId(e.target.value);
    dispatch(fetchProgramById(e.target.value));
    dispatch(fetchSubProgramId(e.target.value));
    dispatch(fetchProgramId(e.target.value));
  };

  const handleCourseChange = (e) => {
    setCourseId(e.target.value);
  };
  const handleSubProgramChange = (e) => {
    setSubProgramId(e.target.value);
  };

  useEffect(() => {
    setItem(subprogramId);
  }, [item, subprogramId]);

  //pop up modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    if (formData !== '' && programId !== '' && courseId !== '') {
      setOpen(true);
      
    }
  };
  const handleClose = () => setOpen(false);


  const handleChange = (e) => {
    const { name, value } = e.target;
    const newErrors = { ...errors };
    delete newErrors[name];

    setErrors(newErrors);
    setFormData({ ...formData, [name]: value });
  };



  


  const registerData = {
    subscriber_name: formData.username,
    email: formData.email,
    phone_number: formData.phone_number,
    subprogram_id: subProgramId,
    // program_course_id: programCourseId[0]?.program_course_id,
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the form fields
    const validationErrors = {
      username: FormValidator({ name: "username", value: username, errors }),
      email: FormValidator({ name: "email", value: email, errors }),
      phone_number: FormValidator({ name: "phone_number", value: phone_number, errors }),
      programId: FormValidator({ name: "programId", value: programId, errors }),
      subProgramId: FormValidator({ name: "subProgramId", value: subProgramId, errors }),
    };


    // Check if there are any validation errors
    const isFormValid = Object.values(validationErrors).every((error) => !error);

    if (isFormValid) {
      // If the form is valid, proceed with form submission
      const registerData = {
        subscriber_name: formData.username,
        username:formData.username,

        email: formData.email,
        phone_number: formData.phone_number,
        subprogram_id: subProgramId,
        programId:programId,
        role_id:2,
         status:'Registered',
        password:'UplSnipe@2024',   //by default set the password for  a time being 

        // program_course_id: programCourseId[0]?.program_course_id,
      };

      if (subprogramId !== "") {
        dispatch(registerSubsciber(registerData));
        dispatch(register(registerData));
        dispatch(uploadbellNofifications({registerData,"number":1}));


      }

      // Reset form fields and errors
      setFormData({
        username: "",
        email: "",
        phone_number: "",
      });
      setProgramId("");
      setSubProgramId("");
      setCourseId("");
      setErrors({
        username: "",
        email: "",
        phone_number: "",
        programId: "",
        subProgramId: "",
      });
    } else {
      // If there are validation errors, update the errors state
      setErrors(validationErrors);
    }
  };

  useEffect(() => {
    if (formData !== "") {
      dispatch(
        getProgramCourseCombo({ program_id: programId, course_id: courseId })
      );
    }
  }, [formData, programId, courseId]);

  useEffect(() => {
    dispatch(fetchProgramList());
  }, [dispatch]);
  // console.log("items",item)
  return (
    <>
   <div className="border  animated slideInRight">
      <form onSubmit={handleSubmit} className="reg-form animated slideInRight">
        <Paper elevation={2} className="p-3">
        <h4 style={{display:"inline-block",textAlignLast:"left"}}><strong>REGISTER FOR LATEST PROGRAMS</strong></h4>

          <Grid
            container
            spacing={3}
            sx={{ border: "1px solid rgba(0,0,0,0.125", zIndex: "3" }}
          >
            <Grid item xs={8}>
              <input
                type="text"
                name="username"
                value={username}
                onChange={handleChange}
                placeholder="Username"
                style={{fontSize:"20px",border:"2px solid black",color:"black",width:"300px",height: "50px" ,backgroundColor: "rgba(162,240,255,0.8)" ,borderRadius:"15px", textAlign:"center"}}

                // required
              />
              {errors.username && (
                <div className="error" style={{ color: "red" }}>{errors.username}</div>
              )}
            </Grid>
            <Grid item xs={10}>
              <input type="text"
                // type="email"
                name="email"
                value={email}
                onChange={handleChange}
                placeholder="Email"
                style={{fontSize:"20px",border:"2px solid black",width:"300px",height: "50px" ,backgroundColor: "rgba(162,240,255,0.8)"  ,borderRadius:"15px", textAlign:"center"}}

      
              />
              {errors.email && <div className="error" style={{ color: "red" }}>{errors.email}</div>}
            </Grid>
            <Grid item xs={12}>
              <input
                type="text"
                name="phone_number"
                // required
                value={phone_number}
                onChange={handleChange}
                placeholder="Phone Number"
                style={{fontSize:"20px",border:"2px solid black",width:"300px",height: "50px" ,backgroundColor: "rgba(162,240,255,0.8)" ,borderRadius:"15px", textAlign:"center"}}

              />
              {errors.phone_number && (
                <div className="error" style={{ color: "red" }}>{errors.phone_number}</div>
              )}
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Programs</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={programId}
                  defaultValue={programId}
                  label="Program name"
                  onChange={handleProgramChange}
                  error={Boolean(errors.programId)}
                  style={{fontSize:"20px",border:"2px",color:"black",height: "50px" ,backgroundColor: "rgba(162,240,255,0.8)" ,borderRadius:"15px", textAlign:"center"}}

                  // required
                >
                  {fetchPrograms.map((eachItem, i) => (
                    <MenuItem
                      key={eachItem.program_id}
                      value={eachItem.program_id}
                    >
                      {eachItem.program_name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.programId && (
      <div className="error" style={{ color: "red" }}>
        {errors.programId}
      </div>
    )}
              </FormControl>
            </Grid>
            
            {programId !== "" ? (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    SubPrograms
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={subProgramId}
                    defaultValue={subProgramId}
                    label="program"
                    onChange={handleSubProgramChange}
                    error={Boolean(errors.subProgramId)} 
                    style={{fontSize:"20px",border:"2px",color:"black",height: "50px" ,backgroundColor: "rgba(162,240,255,0.8)" ,borderRadius:"15px", textAlign:"center"}}

                    // required
                  >
                    {item?.map((eachItem, i) => (
                      <MenuItem
                        key={eachItem.subprogram_id}
                        value={eachItem.subprogram_id}
                      >
                        {eachItem.subprogram_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.programId && (
      <div className="error" style={{ color: "red" }}>
        {errors.programId}
      </div>
    )}
                </FormControl>
              </Grid>
            ) : (
              //   <Grid item xs={12}>
              //   <FormControl fullWidth>
              //     <InputLabel id="demo-simple-select-label">Courses</InputLabel>
              //     <Select
              //       labelId="demo-simple-select-label"
              //       id="demo-simple-select"
              //       value={courseId}
              //       defaultValue={courseId}
              //       label="Course"
              //       onChange={handleCourseChange}
              //       required
              //     >
              //       {item?.map((eachItem, i) => (
              //         <MenuItem
              //           key={eachItem.course_id}
              //           value={eachItem.course_id}
              //         >
              //           {eachItem.course_name}
              //         </MenuItem>
              //       ))}
              //     </Select>
              //   </FormControl>
              // </Grid>
              ""
            )}
{/* 
           <Grid item xs={12} className="text-start">
              <PopUpModal
                buttonname={"Book Now"}
                buttonClassName="btn btn-danger py-md-3 animated slideInRight"
                buttonType="submit"
                open={open}
                setOpen={setOpen}
                handleClose={handleClose}
                handleOpen={handleOpen}
              />
              
            {/* <Grid item xs={12} className="text-end"> */}
              {/* <Button variant="contained" onClick={() => navigate("/login")}>
                If you have account <span className="text-primary">Login</span>
              </Button> */}
            {/* </Grid> */}
            {/* </Grid>  */}
            <Grid container alignItems="end">
  <Grid item xs={12} className="text-start">
    <PopUpModal
      buttonname={"Book Now"}
      buttonClassName="btn btn-danger py-md-3 animated slideInRight"
      buttonType="submit"
      open={open}
      setOpen={setOpen}
      handleClose={handleClose}
      handleOpen={handleOpen}
    />
  
  <Grid item xs={12} className="text-end">
    {/* <button className="text-end" variant="contained" id="default-login" onClick={() => navigate("/login")}>
      If you have an account <span className="text-primary"><strong>Login</strong></span>
    </button> */}
    {/* in the future open the comment */}
    </Grid>
  </Grid>
</Grid>




          </Grid>
        </Paper>
      </form>
      </div>
    </>
  );
};

export default HeroPromotionForm;
