import { ActionTypes } from "../constants/action-types";

const initialState = {
  selectedPromotionImages: [],
};

export const  selectedPromotionImageReducer= (state = initialState, action) => {
  switch (action.type) {
 

    case ActionTypes.FETCH_SELECTED_PROMOTION_IMAGES_SUCCESS:
      return {
        ...state,
        selectedPromotionImages: action.payload,
        
        
      };

    default:
      return state;
  }
};
