export const ActionTypes = {
    FETCH_PROGRAMS : "FETCH_PROGRAMS",
    FETCH_PROGRAM_BY_ID: "FETCH_PROGRAM_BY_ID",
    FETCH_COURSES: "FETCH_COURSES",
    FETCH_COURSE_BY_ID: "FETCH_COURSE_BY_ID",
    GET_MAIL_DOWNLOAD: "GET_MAIL_DOWNLOAD",
    GET_VISITORS: "GET_VISITORS",
    GET_VISITORS_BY_ID: "GET_VISITORS_BY_ID",
    GET_ROLES: "GET_ROLES",
    GET_ALL_USERS: "GET_ALL_USERS",
    GET_USER_BY_ID: "GET_USER_BY_ID",
    UPDATE_USER: "UPDATE_USER",
    DELETE_USER: "DELETE_USER",
    UPLOAD_PROGRAM: "UPLOAD_PROGRAM",
    EDIT_PROGRAM: "EDIT_PROGRAM",
    DELETE_PROGRAM: "DELETE_PROGRAM",
    FETCH_PROGRAM_ID: "FETCH_PROGRAM_ID",
    FETCH_SUBPROGRAM_ID: "FETCH_SUBPROGRAM_ID",
    FETCH_PROGRAMS_WITH_COURSE:"FETCH_PROGRAMS_WITH_COURSE",
    GET_STATUS_LIST: "GET_STATUS_LIST",
    UPDATE_STATUS: "UPDATE_STATUS",
    GET_USER_BY_STATUS: "GET_USER_BY_STATUS",
    GET_VISITORS_BY_STATUS: "GET_VISITORS_BY_STATUS",
    GET_PROGRAM_COURSE_COMBINATION: "GET_PROGRAM_COURSE_COMBINATION",
    SUBSCRIBER_REGISTER: "SUBSCRIBER_REGISTER",
    GET_ALL_SUBSCRIBER: "GET_ALL_SUBSCRIBER",
    DELETE_SUBSCRIBER: "DELETE_SUBSCRIBER",
    GET_LESSONS: "GET_LESSONS",
    GET_UNITS: "GET_UNITS",
    GET_UNITS_BY_LESSON:"GET_UNITS_BY_LESSON",
    GET_COURSE_WITH_LESSON: "GET_COURSE_WITH_LESSON",
    GET_UNIT_BY_COURSE: "GET_UNIT_BY_COURSE",
    GET_PREREQUISITE_BY_COURSE: "GET_PREREQUISITE_BY_COURSE",
    GET_LESSONS_BY_COURSE_ID: "GET_LESSONS_BY_COURSE_ID",
    GET_ACTIVE_PROMOTION_EVENT: "GET_ACTIVE_PROMOTION_EVENT",
    UPLOAD_COURSE:"UPLOAD_COURSE",
    DELETE_COURSE: "DELETE_COURSE",
    FETCH_COURSE_ID: "FETCH_COURSE_ID",
    EDIT_COURSE: "EDIT_COURSE",
    UPLOAD_LESSON:"UPLOAD_LESSON",
    UPLOAD_UNIT:"UPLOAD_UNIT",
    FETCH_LESSON_ID :"FETCH_LESSON_ID",
    CREATE_PROGRAMCOURSE:'CREATE_PROGRAMCOURSE',
    UPLOAD_PREREQUISITE:"UPLOAD_PREREQUISITE",
    UPLOAD_IMAGE:'UPLOAD_IMAGE',
    UPLOAD_IMAGE_PROGRAM: 'UPLOAD_IMAGE_PROGRAM',
    UPLOAD_IMAGE_COURSE: 'UPLOAD_IMAGE_COURSE',
    GET_IMAGE_LIST:'GET_IMAGE_LIST',
    GET_IMAGE_TABLE: 'GET_IMAGE_TABLE',
    GET_IMAGE_PROGRAM:'GET_IMAGE_PROGRAM',
    GET_IMAGE_COURSE:'GET_IMAGE_COURSE',
    GET_IMGAGE_COURSE_FOR_PROGRAMDETAIL: 'GET_IMGAGE_COURSE_FOR_PROGRAMDETAIL',
    SEND_EMAIL_NOTIFICATION: 'SEND_EMAIL_NOTIFICATION',
    GET_USER_BY_EMAIL: 'GET_USER_BY_EMAIL',
    DELETE_LESSON:"DELETE_LESSON",
    EDIT_LESSON:"EDIT_LESSON",
    DELETE_UNIT:"DELETE_UNIT",
    FETCH_UNIT_ID :"FETCH_UNIT_ID",
    EDIT_UNIT:"EDIT_UNIT",
    FETCH_PREREQUISITE: "FETCH_PREREQUISITE",
    DELETE_PREREQUISITE:"DELETE_PREREQUISITE",
    EDIT_PREREQUISITE:"EDIT_PREREQUISITE",
    FETCH_PREREQUISITE_BY_ID:"FETCH_PREREQUISITE_BY_ID",
    FETCH_EVENT_AD:"FETCH_EVENT_AD",
    FETCH_EVENTAD_BY_ID :"FETCH_EVENTAD_BY_ID",
    EVENT_SUBSCRIBER_REGISTER :"EVENT_SUBSCRIBER_REGISTER",
    FETCH_EVENTS:'FETCH_EVENTS',
    FETCH_EVENT_BY_ID:'FETCH_EVENT_BY_ID',
    DELETE_EVENT:'DELETE_EVENT',
    UPDATE_EVENT:'UPDATE_EVENT',
    GET_EVENT_BY_STATUS:'GET_EVENT_BY_STATUS',
    GET_EVENT_SUBSCRIBER:"GET_EVENT_SUBSCRIBER",
    FETCH_TESTIMONIALS : "FETCH_TESTIMONIALS",
    FETCH_FAQ: "FETCH_FAQ",
    UPLOAD_FAQ:"UPLOAD_FAQ",
    FETCH_FAQ_BY_ID:"FETCH_FAQ_BY_ID",
    EDIT_FAQ:"EDIT_FAQ",
    DELETE_FAQ:"DELETE_FAQ",
    FETCH_JOBS: "FETCH_JOBS",
    FETCH_JOBS_BY_ID: "FETCH_JOBS_BY_ID",
    FETCH_EVENT_IMAGES_START:"FETCH_EVENT_IMAGES_START",
    FETCH_EVENT_IMAGES_SUCCESS:"FETCH_EVENT_IMAGES_SUCCESS",
    FETCH_EVENT_IMAGES_FAILURE:"FETCH_EVENT_IMAGES_FAILURE",
    UPLOAD_EVENT_IMG:"UPLOAD_EVENT_IMG",
    UPLOAD_EVENT_SUCCESS:"UPLOAD_EVENT_SUCCESS",
    UPLOAD_EVENT_FAILURE:"UPLOAD_EVENT_FAILURE",
    FETCH_EVENT_LIST:"FETCH_EVENT_LIST",
    UPLOAD_JOB_DETAILS_SUCCESS:"UPLOAD_JOB_DETAILS_SUCCESS",
    UPLOAD_COMPANY_LOGO:"UPLOAD_COMPANY_LOGO",
    FETCH_POPULAR_COURSES:"FETCH_POPULAR_COURSES",
  
    FETCH_POPULAR_COURSES_LIMIT:"FETCH_POPULAR_COURSES_LIMIT",
    SET_VERIFICATION_CODE : 'SET_VERIFICATION_CODE',
    SET_EVENT_ROW_DATA:'SET_EVENT_ROW_DATA',
    GET_IMAGE_NAME:'GET_IMAGE_NAME',
    UPDATE_EVENT_BY_ID:'UPDATE_EVENT_BY_ID',
    DELETE_EVENT_BY_ID:'DELETE_EVENT_BY_ID',
    DELETE_EVENTIMAGE_BY_ID:'DELETE_EVENTIMAGE_BY_ID',
    FETCH_TUTORIAL_CATEGORY:'FETCH_TUTORIAL_CATEGORY',
    FETCH_TUTORIAL_CATEGORY_BY_ID:'FETCH_TUTORIAL_CATEGORY_BY_ID',
    UPLOAD_TUTORIAL_CATEGORY:'UPLOAD_TUTORIAL_CATEGORY',
    EDIT_TUTORIAL_CATEGORY:'EDIT_TUTORIAL_CATEGORY',
    DELETE_TUTORIAL_CATEGORY:'DELETE_TUTORIAL_CATEGORY',
    FETCH_TUTORIAL_TECHNOLOGY:'FETCH_TUTORIAL_TECHNOLOGY',
    FETCH_TUTORIAL_TECHNOLOGY_BY_ID:'FETCH_TUTORIAL_TECHNOLOGY_BY_ID',
    UPLOAD_TUTORIAL_TECHNOLOGY:'UPLOAD_TUTORIAL_TECHNOLOGY',
    EDIT_TUTORIAL_TECHNOLOGY:'EDIT_TUTORIAL_TECHNOLOGY',
    DELETE_TUTORIAL_TECHNOLOGY:'DELETE_TUTORIAL_TECHNOLOGY',
    FETCH_TUTORIAL_TOPIC:'FETCH_TUTORIAL_TOPIC',
    FETCH_TUTORIAL_TOPIC_BY_ID:'FETCH_TUTORIAL_TOPIC_BY_ID',
    UPLOAD_TUTORIAL_TOPIC:'UPLOAD_TUTORIAL_TOPIC',
    EDIT_TUTORIAL_TOPIC:'EDIT_TUTORIAL_TOPIC',
    DELETE_TUTORIAL_TOPIC:'DELETE_TUTORIAL_TOPIC',
    FETCH_CATEGORIES: 'FETCH_CATEGORIES',
    FETCH_CATEGORY_BY_ID: 'FETCH_CATEGORY_BY_ID',
    UPDATE_CATEGORY: 'UPDATE_CATEGORY',
    UPLOAD_CATEGORY: 'UPLOAD_CATEGORY',
    FETCH_INTERVIEW_QA: 'FETCH_INTERVIEW_QA',
    FETCH_INTERVIEW_QA_BY_ID: 'FETCH_INTERVIEW_QA_BY_ID',
    UPDATE_INTERVIEW_QA: 'UPDATE_INTERVIEW_QA',
    UPLOAD_INTERVIEW_QA: 'UPLOAD_INTERVIEW_QA',
    DELETE_INTERVIEW_QA: 'DELETE_INTERVIEW_QA',
    FETCH_TOPICS: 'FETCH_TOPICS',
    UPLOAD_TOPIC: 'UPLOAD_TOPIC',
    UPDATE_TOPIC: 'UPDATE_TOPIC',
    FETCH_LINKEDIN_URL: 'FETCH_LINKEDIN_URL',
    UPLOAD_PROMOTION_HERO_IMAGE:'UPLOAD_PROMOTION_HERO_IMAGE',
    FETCH_PROMOTION_IMAGES_SUCCESS:'FETCH_PROMOTION_IMAGES_SUCCESS',
    FETCH_PROMOTION_IMAGES_FAILURE:'FETCH_PROMOTION_IMAGES_FAILURE',
    TOGGLE_SWITCH :'TOGGLE_SWITCH',
    TOGGLE_HERO_CONTENT: 'TOGGLE_HERO_CONTENT',
    FETCH_OTP_SUCCESS: 'FETCH_OTP_SUCCESS',
    SET_EMAIL_CODE_FAILURE: 'SET_EMAIL_CODE_FAILURE',
    SET_EMAIL_CODE: 'SET_EMAIL_CODE',
    GET__JOB_APPLICATIONS_BY_ID: "GET_JOB_APPLICATIONS_BY_ID",
    FETCH_JOB_APPLICATIONS: "FETCH_JOB_APPLICATIONS",
    UPLOAD_JOB_APPLICATIONS_DETAILS_SUCCESS:"UPLOAD_JOB_APPLICATIONS_DETAILS_SUCCESS",
    UPLOAD_JOB_APPLICATION_DOCFILE:"UPLOAD_JOB_APPLICATION_DOCFILE",
    DELETE_JOB_AWS_APPLICATIONS_BY_NAME: "DELETE_JOB_AWS_APPLICATIONS_BY_NAME",
     FETCH_JOB_AWS_APPLICATIONS:'FETCH_JOB_AWS_APPLICATIONS',
     UPLOAD_SELECTED_PROMOTION_HERO_IMAGE:'UPLOAD_SELECTED_PROMOTION_HERO_IMAGE',
     FETCH_SELECTED_PROMOTION_IMAGES_SUCCESS:'FETCH_SELECTED_PROMOTION_IMAGES_SUCCESS',
     FETCH_SELECTED_PROMOTION_IMAGES_FAILURE:'FETCH_SELECTED_PROMOTION_IMAGES_FAILURE',
     DELETE_SELECTED_PROMOTION_IMAGE:'DELETE_SELECTED_PROMOTION_IMAGE',
     FETCH_REGISTRATION: 'FETCH_REGISTRATION',
     UPLOAD_ONLINECLASS:'UPLOAD_ONLINECLASS',
     UPLOAD_PROMOTION_EVENT_TEXT:'UPLOAD_PROMOTION_EVENT_TEXT',
    FETCH_PROMOTION_EVENT_TEXT_BY_ID:'FETCH_PROMOTION_EVENT_TEXT_BY_ID',
    DELETE_PROMOTION_EVENT_TEXT:'DELETE_PROMOTION_EVENT_TEXT',
    FETCH_PROMOTION_EVENT_TEXT:'FETCH_PROMOTION_EVENT_TEXT',
    EDIT_PROMOTION_EVENT_TEXT:'EDIT_PROMOTION_EVENT_TEXT',
    FETCH_INSTRUCTORS: 'FETCH_INSTRUCTORS',
    FETCH_INSTRUCTOR_BY_ID: 'FETCH_INSTRUCTOR_BY_ID',
    DELETE_INSTRUCTOR: 'DELETE_INSTRUCTOR',
    UPLOAD_INSTRUCTOR: 'UPLOAD_INSTRUCTOR',
    EDIT_INSTRUCTOR: 'EDIT_INSTRUCTOR',
    DELETE_ONLINECLASS:'DELETE_ONLINECLASS',
    FETCH_REMINDME: 'FETCH_REMINDME',
    UPLOAD_REMINDME:'UPLOAD_REMINDME',
    UPLOAD_PROCESS_LIST:'UPLOAD_PROCESS_LIST',
    UPLOAD_MATERIAL_ACCESS:'UPLOAD_MATERIAL_ACCESS',
    FETCH_MATERIALS:'FETCH_MATERIALS',
    FETCH_PROCESS_LIST_SUCCESS:'FETCH_PROCESS_LIST_SUCCESS',
    DELETE_PROCESS_LIST:"DELETE_PROCESS_LIST",
    FETCH_PROCESS_LIST_BY_PROGRAMID_COURSEID_SUCCESS:"FETCH_PROCESS_LIST_BY_PROGRAMID_COURSEID_SUCCESS",
    EDIT_PROCESS_LIST:'EDIT_PROCESS_LIST',
    UPDATE_PROGRAM_IMAGE:'UPDATE_PROGRAM_IMAGE',
    UPDATE_COURSE_IMAGE:'UPDATE_COURSE_IMAGE',
    FETCH_REALTIMEPROJECTS: "FETCH_REALTIMEPROJECTS",
    FETCH_REALTIMEPROJECTS_BY_ID: "FETCH_REALTIMEPROJECTS_BY_ID",
    UPLOAD_PROJECT_IMAGE:"UPLOAD_PROJECT_IMAGE",
    UPLOAD_REAL_TIME_PROJECT_DETAILS_SUCCESS:"UPLOAD_REAL_TIME_PROJECT_DETAILS_SUCCESS",
    FETCH_PROJECT_IMAGE:"FETCH_PROJECT_IMAGE",
    DELETE_PROJECT_AWS_IMAGE:'DELETE_PROJECT_AWS_IMAGE',
     EDIT_PROJECT_AWS_IMAGE:'EDIT_PROJECT_AWS_IMAGE',
   // EDIT_PROJECT_AWS_IMAGE_ERROR:'EDIT_PROJECT_AWS_IMAGE_ERROR',
   FETCH_PROJECT_IMAGE_BY_PROJECT_NAME:"FETCH_PROJECT_IMAGE_BY_PROJECT_NAME",
   FETCH_PROGRAM_COURSE_BY_ID:"FETCH_PROGRAM_COURSE_BY_ID",
   FETCH_VISTOR_COUNT:"FETCH_VISTOR_COUNT",
   UPDATE_BELL_NOTIFICATION:"UPDATE_BELL_NOTIFICATION",
   FETCH_BELL_NOTIFICATION:'FETCH_BELL_NOTIFICATION',
   UPLOAD_BELL_NOTIFICATION_ENTRY:'UPLOAD_BELL_NOTIFICATION_ENTRY',
   
   FETCH_COMPANY_NAME:"FETCH_COMPANY_NAME",
   DELETE_COMPANY_NAME_AWS:"DELETE_COMPANY_NAME_AWS",
   FETCH_COMPANY_NAME_BY_COMPANY_NAME:"FETCH_COMPANY_NAME_BY_COMPANY_NAME",
   EDIT_COMPANY_NAME_AWS:"EDIT_COMPANY_NAME_AWS",
   UPDATE_COMPANY_NAME:"UPDATE_COMPANY_NAME",
   UPLOAD_COMPANY_NAME:"UPLOAD_COMPANY_NAME",
   FETCH_MATERIALS_BY_ID:"FETCH_MATERIALS_BY_ID",
   UPDATE_MATERIAL_ACCESS:"UPDATE_MATERIAL_ACCESS",
   DELETE_MATERIAL_LIST:"DELETE_MATERIAL_LIST",   UPLOAD_INSTRUCTOR_IMAGE:"UPLOAD_INSTRUCTOR_IMAGE",
}

// constants/actionTypes.js

