
import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, CardMedia } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchTutorialCategoriesList, fetchTutorialTechnologiesList } from "../redux/actions/Actions";
// import './css/style.css';
const Tutorials = () => {
  const fetchTutorialCategories = useSelector((state) => state.fetchAllTutorialCategories.tutorialCategories);
  const fetchTechnologies = useSelector((state) => state.fetchAllTutorialTechnologies.tutorialTechnologies);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTutorialCategoriesList());
  }, [dispatch]);

  const { category_id } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    // Fetch technologies when the selected category changes
    if (selectedCategory) {
      dispatch(fetchTutorialTechnologiesList(selectedCategory.category_id));
    }
  }, [selectedCategory, dispatch]);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  return (
    <>
      <div className="container-fluid p-0">
        <h1 className="text-center">Tutorials</h1>
        <div className="row m-0" >
          <div className="col-md-2 p-0">
            <div className="container-xl hoverable-card">
             
            
          <Card>
                <CardContent>
                  {fetchTutorialCategories.map((eachCategory) => (
                    <Link
                      key={eachCategory.category_id}
                      onClick={() => {
                        setSelectedCategory(eachCategory);
                      }}
                      className="tutorial-technologies"
                    >
                      <h5
                        style={{
                          color: selectedCategory === eachCategory ? "blue" : "black", 
                          marginBottom: "5px", padding:"8px",backgroundColor: '#E7E9EB',fontFamily: "poppins",borderRadius:"0.5rem"
                         
                         
                          
                        }}
                      >
                        {eachCategory.category_name}
                      </h5>
                    </Link>
                  ))}
                </CardContent>
             </Card>
            </div>
          </div>
          
          <div className="col-lg-9 p-0">
         <div className="hero-register-form-wrapper">
              <Grid container spacing={3}>
                {selectedCategory
                  ? fetchTechnologies
                      .filter((technology) => technology.category_id === selectedCategory.category_id)
                      .map((technology) => (
                        <Grid item xs={3} sm={6} md={3} key={technology.technology_id}>
                          <Link to={`/tutorials/${technology.technology_id}`}>
                            <div>
                              <Card className="tutorial-addcard">
                                <CardMedia
                                  component="img"
                                  height="130"
                                  width="190"
                                //  sx={{borderRadius:"2rem"}}
                                  alt="tech image"
                                  src={technology.tutorial_tech_image_url ? technology.tutorial_tech_image_url : technology.technology_name }
                                />
                                <p style={{ textAlign: "center",fontWeight: "bold"}}>{technology.technology_name}</p>
                              </Card>
                            </div>
                          </Link>
                        </Grid>
                      ))
                  : <>
                  <div class="w3-panel w3-info intro">
                  <p>Front-end development focuses on the visual aspects of a website — the part that users see and interact with.</p><p>Back-end development comprises a site's structure, system, data, and logic.
                    </p><p>A database is an organized collection of structured information, or data, typically stored electronically in a computer system. </p><p>
                    DevOps combines development and operations to increase the efficiency, speed, and security of software development and delivery compared to traditional processes.</p><p>
                    Data science combines math and statistics, specialized programming, advanced analytics, artificial intelligence (AI), and machine learning with specific subject matter expertise to uncover actionable insights hidden in an organization’s data. </p></div></>
                  
}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tutorials;


