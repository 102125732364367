import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteFaqById, fetchFaqList } from "../../../redux/actions/Actions";
import {
  Container,
  Grid,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Link from "@mui/material/Link";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const FaqList = () => {
  const fetchFaq = useSelector((state) => state.fetchAllFaq.faq);
  const { user } = useSelector((state) => state.auth);
  const userId = user?.role_id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const userData =(userId===1||user?.role_name==="admin");
  // console.log("user data ",userData);

  useEffect(() => {
    dispatch(fetchFaqList());
  }, [dispatch]);

  function ExpandableCell({ value }) {
    const [expanded, setExpanded] = React.useState(false);
    return (
      <div>
        {expanded ? value : value.slice(0, 200)}&nbsp;
        {value.length > 200 && (
          <Link
            type="button"
            component="button"
            sx={{ fontSize: "inherit" }}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? "view less" : "view more"}
          </Link>
        )}
      </div>
    );
  }

  const columns = useMemo(
    () => { const commonColumns=[
      {
        field: "faq_id",
        headerName: "Id",
        width: 60,
        disableColumnMenu: true,
        sortable: false,
      },
      {
        field: "heading",
        headerName: "Heading",
        width: 180,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: "details",
        headerName: "Details",
        width: 240,
        disableColumnMenu: true,
        renderCell: (params) => <ExpandableCell {...params} />,
      },
      {
        field: "link",
        headerName: "Link",
        width: 120,
        disableColumnMenu: true,
      },
      {
        field: "linkContent",
        headerName: "Link Content",
        width: 120,
        disableColumnMenu: true,
      },
      {
        field: "is_active",
        headerName: "isActive",
        width: 100,
        type: "boolean",
        editable: true,
        disableColumnMenu: true,
        sortable: false,
      },
      {
        field: "date",
        headerName: "Date",
        width: 100,
        disableColumnMenu: true,
        valueFormatter: (params) => {
          const date = new Date(params.value);
          const formattedDate = date.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });
          return formattedDate;
        },
      },
    ];
    const userSpecificColumns=userData ?[
 
    
      {
        field: "action",
        headerName: "Actions",
        width: 160,
        sortable: false,
        disableClickEventBubbling: true,

        renderCell: (params) => {
          const onClick = (e) => {
            const currentRow = params.row;
            navigate("/auth/updateFaq/" + currentRow.faq_id);
          };
          const handleDelete = (e) => {
            const currentRow = params.row;
            if (window.confirm("Are you sure you wanted to delete the FAQ?")) {
              dispatch(deleteFaqById(currentRow.faq_id));
            }
          };

          return (
            <Stack direction="row" spacing={2}>
              <IconButton onClick={onClick}>
                <Edit />
              </IconButton>
              {params.row?.role_id !== 1 ? (
                <IconButton onClick={() => handleDelete()}>
                  <Delete />
                </IconButton>
              ) : (
                <IconButton disabled>
                  <Delete />
                </IconButton>
              )}
            </Stack>
          );
        },
      },
    ]:
    [];
    return [...commonColumns, ...userSpecificColumns];
  }, [userId, data, dispatch, navigate]);

  const rows = useMemo(
    () =>
      data?.map((row, index) => {
        return {
          ...row,
          id: index + 1,
          heading: row.heading,
          details: row.details,
          link: row.link,
          linkContent: row.linkContent,
          is_active: row.is_active,
          date: row.created_date,
        };
      }),
    [data]
  );

  const [searchValue, setSearchValue] = useState("");

  const requestSearch = (searchValue) => {
    setSearchValue(searchValue);
    const filteredRows = rows.filter((row) =>
      Object.values(row).some(
        (value) =>
          value !== null &&
          value !== undefined &&
          value.toString().toLowerCase().includes(searchValue.toLowerCase())
      )
    );
    setDataRows(filteredRows);
  };
 
  useEffect(() => {
    setData(fetchFaq);
    setDataColumns(columns);
    setDataRows(rows);
  }, [data, fetchFaq, columns,rows]);

  return (
    <Container
      sx={{ border: 1, width: "100vw", height: "100vh", overflow: "auto" }}
    >
      <Grid container>
        <Grid item xs={6}>
          <TextField
            label="Search"
            variant="outlined"
            placeholder="Search…"
            sx={{ my: 2 }}
            value={searchValue}
            onChange={(e) => requestSearch(e.target.value)}
            InputProps={{
              startAdornment: <SearchIcon />,
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: searchValue ? "visible" : "hidden" }}
                  onClick={() => requestSearch("")}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
        </Grid>

       { (
          <Grid item xs={12}>
            <DataGrid
              autoHeight
              rows={dataRows}
              columns={dataColumns}
              getRowHeight={() => "auto"}
              slots={{
                toolbar: CustomToolbar,
              }}
              sx={{
                "& .MuiDataGrid-columnHeaders": {
                  fontSize: 16,
                },
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: 1,
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
              }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10, 20]}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default FaqList;
