import React from 'react'
import { useSelector } from 'react-redux';
import ProgramDataGrid from './ProgramDataGrid';
import WelcomeMenus from '../../WelcomeMenus';

const AllPrograms = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
    const userId = user?.role_id
    console.log('logged in as',userId)
  return (
  
          <div className="container-xxl">
            {  userId === 1 ? (
            isAuthenticated ? <ProgramDataGrid /> : <WelcomeMenus />
          ) : userId === 3 ? (
            isAuthenticated ? <ProgramDataGrid /> : <WelcomeMenus />
          ) : (
         
          <WelcomeMenus />
        )
        
          }
            
    </div>
  )
}

export default AllPrograms