import React, { useState } from 'react';
import { Stepper, Step, StepLabel, Button, TextField, Input,Typography } from '@mui/material';
import { registerEventSubsciber ,uploadbellNofifications} from '../redux/actions/Actions';
import { useDispatch } from 'react-redux';
import { Link, useLocation,useParams } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import securedInstance  from '../../api/securedInstance';


const EventRegistrationForm =  () => {

  
  const navigate = useNavigate();
    const { eventName } = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const eventId = searchParams.get('eventId');
    const [activeStep, setActiveStep] = useState(-1);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    
  
    const steps = ['Name', 'Email', 'Phone'];
    const [validationError, setValidationError] = useState('');
    const [tooltipOpen, setTooltipOpen] = useState(false); 
  
    const handleButtonClick = () => {
      // Navigate to the home page
      navigate('/');
    };
  const findEmailAlreadyExist = async(req)=>{
    console.log("Event Id "+eventId +" and event Name  "+eventName);
    let isDuplicateUser=false;
      try {
        const response = await securedInstance.get(`/api/subscribers/${eventId}`);
        //const token = req.cookies.access_token;
        const eventList = response.data;
           console.log(eventList," EXISTING USER LIST");
        // Check for duplicate users
         isDuplicateUser = eventList.some((event) => {
          const users = event.registeredUsers || [];
          console.log(users, "USERS");
          for(var i=0;i<eventList.length;i++){
            console.log(eventList[i]," events inside for loop");
          if(eventList[i].email===email){
            console.log(eventList[i].email," events mail ID");

            isDuplicateUser=true;
            return isDuplicateUser;
           //break;
            
          }
        }
          return isDuplicateUser;
          // return users.some(
          //   (user) =>
          //     user.email === email
          //   );
        });
      } catch (error) {
        // Handle the error (e.g., show an error message)
        console.error('Error fetching subscribers:', error);
          //isDuplicateUser=true; //bcz if get exceptions means NOT Validated...!
        return isDuplicateUser;
      };
return isDuplicateUser;
  }

  

    const handleNext = async () => {
      
      let error = '';
    
      if (activeStep === 0 && !isNameValid(name)) {
        error = 'Name is required.';
      } else if (activeStep === 1 && !isEmailValid(email)) {
        error = 'Invalid email address.';
        //error = 'Already Registered with this email id';
      }  
      else if(activeStep ===1){
        const flag = await findEmailAlreadyExist();  //true or false
      //console.log(flag, 'flag');
        if(flag) { 
           console.log("DUPLICATE USER ");
          error='Already Registered with this email id';
          setActiveStep((prevActiveStep) => prevActiveStep );
          
        }else{
          error='';
        }
      }
      if (error) {
        setValidationError(error);
        setTooltipOpen(true); 
      } else {
        setValidationError('');
        setTooltipOpen(false); 
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    };
    
      
    const handleBack = () => {
      setValidationError('');
      setTooltipOpen(false); 
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const isNameValid = (name) => {
        return name.trim() !== '';
      };
      
      const isEmailValid = (email) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email);
      };
      
      const isPhoneValid = (phone) => {
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(phone);
      };
      const formData = {
        name,
        email,
        phone,
      };
      const handleSubmit = () => {
        
        if (activeStep === 2) {
          if (!isPhoneValid(phone)) {
            setValidationError('Invalid phone number.');
            setTooltipOpen(true);
            return;
          }
          else{
            setTooltipOpen(false);
          }
        }
        if (isNameValid(name) && isEmailValid(email) && isPhoneValid(phone)) {
          const registerData = {
            subscriber_name: formData.name,
            email: formData.email,
            phone_number: formData.phone,
            event_id: eventId,
            username:formData.name,

          };
          console.log('registerdata',registerData);
          dispatch(registerEventSubsciber(registerData));
          dispatch(uploadbellNofifications({registerData,"number":1}));

          handleNext();
          setName('');
          setEmail('');
          setPhone('');
        } else {
          // Display an error message or handle validation errors
          // For example, you can set error states for each field
        }
      };     
  
    const getStepContent = (step) => {
      switch (step) {
        case -1:
          return (
            <div className='event-container'>
              <Typography variant="h5" className="white-text">
                Would you like to register for the event?
              </Typography>
              <Typography variant="body2" className="white-text">
                Give us your details, and we will get back to you!!
              </Typography>
              <Button
                variant="contained"
                onClick={() => setActiveStep(0)}
                className="ok-button"
              >
                OK
              </Button>
            </div>
          );
        case 0:
          return (
            <div className='event-container'>
              <Typography variant="h5" className="question-text">What should we call you?</Typography>
              <Tooltip title={validationError} open={tooltipOpen} arrow>
                <TextField
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  error={!isNameValid(name)}
                  variant="outlined"
                  color="secondary" focused
                  InputProps={{
                    style: { color: '#fff',marginTop:'10px' }, 
                  }}
                  autoComplete="off"
                  className={!validationError ? 'custom-outlined' : ''}
                />
              </Tooltip>
            </div>
          );
        case 1:
          return (
            <div className='event-container'>
              <Typography variant="h5" className="question-text">Cool, your email?</Typography>
              <Tooltip title={validationError} open={tooltipOpen} arrow >
                <TextField
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!isEmailValid(email)}
                  variant="outlined"
                  color="secondary" focused
                  InputProps={{
                    style: { color: '#fff',marginTop:'10px' },
                  }}
                  autoComplete="off"
                  className={!validationError ? 'custom-outlined' : ''}
                />
              </Tooltip>
            </div>
          );
        case 2:
          return (
            <div className='event-container'>
              <Typography variant="h5" className="question-text">Great, your phone number?</Typography>
              <Tooltip title={validationError} open={tooltipOpen} arrow>
                <TextField
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  error={!isPhoneValid(phone)}
                  variant="outlined"
                  InputProps={{
                    style: { color: '#fff',marginTop:'10px'}, 
                  }}
                  autoComplete="off"
                  className={!validationError ? 'custom-outlined' : ''}
                />
              </Tooltip>
            </div>
          );
        default:
          return 'Unknown step';
      }
    };
    
  
    return (
      <div className='container-event-registration'>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel className="MuiStepLabel-root">{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div className='registration-success'>
              <h2 className='white-text'>Success!</h2>
              <p>You are successfully registered for the event.</p>
             <p>
              Interested in boosting event attendence ? Register more users now.!
              </p>
              <br/>
              <div className='button-container'>
              <Button  sx={{ marginRight: 1 }}   onClick={() => handleButtonClick()}>
                Go To Home
              </Button>
              <Button variant="contained"  className="next-button" onClick={() => setActiveStep(0)}>
                Register
              </Button>
             
            </div>
            </div>
          ) : (
            <div className='event-registration-title'>
              <h2 className='event-title'>{eventName}</h2>
              {getStepContent(activeStep)}
              <div>
           </div>
           <br/>
              <div className='button-container'>
              <Button
                  hidden={activeStep < 0}
                  onClick={handleBack}
                  sx={{ marginRight: 1 }}
                >
                  Back
                </Button>
                <Button
                    variant="contained"
                    hidden={activeStep < 0}
                    className="next-button"
                    onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                    >
                    {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                </Button>
              </div>
            </div>
          )}
        </div>
        

        
      </div>
    );
  };
  
  export default EventRegistrationForm;
  