import React from 'react';
import { useSelector } from 'react-redux';
import WelcomeMenus from './WelcomeMenus';

const SubscriberEvents = () => {
    const { isAuthenticated, user } = useSelector((state) => state.auth);
    const userId = user?.role_id
    console.log('logged in as',userId)
  return (
    <div>
       {
        isAuthenticated && userId === 3 ? ("Subscriber Events") : (
          <WelcomeMenus />
        )

      }
            
    </div>
  )
}

export default SubscriberEvents