import React from "react";

const Contact = () => {
  return (
    <>
      <div className="container-xxl py-5" id="about">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.8517506592934!2d77.54813426887321!3d12.981332286700905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3df16ba1c857%3A0x77ef0332eff2bd02!2s70th%20Cross%20Rd%2C%205th%20Block%2C%20Rajajinagar%2C%20Bengaluru%2C%20Karnataka%20560010!5e0!3m2!1sen!2sin!4v1708703918640!5m2!1sen!2sin"
                  style={{ border: "2px", width: "100%", height: "100%" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                For queries...
              </h6>
              <h1 className="mb-4">Contact us here</h1>
              <h4 className="text-white mb-3">Contact</h4>
              <p className="mb-2">
                <i className="fa fa-map-marker-alt me-3"></i>#123, 70Th Cross
                2Nd Floor, SVA Arcade
                5Th Block, Rajaji Nagara
                Bengaluru - 560010
              </p>
              <p className="mb-2">
                <i className="fa fa-phone-alt me-3"></i>+91 8431 6630 25 / +91 6363 5078 58
              </p>
              <p className="mb-2">
                <i className="fa fa-envelope me-3"></i>hr@uplsnipe.com
              </p>
              <div className="d-flex pt-2 contact-social">
                <a
                  className="btn btn-outline-primary btn-social"
                  href="https://www.facebook.com/profile.php?id=100091713887746"
                  target="_blank"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  className="btn btn-outline-primary btn-social"
                  href="https://youtube.com/@uplsnipe"
                  target="_blank"
                >
                  <i className="fab fa-youtube"></i>
                </a>
                <a
                  className="btn btn-outline-primary btn-social"
                  href="https://www.linkedin.com/company/upl-snipe/"
                  target="_blank"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a
                  className="btn btn-outline-primary btn-social"
                  href="https://www.instagram.com/uplsnipe/?igshid=NjIwNzIyMDk2Mg%3D%3D"
                  target="_blank"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
              <h4 className="text-white mb-3">Contact</h4>
              <p className="mb-2">
                <i className="fa fa-map-marker-alt me-3"></i>Edison , NJ -08817,
                USA
              </p>
              <p className="mb-2">
                <i className="fa fa-phone-alt me-3"></i>+1 (848) 285-2392
              </p>
              <p className="mb-2">
                <i className="fa fa-envelope me-3"></i>hr@uplsnipe.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
