import {
    FETCH_CHANNEL_PARTNERS_SUCCESS,
    FETCH_CHANNEL_PARTNERS_FAILURE,
    APPROVE_REQUEST_SUCCESS,
    APPROVE_REQUEST_FAILURE,
    REJECT_REQUEST_SUCCESS,
    REJECT_REQUEST_FAILURE,
  } from './authTypes';
  
  const initialState = {
    channelPartners: [],
    error: null,
    approveMessage: null,
    approveError: null,
    rejectMessage: null,
    rejectError: null,
  };
  
  const channelPartnerReducer = (state = initialState, action) => {
    switch (action.type) {
      case APPROVE_REQUEST_SUCCESS:
        return {
          ...state,
          approveMessage: action.payload,
          approveError: null,
        };
      case APPROVE_REQUEST_FAILURE:
        return {
          ...state,
          approveMessage: null,
          approveError: action.payload,
        };
      case REJECT_REQUEST_SUCCESS:
        return {
          ...state,
          rejectMessage: action.payload,
          rejectError: null,
        };
      case REJECT_REQUEST_FAILURE:
        return {
          ...state,
          rejectMessage: null,
          rejectError: action.payload,
        };
      case FETCH_CHANNEL_PARTNERS_SUCCESS:
        return {
          ...state,
          channelPartners: action.payload,
          error: null,
        };
      case FETCH_CHANNEL_PARTNERS_FAILURE:
        return {
          ...state,
          channelPartners: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default channelPartnerReducer;
  