import { ActionTypes } from "../constants/action-types"

const initialState = {
    tutorialTechnologyById: []
}

const FetchTutorialTechnologyById = (state=initialState, {type, payload})=>{
    switch(type){
        case ActionTypes.FETCH_TUTORIAL_TECHNOLOGY_BY_ID : return {...state, 
            tutorialTechnologyById: payload}           
        default: return state;
    }
    
}


export default FetchTutorialTechnologyById;