import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Container, Button } from '@mui/material';

const HeroPromotionContent = ({ data}) => {


  const handleButtonClick = () => {
    // Navigate to the register page
    
  };


  return (
    <Box className='hero-img-section'>
    <Box className='hero-img-wrapper'>
      <Box className='discription' style={{ backgroundColor: "rgb(129 169 171 / 40%)", textAlign: 'center', marginTop: '44px' }}>
        {data.map((each) => (
          <div key={each.event_id}>
            <Box className="d-flex align-items-center pt-5 pb-5">
              <Container>
                <Box className="row justify-content-start m-0">
                  <Box className="col-sm-10 col-lg-8">
                    <Box className='overlay-text'>
                    <h6 style={{ fontWeight: 'bold',fontSize:"50px",textTransform: "uppercase",color:"yellow",whiteSpace: "nowrap",marginRight:"320px"}}>{each.header}</h6> 
                    <p style={{ color: "white", fontSize: "24px", textAlign: "left",width:"900px" }}>{each.paragraph}</p>
                    <Button variant="contained" onClick={handleButtonClick} style={{ fontSize: '20px',backgroundColor:"yellow ",color:"black",fontWeight: 'bold',marginRight:"320px"}}>THE COURSES WE PROVIDE</Button>
                    </Box>
                  </Box>
                </Box>
              </Container>
            </Box>
          </div>
        ))}
      </Box>
    </Box>
  </Box>
  )
}

export default HeroPromotionContent
