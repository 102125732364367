import { ActionTypes } from "../constants/action-types";

const initialState = {
  faqById: []
};

export const FetchFaqById = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_FAQ_BY_ID:
      return { ...state, faqById: action.payload };
    default:
      return state;
  }
};

