import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInstructorList, deleteInstructorByinstructor_name } from '../../../redux/actions/Actions';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Delete, Edit } from '@mui/icons-material';
import { Box, Grid, TextField, IconButton, Stack, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const InstructorList = () => {
  const fetchInstructors = useSelector((state) => state.fetchInstructors.instructors);
  //const fetchInstructors = useSelector((state) => state.fetchAllInstructors.instructors);
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userId = user?.role_id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dataRows, setDataRows] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [data, setData] = useState([]);

     
  const [file, setFile] = useState(null);
   const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0]; // Capture the uploaded file
    const formData = new FormData();
  };
  

  const columns = useMemo(
    () => [
      { field: 'id', headerName: 'Id', width: 60, disableColumnMenu: true, sortable: false },
      { field: 'instructor_name', headerName: 'Instructor Name', width: 160, disableColumnMenu: true },
      { field: 'qualification', headerName: 'Qualification', width: 160, disableColumnMenu: true },
      { field: 'course', headerName: 'Course', width: 160, disableColumnMenu: true },
      { field: 'experience', headerName: 'Experience', width: 160, disableColumnMenu: true },
      {
        field: 'action',
        headerName: 'Actions',
        width: 250,
        sortable: false,
        disableClickEventBubbling: true,

        renderCell: (params) => {
          const onClick = (e) => {
            const currentRow = params.row;
            navigate(`/auth/updateInstructor/${currentRow.instructor_id}`);
          };
          const handleDelete = (e) => {
            const currentRow = params.row;
            dispatch(deleteInstructorByinstructor_name(currentRow.instructor_name));
            console.log('clicked Instructor is', currentRow.instructor_name);
          };



          //const handleFileChange = (e) => {
            //setFile(e.target.files[0]); // Capture the uploaded file
          //};



          return (
            <Stack direction="row" spacing={2}>
              <IconButton onClick={onClick}>
                <Edit />
              </IconButton>
              <IconButton onClick={() => handleDelete()}>
                <Delete />
              </IconButton>
              {/* <Button variant="contained" component="label" sx={{ width: 120 }}>
              Upload Image
              <input type="file" hidden onChange={handleFileChange} />
            </Button> */}
            </Stack>
          );
        },
      },
    ],
    []
  );

  const rows = useMemo(
    () =>
      data?.map((row, index) => {
        return {
          ...row,
          id: index + 1,
          instructor_name: row.instructor_name,
          qualification: row.qualification,
          course: row.course,
          experience: row.experience,
        };
      }),
    [data]
  );

  const [searchValue, setSearchValue] = useState('');

  const filteredRows = rows.filter((row) =>
    Object.values(row).some(
      (value) =>
        value !== null &&
        value !== undefined &&
        value.toString().toLowerCase().includes(searchValue.toLowerCase())
    )
  );

  useEffect(() => {
    dispatch(fetchInstructorList());
  }, [dispatch, fetchInstructors]);

  useEffect(() => {
    setData(fetchInstructors);
    setDataColumns(columns);
    setDataRows(rows);
  }, [fetchInstructors, columns, rows]);
  

  return (
    <div className='instructor-list'>
      <Grid item xs={5} container justify='flex'>
        <TextField
          label='Search'
          variant='outlined'
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          style={{ marginTop: 0, marginBottom: 15 }}
        />
      </Grid>
      {userId === 1 && (
        <Box sx={{ height: 400, width: 1050 }}>
          <DataGrid
            rows={filteredRows}
            columns={dataColumns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
          />
        </Box>
      )}
       
    </div>
  );
};

export default InstructorList;
