import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Delete, Edit } from '@mui/icons-material';
import { Box, Grid, TextField, IconButton, Stack, Modal, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { fetchEventList } from '../../../redux/actions/Actions';
import { setEventRowData } from '../../../redux/actions/Actions';
import { deleteEventRequest } from '../../../redux/actions/Actions';
import { deleteEventImageRequest } from '../../../redux/actions/Actions';
import { toast} from 'react-toastify';


const EventList = () => {
  //const [dataRows, setDataRows] = useState([]);
  //const [dataColumns, setDataColumns] = useState([]);
  const [data, setData] = useState([]);

  const dispatch = useDispatch();
  const fetchEvents = useSelector((state) => state.fetchEventList.events);
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userId = user?.role_id;
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    dispatch(fetchEventList());
  }, [dispatch]);
 useEffect(() => {
    setData(fetchEvents);
  }, [fetchEvents]);

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleOpenModal = (event) => {
    setSelectedEvent(event);
    setShowModal(true);
};

const handleCloseModal = () => {
    setShowModal(false);
};

const columns = useMemo(() => {
    const commonColumns = [
      { field: 'event_name', headerName: 'Event', width: 120, disableColumnMenu: true },
      { field: 'event_heading', headerName: 'Event Heading', width: 220, disableColumnMenu: true },
      { field: 'category', headerName: 'Category', width: 140, disableColumnMenu: true },
      { field: 'event_date', headerName: 'Date', width: 120, disableColumnMenu: true, valueFormatter: (params) => {
        // Format the date to YYYY-MM-DD
        const date = new Date(params.value);
        const formattedDate = date.toLocaleDateString('en-US');
        return formattedDate;
      },
     },
      { field: 'event_type', headerName: 'Event Type', width: 140, disableColumnMenu: true,headerClassName: 'bold-header' },
    //   { field: 'event_register_link', headerName: 'Registration Link', width: 220, disableColumnMenu: true,
    //   renderCell: (params) => {
    //     const registrationLink = params.value;
    //     return registrationLink ? (
    //       <a href={registrationLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'black' }}
    //       >
    //         {registrationLink}
    //       </a>
    //     ) : null;
    //   }
    //  },
    ];
    const userSpecificColumns = true ? [

      {
        field: 'action',
        headerName: 'Actions',
        width: 250,
        sortable: false,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          const onClick = () => {
            const currentRow = params.row;
            const formattedEventDate = new Date(currentRow.event_date).toISOString().split('T')[0];
            const formattedRowData = {
              ...currentRow,
              event_date: formattedEventDate,
              category: capitalizeFirstLetter(currentRow.category)
            };
            //console.log('rowDataWithFormattedDate',formattedRowData);
            dispatch(setEventRowData(formattedRowData));
            navigate(`/auth/updateEvent`);
          };
          const handleDelete = async () => {
            const currentRow = params.row;
            try {
            dispatch(deleteEventImageRequest(currentRow.event_id));
            dispatch(deleteEventRequest(currentRow.event_id));
            setData(prevData => prevData.filter(event => event.event_id !== currentRow.event_id));
            toast.success(`Event ${currentRow.event_name} deleted successfully`);
            } catch (error) {
              console.error('Error deleting event:', error);
              toast.error(`Error deleting event ${currentRow.event_name}`);
            }
          };
          return (
            <Stack direction="row" spacing={2}>
              <IconButton onClick={onClick}>
                <Edit />
              </IconButton>
              <IconButton onClick={handleDelete}>
                <Delete />
              </IconButton>
              <Button onClick={() => handleOpenModal(params.row)}>View More</Button>

            </Stack>
          );
        },
      },
    ] : [];

    return [...commonColumns, ...userSpecificColumns];
}, [userId, user, dispatch, navigate]);

//  
const rows = useMemo(() => {
  if (!data) return [];
  return data.map((row, index) => {
      const mappedRow = {
      ...row,
      id: index + 1,
      event_name: row.event_name,
      category: row.category,
      event_date: row.event_date,
      event_type: row.event_type,
      event_target_audience: row.event_target_audience,
     // event_register_link: row.event_register_link,
      event_subheading: row.event_subheading,
      event_text: row.event_text,
      event_hash_tags: row.event_hash_tags,
      more_about_event:row.more_about_event,

    };
    return mappedRow;
  });
}, [data]);



  const filteredRows = rows.filter((row) =>
    Object.values(row).some(
      (value) =>
        value !== null && value !== undefined && value.toString().toLowerCase().includes(searchValue.toLowerCase())
    )
  );

  return (
    <div className="course-list">
      <Grid item xs={5} container justify="flex">
        <TextField
          label="Search"
          variant="outlined"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          style={{ marginTop: 0, marginBottom: 15 }}
        />
      </Grid>
        <Box sx={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            initialState={{
              pagination: {
                  paginationModel: { page: 0, pageSize: 5},
              },
              }}
            pageSizeOptions={[5, 10]}
            checkboxSelection={false}
          />
        </Box>
        <Modal open={showModal} onClose={handleCloseModal} aria-labelledby="modal-title" aria-describedby="modal-description">
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "fit-content", backgroundColor:"white", boxShadow: 24, p: 4 ,padding:'10px'}}>
                    <h2 id="modal-title">Event Details</h2>
                    <p id="modal-description">
                        {selectedEvent && (
                            <>
                                <div><strong>Event Category:</strong> {selectedEvent.category}</div>
                                <div><strong>Event Name: </strong>{selectedEvent.event_name}</div>
                                <div><strong>Event Date: </strong>{selectedEvent.event_date}</div>

                                <div><strong>Event Type: </strong>{selectedEvent.event_type}</div>

                                <div><strong>Event Event Sarget audience: </strong>{selectedEvent.event_target_audience}</div>

                                {/* <div><strong>Event Event Segister link: </strong>{selectedEvent.event_register_link}</div> */}

                                <div><strong>Event Event Subheading: </strong>{selectedEvent.event_subheading}</div>
                                <div><strong>Event Event Text:</strong>{selectedEvent.event_text}</div>

                                <div><strong>Event Event Hash Tags: </strong>{selectedEvent.event_hash_tags}</div>

                            </>
                        )}
                    </p>
                    <Button style={{border:'3px solid black' ,backgroundColor:'red',color:'white'}} onClick={handleCloseModal}>Close</Button>
                </div>
            </Modal>
    </div>
  );
};

export default EventList;
