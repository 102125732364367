import { ActionTypes } from "../constants/action-types";
const initialState = {
    events: [],
    deletedEventId: null,
  };
  
  const deleteEventReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.DELETE_EVENT_BY_ID:
        return {
          ...state,
          events: state.events.filter(event => event.event_id !== action.payload.deletedEventId),
        };
      default:
        return state;
    }
  };
  
  export default deleteEventReducer;