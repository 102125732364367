
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container, Grid, Paper } from "@mui/material";
import TextfieldCustom from "../../../common/TextfieldCustom";
import { fetchProcessListByprogramIdANDCourseId, updateProcessListById } from "../../../redux/actions/Actions";

const UpdateProcessList = () => {
  const [processList, setProcessList] = useState([]);
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const fetchProcessListById = useSelector((state) => state.processlistIDdata.processperticulardata);
  const dispatch = useDispatch();
  const { programId, courseId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchProcessListByprogramIdANDCourseId(programId, courseId));
  }, [dispatch, programId, courseId]);

  useEffect(() => {
    if (fetchProcessListById && fetchProcessListById.length > 0) {
      const firstResult = fetchProcessListById[0];
      const parsedProcessList = firstResult ? JSON.parse(firstResult.process_list) : [];
      setProcessList(parsedProcessList);
    }
  }, [fetchProcessListById]);

  const handleChange = (e, index) => {
    const updatedProcessList = [...processList];
    updatedProcessList[index] = e.target.value;
    setProcessList(updatedProcessList);
  };
  const formdata={
    process_list:processList
  }
  const handleSubmit = async () => {
    try {
      // console.log('ffffffffffformdata', formdata);
      await dispatch(updateProcessListById(programId, courseId, formdata));
     // Redirect back after successful update
    } catch (error) {
      console.error("Error updating process list:", error);
    }
  };

  return (
    <div>
      <Container maxWidth="sm" className="d-flex">
        <Grid container spacing={2} direction="column" justifyContent="center">
          <div style={{ width: "inherit" }}>
            <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: "50px" }}>
              <h3>Update Process List</h3>
              <Button onClick={() => navigate(-1)}>Back</Button>
              <Grid
                container
                spacing={2}
                sx={{ border: "1px solid rgba(0,0,0,0.125)", zIndex: "3" }}
                style={{ padding: "30px" }}
              >
                {processList.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <TextfieldCustom
                      type="text"
                      value={item}
                      onChange={(e) => handleChange(e, index)}
                      placeholder="Process List"
                      required
                    />
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#3251A3", borderColor: "#FF5E14" }}
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Update & Save Process List
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Grid>
      </Container>
    </div>
  );
};

export default UpdateProcessList;
