import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../../../redux/auth/authActions';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Container, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { getRoles,uploadbellNofifications } from '../../../redux/actions/Actions';
import TextfieldCustom from '../../../common/TextfieldCustom';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NewUserForm = () => {
const { isAuthenticated, user } = useSelector((state) => state.auth);
const userId = user?.role_id
const allRole = useSelector((state)=>state.getAllRoles.roles)
const [roleId, setRoleId] = useState([])
  const [roleName, setRoleName] = useState('')
  const [showPassword,setShowPassword] = useState(false)
  const [showConfirmPassword,setShowConfirmPassword] = useState(false)
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    email: '',
    phone_number: '',
    role_id:'',
    confirmPassword:''
  });

  const [errors, setErrors] = useState({
    username: "",
    password: "",
    email: "",
    confirmPassword: "",
    phone_number: "",
    role_id: "",
  });

  const { username, password, email, phone_number,confirmPassword  } = formData;
  //console.log('roles', allRole)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (errors[name]) {
      setErrors({ ...errors, [name]: "" });
    }

  };
  const registerData = {
    username: formData.username,
    password: formData.password,
    email: formData.email,
    phone_number: formData.phone_number,
    role_id: roleName
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.email.trim()) {
      alert('Please enter an email');
    }
    else if(formData.password !== formData.confirmPassword){
      alert('Password and Confirm Password Should Match')
    }else{
      // dispatch(register(registerData));
      // navigate("/auth/AdminDashboard");
      //navigate("/AdminCp/AdminDashboard")
      //console.log("formdata", registerData)
    }
  };

  const validation =  () => {
  // Form validation
  const newErrors = {};

  // Trim values
  const trimmedUsername = username.trim();
  const trimmedEmail = email.trim();
  const trimmedPassword = password.trim();
  const trimmedPhone = phone_number.trim();

  // Username basic format
  if (!trimmedUsername) {
    newErrors.username = "Username is required";
  } else if (!/^[a-zA-Z][a-zA-Z0-9]*$/.test(trimmedUsername)) {
    newErrors.username =
      "Username must start with an alphabet letter and can have numbers after that";
  }

  // Email basic format
  if (!trimmedEmail) {
    newErrors.email = "Email is required";
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trimmedEmail)) {
    newErrors.email = "Invalid email format";
  }

  // Password pattern
  if (!trimmedPassword) {
    newErrors.password = "Password is required";
  } else if (trimmedPassword.length < 8) {
    newErrors.password = "Password should be at least 8 characters long";
  }

  // Password confirmation
  if (trimmedPassword !== confirmPassword) {
    newErrors.confirmPassword = "Password and Confirm Password should match";
  }

  // Phone number format
  if (!trimmedPhone) {
    newErrors.phone_number = "Phone Number is required";
  } else if (!/^\d{10}$/.test(trimmedPhone)) {
    newErrors.phone_number = "Phone Number should be a 10-digit number";
  }

  // Update errors state
  setErrors(newErrors);

  // Check if there are any errors
  if (Object.keys(newErrors).length === 0) {
  
    dispatch(register(registerData));
    console.log("formdata", registerData)
    dispatch(uploadbellNofifications({"username":registerData.username,"number":1}));

     navigate("/auth/AdminDashboard");
    // toast.success("User successfully registered!");
    

//     navigate("/auth/AdminCp");

//   }
// };

//   const handleCreateUser = () =>{
//     if(userId === 1){
//         if(formData.password !== formData.confirmPassword){
//             alert('Password and Confirm Password Should Match')
//           }else{
//            // dispatch(register(registerData));
//             //navigate("//AdminDashboard")
//             // navigate("/auth/AdminDashboard");
//             console.log("formdata", registerData)
//           }
//     }
//   }
  }};

  const handleRoleChange = (e) => {
    //console.log('roleId', e.target.value)
    setRoleName(e.target.value)
    
  }

  const handleClickShowPassword = () =>{
    setShowPassword(!showPassword)
  }
  const handleClickShowConfirmPassword = () =>{
    setShowConfirmPassword(!showConfirmPassword)
  }

  //console.log('role id is', roleName)
  useEffect(() => {
    dispatch(getRoles())
    setRoleId(allRole)
  }, [dispatch,allRole])
  
  return (
    <div className='container'>

    <form onSubmit={handleSubmit}>
      <Container maxWidth="sm" className=" d-flex" >
    
    <Grid
      container
      spacing={2}
      direction="column"
      justifyContent="center"
      
    >
       <div style={{ width: "inherit"}}>
      <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
      <h3>Add New User Form</h3>
      <Button onClick={()=>navigate(-1)}>Back</Button>
      <Grid container  spacing={2} sx={{border: '1px solid rgba(0,0,0,0.125', zIndex:"3"}} style={{padding: "30px"}}>
          <Grid item xs={12} sm={6}>
             <TextfieldCustom
             type="text"
             name="username" 
             value={username} onChange={handleChange} placeholder="Username"
             />        
            {errors.username && (
              <p style={{ color: "red" }}>{errors.username}</p>
            )}
           </Grid>
          <Grid item xs={12} sm={6}>
             <TextfieldCustom
             type="email"
             name="email" 
             value={email} onChange={handleChange} placeholder="Email" /> 
                  {errors.email && (
                    <p style={{ color: "red" }}>{errors.email}</p>
                  )}                     
           </Grid>
          <Grid item xs={12} sm={6}>
            <TextfieldCustom 
            type={showPassword?"text":"password"}
             name="password" value={password} onChange={handleChange} placeholder="Password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            />    
              {errors.password && (
              <p style={{ color: "red" }}>{errors.password}</p>
            )}      
           </Grid>
          <Grid item xs={12} sm={6}>
            <TextfieldCustom 
            type={showConfirmPassword?"text":"password"}
             name="confirmPassword" value={confirmPassword} onChange={handleChange} placeholder="Confirm Password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            />         
                                    {errors.password && (
              <p style={{ color: "red" }}>{errors.password}</p>
            )} 
           </Grid>
          <Grid item xs={12}sm={6}>
             <TextfieldCustom
             type="tel"
             name="phone_number" 
             value={phone_number} onChange={handleChange} placeholder="Phone Number" /> 
                                       {errors.phone_number && (
              <p style={{ color: "red" }}>{errors.phone_number}</p>
            )}
           
           </Grid>
           <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" style={{ minWidth: '100%' }}>
              <InputLabel>Role Name</InputLabel>
              <Select
                name="roleName"
                label="Role Name"
                value={roleName}
                onChange={handleRoleChange}
                options={roleId}
              >
                   {errors.phone_number && (
              <p style={{ color: "red" }}>{errors.phone_number}</p>
            )}
                {roleId.map((eachRoleName) => (
                  <MenuItem key={eachRoleName.role_id} value={eachRoleName.role_id}>
                    {eachRoleName.role_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>        
           </Grid>
           <Grid item xs={12}sm={6}>
                 <Button variant="contained" style={{backgroundColor: '#3251A3', borderColor: '#FF5E14'}} 
                 fullWidth type="submit"
                 onClick={validation}
                 >
                   Create New User
                 </Button>
               </Grid>
           {/* <Grid item xs={12}sm={6}>
                 <Button variant="contained" fullWidth onClick={()=>navigate("/login")}sx={{ width: '120%' }} >
                   Login If you have account
                 </Button>
               </Grid> */}
      </Grid>
      </Paper>
      </div>
      </Grid>
      </Container>
    </form>
    </div>
  );
};

export default NewUserForm;