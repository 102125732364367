import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./jobs.css";
import { useSelector, useDispatch } from "react-redux";
import { fetchJobs } from "../redux/actions/Actions";
import { Link } from "react-router-dom";

import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';

import LocationOnIcon from '@mui/icons-material/LocationOn';


const JobsPage = () => {

const [showResponsibilities, setShowResponsibilities] = useState(false);
const [showSidebar, setShowSidebar] = useState(false);
const [selectedJob, setSelectedJob] = useState(null);
const [showAllJobs, setShowAllJobs] = useState(true);

const [locationSearchTerm, setLocationSearchTerm] = useState( localStorage.getItem("locationSearchTerm") || ""
);
const [searchTerm, setSearchTerm] = useState( localStorage.getItem("searchTerm") || ""
);
const [searchAlertShown, setSearchAlertShown] = useState(false);
const [locationAlertShown, setLocationAlertShown] = useState(false);


  const jobsData = useSelector((state) => state.jobs.jobsData);
  // const [searchTerm, setFilterTerm] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchJobs());
  }, [dispatch]);

  useEffect(() => {
    setShowAllJobs(!selectedJob); // Set showAllJobs based on selectedJob
  }, [selectedJob]);

  useEffect(() => {
    // Store locationSearchTerm in localStorage
    localStorage.setItem("locationSearchTerm", locationSearchTerm);
  }, [locationSearchTerm]);

  useEffect(() => {
    // Store searchTerm in localStorage
    localStorage.setItem("searchTerm", searchTerm);
  }, [searchTerm]);


  const handleCardClick = (job) => {
    setShowResponsibilities(false); 
    setSelectedJob(job);
    // setShowSidebar(true);
  };
  
  const handleCloseSidebar = () => {
    setSelectedJob(null);
    // setShowSidebar(false);

  };
  
  const closeJobDescription = () => {
    setShowResponsibilities(false);
  };

  const handleLocationFilterChange = (e) => {
    const searchLocation = e.target.value.toLowerCase();
    setLocationSearchTerm(searchLocation);

    // Log the search location to check if it's being updated correctly
    console.log("Search Location:", searchLocation);
  
  };
  
  const handleFilterChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredJobs = jobsData.filter((job) => {
    const searchTermLowerCase = searchTerm.toLowerCase();
    const locationSearchTermLowerCase = locationSearchTerm.toLowerCase();

    const matchesTitleOrCompany = (
      searchTerm === "" ||
      job.job_title.toLowerCase().includes(searchTermLowerCase) ||
      job.company_name.toLowerCase().includes(searchTermLowerCase)
    );

    const matchesLocation = (
      locationSearchTerm === "" ||
      job.location.toLowerCase().includes(locationSearchTermLowerCase)
    );

    return matchesTitleOrCompany && matchesLocation;
  });

  const handleSearchMouseEnter = () => {
    const alertBox = document.querySelector('.search-alert-box');
    // alertBox.classList.add('active');
  };
  
  const handleLocationMouseEnter = () => {
    const alertBox = document.querySelector('.location-alert-box');
    // alertBox.classList.add('active');
  };
  


  const maxDescriptionLength = 50;

  return (
    <div className="jobs-page">
      <h1 className="color-brown jobs-header">Discover your next career move</h1>
      <div className="jobs-filter-container"
      style={{ marginTop: '40px' }}
      >
                <br></br>
                <RefreshIcon
  style={{ width: "40px", height: "60px", marginRight: "20px" }}
  onClick={() => {
    // Clear stored search terms in local storage
    localStorage.removeItem("searchTerm");
    localStorage.removeItem("locationSearchTerm");
    // Refresh the page
    window.location.reload();
  }}
/>

        <div className="jobs-search-input"
        onMouseEnter={handleSearchMouseEnter}
        >
                    <div style={{ display: "flex", alignItems: "center" }}>
            <SearchIcon className="search-icon" />

          <TextField
            placeholder="Search by job title or company name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth

          />
        </div>
      </div>
      <div className="location-search-input" onMouseEnter={handleLocationMouseEnter}>

      <div style={{ display: "flex", alignItems: "center" }}>
            <LocationOnIcon 
            className="location-icon" />
                        <TextField
              className="location-textfield"
              value={locationSearchTerm}
              onChange={(e) => setLocationSearchTerm(e.target.value)}
              placeholder="Search by location"
              fullWidth
            />
          </div>
        </div>
        <br></br>
      </div>


      <div className="discription_card" style={{ marginTop: '100px' }}>

      <div className="jobs-list">
      {selectedJob ? (
            <Card className="jobs-card" style={{ width: "300px" }} key={selectedJob.id}>
              <CardMedia
                component="img"
                src={selectedJob.company_logo_url ? selectedJob.company_logo_url : selectedJob.company_name}
                className="jobs-card-media jobs-desktop-image"
                onError={(e) => console.error("Error loading image:", e)}
              />
              <CardContent className="jobs-card-content">
                <Typography id="jobs-company-bold" variant="h5" component="div">
                  {selectedJob.company_name}
                </Typography>
                <Typography id="jobs-title-bold" variant="subtitle1">
                  {selectedJob.job_title}
                </Typography>
                <Typography style={{ marginTop: "10px" }} variant="subtitle2">
                  {selectedJob.location}
                </Typography>
              </CardContent>
            </Card>
          ) : (
            filteredJobs.map((job) => (
              <Card
                className="jobs-card"
                style={{ width: "300px" }}
                key={job.id}
                onClick={() => handleCardClick(job)}
              >
                <CardMedia
                  component="img"
                  src={job.company_logo_url ? job.company_logo_url : job.company_name}
                  className="jobs-card-media jobs-desktop-image"
                  onError={(e) => console.error("Error loading image:", e)}
                />
                <CardContent className="jobs-card-content">
                  <Typography id="jobs-company-bold" variant="h5" component="div">
                    {job.company_name}
                  </Typography>
                  <Typography id="jobs-title-bold" variant="subtitle1">
                    {job.job_title}
                  </Typography>
                  <Typography style={{ marginTop: "10px" }} variant="subtitle2">
                    {job.location}
                  </Typography>
                </CardContent>
              </Card>
            ))
          )}

      </div>

      {selectedJob && (
  <div className="job-description-sidebar">
    <h2>Job Description</h2>
    <Typography variant="h6" component="div">
      {selectedJob.job_title}
    </Typography>
    <Typography>{selectedJob.job_description}
</Typography>

    <br></br>
    <h4> job Responsibilities</h4>
    <br/>
    {selectedJob.responsibilities && (
      <div>
        <Typography variant="h6">Responsibilities</Typography>
        <ul>
          {selectedJob.responsibilities.map((responsibility, index) => (
            <li key={index}>{responsibility}</li>
          ))}
        </ul>
      </div>
)}
<ul>
<li><h6>Collaboration:</h6></li>
<ul>
<li>Work collaboratively with team members to achieve common goals.</li>
<li>Communicate effectively within the team and with other departments.</li>
</ul>

<li><h6>Time Management:</h6></li>
<ul>
<li>Meet project deadlines and deliverables in a timely manner.</li>
<li>Prioritize tasks effectively to maximize productivity.</li>
</ul>

<li><h6>Problem-Solving:</h6></li>
<ul>
<li>Analyze problems and develop effective solutions.</li>
<li>Demonstrate critical thinking and decision-making skills.</li>
</ul>

<li><h6>Communication Skills:</h6></li>
<ul>
<li>Clearly communicate ideas, instructions, and updates.</li>
<li>Listen actively and respond appropriately to colleagues and clients.</li>
</ul>

<li><h6>Quality Assurance:</h6></li>
<ul>
<li>Ensure the quality of work and deliver high-standard outputs.</li>
<li>Conduct thorough reviews and testing as needed.</li>
</ul>
</ul>
<div>
    <Typography variant="body2">Job ID: {selectedJob.id}</Typography>
      <Link to={`/jobs/${selectedJob.id}`}>
      <Button   style={{ marginRight: "40px" }}
variant="contained">Apply</Button>
      </Link>
      <Button
  style={{ marginRight: "10px" }}
  // variant="outlined"
  color="primary"
  onClick={handleCloseSidebar}
  className="back-button" 

>
  Back
</Button>

     </div>
     </div>
     )}
     </div>
    </div>
  );
};

export default JobsPage;
