import React, { useEffect,useMemo, useState } from 'react'
import {DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux'
import {  fetchUnits, deleteUnitById } from '../../../redux/actions/Actions';
import { useNavigate } from 'react-router-dom';
import { Delete, Edit } from '@mui/icons-material';
import {  IconButton,  Stack ,TextField,Grid} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';

const UnitList = () => {
    const fetchUnit = useSelector((state)=> state.getUnits.units)
    const { isAuthenticated, user } = useSelector((state) => state.auth);
    const userId = user?.role_id
    const navigate = useNavigate()
    const dispatch = useDispatch()
     const[dataRows,setDataRows] = useState([]) 
    const[dataColumns,setDataColumns]=useState([])
    const[data,setData] = useState([])
    const userData =(userId===1||user?.role_name==="admin");
    // console.log("user data ",userData);

    const columns = useMemo(
      () => {const commonColumns=[
        { field: 'id', headerName: 'Id', width: 60, disableColumnMenu: true, sortable: false },
        { field: 'lesson_name', headerName: 'Lesson Name', width: 160, disableColumnMenu: true },
       { field: 'unit_name', headerName: 'Unit Name', width: 160, disableColumnMenu: true },
        { field: 'content', headerName: 'Content', width: 160, disableColumnMenu: true },
      ];
       
      const userSpecificColumns=userData
       ?[
       
       
        {
            field: 'action',
            headerName: 'Actions',
            width: 180,
            sortable: false,
            disableClickEventBubbling: true,
            
            renderCell: (params) => {
                 const onClick = (e) => {
                  const currentRow = params.row;
                  navigate(`/auth/updateUnit/${currentRow.unit_id}`)
                };  
                 const handleDelete = (e) =>{
                    const currentRow = params.row;
                    dispatch(deleteUnitById(currentRow.unit_id))
                    console.log('clicked unit is', currentRow.unit_id)
                }  
                
                return (
                  <Stack direction="row" spacing={2}>
                     <IconButton onClick={onClick}>
                        <Edit />
                    </IconButton> 
                     <IconButton onClick={()=>handleDelete()}>
                        <Delete />
                    </IconButton> 
                  </Stack>
                );
            },
          },
        ]: [];
        return[...commonColumns, ...userSpecificColumns];
      },[userId,data,dispatch,navigate]);

      const rows = useMemo(
        () => data?.map((row, index) => {
        return {
          ...row,
          id: index + 1,
          lesson_id: row.lesson_id,
          unit_name: row.unit_name,
          content: row.content,
        }
      }),[data]);

  const [searchValue, setSearchValue] = useState("");
  
   const filteredRows = rows.filter((row) =>
    Object.values(row).some((value) =>
    value !== null &&
    value !== undefined &&
    value.toString().toLowerCase().includes(searchValue.toLowerCase())
    )
  );

  useEffect(() => {
    dispatch(fetchUnits())
  }, [ dispatch,fetchUnit]);

  //console.log('units', fetchUnits)

  useEffect(()=>{
    setData(fetchUnit)
     setDataColumns(columns) 
    setDataRows(rows) 
    
}, [data,fetchUnit])

  return (
    <div className='unit-list'>
      <Grid item xs={5}
        container justify="flex">
        <TextField
          label="Search"
          variant="outlined"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          style={{ marginTop: 0, marginBottom:15 }} />
   
      </Grid>

        {
            (
              <Box sx={{ height: 400, width: '100%' }}>
              <DataGrid 
              style={{marginTop: '15px',marginBottom: '0px'}}
              rows={filteredRows }
              columns={dataColumns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
           /> </Box>)
        }
    </div>
  )
}

export default UnitList;