import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Grid, Paper, CircularProgress, Snackbar } from '@mui/material';
import { updateCourseFiles, fetchMaterialWithID } from '../../../redux/actions/Actions';

const AddFile = () => {
  const [showMaxFilesMessage, setShowMaxFilesMessage] = useState(false);
  const [showDuplicateMessage, setShowDuplicateMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    course_id: '',
    selectedImages: [], // Array to store selected images
  });
  const { id } = useParams();
  const dispatch = useDispatch();
  const FetchMaterialById = useSelector((state) => state.fetchMaterialById.materialById);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchMaterialWithID(id));
  }, [dispatch, id]);

  const handleDeleteFile = (index) => {
    const updatedFileList = [...formData.selectedImages];
    updatedFileList.splice(index, 1);
    setFormData({ ...formData, selectedImages: updatedFileList });
  };

  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);
    setFormData((prevFormData) => ({
      ...prevFormData,
    }));
  };

  const handleAddImages = () => {
    const fileInput = document.getElementById('imageUpload');
    const newSelectedImages = Array.from(fileInput.files);
  
    if (formData.selectedImages.length + newSelectedImages.length > 10) {
      setShowMaxFilesMessage(true); // Display message
      return;
    }
  
    const existingFileNames = getFileNamesFromURLs(FetchMaterialById[0].material_file_paths);
  
    const uniqueNewSelectedImages = newSelectedImages.filter(newImage => {
      // Check if the file name already exists in the existing list
      return !existingFileNames.includes(newImage.name) &&
             !formData.selectedImages.some(existingImage => existingImage.name === newImage.name);
    });
  
    if (uniqueNewSelectedImages.length < newSelectedImages.length) {
      setShowDuplicateMessage(true);
    }
  
    fileInput.value = null;
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedImages: [...prevFormData.selectedImages, ...uniqueNewSelectedImages],
    }));
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formImageData = new FormData();
    formData.selectedImages.forEach(file => {
      formImageData.append('files', file);
    });
    // Append the course_id to the form data
    formImageData.append('course_id', FetchMaterialById[0].course_id);
    try {
      await dispatch(updateCourseFiles(id, formImageData));
      setLoading(false);
      navigate("/auth/AllMaterials");
    } catch (error) {
      setLoading(false);
      console.error('Error uploading files:', error);
    }
  };
  

  const getFileNamesFromURLs = (urls) => {
    return urls.map(url => {
      const parts = url.split('/');
      return parts[parts.length - 1];
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Container maxWidth="md" className="d-flex">
          <Grid container spacing={10} direction="column" justifyContent="center">
            <div style={{ width: "inherit" }}>
              <Paper elevation={2} sx={{ padding: 5 }} style={{ marginTop: '50px' }}>
                <h3>Add Course Materials</h3>
                <Button onClick={() => navigate(-1)}>Back</Button>
                <Grid container spacing={4} sx={{ border: '1px solid rgba(0,0,0,0.125)', zIndex: "3" }} style={{ padding: "30px" }}>
  {FetchMaterialById && FetchMaterialById.length > 0 && (
    <>
      <Grid item xs={14}>
        <label>Course Name : {FetchMaterialById[0].course_name}</label>
      </Grid>
      <Grid item xs={6}>
        <div>
          <h4>Files:</h4>
          {getFileNamesFromURLs(FetchMaterialById[0].material_file_paths).map((fileName, index) => (
            <div key={index}>{`${index + 1}. ${fileName}`}<br /></div>
          ))}
        </div>
      </Grid>
    </>
  )}
  <Grid item xs={8} style={{ display: 'flex' }}>
    Select file
    <input
      id="imageUpload"
      name='files'
      type="file"
      accept=".pdf,"
      multiple
      onChange={handleImageChange}
    />
    &nbsp;&nbsp;&nbsp;&nbsp;
    <Button onClick={handleAddImages}>Add File</Button>
  </Grid>
  <Grid item xs={12}>
    <div>
      {showMaxFilesMessage && (
        <p style={{ color: 'red' }}>Cannot select more than 10 files</p>
      )}
    </div>
    {formData.selectedImages.map((image, index) => (
      <div key={index}>
        {image && image.name ? image.name : `Image ${index + 1}`} <Button onClick={() => handleDeleteFile(index)}><img src={process.env.PUBLIC_URL + '/img/delete.png'} alt="png" style={{ height: '20px', width: '20px' }} /></Button>
      </div>
    ))}
  </Grid>
  <Grid item xs={12}>
    <Button
      variant="contained"
      disabled={loading || formData.selectedImages.length === 0}
      style={{
        backgroundColor: '#3251A3',
        borderColor: '#FF5E14',
        color: (loading || formData.selectedImages.length === 0) ? 'silver' : 'white'
      }}
      fullWidth
      type="submit"
    >
      {loading ? <CircularProgress size={24} /> : 'Upload Materials'}
    </Button>
  </Grid>
</Grid>

              </Paper>
            </div>
          </Grid>
        </Container>
      </form>
      <Snackbar
        open={showDuplicateMessage}
        autoHideDuration={6000}
        onClose={() => setShowDuplicateMessage(false)}
        message="Duplicate entry have been excluded."
      />
    </div>
  );
}

export default AddFile;

