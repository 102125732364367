import { ActionTypes } from "../constants/action-types"

const initialState = {
  eventImgStatus: [],
};

export const uploadEventImg = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'UPLOAD_EVENT_IMG':
      return {...state, 
        eventImgStatus: payload };
    default:
      return state;
  }
};