// JobsReducer.js
import { ActionTypes } from "../constants/action-types";

const initialState = {
  jobsData: [],
};

const jobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_JOBS:
      return {
        ...state,
        jobsData: action.payload,
      };

    default:
      return state;
  }
};

export default jobsReducer;
